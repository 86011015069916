.contacts-sidebar-section-emails {
  display: flex;
  flex-direction: column;
  gap: 6px;

  &--empty {
    color: #7b7b7b;
    font-size: 14px;
  }

  &__email {
    position: relative;

    display: flex;
    flex-direction: column;

    gap: 6px;

    padding: 18px 15px;
    border: 1px solid #dce1e4;
    border-radius: 8px;

    background-color: #ffffff;

    transition: box-shadow 180ms ease;

    cursor: pointer;

    &:hover {
      box-shadow: 0 7px 25px rgba(0, 0, 0, 0.1);
    }

    &-title {
      display: flex;
      align-items: center;

      max-width: 80%;

      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      color: #000000;

      svg {
        margin-right: 6px;
        flex-shrink: 0;
      }
    }

    &-text {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #7b7b7b;
    }

    &-date {
      position: absolute;
      top: 10px;
      right: 10px;

      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #bdbdbd;
    }
  }

  &__email-tooltip {
    background-color: #ffffff !important;

    box-shadow: 0 4px 39px rgba(0, 0, 0, 0.12);
  }

  &__email-preview {
    position: relative;

    display: flex;
    flex-direction: column;

    width: 353px;
    height: 462px;

    border-radius: 8px;
    background-color: #ffffff;

    color: #000000;

    &-header {
      display: flex;
      align-items: center;

      padding: 20px 20px 20px;

      border-bottom: 1px solid rgba(189, 189, 189, 0.35);

      &-avatar {
        margin-right: 14px;
      }

      &-link {
        position: absolute;
        top: 15px;
        right: 15px;

        cursor: pointer;

        &:hover {
          path {
            stroke: #221cb5;
          }
        }
      }

      &-text {
        display: flex;
        flex-direction: column;
        gap: 7px;

        width: calc(100% - 60px);
        padding-right: 14px;

        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
        color: #7b7b7b;

        span {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      &-text-email {
        max-width: 80%;

        font-weight: 600;
        font-size: 16px;
        line-height: 20px;

        color: #000000;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &-content {
      padding: 18px 32px 28px;

      font-weight: 500;
      font-size: 13px;
      line-height: 22px;

      max-height: 400px;
      overflow: auto;

      letter-spacing: -0.01em;

      color: #000000;
    }
  }
}
