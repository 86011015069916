@import "src/vendors/mixins";
@import "src/vendors/colors";

.unsubscribe-table {
  margin-top: 20px;

  &__empty {
    margin-top: 20px;
    font-size: 20px;
    color: $grey-text-color;
    text-align: center;
  }

  &__row {
    height: 50px;

    .table__td  {
      .actions-menu .react-tooltip:not(.react-tooltip--position-fixed) {
        left: -120px !important;
      }
    }
  }

  &__pattern-cell {
    color: #1B1E22;
    width: 300px;
    max-width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__reason-cell {
    color: #1B1E22;
    padding-left: 15px;
    position: relative;

    &:after {
      position: absolute;
      content: '';
      display: inline-block;
      height: 8px;
      width: 8px;
      border-radius: 100%;
      left: 0;
      top: calc(50% - 4px);
    }

    &--manual {
      &:after {
        background-color: #2AA4CB;
      }
    }

    &--automatic {
      &:after {
        background-color: #221CB6;
      }
    }
  }

  &__created-by {
    margin-left: 5px;
    color: $grey-text-color;
  }

  &__time-cell {
    line-height: 18px;
    color: $grey-text-color;
  }

  &__scope {
    line-height: 18px;
    color: $grey-text-color;

    &-item {
      display: flex;
      align-items: center;
      gap: 6px;
    }
  }
}
