.content-sidebar-crm__content-personal-info {
  .inbox-content-wrapper {
    &__content {
      &-title {
        display: flex;
        align-items: center;
        margin-bottom: 6px;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: #000000;
        padding-right: 18px;
        position: relative;

        span {
          width: fit-content;
        }
      }
    }
  }
}
