@import "src/vendors/colors";

.slide-toggler {
  position: relative;
  width: 32px;
  height: 20px;
  background-color: #DBE2FE;
  display: inline-block;
  border-radius: 31px;
  cursor: pointer;

  &__pointer {
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: 3px;
    display: inline-block;
    background: #AAB3DA;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.06);
    width: 14px;
    height: 14px;
    border-radius: 100%;
    transition: 0.5s;
  }

  &--active {
    background-color: #3424EB;
    
    .slide-toggler__pointer {
      transition: 0.5s;
      left: 15px;
    }
  }
  
  &--disabled {
    background-color: #F5F5F5;
    cursor: not-allowed;

    .slide-toggler__pointer {
      background-color: $grey-icons-color;
    }
  }
}