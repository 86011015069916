@import 'src/vendors/mixins';
@import 'src/vendors/colors';


.ai-agents-table {
  width: 100%;

  .table__thead {
    position: sticky;
    top: 68px;
    background: white;
    z-index: 8;

    .table__tr {
      border-bottom: none;

      .table__th {
        padding-top: 25px;

        #opportunity-column-title {
          background: black;

          &:after {
            display: none;
          }
        }
      }
    }
  }

  .table-tr {
    border-bottom: 2px solid #F9F9F9;

    .table-td {
      .colored-toggler {
        & + {
          .type-dark {
            &:after {
              display: none;
            }
          }
        }
      }
      .total-cell,
      .launched-cell,
      .scheduled-cell,
      .contacted-cell,
      .opened-cell,
      .replied-cell,
      .created_on-cell {
        color: #393939;

        .subtitle-cell {
          color: #928e8d;
          font-size: 12px;
          display: block;
        }
      }

      .opened-cell,
      .replied-cell {
        display: flex;
        align-items: center;
        grid-column-gap: 6px;

        & > div {
          display: flex;
          flex-direction: column;
        }
      }

      .created_on-cell {
        p {
          margin-top: 3px;
          color: #928E8D;
          font-size: 12px;
        }
      }
    }
  }

  &-header {
    display: flex;
    align-items: center;
    gap: 13px;
  }

  @include table-row;

  .title-cell {
    display: flex;
    align-items: center;
    gap: 13px;


    &__body {
      grid-row-gap: 4px;
      display: flex;
      flex-direction: column;
    }

    &__title {
      @include mulish-text;
      font-weight: 500;
      font-size: 14px;
      color: #1b1e22;

      max-width: 600px;

      /*
        To make big titles overflow - ellipsis
       */
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      /*
        Or just line - height
       */
      line-height: 21px;
    }

    &__folder {
      @include mulish-text;
      font-size: 12px;
      line-height: 130%;
      color: #928e8d;
    }
  }

  .status-cell {
    &__header {
      cursor: pointer;
      svg {
        width: 8px;
        margin-left: 4px;
        transform: rotate(180deg);
        & > path {
          fill: $grey-text-color;
        }
      }

      &--active {
        color: #000000;
      }
    }

    &__status {
      @include mulish-text;
      font-size: 12px;
      line-height: 16px;
      color: #4b4c4d;

      margin-bottom: 7px;
    }

    &__filters {
      padding: 6px 14px;

      &-tooltip {
        background-color: #000000;
      }

      &-row {
        display: flex;
        align-items: center;
        padding: 4px 6px;
        border-radius: 8px;
        margin: 2px 0;

        cursor: pointer;

        &:hover,
        &--active {
          background-color: #282828;
        }

        & > span {
          flex-shrink: 0;
          width: 8px;
          height: 8px;
          background-color: $grey-text-color;
          border-radius: 50%;
          margin-right: 14px;
        }
      }

      &-green {
        background-color: $secondary-green-color !important;
      }
      &-orange {
        background-color: $secondary-orange-color !important;
      }
      &-purple {
        background-color: $secondary-purple-color !important;
      }
    }
  }

  .delivered-cell {
    @include mulish-text;
    font-size: 14px;
    line-height: 130%;
    color: $grey-text-color;
  }

  .shares-cell {
    @include mulish-text;
    font-size: 10px;
  }
}
