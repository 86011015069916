.campaign-templates-list {
  flex: 1;
  display: flex;
  flex-direction: column;

  overflow: auto;

  .page-header__title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__container {
    display: flex;
    overflow: hidden;
    height: 100vh;
  }

  &__sidebar {
    display: flex;
    flex-direction: column;

    height: 100vh;
    background-color: #fbfbfb;

    .configurable-sidebar {
      height: auto;
    }

    &-header {
      padding: 17px 14px 8px;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #7b7b7b;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 12px;

    .input input {
      width: 240px;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(auto-fill, 279px);

    width: 100%;
    height: 100%;

    gap: 18px;

    padding: 16px 20px;

    overflow: auto;

    @media (min-width: 1260px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (min-width: 1480px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media (min-width: 1830px) {
      grid-template-columns: repeat(5, 1fr);
    }
  }

  &__advanced {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    //padding: 22px 32px;

    height: 279px;
    min-width: 261px;

    border: 1px dashed #251fb6;
    border-radius: 8px;

    cursor: pointer;

    &--preview {
      background-color: rgba(37, 31, 182, 0.04);
      svg path {
        fill: #221cb6;
      }
    }

    &--preview &-text {
      color: #251fb6;
    }

    &:not(&--disabled):hover {
      background-color: rgba(37, 31, 182, 0.04);

      svg path {
        fill: #221cb6;
      }
    }

    &:not(&--disabled):hover &-text {
      color: #251fb6;
    }

    &--disabled {
      cursor: default;
      pointer-events: none;

      background-color: #ffffff;

      div {
        color: #251fb6;
      }
    }

    &-text {
      margin-top: 15px;

      font-weight: 400;
      font-size: 20px;
      line-height: 25px;
      text-align: center;

      color: #8d8f92;
    }
  }

  &__preview {
    flex: 1;
    display: flex;
    flex-direction: column;

    height: 279px;
    min-width: 261px;

    background-color: #ffffff;
    box-shadow: 0 4px 12px rgba(201, 201, 201, 0.25);
    border-radius: 8px;

    transition: box-shadow 200ms ease;

    overflow: hidden;
    cursor: pointer;

    &:hover {
      box-shadow: 0 7px 25px rgba(0, 0, 0, 0.1);
    }

    &-image {
      height: 153px;

      background-color: #251fb6;

      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
        padding-top: 8px;
        margin-top: 1px;
      }
    }

    &-info-container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      padding: 18px 21px 19px;
      background-color: #ffffff;
    }

    &-info {
      &-title {
        margin-bottom: 5px;

        font-weight: 400;
        font-size: 18px;
        line-height: 18px;
        color: #000000;
      }
      &-subtitle {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: rgba(0, 0, 0, 0.54);
        margin-bottom: auto;
      }
      &-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &-tag {
        display: flex;
        align-items: center;

        padding: 5px 8px 5px 7px;
        background-color: #e9e9e9;
        border-radius: 5px;

        font-weight: 700;
        font-size: 10px;
        line-height: 10px;
        color: #4b4c4d;

        &-dot {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          margin-left: 7px;

          background-color: #23e771;

          &--red {
            background-color: #ed483d;
          }
          &--orange {
            background-color: #ffc300;
          }
        }
      }

      &-fav-icon {
        cursor: pointer;

        &--filled svg path {
          fill: #221cb6 !important;
        }

        &--disabled {
          pointer-events: none;
        }

        svg {
          path {
            fill: #d1d1d1;
          }
          &:hover {
            path {
              fill: #221cb6;
            }
          }
        }
      }
    }
  }
}
