@import "src/vendors/mixins";

.steps {
  &__title {
    @include mulish-text;
    font-size: 12px;
    color: #4B4C4D;
    margin-bottom: 7px;
  }

  &__items {
    display: flex;
  }

  &__step-item {
    &:not(&:last-child) {
      margin-right: 3px;
    }

    width: 13px;
    height: 3px;
    background: #EAEDF2;
    border-radius: 21px;

    &--active {
      background: #251FB6;
    }
  }
}