@import 'src/vendors/colors';
@import 'src/vendors/mixins';

.help-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 59px;
  width: 59px;
  border-radius: 10px;
  border: 0 solid rgba(224, 224, 224, 0.37);
  color: $primary-main-color;
  font-size: 12px;
  position: relative;
  cursor: pointer;
  background-color: #dbe2fe;
  padding: 0 15px;

  &:hover {
    background-color: $primary-main-color;

    .help-button__text {
      color: #ffffff;
    }

    svg {
      filter: brightness(1000) saturate(100%) invert(0%) sepia(100%);
    }
  }

  & > svg {
    height: 18px;
    width: 18px;
  }

  &__text {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: $primary-main-color;
    margin-top: 5px;
  }

  &--open {
    &:hover {
      background-color: #dbe2fe;
      color: $primary-main-color;

      svg {
        filter: none;
      }
    }
  }

  &:not(&--open) {
    flex-direction: column;
  }

  &__tooltip {
    max-width: 235px;

    &::after {
      top: 85% !important;
    }
  }

  &__option {
    display: flex;
    flex-direction: column;
    padding: 18px 18px 20px;

    &:first-of-type {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    &:last-of-type {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #494949;
    }

    &:hover {
      background-color: #1b1b1b;
    }

    cursor: pointer;

    &-icon {
      margin-right: 9px;
    }

    &-title {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
    }

    &-icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }

    &-subtitle {
      margin-top: 8px;
      padding-left: 28px;

      font-weight: 400;
      font-size: 13px;
      line-height: 14px;
      color: rgba(255, 255, 255, 0.56);
    }
  }
}
