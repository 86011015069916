.toolbox-email-verification-status {
  display: flex;
  align-items: center;
  flex-shrink: 0;

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 15px;
  }

  &__title {
    margin-bottom: 2px;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    color: #000000;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #7b7b7b;
  }
}

.toolbox-email-verification-status-text {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex-grow: 0;

  padding: 10px;
  border-radius: 8px;
  background-color: #251fb6;

  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.5px;

  color: #f8f9fc;

  &--valid {
    background-color: #23e771;
  }

  &--catch-all {
    background-color: #fc8619;
  }

  &--invalid {
    background-color: #ed483d;
  }
}
