@import 'src/vendors/colors';
@import 'src/vendors/mixins';

.personalization-editor {
  position: relative;
  //max-width: 790px;
  box-sizing: border-box;
  padding-top: 26px;
  padding-left: 26px;
  padding-right: 26px;

  &__container {
    border-radius: 8px;
    box-shadow: 0px 4px 17px 0px rgba(18, 16, 68, 0.10);
  }

  .slate-editor__controls {

    &-separator {
      margin: 0 10px;
    }

    .button--inline {
      width: 22px;
      height: 22px;

      &:hover {
        border-radius: 5px;
        background: #E9EEFF;
      }
    }
  }

  &__title {
    @include mulish-text;
    font-size: 16px;
    margin-bottom: 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .autosave-indicator {
      margin: 0 8px;
    }

    .moderate-chance {
      padding: 10px 15px;
    }
  }

  &__title-description {
    color: $grey-text-color;
    font-size: 14px;
    margin-left: 8px;
  }

  &__header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #FBFCFD;
  }

  .editor-email-meta {
    background: #FBFCFD;
  }

  &__addressee {
    padding: 25px 28px;
    border-bottom: 1px solid #E9ECF1;

    .addressee-from {
      margin-bottom: 6px;
    }

    .addressee-from,
    .addressee-to,
    .addressee {
      display: flex;
      align-items: center;
    }

    .addressee-list {
      display: flex;
      gap: 6px;
      margin-left: 6px;

      span {
        color: #000;
        font-size: 14px;
        font-weight: 400;
        background-color: #EAEDF2;
        padding: 4px 6px;
        display: flex;
        border-radius: 5px;
      }
    }

    .addressee {
      gap: 6px;
      margin-top: 16px;

      span {
        color: #221CB6;
        font-size: 12px;
        font-weight: 400;
      }
    }

    span {
      color: #8F9199;
      font-size: 14px;
    }
  }

  &__editor {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    .slate-editor__editor {
      max-height: calc(100vh - 478px);
      overflow-y: auto;
      height: auto;

      .editable-footer {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        flex-grow: 1;

        .slate-editor__signature-placeholder {
          border-radius: 8px;
          border: 1px solid #E9ECF1;
          background: #FFF;
          box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.04);
        }
      }
    }

  }

  &__header {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $secondary-grey-lines-color;

    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
    color: #202430;

    ::-webkit-scrollbar {
      display: none;
    }

    .slate-editor {
      display: flex;
      min-height: 64px;
    }

    .slate-editor__editor--inline {
      height: auto;
    }
  }

  &__autosave-label {
    @include mulish-text;
    color: $grey-text-secondary;
    font-size: 14px;
    margin-left: auto;
    height: 30px;
  }

  /* Overload styles */
  .RichEditor-editor {
    height: 250px;
  }
}
