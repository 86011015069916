@import "src/vendors/colors";
@import "src/vendors/mixins";

.email-settings {
  &__header {
    @include mulish-text;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    height: 85px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #F4F4F4;

    padding-left: 20px;
  }

  &__header-right {
    margin-left: auto;
    display: flex;
  }

  &__button-text {
    margin-left: 10px;
  }
}