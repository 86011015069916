.accordion-content {
  background-color: #fff;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);

  &.active {
    overflow: initial;
    max-height: 9999px;
    transition: max-height 0.5s ease-in-out;
  }

  &-body {
    &-list {
      list-style-type: none;
      padding-left: 0;

      &__item {
        display: flex;
        align-items: center;

        &.flex-column {
          flex-direction: column;
          align-items: flex-start;
        }

        &:not(:last-child) {
          margin-bottom: 15px;
        }

        .select {
          min-width: 160px;
          max-width: 550px;
          font-size: 16px;
        }

        .input {
          margin: 0 10px;

           &__field {
             width: fit-content;
             padding: 10px;
             max-width: 57px;
             color: #202430;
           }
        }

        .sub-list {
          border-radius: 9px;
          border: 1px solid rgba(99, 99, 99, 0.21);
          padding: 10px 20px;
          padding-left: 35px;
          margin-top: 17px;
          width: 100%;
          font-size: 13px;

          &__item {
            line-height: 23px;
          }
        }

        &-info {
          border-radius: 5px;
          background-color: #E9E9E9;
          padding: 5px;
          height: 21px;
          display: flex;
          align-items: center;
          margin-left: 7px;
          margin-top: 2px;

          span {
            margin-left: 5px;
            display: inline-block;
            font-size: 10px;
            color: #4B4C4D;
          }
        }
      }
    }
  }
}
