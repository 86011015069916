@import 'src/vendors/mixins';
@import 'src/vendors/colors';

.email-accounts-card {
  height: 312px;
  box-shadow: 0 4px 90px rgba(201, 201, 201, 0.25);
  border-radius: 8px;

  &__header {
    @include card-header;
  }

  &__change-link {
    text-decoration: none;
    color: #3424eb;
    font-size: 16px;
    line-height: 20px;

  }

  &__body {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 45px;
  }

  &__chart-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 78px;
    height: 78px;
  }

  &__chart-icon {
    position: absolute;
  }

  &__info {
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 70px;
  }

  &__info-title {
    @include mulish-text;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 5px;
    line-height: 1.5rem;
  }

  &__current-accounts {
    @include mulish-text;
    font-weight: 600;
    font-size: 24px;
    color: $secondary-blue-color;

    &--empty {
      color: $alert-color;
    }
  }

  &__total-accounts {
    @include mulish-text;
    font-size: 12px;
    color: $grey-text-color;
  }

  &__info-amount {
    @include mulish-text;
    font-weight: 600;
    font-size: 24px;
    color: $secondary-blue-color;
  }
}
