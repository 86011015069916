@import 'src/vendors/mixins';

.scroll-groups {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: auto;
  min-height: 50px;

  &--without-scrolling {
    //padding-left: 20px;
  }

  &--with-bottom-border {
    border-bottom: 1px solid $secondary-grey-lines-color;
  }

  &__children {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    margin-left: -30px;
    padding-left: 20px;
    padding-right: 20px;

    &--no-scroll {
      width: 100%;
      margin-left: unset;
    }
  }

  &__arrow {
    flex-shrink: 0;
    will-change: transform;
    position: fixed;
    transition: opacity linear 0.2s, z-index linear 0.2s;

    display: flex;
    align-items: center;
    padding: 0 3px;

    &-container {
      flex-shrink: 0;
      border-radius: 50%;
      background-color: #ffffff;
      box-shadow: 0 4px 4px 0 #00000012;
      z-index: 5;

      height: 30px;
      width: 30px;
      padding: 0 10px;

      display: flex;
      justify-content: center;
      align-items: center;

      cursor: pointer;
    }

    &--right {
      position: sticky;
      right: 0;
      top: 0;

      //background: linear-gradient(90deg, transparent, white 60%);
    }

    &--left {
      z-index: 100;
      opacity: 0;
      position: sticky;
      left: 0;

      //background: linear-gradient(90deg, white 60%, transparent);

      svg {
        transform: rotateY(180deg);
      }
    }
  }

  /*
   Scrollbar
   */
  &::-webkit-scrollbar {
    width: 1px;
    height: 1px;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 0px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 0px;
    box-shadow: inset 0px 0px 0px 0px transparent;
  }
}
