@import "src/vendors/mixins";
@import "src/vendors/colors";

@keyframes slideinbar {
  0% {
    left: 0;
  }
  50% {
    left: 80%;
  }
  100% {
    left: 0;
  }
}

.progress-bar {
  &__title {
    @include mulish-text;
    font-size: 16px;
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
  }

  &__percents {
    color: $grey-black-color;
  }

  &__bar-wrapper {
    margin-top: 12px;
    position: relative;
    height: 5px;
    background: $secondary-grey-lines-color;
    border-radius: 8px;
    overflow: hidden;
  }

  &__bar {
    height: 5px;
    border-radius: 8px;
    background-color: $primary-main-color;
    transition: width linear .2s;

    &--zero-value {
      width: 20%;
      position: absolute;
      left: 0;
      top: 0;
      animation: slidein 2s;
      -webkit-animation: linear infinite alternate;
      -webkit-animation-name: slideinbar;
      -webkit-animation-duration: 3s;
    }
  }
}