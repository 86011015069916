.outbox-mail-thread {
  flex: 1;
  background-color: #fbfbfb;

  height: 100vh;
  overflow-y: scroll;

  &__header {
    padding: 0 20px;
    font-weight: bold;
    font-size: 20px;
    line-height: 100%;
    margin: 20px 0 30px 0;
  }

  &__empty-message {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding-bottom: 40px;
    font-style: normal;
    font-weight: normal;
    line-height: 100%;
    text-align: center;
    font-size: 18px;
    color: #7b7b7b;
  }

  &__mails {
    padding: 0 20px 20px;
  }

  &__edit-btn {
    margin-top: 12px;
    padding: 0;

    font-size: 14px;
    color: #7b7b7b;

    cursor: pointer;
  }

  &__editor {
    height: auto;
    padding: 0;
  }

  &__editor-controls {
    height: auto;
    padding: 16px 0 0;
  }
}
