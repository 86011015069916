.integration-status {
  display: flex;
  align-items: center;

  &__icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 14px;
    width: 14px;
    margin-right: 12px;
  }

  &__text {
    line-height: 18px;
    flex-shrink: 0;
  }
}
