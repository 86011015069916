@import 'src/vendors/mixins';
@import 'src/vendors/colors';

.content-autosearch {
  &__steps {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    //gap: 21px;

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 2px;
      background-color: #e9ecf1;
      z-index: -1;
    }
  }

  &__step {
    @include mulish-text;
    display: flex;
    align-items: center;
    padding-bottom: 14px;
    padding-right: 19px;
    cursor: pointer;

    & + svg {
      transform: rotate(90deg);
      margin-right: 21px;
      margin-top: -14px;
    }

    &:hover > .content-autosearch__step-title {
      color: $primary-main-color;
    }

    &:hover > .content-autosearch__step-index {
      background-color: $light-main-color;
    }

    &--active {
      border-bottom: 2px solid $primary-main-color;

      .content-autosearch__step-index {
        color: $primary-main-color;
        background-color: #eef3ff;
        border: 1px solid #e0e6f5;
      }

      .content-autosearch__step-title {
        color: $primary-main-color;
      }
    }

    &--disabled {
      opacity: 0.6;
      cursor: not-allowed;

      &:hover > .content-autosearch__step-title {
        color: $grey-black-color;
      }

      &:hover > .content-autosearch__step-index {
        background-color: $primary-grey-action-color;
      }
    }
  }

  &__step-index {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    width: 24px;
    height: 24px;

    margin-right: 12px;
    color: $grey-black-color;
    background-color: #f5f5f5;
    border: 1px solid #e8e8e8;
    border-radius: 50%;

    font-size: 10px;
    font-weight: 700;
  }

  &__step-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: $grey-black-color;
  }
}
