.advanced-settings-wrapper {
  margin-top: 30px;

  &__header {
    display: flex;
    align-items: center;

    height: auto;
    padding: 20px 22px 20px;

    background-color: #F5F5F5;
  }

  &__content {
    padding: 8px 24px 20px;
    background-color: #F5F5F5;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}
