@import "src/vendors/mixins";
@import "src/vendors/colors";

.billing-form-field {
  margin-bottom: 20px;

  &__label {
    font-weight: 600;
    font-size: 14px;
    color: $grey-text-secondary;
    margin-bottom: 10px;
  }
}