@import "../../vendors/colors";
@import "../../vendors/mixins";

.moderate-chance {
  min-width: 31px;
  width: fit-content;
  height: 31px;
  border: 1px solid #DBDFE7;
  box-sizing: border-box;
  border-radius: 8.5px;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 5px;
  flex-shrink: 0;
  padding: 10px 15px;

  p {
    color: #8F9199;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-left: 8px;
    white-space: nowrap;

    &.ml-0 {
      margin-left: 0;
    }
  }

  &:hover {
    cursor: pointer;
    border: 1px solid #221CB6;

    .editor-emoji-button {
      background: transparent;
    }
  }

  &__level {
    width: 11px;
    height: 11px;
    border-radius: 8.5px;

    &--green {
      background-color: $success-color;
    }

    &--yellow {
      background-color: $warning-bg-color;
    }

    &--red {
      background-color: $alert-color;
    }

    &--grey {
      background-color: $grey-black-color;
    }
  }
}

.moderate-chance-tooltip-content {
  padding: 24px;
  width: 270px;

  &__title {
    @include mulish-text;
    font-weight: 500;
    font-size: 20px;
    color: #fff;
  }

  &__subtitle {
    @include mulish-text;
    font-size: 12px;
    color: #7B7B7B;
    margin-bottom: 24px;
  }

  .level-bar-wrapper {
    margin-bottom: 16px;

    &__label {
      display: flex;
      justify-content: space-between;
      @include mulish-text;
      font-weight: 600;
      font-size: 14px;
      color: $grey-black-color;
    }

    &__value {
      text-align: right;
      color: #FFFFFF;
    }
  }
}

#moderate-chance-tooltip {
  &.show {
    opacity: 1 !important;
    pointer-events: auto !important;
  }
  &:after {
    display: none !important;
  }
}
