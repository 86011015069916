@import 'src/vendors/mixins';
@import 'src/vendors/colors';

.templates-table {
  margin-top: 12px;
  margin-right: 72px;

  color: #8d8f92;

  @include table-row;
  &__row {
    border-bottom: 1px solid #f4f4f4;

    .table-td {
      .actions-menu .react-tooltip:not(.react-tooltip--position-fixed) {
        left: -120px !important;
      }
    }
  }

  .title-cell__title {
    font-weight: bold;
    color: #1b1e22;
  }

  .title-cell__subtitle {
    margin-top: 10px;
  }

  &__template-warning-triangle {
    padding: 10px;
  }
}
