@import 'src/vendors/mixins';

$search-contacts-field-height: 48px;

.contacts-search-field {
  margin-top: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr 48px;
  position: relative;

  &--two-columns {
    grid-template-columns: 1fr 48px;
  }

  .input {
    &__field {
      min-height: $search-contacts-field-height;
      height: 100%;
    }

    &:nth-child(1) {
      .input__field {
        border-radius: 8.5px 0 0 8.5px;
      }
    }

    &:nth-child(2) {
      .input__field {
        border-radius: 0;
      }
    }
  }

  &__search-btn {
    /* overlap styles */
    .button {
      height: 100%;
      width: 48px;
      min-width: 48px;
      border-radius: 0 8.5px 8.5px 0;
      padding: 0;
    }

    svg {
      @include svg-colors-path-fill(#ffffff);
      margin-right: 0;
    }
  }
}
