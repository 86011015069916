@import 'src/vendors/colors';

.smpt-email-settings {
  padding-bottom: 40px;

  &__sidebar .right-sidebar {
    width: 720px;
  }

  &__close {
    cursor: pointer;
    height: 20px;
  }

  &__content {
    max-width: 730px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 50px auto 0 auto;
    padding: 0 25px;
  }

  &__main-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    margin-top: 50px;
    margin-bottom: 30px;
  }

  &__field-row {
    margin-bottom: 25px;
    position: relative;
    max-width: 315px;

    &--doubled {
      display: flex;
    }
  }

  &__left-field {
    width: 70%;

    .input__field {
      border-radius: 8.5px 0 0 8.5px;
    }
  }

  &__right-field {
    width: 30%;

    .input__field {
      border-radius: 0 8.5px 8.5px 0;
    }
  }

  &__field-label {
    font-weight: 600;
    color: $grey-text-secondary;
    margin-bottom: 10px;
  }

  &__footer {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__footer-right {
    display: flex;
    align-items: center;
  }

  &__test-btn {
    margin-right: 10px;
  }

  .button {
    width: auto;
  }
}
