.from-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin-top: 15px;

  &__label {
    display: flex;
    flex: auto;
    align-items: center;
    color: #1B1E22;
    font-weight: bold;
    width: 100%;
    font-size: 16px;
  }

  &__single-input {
    display: flex;
    flex: 1;

    .input {
      width: 100%;

      .input__field {
        width: auto;
        height: auto;
        padding: 15px;
      }
    }
  }
}
