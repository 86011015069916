.how-to-use-button {
  display: flex;
  align-items: center;
  flex-shrink: 0;

  text-decoration: none;

  &__text {
    margin-left: 5px;
    font-weight: 600;
    font-size: 11px;
    line-height: 14px;

    color: #c6c6c6;
  }

  &:hover &__text,
  &:hover svg path {
    color: #251fb6;
    fill: #251fb6;
  }
}
