.search-types-tab {
  display: flex;

  &--blocked {
    opacity: 0.4;
    pointer-events: none;
  }

  &__item {
    height: 83px;
    flex-grow: 1;

    padding: 24px;

    border-radius: 15px;
    background-color: #F5F5F5;
    border: 1px solid rgba(99, 99, 99, 0.21);

    cursor: pointer;

    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 24px;
    }

    &:hover {
      background: #EEF3FF;
      border: 1px solid #221DB6;
      font-weight: 600;

      .search-types-tab__item-text {
        color: #221DB6;
      }
    }
  }

  &__item-text {
    margin-left: 20px;
  }
}
