@import 'src/vendors/colors';

.analytic-sequence-editor {
  height: 570px;
  padding-right: 100px;
  border-left: 1px solid $secondary-grey-lines-color;

  &__header {
    box-sizing: border-box;
    height: 64px;
    margin-right: -80px;
    padding: 0 90px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $secondary-grey-lines-color;
    position: relative;
  }

  &__autosave-indicator {
    position: absolute;
    top: -40px;
    right: 4px;
  }

  &__footer {
    margin-right: -80px;
    padding-right: 90px;
  }
}
