.onboarding-welcome {
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    position: relative;
  }

  &__video {
    border-radius: 15px;
    overflow: hidden;
  }

  &__continue-btn {
    margin-top: 30px;
    width: 400px;
  }
}