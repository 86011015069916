.select-wrapper {
  display: flex;
  align-items: center;

  &__svg {
    margin-right: 10px;
  }

  &__select {
    flex: 1;
  }
}