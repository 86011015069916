@import '../../../../../../vendors/colors';
@import '../../../../../../vendors/mixins';

.map-attribute-step {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  position: relative;

  &__fields {
    //overflow-y: auto;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__column {
    width: 45%;
  }

  &__label {
    @include mulish-text;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: $grey-text-secondary;
    margin-bottom: 6px;
  }

  &__field {
    display: flex;
    align-items: center;
    font-size: 16px;

    &--pseudo {
      @include mulish-text;
      color: $grey-text-secondary;
      font-size: 16px;
      background: #ffffff;
      border: 1px solid #dbdfe7;
      border-radius: 8.5px;
      height: 48px;
      padding: 0 19px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    > div:first-child {
      flex-grow: 1;
    }

    svg {
      margin-left: 1rem;
    }

    .input__icon {
      svg {
        margin-left: 0;
      }
    }

    .editor-variables-list {
      width: 100%;
    }
  }

  &__field-reset:hover {
    cursor: pointer;
  }

  &__footer {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    bottom: 0;
    background-color: #FFFFFF;

    button {
      padding: 0;
    }
  }
}
