.subscription-type-bar-container {
  display: grid;
  grid-template-columns: 220px 220px 220px;
  grid-gap: 20px;
  margin-bottom: 28px;

  * {
    transition: none;
  }
}

.subscription-type-bar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 305px;

  padding: 29px 15px 28px;
  background-color: #ffffff;
  color: #3a3b3f;
  border: 1px solid #dbdfe7;
  border-radius: 8px;

  cursor: pointer;

  &:hover {
    box-shadow: 0 2px 20px #cacdd4;
  }

  &__title {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    &__features {
      &:hover {
        text-decoration: underline;
      }
    }

    &__features-all {
      padding-top: 6px;
      font-size: 11px;
      line-height: 11px;
      font-weight: 600;
      color: #8F9199;
    }

    &__features_description {
      display: flex;
      flex-direction: column;
      width: 198px;
      margin: 20px;

      &__item {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 40px;

        svg {
          margin-right: 10px;
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 21px;
    padding-top: 20px;

    &-info {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
    }
  }

  //svg path {
  //  fill: #c4c6cd;
  //}

  &--active {
    background-color: #251fb6;
    border-color: #251fb6;
    color: #ffffff;

    .subscription-form__param-unlimited-field,
    .subscription-form__param-enterprise-field,
    .subscription-form__param-title,
    .subscription-form__param-label {
      color: #ffffff;
    }

    .subscription-form__param-unlimited-field,
    .subscription-form__param-enterprise-field,
    .poor-number-input input {
      background-color: #251fb6;
      border: 1px solid rgba(255, 255, 255, 0.28);
      color: #ffffff;

      svg path {
        fill: #ffffff;
      }
    }
    .poor-number-input {
      &__decrease-btn,
      &__increase-btn {
        &:hover {
          color: #ffffff;
        }
      }
    }
    .subscription-type-bar__title__features-all {
      color: #FFFFFF;
    }
  }

  &__checkbox {
    width: fit-content;

    font-weight: 400;
    font-size: 20px;
    line-height: 20px;

    cursor: pointer;
  }

  .react-tooltip {
    min-width: 210px;
  }
}
