@import 'src/vendors/mixins';
@import 'src/vendors/colors';

.component-error {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 24px auto;

  &__icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    background-color: rgba(252, 14, 14, 0.1);
    border-radius: 8px;
  }

  &__title {
    text-align: center;
    @include mulish-text;
    font-weight: 500;
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  &__subtitle {
    @include mulish-text;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 30px;
  }

  &__button {
  }
}
