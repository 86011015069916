.contacts-sidebar-section-variables {
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 8px;

  &--empty {
    color: #7b7b7b;
    font-size: 14px;
  }

  .rdrCalendarWrapper {
    border-radius: 9px;

    .rdrDayToday {

      &.rdrDayActive {
        .rdrSelected {
          color: #FFFFFF;
        }
      }

      .rdrDayNumber {
        .disabled {
          color: #BDBDBD;
        }
      }
    }

    .rdrMonthPicker,
    .rdrYearPicker {
      select {
        color: #ffffff;
      }
    }

    .rdrDayDisabled {
      background-color: #BDBDBD;
    }

    .rdrNextPrevButton {
      i {
        border-right-color: #ffffff;
      }
    }

    .rdrNextButton {
      i {
        border-right-color: transparent;
        border-left-color: #ffffff;
      }
    }

    span {
      color: #ffffff;

      &.rdrSelected {
        color:  rgb(137 126 255) !important;
      }
    }
  }

  &-variable-info {
    position: relative;

    display: flex;
    flex-direction: column;

    padding: 0;
    gap: 10px;

    &-label {
      margin-bottom: 3px;

      font-weight: 400;
      font-size: 14px;
      line-height: 22px;

      color: #7b7b7b;

      &:not(:first-child) {
        margin-top: 11px;
      }

      &.w-100 {
        width: 100%;
      }

    }

    &-select {
      width: 100%;
    }

    &-data-row {
      display: flex;
      align-items: center;

      margin-bottom: 8px;

      font-weight: 500;
      font-size: 14px;
      line-height: 22px;

      color: #000000;

      &-social > div {
        margin-bottom: 0;
        width: 100%;
      }

      &-social > span,
      &-social-link > span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 435px;
      }

      &-social-link:hover {
        cursor: pointer;
        color: #221cb5;

        & > svg > path {
          fill: #221cb5;
        }
      }

      & > svg {
        flex-shrink: 0;
        margin-left: -2px;
        margin-right: 6px;
      }
    }
  }

}
