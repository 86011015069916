@import "../../../../../vendors/mixins";
@import "../../../../../vendors/colors";

.analytics-outbox-emails {
  padding: 0 20px 20px;

  .analytics-outbox-email-info {
    margin-top: 20px;
    background-color: #fff;
    padding: 15px 0;
    border-radius: 8.5px;
    min-width: 500px;

    &__header {
      padding: 15px;
      border-bottom: 1px solid $secondary-grey-lines-color;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__header-left {
      display: flex;
    }

    &__subject {
      @include mulish-text;
      display: flex;
      align-items: center;
      font-size: 16px;
      color: $grey-text-color;
      padding-left: 15px;
    }

    &__from-avatar {
      margin-right: 15px;
    }

    &__from-name {
      @include mulish-text;
      font-weight: 600;
      font-size: 16px;
      color: #202430;
      margin-right: 8px;
    }

    &__from-info {
      margin-bottom: 8px;
    }

    &__from-email,
    &__to-name,
    &__to-email {
      @include mulish-text;
      font-size: 14px;
      color: $grey-text-secondary;
    }

    &__to-name {
      margin-right: 8px;
    }

    &__auto-saving-label,
    &__date {
      @include mulish-text;
      font-size: 14px;
      color: $grey-text-secondary;
    }

    &__auto-saving-label {
      font-size: 12px;
      margin-bottom: 8px;
    }

    &__content {
      padding: 15px 0 0;
    }

    .RichEditor-editor {
      height: 200px;
    }
  }

  .slate-editor {
    &__editor {
      height: 300px;
      padding-top: 5px;
    }
  }
}