@import '../../vendors/mixins';
@import '../../vendors/colors';

.steps-links {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px;
  //height: 73px;

  background-color: #FFFFFF;
  border-bottom: 1px solid #E8E8E8;

  .step-link__container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    & + svg {
      transform: rotate(90deg);
      margin: 0 17px;
    }

    &--completed {
      .step-link__step {
        background-color: rgba(35, 231, 113, 0.19);
        border-color: rgba(35, 231, 113, 0);
        color: #ffffff;
      }

      .step-link--active .step-link__step {
        background-color: #23e771 !important;
        border-color: #23e771 !important;
      }
    }

    &--disabled {
      .step-link__step {
        background: #f5f5f5;
        border: 1px solid #e8e8e8;
        color: #7b7b7b;
      }
    }
  }

  .step-link {
    display: flex;
    align-items: center;
    gap: 7px;

    width: auto;
    position: relative;
    padding-right: 4px;

    @include mulish-text;
    text-decoration: none;
    color: $grey-black-color;

    &:hover {
      color: $grey-text-color;
    }

    &__link-title {
      font-size: 16px;
      line-height: 16px;
      white-space: nowrap;
    }

    &__step {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;

      width: 28px;
      height: 28px;
      margin-right: 7px;

      font-size: 14px;
      line-height: 17px;

      background-color: rgba(34, 28, 182, 0.08);
      border: 1px solid #221cb6;
      border-radius: 50%;

      color: #221cb6;
      font-weight: 600;

      &--completed {
        background-color: #221cb6;
        color: #ffffff;
      }
    }

    &__how-to {
      position: absolute;
      top: 38px;
      right: 0;
    }

    &--active {
      svg path {
        fill: #ffffff;
      }

      .step-link__step {
        background-color: #221cb6;
        color: #ffffff;
      }

      &:after {
        content: '';
        display: inline-block;
        position: absolute;
        background: #221cb6;
        bottom: -25px;
        left: 0;
        right: 0;
        height: 3px;
      }

      .step-link__link-title {
        color: #000;
      }
    }
  }

  &__back-button {
    background-color: #ffffff;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.06);
    color: #444444;

    margin-right: 20px;

    &:hover {
      background-color: #e9eeff;
      border: 1px solid #221cb6;
      color: #221cb6;
    }

    &.button--disabled:hover {
      background-color: #ffffff;
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.06);
      border: none;
      color: #444444;
    }

    &-close {
      background-color: transparent;
      width: 78px;
      height: 78px;
      border-radius: 0;
      border-right: 1px solid #E8E8E8;

      & > svg:not(:last-child) {
        margin-right: 0;
      }

      .__react_component_tooltip {
        left: 7px !important;
        top: 82px !important;

        &.place-right {
          margin-left: 0 !important;
          margin-top: 7px !important;

          &::after {
            left: 30px !important;
            top: 3px !important;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom-style: solid;
            border-bottom-width: 5px;
          }

          &:before {
            left: 5px;
          }
        }
      }

      &-tooltip {
        padding: 2px 6px;
      }

      &:hover {
        svg {
          path {
            stroke: #e9eeff;
          }
        }
      }
    }
  }
  &__next-button {
    margin-left: 45px;
    width: 120px;

    &--in-completed {
      background: $grey-text-color;
    }
  }
}
