@import "src/vendors/colors";
@import "src/vendors/mixins";

.emails-table {
  margin-top: 17px;

  &__tr {
    cursor: pointer;
    height: 90px;

    &:hover {
      background-color: #EEF3FF;
    }
  }

  &__th {
    text-align: left;

    &:first-child {
      padding-left: 0;
    }
  }

  &__td {
    @include mulish-text;
    font-size: 14px;
    line-height: 130%;

    &--notification:first-child {
      padding: 0 3px 0 7px;
      vertical-align: middle;
      max-width: 46px;
    }
  }

  &__email {
    @include mulish-text;
    font-weight: 600;
    font-size: 14px;
    color: #1B1E22;
  }

  &__imap-smtp {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 14px;
    color: #1B1E22;
    img {
      margin-right: 5px;
    }
  }

  &__name {
    @include mulish-text;
    font-weight: 500;
    font-size: 12px;
    margin-top: 5px;
    color: $grey-text-secondary;
  }

  &__actions-icon {
    cursor: pointer;

    &:hover {
      svg {
        @include svg-colors-path-fill(#4B4C4D);
      }
    }
  }

  .diagram-cell {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 81px;

    &__ring {
      width: 38px;
      height: 38px;
      margin-bottom: 5px;
    }

    &__label {
      @include mulish-text;
      font-size: 14px;
      line-height: 130%;
      color: $grey-text-color;
    }
  }
}