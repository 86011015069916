@import './variables';
@import './colors';

@mixin mulish-text {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;
  color: #000000;
}

@mixin right-close-icon($width, $weight, $color) {
  position: absolute;
  right: 0;
  top: 0;

  height: $width; /* this can be anything */
  width: $width; /* ...but maintain 1:1 aspect ratio */
  display: flex;
  flex-direction: column;
  justify-content: center;

  cursor: pointer;

  &::before,
  &::after {
    position: absolute;
    content: '';
    width: 100%;
    height: $weight; /* cross thickness */
    background-color: $color;
  }

  &::before {
    transition: transform 0.3s linear;
    transform: rotate(45deg);
  }

  &::after {
    transition: transform 0.3s linear;
    transform: rotate(-45deg);
  }

  &:hover {
    &::after {
      transform: rotate(45deg);
    }
    &::before {
      transform: rotate(-45deg);
    }
  }
}

@mixin respona-logo-text {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #221cb6;
  margin-left: 8px;
}

@mixin input-field {
  border: 1px solid #C1C1C1;
  box-sizing: border-box;
  border-radius: 8px;

  width: $input-width-default;
  height: $input-height-default;

  &:focus {
    border: 1px solid #221cb6;
    outline: none;
  }
}

@mixin svg-colors-path($color) {
  circle,
  rect,
  path {
    &[stroke] {
      stroke: $color;
    }

    &[fill] {
      fill: $color;
    }
  }
}

@mixin svg-colors-path-fill($color) {
  circle {
    stroke: $color;
  }
  rect,
  path {
    fill: $color;
  }
}

@mixin svg-colors-path-stroke($color) {
  circle,
  path {
    stroke: $color;
  }
  rect {
    fill: $color;
  }
}

@mixin date-range-picker-wrapper {
  max-width: 400px;
  background-color: $primary-black-color;
  border-radius: 20px;
  font-weight: 600;

  .rdrDefinedRangesWrapper {
    background-color: transparent;
    border-right-color: $secondary-black-color;
    padding-top: 1rem;
  }

  .rdrStaticRange {
    background-color: transparent;
    color: $grey-text-color;
    border: 0;
  }

  .rdrStaticRange:hover .rdrStaticRangeLabel,
  .rdrStaticRange:focus .rdrStaticRangeLabel {
    color: $light-main-color;
    background-color: transparent;
  }

  .rdrInputRanges,
  .rdrDateDisplayWrapper {
    display: none;
  }

  .rdrCalendarWrapper {
    background-color: transparent;
  }

  .rdrDayDisabled {
    background-color: $primary-black-color;
  }

  .rdrDayNumber span,
  .rdrMonthAndYearPickers select,
  .rdrWeekDay {
    color: $light-main-color;
    font-weight: 900;
  }

  .rdrMonthAndYearPickers {
    option {
      background-color: $primary-black-color;
    }
  }

  .rdrMonthPicker,
  .rdrYearPicker {
    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: $primary-main-color;
      border-radius: 30px;
      //border: $light-main-color .3px solid;
    }

    ::-webkit-scrollbar-track {
      background-color: $primary-black-color;
    }

    // For Mozila
    scrollbar-width: thin;
    scrollbar-color: $primary-main-color $primary-black-color;

    // For IE
    scrollbar-face-color: $primary-main-color;
    scrollbar-track-color: $primary-black-color;
  }

  .rdrDayPassive,
  .rdrDayDisabled {
    .rdrDayNumber span {
      color: $grey-black-color;
    }
  }

  .rdrEndEdge,
  .rdrDayEndPreview {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .rdrStartEdge,
  .rdrDayStartPreview {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .rdrMonth {
    width: 22.667em;
  }

  .rdrDefinedRangesWrapper {
    width: 134px;
  }

  .rdrDayToday .rdrDayNumber span:after,
  .rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after,
  .rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after,
  .rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after,
  .rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after {
    background: transparent;
  }

  .rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after,
  .rdrSelected {
    border-radius: 5px !important;
  }

  .rdrDayHovered .rdrDayNumber:after {
    border-radius: 5px !important;
  }

  .rdrDayEndOfMonth .rdrDayInPreview,
  .rdrDayEndOfMonth .rdrDayStartPreview,
  .rdrDayEndOfMonth .rdrInRange,
  .rdrDayEndOfMonth .rdrStartEdge,
  .rdrDayEndOfWeek .rdrDayInPreview,
  .rdrDayEndOfWeek .rdrDayStartPreview,
  .rdrDayEndOfWeek .rdrInRange,
  .rdrDayEndOfWeek .rdrStartEdge {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .rdrDayStartOfMonth .rdrInRange,
  .rdrDayStartOfMonth .rdrEndEdge,
  .rdrDayStartOfMonth .rdrDayInPreview,
  .rdrDayStartOfMonth .rdrDayEndPreview,
  .rdrDayStartOfWeek .rdrInRange,
  .rdrDayStartOfWeek .rdrEndEdge,
  .rdrDayStartOfWeek .rdrDayInPreview,
  .rdrDayStartOfWeek .rdrDayEndPreview {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .rdrEndEdge,
  rdrStartEdge,
  .rdrEndEdge,
  .rdrStartEdge {
    border-radius: 5px;
  }

  .rdrDayNumber {
    font-weight: 400;
  }

  .rdrInRange {
    opacity: 0.4;
  }

  .rdrStartEdge {
    background: linear-gradient(0deg, #0c083a, #0c083a);
    border: 1px solid #3424eb;
  }

  .rdrNextPrevButton {
    background-color: transparent;
  }

  .rdrNextButton i {
    border-left-color: $light-main-color;
  }

  .rdrPprevButton i {
    border-right-color: $light-main-color;
  }
}

@mixin modal-header {
  @include mulish-text;
  font-weight: 600;
  font-size: 20px;
  color: #1b1e22;
  height: 75px;
  display: flex;
  align-items: center;
  padding-left: 24px;
  border-bottom: 1px solid #f4f4f4;
}

@mixin modal-footer {
  padding: 12px 24px 24px 24px;
  display: flex;
}

@mixin card-header {
  @include mulish-text;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  height: 85px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f3f3f3;

  a {
    text-decoration: none;
  }
}

@mixin auth-header-gradient {
  font-family: OakesGrotesk, sans-serif;
  font-weight: 600;
  font-size: 76px;
  line-height: 96px;
  background: -webkit-linear-gradient(#7f7aeb, #221cb6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin table-row {
  &__row {
    height: 80px;

    &:hover {
      background-color: $light-main-color;
      cursor: pointer;
    }
  }
}
