@import '../../vendors/colors';

.verify-campaign-button {
  flex-shrink: 0;
  margin-left: 45px;

  &__icon {
    height: 20px;
    margin-right: 8px;
  }

  /* Overload styles */
  &__button {
    display: flex;
    align-items: center;

    &--verifying {
      background-color: #918de2;

      &:hover {
        background-color: #918de2;
      }
    }

    &--verified {
      background-color: #23e771;

      &:hover {
        background-color: #35e178;
      }
    }
  }

  .react-tooltip {
    background-color: black !important;

    min-width: 200px !important;

    &:after {
      left: 95% !important;
      top: -3px !important;
    }
  }
}
