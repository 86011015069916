.billing-cancellation-flow {
  &__content {
    padding: 47px 20px;
    min-width: 590px;
  }

  &__close-btn {
    display: flex;
    padding: 5px 0 5px 5px;
    cursor: pointer;
  }

  &__title {
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
  }

  &__subtitle {
    max-width: 627px;
    font-size: 16px;
    line-height: 21px;
    color: #4f4f4f;
  }

  &__plan-details {
    display: flex;
    flex-direction: column;
    padding: 37px 51px 22px;
    background: rgba(233, 238, 255, 0.67);
    border-radius: 8px;

    max-width: 505px;
    margin-top: 83px;
    margin-bottom: 102px;

    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    color: #000000;

    &-price-row {
      display: flex;
      align-items: flex-end;
      margin-top: 11px;
      margin-bottom: 47px;
    }
    &-price {
      font-weight: 600;
      font-size: 60px;
      line-height: 60px;
      color: #221cb6;
    }
    &-period {
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      color: #221cb6;
    }
    &-point {
      display: flex;
      align-items: center;

      margin-bottom: 25px;
      svg {
        margin-right: 10px;
        path {
          fill: #221cb6;
        }
      }
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    margin-top: 8px;

    &-content {
      max-width: 539px;
    }

    &-label {
      margin-top: 27px;
      margin-bottom: 8px;
      padding-left: 3px;
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      color: #8f9199;
    }

    &-character-counter {
      margin-top: 6px;
      margin-bottom: -18px;
      padding-left: 3px;
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      color: #8f9199;
    }

    &-textarea textarea {
      height: 154px;
      line-height: 24px;
      padding: 12px 17px 15px 16px;

      &::placeholder {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #8f9199;
      }
    }

    &-hidden-input {
      display: none;
    }

    &-input input {
      height: 48px;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;

    button {
      width: auto;
    }
  }
}
