@import 'src/vendors/mixins';
@import 'src/vendors/variables';

.page-header {
  height: $page-header-height;
  padding: 0 20px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  position: relative;
  z-index: 2;
  background: white;

  &.is-fixed {
    position: fixed;
    top: 0;
    right: 0;
    left: 90px;
    z-index: 20;
  }

  &:after {
    position: absolute;
    content: '';
    background-color: #f4f4f4;
    bottom: 0;
    left: 20px;
    right: 20px;
    height: 1px;
  }

  &__with-close-btn {
    padding-left: 90px;

    .close-btn {
      left: 0;
      top: 0;
      position: absolute;
      background-color: transparent;
      width: 68px;
      height: 66px;
      border-radius: 0;
      border-right: 1px solid #E8E8E8;

      &:hover {
        background-color: #4e48dc;
      }
    }

    &:after {
      left: 0;
    }
  }

  &__title {
    @include mulish-text;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    display: inline-flex;
    align-items: center;

    .slide-toggle {
      & + .page-header-creation-wrapper__settings-name {
        margin-left: 10px;
      }
    }
  }

  &__title-with-how-to {
    display: flex;
    align-items: flex-end;
    flex-shrink: 0;
  }

  &__title-with-backarrow {
    display: flex;
    align-items: center;

    .back-arrow-button {
      width: 26px;
      height: 26px;
      border-radius: 5px;
      margin-right: 11px;

      svg {
        flex-shrink: 0;
      }
    }

    .back-arrow-button:hover {
      background-color: #e5e4f6;

      svg {
        @include svg-colors-path-fill(#251fb6);
      }
    }
  }

  &__actions {
    margin-left: auto;
  }

  &-creation-wrapper__settings-name {
    display: flex;
    position: relative;
    align-items: center;
    margin-left: 15px;
    margin-right: auto;

    input {
      width: fit-content;
      min-width: 240px;
      height: 35px;
      padding-top: 6px;
      padding-bottom: 6px;
      padding-right: 10px !important;
      padding-left: 10px;
      border-radius: 5px;
      border: 1px solid #dbe2fe;
      font-weight: 600;
      font-size: 20px;
      line-height: 20px;
      color: #000000;
    }
  }

  &-creation-wrapper__page-name-length {
    position: absolute;
    bottom: -5px;
    left: 39px;
    font-size: 12px;
    color: $grey-text-secondary;
  }
}
