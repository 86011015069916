@import 'src/vendors/mixins';
@import 'src/vendors/colors';

.credits-card {
  &__info {
    display: flex;
    flex-direction: column;
  }

  &__info-title {
    margin-bottom: 4px;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #000000;
  }

  &__info-subtitle {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #8d8f92;
  }

  &__info-highlight {
    color: #30c6f3;
  }
}
