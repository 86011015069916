@import "src/vendors/mixins";
@import "src/vendors/colors";

.user-links-wrapper {
  &--blocked {
    pointer-events: none;
  }
}

.user-link {
  display: flex;
  position: relative;
  align-items: center;
  font-size: 16px;
  color: $grey-black-color;
  text-decoration: none;
  transition: background-color .3s ease-in-out;
  padding: 8px 16px;
  border-radius: 11px;
  margin: 5px -10px;
  cursor: pointer;

  &__icon {
    margin-right: 10px;
    height: 20px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &__tooltip-content {
    padding: 15px;
    font-size: 16px;
  }

  &--short {
    justify-content: center;

    .user-link {
      &__icon {
        cursor: pointer;
        margin-right: 0;
      }
    }
  }

  &--column {
    flex-direction: column;
  }

  &__credits-number {
    color: #181616;

    &--closed {
      color: #FFFFFF;
    }
  }

  &__notification-flag {
    display: inline-block;
    position: absolute;
    top: 5px;
    right: 15px;

    background-color: #3424EB;
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }

  &:hover {
    .label-color-blue {
      background-color: $primary-main-color;
      color: #fff;
    }

    .user-link__icon {
      @include svg-colors-path-stroke(black);
    }

    .label-color-orange {
      color: #fff;
      background-color: $secondary-orange-color;

      .sidebar-trial-counter__icon {
        @include svg-colors-path-fill(white);
      }
    }
  }
}