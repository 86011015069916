@import '../../vendors/mixins';
@import '../../vendors/colors';

.campaign-creation-wrapper {
  display: flex;
  max-width: 100%;
  min-width: 1270px;

  &__content-wrapper {
    flex: 1;
    max-width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__content {
    box-sizing: border-box;
    padding: 50px 20px 0 20px;
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 60px;

    @media (min-width: 1270px) {
      padding-left: 50px;
      padding-right: 50px;
    }

    .manual-confirm-contact-sidebar {

      &__cross-btn {
        position: absolute;
        top: 38px;
        right: 26px;

        &:hover {
          cursor: pointer;
        }
      }

      &__sidebar {
        h1 {
          font-size: 28px;
          font-weight: 600;
          margin-top: 32px;
          margin-left: 30px;
          margin-bottom: 15px;
        }

        .section-select {
          margin: 0 0 25px;
          width: 100%;

          &__item {
            padding: 19px 20px;
          }
        }
      }

      &__content {
        height: 100%;

        .contacts-search-field {
          display: flex;
          flex-direction: column;
          margin: 0 24px 0 23px;

          .input {
            &__icon {
              z-index: 10;
              height: fit-content;
              top: 16px;
            }

            &__field {
              border-radius: 8.5px;
              min-height: 49px;

              &_label {
                color: #8F9199;
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 8px;
                display: inline-block;
              }
            }

            &__field[name='domainString'] {
              position: relative;
              margin-bottom: 31px;
            }
          }

          &__search-btn {
            position: absolute;
            right: 0;

            .button {
              width: 52px;
              height: 49px;
            }
          }
        }

        .contacts-result {
          margin-top: 17px;

          &__title {
            margin-left: 23px;
          }

          &__table {
            .table {
              margin: 0 auto;
              width: calc(100% - 16px);

              &__thead {
                .table__tr {
                  position: relative;

                  &:after {
                    content: '';
                    position: absolute;
                    height: 1px;
                    background-color: #DBDFE7;
                    bottom: 0;
                    left: 14px;
                    right: 0;
                  }
                }
              }

              &-th {
                padding-bottom: 7px;

                &:first-child {
                  width: 60%;
                  padding-left: 15px;
                }

                &:nth-child(2) {
                  width: 20%;
                }

                &:last-child {
                  min-width: 148px;
                }
              }

              &__td {
                padding-left: 15px;
                padding-top: 14px;
                padding-bottom: 14px;

                &:first-child {
                  border-top-left-radius: 10px;
                  border-bottom-left-radius: 10px;
                }

                &:last-child {
                  border-top-right-radius: 10px;
                  border-bottom-right-radius: 10px;
                  padding-right: 13px;

                  .contacts-result-table__td--status {
                    display: flex;

                    button {
                      margin: auto 0 auto auto;

                    }
                  }
                }
              }
            }
          }

          &-table {
            overflow: auto;
            max-height: 600px;

            &__body-row {
              &:hover {
                background: rgba(217, 217, 217, 0.16);
              }
            }

            &__name {
              margin-bottom: 3px;
              font-weight: 600;
            }

            &__job-position {
              font-size: 14px;
              font-weight: 400;
              color: #474747;
              margin-bottom: 3px;
            }

            .social-links {
              .social-link {
                &__link {
                  svg {
                    width: 15px;
                    height: 15px;

                    path {
                      fill: #007BB5;
                    }
                  }

                  &:hover {
                    svg {
                      path {
                        fill: #C4C6CD;
                      }
                    }
                  }
                }
              }
            }

            &__td {
              &--email {
                //position: relative;
                //height: 72px;
                //display: flex;
                //align-items: center;

                //&.email-active {
                //  &:after {
                //    content: '';
                //    position: absolute;
                //    background: linear-gradient(270deg, #F9F9F9 35.86%, rgba(249, 249, 249, 0.00) 90.4%);
                //    height: 72px;
                //    left: 0;
                //    right: 0;
                //    bottom: 0;
                //    top: 0;
                //  }
                //}
              }
            }

            &__email-row {
              .email-accuracy {
                margin-right: 6px;
              }

              .contacts-result-table__email-string {
                padding-left: 0;
              }
            }
          }
        }
      }

      &__search {
        padding-bottom: 0;

        .right-sidebar {
          position: relative;
          box-shadow: none;
          padding-top: 0;
        }
      }
    }
  }

  &__campaign-name {
    display: flex;
    position: relative;
    align-items: center;
    margin-left: 15px;
    margin-right: auto;
  }

  &__settings-link {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #dbe2fe;
    border-radius: 6px;
    margin-right: 8px;
    width: 34px;
    height: 34px;

    &:hover {
      svg {
        @include svg-colors-path-stroke($secondary-blue-color);
      }
    }
  }

  &__settings-name {
    margin-left: 10px;
  }

  &__settings-name input {
    width: fit-content;
    min-width: 240px;
    height: 35px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 10px !important;
    padding-left: 10px;
    border-radius: 5px;
    border: 1px solid #dbe2fe;

    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #000000;

    &::placeholder {
      font-weight: 400;
    }
  }

  &__campaign-name-length {
    position: absolute;
    bottom: -18px;
    left: 11px;

    font-size: 12px;
    color: $grey-text-secondary;
  }
}
