@import 'src/vendors/mixins';
@import 'src/vendors/colors';

.invoice-info {
  padding: 32px 70px 120px;
  position: relative;
  width: 850px;
  margin: 0 auto;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 32px;
    border-bottom: 1px solid #f4f4f4;
  }

  &__header-info {
    @include mulish-text;
    font-size: 16px;
    line-height: 20px;
  }

  &__bill-info {
    margin-top: 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid #f4f4f4;
  }

  &__bill-info-title {
    @include mulish-text;
    font-weight: 600;
    font-size: 48px;
  }

  &__bill-info-content {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
  }

  &__bill-info-sub-title {
    @include mulish-text;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 21px;
  }

  &__bill-to-content {
    @include mulish-text;
    font-size: 16px;
    line-height: 22px;
  }

  &__bill-details {
    margin-right: 60px;
  }

  &__bill-details-row {
    width: 220px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__bill-details-label {
    @include mulish-text;
    font-size: 16px;
    line-height: 20px;
  }

  &__bill-details-value {
    @include mulish-text;
    font-size: 16px;
    line-height: 20px;
    color: $grey-text-secondary;
  }

  &__cost-grid {
    margin-top: 15px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 35px;
  }

  &__cost-grid-title {
    @include mulish-text;
    font-size: 14px;
    line-height: 18px;
    color: $grey-text-color;

    &--righted {
      text-align: right;
    }
  }

  &__cost-grid-cell {
    margin-top: 5px;
    display: flex;
    align-items: center;

    &--with-border {
      border-bottom: 1px solid #f4f4f4;
    }

    &--righted {
      justify-content: flex-end;
    }
  }

  &__comment {
    margin-top: 22px;
  }

  &__additional-info {
    margin-top: 40px;
    padding-bottom: 14px;
    border-bottom: 1px solid #f4f4f4;
  }

  &__additional-info-label {
    @include mulish-text;
    font-weight: 600;
    color: #1b1e22;
  }

  &__additional-info-value {
    @include mulish-text;
    color: $grey-text-color;
    margin-left: 6px;
    margin-right: 30px;
  }
}
