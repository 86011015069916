@import 'src/vendors/colors';
@import 'src/vendors/mixins';

.campaign-creation-filters {
  box-sizing: border-box;
  overflow: visible;
  transition: all 0.5s;
  min-height: calc(100% - 78px);
  height: 100%;
  width: 0;
  min-width: 0;
  background-color: $grey-bg-color;

  &--opened {
    width: 240px;
    min-width: 220px;
    padding: 16px 20px 0 17px;
  }

  &__title {
    @include mulish-text;
    color: $grey-black-color;
    font-size: 16px;
    line-height: 20px;
    white-space: nowrap;
    margin-bottom: 24px;
  }

  &__input-field {
    .input__field {
      width: 100%;
    }
  }

  &__apply-btn {
    width: 100%;
  }
}
