@import 'src/vendors/mixins';
@import 'src/vendors/colors';

.integrations-table {
  margin-top: 21px;
  margin-right: 21px;

  color: #8d8f92;

  @include table-row;
  &__row {
    padding-top: 18px;
    padding-bottom: 18px;
    border-bottom: 1px solid #f4f4f4;

    .table-td {
      .actions-menu .react-tooltip:not(.react-tooltip--position-fixed) {
        left: -120px !important;
      }
    }
  }

  .title-cell__title {
    font-weight: 600;
    color: #1b1e22;
  }

  .title-cell__subtitle {
    font-size: 12px;
    line-height: 16px;
    margin-top: 4px;
    color: #928e8d;
  }

  &__switch-cell {
    width: 65px;
  }

  &__app-title-cell {
    padding-left: 5px;
  }

  &__app-info-cell {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__app-info-avatar {
    position: relative;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    margin-right: 12px;
  }

  &__template-warning-triangle {
    padding: 10px;
  }
}
