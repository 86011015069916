.content-search-editor-v2 {
  display: flex;
  flex-direction: column;

  &__block-title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: -5px;
    margin-bottom: 19px;

    font-weight: 600;
    font-size: 20px;
    line-height: 100%;
    color: #000000;

    a {
      color: #3424eb;
      text-decoration: none;
    }
  }

  &__source {
    margin-right: 70px;
  }

  &__queries {
    flex: 1;
  }

  &__source-item {
    width: 189px;
    height: 95px;

    background: #fafafa;
    border: 1px solid rgba(99, 99, 99, 0.21);
    box-sizing: border-box;
    border-radius: 15px;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    &--active {
      background: #eef3ff;
      border: 1px solid #221db6;
    }
  }

  &__query-editor-wrapper {
    position: relative;
    overflow: hidden;

    max-height: 226px;

    border: 1px solid rgba(99, 99, 99, 0.21);
    box-sizing: border-box;
    border-radius: 15px;
  }

  &__query-editor {
    display: flex;
    flex-direction: column;

    max-height: 226px;

    height: 100%;
    padding: 0;

    overflow-y: auto;

    & > div:not(:last-child) {
      min-height: 39px;
      transition: min-height 200ms ease;
    }

    &--with-placeholder > div:not(:last-child) {
      min-height: 97px !important;
    }

    &-placeholder {
      position: absolute;
      display: block;

      padding: 10px 17px 10px 17px;

      color: #acacac;
      font-size: 16px;
      pointer-events: none;

      white-space: pre;

      z-index: -1;

      &-row {
        display: flex;
        margin-bottom: 10px;
      }

      &-order {
        margin-right: 17px;
      }
    }

    //&:not([placeholder='']) {
    //  &::before {
    //    content: '1.';
    //    position: absolute;
    //    display: block;
    //
    //    padding: 10px 19px;
    //
    //    color: #acacac;
    //    font-size: 16px;
    //    pointer-events: none;
    //
    //    white-space: pre;
    //  }
    //
    //  &::after {
    //    content: attr(placeholder);
    //    position: absolute;
    //    display: block;
    //
    //    padding: 10px 19px 10px 49px;
    //
    //    color: #acacac;
    //    font-size: 16px;
    //    pointer-events: none;
    //
    //    white-space: pre;
    //  }
    //}
  }

  &__query-order-number {
    margin-right: 5px;
    font-size: 18px;
    line-height: 181%;
    color: #202430;
  }

  &__query {
    display: inline-flex;
    width: 100%;

    counter-increment: line;

    svg {
      cursor: pointer;
      margin-left: 10px;
    }

    &:hover {
      span {
        visibility: visible;
      }
    }
  }

  &__query-text {
    position: relative;
    padding-left: 34px;

    width: 100%;
    max-width: 100%;
    font-size: 18px;
    line-height: 181%;
    color: #000;

    overflow: hidden;
    word-break: break-word;

    &:focus-visible {
      outline: none;
    }

    &:before {
      content: counter(line) '.';

      position: absolute;
      left: 0;
      margin-right: 5px;
      font-size: 18px;
      line-height: 181%;
      color: #202430;
    }

    &--empty {
      &:before {
        content: '';
      }
    }
  }

  &__query-link {
    visibility: hidden;
    margin-top: 11px;
    cursor: pointer;
  }

  &__warning-icon svg {
    margin-bottom: -1px;
  }
}
