@import 'src/vendors/colors';
@import 'src/vendors/mixins';

.right-sidebar {
  z-index: 100;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  //width: 307px;
  height: 100%;
  background: #ffffff;
  box-shadow: 0 4px 50px rgba(18, 16, 68, 0.09);
  padding-top: 29px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  &--embedded {
    position: relative;
    z-index: unset;
    padding-top: 0;
    padding-bottom: 0;
    box-shadow: none;
  }

  &__header {
    border-bottom: 1px solid #f4f4f4;
    padding-bottom: 32px;
  }

  &__title {
    @include mulish-text;
    display: flex;
    align-items: center;
    padding: 0 20px 0 20px;
    line-height: 30px;
    font-size: 24px;
    font-weight: 600;
  }

  &__subtitle {
    padding: 0 28px;
    margin-top: 20px;
    font-weight: 400;
    font-size: 16px;
    color: #8f8f8f;
    line-height: 125%;
  }

  &__close-btn {
    margin-right: 16px;
    cursor: pointer;

    &:hover {
      svg {
        @include svg-colors-path-stroke($grey-icons-color);
      }
    }
  }

  &__footer {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    padding: 20px 20px 0 20px;

    .button {
      padding: 0 10px;
      width: auto;
      min-width: 72px;
      height: 35px;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;

      &:first-child {
        margin-right: 12px;
        margin-bottom: 10px;
      }
    }
  }
}
