.relationships-people {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__table-wrapper {
    flex: 1;
    overflow-y: auto;
    position: relative;
  }

  .right-sidebar {
    width: 462px;

    @media screen and (max-width: 462px) {
      width: 100% !important;
    }
  }
}
