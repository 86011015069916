@import "src/vendors/mixins";
@import "src/vendors/colors";
@import "src/vendors/variables";

.analytics-inbox-threads-content-empty-message,
.analytics-inbox-threads-content-select-message {
  @include mulish-text;
  padding: 10px;
  text-align: center;
  font-size: 18px;
  color: $grey-black-color;
  margin-top: 40px;
}

.analytics-inbox-threads-content {
  display: flex;

  &__content {
    flex: 1;
    height: calc(100vh - #{$page-header-height});
    background-color: $grey-bg-color;
    overflow-y: auto;
    padding-bottom: 20px;
  }
}