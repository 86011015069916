.respona-status-tag {
  display: flex;
  align-items: end;

  span {
    line-height: 12px;
  }

  &__icon {
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }
}
