@import 'src/vendors/colors';

.upsell-option {
  display: flex;
  align-items: flex-start;

  padding: 20px 20px 19px;
  margin-bottom: 16px;
  border: 1px solid #eeeeee;
  border-radius: 11px;
  background-color: #ffffff;

  transition: background-color 0.3s linear;
  cursor: pointer;

  &--chosen {
    background-color: #f2f5ff;
  }

  &__radio {
    flex-shrink: 0;
    margin-top: 12px;

    .radio__checkmark-inner {
      width: 15px;
      height: 15px;
      top: 0 !important;
      left: 0 !important;
      bottom: 2px !important;
      right: 2px !important;
    }
  }

  &__description {
    margin-left: 10px;
  }

  &__header {
    margin: 0;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  }

  &__price-per-credit {
    color: $primary-main-color;
  }

  &__text {
    margin-top: 16px;
    font-size: 16px;
    line-height: 1.5rem;

    &--strong {
      font-weight: 800;
    }
  }

  &__select-subscription-row {
    display: flex;
    align-items: baseline;
    margin-top: 8px;
  }

  &__select-wrapper {
    width: 140px;
    margin: 0 12px;
  }

  &__batch-quantity {
    display: flex;
    align-items: baseline;

    .input__field {
      margin-left: 12px;
      padding-left: 10px;
      width: 67px;
      font-weight: bold;
    }
  }
}
