@import "src/vendors/mixins";
@import "src/vendors/colors";

.edit-workspace-sidebar {
  padding-top: 15px;

  &__row {
    padding: 0 20px;

    &:not(&:last-child) {
      margin-bottom: 20px;
    }
  }

  &__field-label {
    @include mulish-text;
    font-weight: 600;
    font-size: 16px;
    color: $grey-text-color;
    margin-bottom: 8px;
  }
}
