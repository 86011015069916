@font-face {
    font-family: 'Oakes Grotesk';
    src: url('./OakesGrotesk-SemiBold.woff2') format('woff2'),
        url('./OakesGrotesk-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oakes Grotesk';
    src: url('./OakesGrotesk-Medium.woff2') format('woff2'),
        url('./OakesGrotesk-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

