@import 'src/vendors/colors';
@import 'src/vendors/mixins';

.inbox-mail-thread {
  flex: 1;
  background-color: #fbfbfb;

  overflow-y: scroll;

  &__header {
    &-tags {
      &-wrapper {
        display: flex;
        align-items: center;
        margin-left: 22px;
      }

     &-tag {
       border: 1px solid #EAEAEA;
       border-radius: 7px;
       padding: 7px;
       display: inline-flex;
       align-items: center;
       justify-content: space-between;
       width: fit-content;
       gap: 10px;
       margin: 0 4px 4px 0;
       background: #ffffff;

       &:first-child {
         margin-left: 0;
       }

       &:last-child {
         margin-right: 0;
       }

       .remove-tag {
         display: flex;

         &:hover {
           cursor: pointer;

           svg {
             @include svg-colors-path-fill($alert-color)
           }
         }
       }
     }
    }

    &-notes {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 22px;
      border-top: 1px solid #E9ECF1;
      background-color: #FBFBFB;
      box-shadow: 0px 5px 10px rgba(154, 154, 154, 0.1);
      margin-top: 20px;

      &-text {
        font-size: 16px;
      }

      .remove-note {
        display: flex;

        &:hover {
          cursor: pointer;

          svg {
            @include svg-colors-path-fill($alert-color)
          }
        }
      }
    }
  }

  &__actions {
    padding: 20px 22px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: #fbfbfb;

    &-buttons {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    .select {
      min-width: 187px;

      .assign__control--is-focused {
        border-color: $primary-main-color;
        box-shadow: none;

        .assign__indicators {
          transform: rotate(180deg);

          .assign__indicator {
            svg {
              @include svg-colors-path-fill($primary-main-color);
            }
          }
        }

        .assign__value-container {
          .assign__single-value {
            color: $primary-main-color;
          }
        }
      }

      .named-avatar,
      .user-avatar-wrapper {
        margin-left: 9px;
        border: 1px solid #C1C1C1;
        border-radius: 100%;
      }

      .assign__option {
        gap: 12px;
        background-color: black;

        svg {
          margin: 0 0 0 auto;
        }

        &:first-child {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }

        &:last-child {
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
        }

        &:not(:first-child) {
          border-top: 1px solid #272727;
        }

        &:hover {
          background-color: #181818;
          cursor: pointer;
        }
      }
    }
  }

  &__action {
    padding: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: auto;
    width: 26px;
    height: 26px;
    border-radius: 5px;

    &:hover {
      background-color: #E9EEFF;

      svg {
        @include svg-colors-path($primary-main-color);
      }
    }

    &-tooltip {
      background-color: black;
      border-radius: 8.5px;
      max-width: 232px;

      &-header {
        padding: 5px 10px;

        .input__field {
          width: 100%;
          color: #FFFFFF;
        }
      }

      &-footer {
        text-align: center;
        padding: 5px 0;

        .button {
          width: 100%;

          &:hover {
            svg {
              opacity: 0.8;
            }
          }
        }

        .input {
          &.labels-input {
            input {
              width: 100%;
              color: #FFFFFF;
              font-size: 14px;
            }
          }
        }
      }
    }

    &-labels,
    &-more {
      list-style-type: none;
      padding-left: 0;
      margin: 0;
      max-height: 320px;
      overflow-y: auto;

      &-item {
        padding: 12px 20px;
        border-bottom: 1px solid #272727;
        gap: 8px;
        display: flex;
        align-items: center;
        font-size: 14px;

        &:first-child {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }

        &:last-child {
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
        }

        &:hover,
        &--active {
          background-color: #181818;
          cursor: pointer;
        }
      }
    }

    &.more-actions {
      svg {
        transform: rotate(90deg);
      }
    }

    &-snooze-dropdown {
      background-color: black;
      border-radius: 8px;

      .snooze-dropdown-container {
        &__header {
          font-size: 16px;
          padding: 20px;
        }

        &__item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px 20px;
          border-top: 1px solid #272727;
          gap: 10px;

          p {
            font-size: 16px;
          }

          &-timing {
            display: flex;
            align-items: center;
            gap: 5px;
            color: $grey-black-color;
          }

          &:hover {
            cursor: pointer;
            background-color: $secondary-black-color;
          }
        }

        &__footer {
          border-top: 1px solid #272727;

          button {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            color: #FFFFFF;

            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }
  }

  .inbox-snooze-container {
    background-color: black;
    border-radius: 8px;
    max-width: 300px;

    &__range-picker {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__item {
      padding: 0 24px;
      margin-bottom: 16px;

      label {
        color: #747474;
        font-size: 14px;
        margin-bottom: 6px;
        display: inline-block;
      }

      .input {
        border: 1px solid #272727;
        border-radius: 6px;

        input {
          color: white;
          font-size: 16px;

          &::placeholder {
            color: #747474;
          }
        }
      }

      &-time-picker {
        border: 1px solid #272727;
        border-radius: 6px;
        padding-left: 19px;
        height: 40px;
        display: flex;
        align-items: center;

        .react-time-picker__wrapper {
          & * {
            background-color: transparent;
            color: white;
            border: none;
            min-width: 15px;
            font-size: 16px;

            &::placeholder {
              color: #747474;
            }
          }

          .react-time-picker__inputGroup__divider {
            margin-right: 5px;
          }
        }
      }
    }

    &__info {
      color: #ED483D;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
    }

    &__footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      flex-wrap: wrap;
      grid-row-gap: 10px;

      button {
        &:hover {
          opacity: 0.8;
        }
      }

      .snooze-btn {
        background-color: #7974F6;
      }

      .unsnooze-btn {
        width: 100%;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  &__header {
    padding: 0 22px;
    font-weight: bold;
    font-size: 20px;
    line-height: 100%;
    margin: 20px 0 20px 0;
  }

  &__empty-message {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding-bottom: 40px;
    font-style: normal;
    font-weight: normal;
    line-height: 100%;

    text-align: center;
    font-size: 18px;
    color: #7b7b7b;
  }

  &__person-info-sidebar {
    width: 307px;
    padding-bottom: 20px;

    .contact-sidebar-crm--small {
      .contact-sidebar-crm-section__content {
        padding: 0;

        .contact-sidebar-crm__content-personal-info {
          gap: 10px;
        }
      }
    }
  }

  .assign__input {
    input {
      caret-color: transparent;
    }
  }
}
