@import "src/vendors/mixins";

.source-select-row {
  display: grid;
  grid-template-columns: repeat(auto-fill, 222px);
  grid-gap: 17px;
  margin-bottom: 30px;

  a {
    text-decoration: none;
  }
}

.source-select-option {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 222px;
  padding: 26px 20px 17px;
  background-color: #f5f5f5;
  border: 1px solid transparent;
  border-radius: 8px;
  user-select: none;

  transition: background-color 160ms ease;
  cursor: pointer;

  &:hover {
    background-color: #eef3ff;
  }

  &__selected {
    background-color: #eef3ff;
    border: 1px solid #221db6;
  }

  &__disabled {
    cursor: not-allowed;
    &:hover {
      background-color: #f5f5f5;
    }
  }

  &__image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 52px;
    margin-bottom: 21px;
    background-color: #ffffff;
    border-radius: 9px;
  }

  &__icon {
    width: 52px;
    height: 52px;
    margin-bottom: 21px;
    border-radius: 9px;
  }

  &__title {
    display: flex;
    align-items: flex-end;

    margin-bottom: 14px;
    font-size: 24px;
    line-height: 100%;
    color: #000000;

    &-beta-tag {
      padding: 3px 5px 4px 6px;
      border: 1px solid #cccccc;
      border-radius: 5px;
      margin-left: 7px;

      font-weight: 700;
      font-size: 10px;
      line-height: 10px;
      text-transform: uppercase;

      color: #4b4c4d;
    }
  }

  &__subtitle {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.6);
  }

  &__subtitle-details {
    margin-bottom: 12px;
    font-size: 14px;
    line-height: 129%;
    color: #000000;
  }

  &__indicator-container {
    display: flex;
    align-items: center;
    padding: 5px 7px 6px 7px;
    font-weight: 700;
    font-size: 10px;
    line-height: 100%;
    color: #4b4c4d;

    background-color: #e9e9e9;
    border-radius: 5px;
  }

  &__indicator {
    display: block;
    width: 7px;
    height: 7px;
    margin-right: 6px;
    background-color: #23e771;
    border-radius: 50%;
  }

  &__tooltip {
    text-align: center;
    line-height: 21px;
    a {
      color: #ffffff;
      text-decoration: underline;
      text-decoration-color: #ffffff;
    }
  }
}

.source-select-option-new {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 222px;
  border-radius: 8px;
  border: 1px dashed #8d8f92;

  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #8d8f92;

  cursor: pointer;

  &:hover {
    background: rgba(37, 31, 182, 0.04);
    border: 1px dashed #251fb6;
    color: #251fb6;
    svg path {
      fill: #251fb6;
    }
  }

  svg {
    width: 20px;
    height: 20px;
    margin-bottom: 18px;
  }
}
