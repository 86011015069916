@import 'src/vendors/mixins';
@import 'src/vendors/colors';

.notification-container {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin-bottom: 7px;
  border-radius: 8px;
  box-shadow: 0 1px 5px $grey-text-secondary;
}

.notification {
  height: auto;
  min-height: 44px;
  border-radius: 8px;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transform-origin: right;
  color: #fff;

  &--with-content {
    font-weight: bold;
    font-size: 16px;
  }

  &--with-actions {
    border-radius: 8px 8px 0 0;
  }

  &__content {
    color: #fff;
    padding: 0 16px 12px;
    font-weight: normal;
    font-size: 14px;
  }

  &__sign {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    margin-right: 8px;
  }

  &--info {
    background-color: #50abf1;
  }

  &--error {
    background-color: $alert-color;
  }

  &--success {
    background-color: $success-color;
  }

  &--warning {
    background-color: $warning-bg-color;
  }

  &--announcement {
    background-color: $primary-main-color-light;
  }

  &--announcement &__action-btn {
    background-color: $primary-main-color-lightest !important;
    color: $primary-main-color !important;
    opacity: 1;
    &:first-child {
      &:not(:last-child) {
        border-right: 1px solid $primary-main-color-light;
      }
    }
  }

  &__close-btn {
    height: 20px;
    margin-left: 15px;
    cursor: pointer;
  }

  &__action-btn-row {
    display: flex;
    border-radius: 0 0 8px 8px;
  }

  &__action-btn {
    display: flex;
    flex: 1;
    border-radius: 0;
    opacity: 0.8;
    color: $grey-text-secondary !important;

    &:first-child {
      border-bottom-left-radius: 8px;
      &:not(:last-child) {
        border-right: 1px solid $light-main-color;
      }
    }

    &:last-child {
      border-bottom-right-radius: 8px;
    }
  }
}
