.info-card {
    border: 1px solid #DBDFE7;
    border-radius: 12px;
    padding: 20px;
    padding-right: 30px;
    background-color: #ffffff;
    min-width: 165px;
    max-width: 180px;
    min-height: 80px;
    word-wrap: break-word;

    p {
        font-size: 14px;
        color: #3C455B;
        line-height: normal;
        text-align: left;
    }

    &:hover,
    .active {
        transition: .3s;
        cursor: pointer;
        border-color: rgba(37, 31, 182, 41%);
        background-color: rgba(37, 31, 182, 4%);
    }
}