.settings-workspace {
  &__search-and-button {
    display: flex;
    gap: 12px;
  }

  &__button-text {
    margin-left: 10px;
  }
}
