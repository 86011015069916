@import 'src/vendors/colors';
@import 'src/vendors/mixins';

.people-table-wrapper {
  &__wrapper-for-horizontal-scrolling {
    overflow-x: auto;
  }
}

.people-table {
  margin-top: 24px;
  position: relative;

  &__row {
    min-height: 80px;
    border-bottom: 1px solid #F4F4F4;

    .table-td, .table-th {
      padding: 15px 5px;

      &:first-child {
        padding-left: 20px;
        vertical-align: top;
      }
    }

    &:hover {
      background-color: $grey-bg-color;

      color: #000000 !important;
    }

    .table-td {
      .actions-menu .react-tooltip:not(.react-tooltip--position-fixed) {
        left: -120px !important;
      }
    }
  }

  .table-td:not(:first-of-type):not(:nth-of-type(2)):not(:last-of-type) {
    padding: 0 8px;
  }

  .table-td:first-of-type {
    width: 41px;
  }

  .table-th:not(:first-of-type) {
    padding-right: 20px;
  }

  &__select-all-cb {
    white-space: nowrap;
    display: flex;
  }

  &__select-columns-wrapper {
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;

    right: 0;
    top: 14px;
    height: 40px;
    width: auto;
    padding-right: 30px;
    padding-left: 5px;

    background-color: #ffffff;
  }

  &__cell {
    padding: 0;

    &--color-light-gray {
      color: $grey-text-color !important;
      font-size: 12px;
      margin: 4px 0 0;
    }
    &--color-dark-gray {
      color: #393939 !important;
      text-decoration: underline;

      &.no-underline {
         text-decoration: none;
      }
    }
  }

  &__contact-header {
    display: flex;
    height: 18px;
    align-content: center;
    margin-bottom: 3px;
  }

  &__website-header {
    display: flex;
    height: 44px;
    align-content: center;

    .people-table__website-square-logo {
      border-radius: 6px;
    }
  }

  &__name {
    @include mulish-text;
    white-space: nowrap;
    font-weight: 500;
    font-size: 14px;
    line-height: normal;
    color: #393939;
  }

  &__socials {
    margin-left: 4px;
  }

  &__email {
    @include mulish-text;
    display: flex;
    align-items: center;
    font-size: 11px;
    line-height: 11px;
    color: #393939;
    white-space: nowrap;

    &-container {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    span {
      margin-bottom: 8px;
    }
  }

  &__position {
    @include mulish-text;
    font-size: 12px;
    line-height: 16px;
    color: #3939398A;
  }

  &__emails_more {
    @include mulish-text;
    font-size: 11px;
    line-height: 11px;
    color: #221CB6;
    text-decoration: underline;

    & + .react-tooltip {
      .contacts-result-emails-tooltip {
        padding: 15px 0;

        &__email {
          padding: 5px 15px;
          display: flex;
          align-items: center;

          &:hover {
            background-color: #181818;
            cursor: pointer;
          }

          &:last-child {
            margin-bottom: 0;
          }

        }
      }
    }

    &:hover{
      cursor: pointer;
    }
  }

  &__website {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
  }

  &__website_title {
    @include mulish-text;
    font-size: 14px;
    line-height: 14px;
    color: #1B1E22;
    font-weight: 600;
  }

  &__email-cell-text,
  &__organization,
  &__website_title,
  &__job-position {
    display: inline-block;
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__email-cell-text {
    display: flex;
    align-items: center;
    max-width: unset;
  }

  &__email-cell-copy-btn {
    opacity: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    margin-left: 3px;

    border-radius: 5px;
    background-color: #ffffff;

    cursor: pointer;
  }

  &__job-position {
    max-width: 200px;
    font-size: 12px;
    margin-bottom: 6px;
  }

  &__status {
    display: flex;
    align-items: center;

    svg {
      margin-right: 5px;
    }
  }

  .people-table-tags,
  .people-table-notes {
    cursor: pointer;
    display: flex;
    align-items: center;

    &__number {
      margin-right: 5px;
      width: 20px;
      height: 20px;
      background-color: $primary-grey-action-color;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;

      &:hover {
        background-color: $primary-main-color;
        color: #fff;
      }
    }

    &__plus {
      height: 20px;
    }
  }

  .peoples-table-emails-chevron {
    svg {
      transform: rotateX(180deg);
    }
  }

  .people-table-emails-tooltip {
    &__email {
      display: flex;
      align-items: center;

      @include mulish-text;
      font-size: 14px;
      color: #fff;
      padding: 10px;
    }
  }
}

.people-table-empty {
  @include mulish-text;
  font-size: 24px;
  line-height: 130%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding-top: 20%;

  &__message {
    color: $grey-text-color;
  }

  &__button-link {
    color: $secondary-blue-color;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.people-table-emails-tooltip__email:hover .people-table__email-cell-copy-btn {
  opacity: 1;
}
.people-table__email-cell-text:hover .people-table__email-cell-copy-btn {
  svg {
    margin-top: 1px;
  }
  opacity: 1;
}
