@import 'src/vendors/colors';
@import 'src/vendors/mixins';

.custom-fields-sidebar {
  font-weight: 400;

  .right-sidebar {
    width: 462px;
  }

  &.contacts-sidebar {
    .right-sidebar {
      width: 526px;
    }
  }

  &__header {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;

    color: #000000;
  }

  &__list {
    list-style-type: none;
    padding-left: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 0;

    &-item {
      padding: 20px;
      padding-bottom: 0;

      &:not(:first-child) {
        padding-top: 31px;
      }

      &-title {
        @include mulish-text;
        font-weight: 600;
        font-size: 14px;
        color: $grey-text-secondary;
        margin-bottom: 10px;
        display: inline-flex;
        align-items: center;
        position: relative;
        width: 100%;
        padding-right: 50px;
      }

      .website-name {
        input {
          width: 100%;
          height: 45px;
        }
      }

    }
  }

  .section-select {
    margin: 0 20px 4px;
    margin-right: 0;
    margin-left: 0;
    width: 100%;

    &__container {
      width: 100%;
    }

    &__item {
      width: 50%;
    }
  }

  .custom-fields-sidebar__field {

    .content-search-editor-v2 {
      &__query-editor-wrapper {
        border-radius: 6px;

        .content-search-editor-v2__query {
          &-text {
            font-size: 14px;
            line-height: 181%;
            padding-left: 17px;

            &:before {
              font-size: 14px;
            }
          }

          &-editor {
            &--with-placeholder > div:not(:last-child) {
              min-height: 73px !important;
            }
          }

          &-editor-placeholder {
            font-size: 14px;
            line-height: 181%;
            white-space: inherit;
          }

          &-editor-placeholder-order {
            margin-right: 8px;

            .content-search-editor-v2__query-editor-placeholder-row {
              .content-search-editor-v2__query-editor-placeholder-order {
                margin-right: 8px;
              }
            }
          }
        }
      }
    }

    .custom-fields-sidebar__from-to {
      .input {
        &:last-child {
          margin-left: -1px;
        }

        .input__field {
          height: 45px;
          font-size: 14px;
          border-radius: 6px 0px 0px 6px !important;
        }

        &:last-child {
          .input__field {
            border-radius: 0px 6px 6px 0px !important;
          }
        }

        .input__before-text {
          font-size: 14px;
          color: #acacac;
        }
      }
    }

    &:not(:first-child) {
      padding-top: 31px;
    }

    .select {
      .css-1wa3eu0-placeholder {
        color: #acacac;
      }

      .css-eqy8aq-control {
        min-height: 45px;
        border: 1px solid #DBDFE7;
        border-radius: 6px;

        .css-1nldu0k-ValueContainer {
          padding: 2px 13px;
        }
      }

      .css-rzky46-control {
        min-height: 45px;
        border-radius: 6px;

        .css-1nldu0k-ValueContainer {
          padding: 2px 13px;
        }
      }
    }

    .slate-editor {
      &__editor--inline {
        .slate-editor__editor-wrapper-inline {
          padding: 15px 16px 15px 17px;
          font-weight: 400;
          font-size: 14px;
          color: #000;
          min-height: 147px;

          span[data-slate-placeholder] {
            color: #acacac;
            opacity: 1!important;
          }
        }

        .editor-variables-button-inline {
          width: 22px;
          height: 22px;
          border-radius: 5px;

          svg {
            width: 12px;
            height: 12px;
          }
        }
      }
    }

    .custom-field-select {
      color: #FFFFFF;

      &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 17px 20px 17px 12px;
        border-top: 1px solid #272727;

        &:first-child {
          border-top: none;
        }

        &:last-child {
          border-bottom: 1px solid #272727;
        }

        &-drag {
          cursor: move;
        }

        &-color {
          margin: 0px 6px 0px 12px;
          width: 13px;
          height: 13px;
          border-radius: 3px;
          display: block;
          flex: none;
        }

        &-input {
          margin: 0 auto 0 6px;
          width: 100%;
          background-color: transparent;
          color: white;
          border: none;

          &:focus {
            outline: none;
            border: none;
          }
        }

        &-close {
          &:hover {
            cursor: pointer;
            filter: brightness(100);
          }
        }

        &:hover {
          background-color: #282828;
        }
      }

      &__create-button {
        padding: 17px 20px;
        color: #7974F6;
        text-align: center;
        font-size: 14px;

        &:hover {
          color: $primary-main-color-lighter;
          cursor: pointer;
        }
      }
    }
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    //padding: 22px 20px;
  }

  &__input {
    height: 45px;
    input {
      padding-left: 17px;
      font-size: 14px;
      border-radius: 6px;
    }
  }

  &__textarea {
    input {
      padding-left: 11px;
    }
  }

  &__from-to {
    display: flex;
    input {
      width: 211px;
    }
  }

  &__field {
    padding: 20px;
    padding-bottom: 0;

    &--relative {
      position: relative;
    }

    .slate-editor {
      border: 1px solid #dbdfe7;
      border-radius: 8px;
      font-size: 16px;
      &__editor {
        &--inline {
          height: 170px;
        }
      }
      &.focused-editor {
        box-shadow: none !important;
        border-color: #221cb6 !important;
      }
    }
  }

  &__field-label {
    @include mulish-text;
    font-weight: 600;
    font-size: 14px;
    color: $grey-text-secondary;
    margin-bottom: 10px;
    display: inline-flex;
    align-items: center;
    position: relative;
    width: 100%;
    padding-right: 50px;

    &-help {
      margin-left: 4px;
      display: inline-flex;
    }

    &-tooltip {
      padding: 12px 15px;
      max-width: 300px;

      &-title {
        font-size: 10px;
        font-weight: normal;
        margin-bottom: 9px;
        margin-top: 0;
      }

      &-color {
        display: flex;

        .color-item {
          width: 18px;
          height: 18px;
          border-radius: 3px;
          display: block;
          position: relative;

          &:not(:last-child) {
            margin-right: 7px;
          }

          &:hover {
            cursor: pointer;
          }

          &.active {
            &:before {
              content: '';
              position: absolute;
              width: 10px;
              height: 10px;
              background-color: #282828;
              top: 50%;
              transform: translateY(-50%) translateX(-50%);
              left: 50%;
              border-radius: 100%;
            }
          }
        }
      }
    }

    &-option {
      position: absolute;
      right: 0;
      font-size: 10px;
      font-weight: 400;
    }
  }

  &__field-value {
    font-weight: 800;
    color: $primary-black-color;
  }

  &__relation-field {
    padding-top: 6px;

    .radio {
      display: flex;
      height: auto;

      & > span {
        bottom: auto !important;
      }
    }
  }

  &__relation-name {
    @include mulish-text;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 7px;
  }

  &__relation-desc {
    @include mulish-text;
    font-size: 14px;
    line-height: 17px;
    color: $grey-text-secondary;
  }

  &__type-select {
    display: flex;
    align-items: center;
    padding: 2px 11px;
    padding-right: 0;
    width: 100%;

    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #8d8f92;

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background-color: #e8eaed;
      margin-right: 1px;
    }
  }

  &__type-option {
    display: flex;
    align-items: center;

    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #ffffff;

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 34px;
      height: 34px;
      border-radius: 50%;
      background-color: #3d3d3d;
      margin-right: 12px;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    margin-top: 15px;

    &-save {
      display: flex;
      margin-left: auto;
      button:first-of-type {
        margin-right: 7px;
      }
    }

    button {
      height: 35px;
      width: 72px;
      min-width: 72px;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
    }
  }

  .slate-editor__editor {
    height: auto;
    min-height: 30px;
  }
}

.pipeline-deal-card {
  &__website-footer {
    padding: 8px 12px;
    display: flex;
    align-items: center;
    grid-column-gap: 6px;
    height: 40px;
    width: 100%;
    border-top: 1px solid rgba(217, 217, 217, 0.25);


    p {
      color: white;
    }

    &:hover {
      cursor: pointer;
      background-color: rgba(217, 217, 217, 0.12);

      p {
        color: #BDBDBD;
      }

      svg {
        @include svg-colors-path(#BDBDBD);
      }
    }
  }
}
