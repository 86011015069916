@import "src/vendors/mixins";
@import "src/vendors/colors";

.campaign-move-modal {
  width: 500px;
  overflow-y: visible;
  min-height: 340px;

  &__header {
    @include modal-header;
  }

  &__campaign-title {
    @include mulish-text;
    font-size: 14px;
    line-height: 18px;
    margin: 0 12px;
    height: 28px;
    display: flex;
    align-items: center;
    padding: 0 5px;
    background: $light-main-color;
    color: $primary-main-color;
    border-radius: 8.5px;
  }

  &__campaign-counter {
    background: $light-main-color;
    border-radius: 8.5px;
    color: $primary-main-color;
    font-size: 14px;
    height: 28px;
    padding: 0 10px;
    display: flex;
    align-items: center;
  }

  &__selector {
    padding: 0 24px;

    &--block {
      margin: 24px 0;
      p {
        color: #8F9199;
        padding-bottom: 10px;
      }
    }
  }

  &__footer {
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
  }
}
