@import 'src/vendors/colors';
@import 'src/vendors/mixins';

.organizations-table {
  margin-top: 24px;
  position: relative;

  .table-td {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .table-td:first-child,
  .table-th:first-child {
    padding-left: 20px;
  }

  .table-td:last-child,
  .table-th:last-child {
    padding-right: 10px;
  }

  .table-td:not(:first-of-type):not(:nth-of-type(2)):not(:last-of-type) {
    padding: 20px 8px;
  }

  .table-td:first-of-type {
    width: 26px;
  }

  .table-th:not(:first-of-type) {
    padding-right: 20px;
  }

  &-empty {
    @include mulish-text;
    font-size: 24px;
    line-height: 130%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding-top: 20%;

    &__message {
      color: $grey-text-color;
    }

    &__button-link {
      color: $secondary-blue-color;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-wrapper {
    overflow-y: auto;
    position: relative;

    &__wrapper-for-horizontal-scrolling {
      overflow-x: auto;
    }
  }

  &__row {
    min-height: 60px;
    color: $grey-text-color;
    border-bottom: 1px solid #F4F4F4;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background-color: $grey-bg-color;
    }

    &.table-tr--is-selected {
      color: #4b4c4d;
    }

    .table-td {
      .actions-menu .react-tooltip:not(.react-tooltip--position-fixed) {
        left: -120px !important;
      }
    }
  }

  &__select-all-cb {
    white-space: nowrap;
    display: flex;
  }

  &__name {
    white-space: nowrap;
    font-weight: 500;
    font-size: 14px;
    color: #1b1e22;
  }

  &__cell {
    padding: 0;

    &.date {
      color: #393939;

      & > div {
        margin-top: 4px;
      }
    }

    &--color-light-gray {
      color: $grey-text-color !important;
      font-size: 12px;
    }

    &--color-dark-gray {
      color: #393939 !important;
      text-decoration: underline;
      margin-top: 7px;
    }

    .metrics-block {
      &:not(:last-child) {
        margin-bottom: 13px;
      }

      &_logo {
        gap: 7px;
        display: flex;
        align-items: center;

        &-text {
          display: flex;
          align-items: center;
          width: 100%;

          p {
            max-width: 105px;
            width: 100%;
          }

          span {
            color: #393939;
          }
        }

        &:not(:last-child) {
          margin-bottom: 13px;
        }

        img {
          width: 19px;
          height: 19px;
        }
      }
    }
  }

  &__link {
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      color: #221cb5;
    }
    svg {
      margin-left: 6px;
      opacity: 0;
      transition: opacity 120ms ease;
    }
    &:hover svg {
      opacity: 1;
    }
  }

  &__contact-header {
    display: flex;
    height: 18px;
    align-content: center;
  }

  &__website-header {
    display: flex;
    height: 44px;
    align-items: center;

    .organizations-table__website-square-logo {
      border-radius: 6px;
    }
  }

  &__name {
    @include mulish-text;
    white-space: nowrap;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #393939;
  }

  &__socials {
    margin-left: 4px;
  }

  &__email {
    @include mulish-text;
    display: flex;
    align-items: center;
    font-size: 11px;
    line-height: 11px;
    color: #393939;
    white-space: nowrap;

    &-container {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }

  &__position {
    @include mulish-text;
    font-size: 12px;
    line-height: 16px;
    color: #3939398A;
  }

  &__emails_more {
    @include mulish-text;
    font-size: 11px;
    line-height: 11px;
    color: #221CB6;
    text-decoration: underline;
    margin-top: 7px;

    & + .react-tooltip {
      .contacts-result-emails-tooltip {
        padding: 15px 0;

        &__email {
          padding: 5px 15px;
          display: flex;
          align-items: center;

          &:hover {
            background-color: #181818;
            cursor: pointer;
          }

          &:last-child {
            margin-bottom: 0;
          }

        }
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__website {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
  }

  &__website_title {
    @include mulish-text;
    font-size: 14px;
    line-height: 17px;
    color: #1B1E22;
    font-weight: 600;
    margin-bottom: 4px;
    max-width: 357px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__status {
    display: flex;
    align-items: center;

    svg {
      margin-right: 5px;
    }
  }

  &__select-columns-wrapper {
    position: absolute;
    right: 30px;
    top: 14px;
    height: 40px;
    width: 30px;
    justify-content: center;
    display: flex;
    align-items: center;
    background-color: #fff;
  }
}
