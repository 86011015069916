@import 'src/vendors/colors';
@import 'src/vendors/mixins';

.personalisation-modal {
  width: fit-content;
  overflow-y: visible;
}

.test-personalisation-modal {
  &__header {
    padding: 27px 61px 24px 24px;
    border-bottom: 1px solid #f4f4f4;
  }

  &__title {
    @include mulish-text;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 10px;
  }

  &__sub-title {
    @include mulish-text;
    line-height: 130%;
    color: #928e8d;
  }

  &__label {
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;

    color: #8f9199;

    &:not(:first-of-type) {
      margin-top: 17px;
    }
  }

  &__body {
    padding: 24px;
    input {
      width: 100%;
    }

    div {
      max-width: unset;
    }
  }

  &__footer {
    display: flex;
    padding: 0 24px 29px;
  }

  &__send-button {
    width: 162px;
    margin-right: 10px;
  }

  &__cancel-button {
    width: 100px;
  }
}
