@import "src/vendors/mixins";
@import "src/vendors/colors";

.add-people-to-campaign-sidebar {
  &__header {
    padding: 0 28px;
  }

  &__title {
    margin-top: 20px;
    font-weight: 400;
    font-size: 16px;
    color: #8F8F8F;
    line-height: 125%;
  }

  &__campaign-name {
    padding: 8px;
    border-radius: 8px;
    color: #221CB6;
    background-color: #E9EEFF;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
}

.export-people-sidebar {
  &__header{
    color: #8F8F8F;
    padding: 20px;
    line-height: 147%;
    font-size: 16px;
  }

  &__selected_people-text {
    color: $primary-main-color;
    font-weight: 600;
  }
  &__filters {
      margin-top: 40px;
      padding: 20px;
    }

    &__filters-title {
      color: #8F8F8F;
    }

    &__filters-raw {
      display: flex;
      align-items: center;
      margin-top: 20px;

      &-text {
        margin-left: 14px;
      }
    }
}
