@import "src/vendors/colors";
@import "src/vendors/mixins";

.date-range-select {
  position: relative;

  &__add-value-btn {
    @include mulish-text;
    color: $secondary-light-blue-color;
    cursor: pointer;

    &:hover {
      color: $primary-main-color;
    }
  }

  &__picker {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99999;
  }


}