@import 'src/vendors/colors.scss';

.campaign-verification {
  position: absolute;

  .right-sidebar {
    padding-bottom: 85px;
    width: 685px;
    overflow: auto;

    @media screen and (max-height: 945px) {
      padding-bottom: 0;
    }
  }

  &__header {
    display: flex;
    align-items: center;

    span {
      margin-right: 17px;
    }

    a {
      margin-top: 3px;
    }
  }

  &__advanced-settings-toggle {
    padding: 19px 41px 21px;
    border-top: 1px solid #f4f4f4;

    cursor: pointer;

    span {
      margin-right: 8px;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #000000;
    }
  }

  &__settings {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 24px;
    padding: 42px 41px 48px;

    &__slider {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      height: 26px;

      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #000000;

      span {
        margin: 0 4px 0 6px;
      }
    }

    &-label {
      min-height: 14px;
      font-size: 14px;
      line-height: 14px;
      font-weight: 500;
      color: $grey-text-secondary;

      width: 150px;
      text-align: left;
      margin-bottom: 10px;
      white-space: nowrap;

      display: flex;
      align-items: center;

      &-tooltip {
        white-space: normal;
        margin-left: 8px;
      }
    }

    &-full-width-row {
      grid-column: span 2;
    }

    &-field {
      width: 290px;
      & input {
        width: 100%;
      }
    }
  }

  &__settings-verification {
    padding: 20px 41px 0;
    border-top: 1px solid #f4f4f4;
  }

  &__settings-footer {
    position: fixed;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    width: 685px;
    bottom: 0;

    padding: 20px 41px 40px;
    background-color: #ffffff;
  }
}
