.switch-to-yearly-card {
  grid-column: span 2;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 22px 20px;

  box-shadow: 0 4px 90px rgba(201, 201, 201, 0.25);
  border-radius: 8px;

  a {
    text-decoration: none;
  }

  &__text-container {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background-color: #eef3fd;
    border-radius: 8px;
  }

  &__text {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
  }

  &__button {
    background-color: #3424eb;
    color: #ffffff;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    padding-right: 9px;

    &:hover {
      opacity: 0.8;
      background-color: #3424eb;
    }
  }
}
