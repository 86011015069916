.upsell-contacts-sidebar {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__body {
    flex: 1;
    padding: 20px;
  }

  &__buy-email-block {
    background-color: #f2f5ff;
    border-radius: 11px;
    padding: 20px 20px 19px;

    font-size: 16px;
  }

  &__price {
    font-weight: 800;
  }

  &__price-quantity-row {
    display: flex;
    align-items: baseline;

    margin-top: 10px;
  }

  &__price-quantity-input {
    margin-left: 12px;

    .input__field {
      padding-left: 10px;
      width: 67px;
    }
  }
}
