.switcher {
  display: flex;
  background-color: #E9E9E9;
  position: relative;
  padding: 4px 5px;
  border-radius: 8px;
  width: 86px;
  cursor: pointer;

  &_item {
    width: 37px;
    height: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &_toggler {
    position: absolute;
    width: 37px;
    height: 29px;
    background-color: white;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 4px 0px #0000000F;
    transform: translate3d(-2%, 0, 0);
    transition: transform 0.4s cubic-bezier(0.85, 0.05, 0.18, 1.35);

    &.active {
      left: inherit;
      transform: translate3d(40px, 0, 0);
    }
  }
}