.priority-icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 100%;

  &--left-margined {
    margin-left: 12px;
  }

  &--right-margined {
    margin-right: 12px;
  }

  &--high {
    background: #FF0909;
  }

  &--medium {
    background: #EFCC11;
  }

  &--low {
    background: #11EF42;
  }
}