@mixin avatarSizes($size) {
  width: $size;
  height: $size;
  min-height: $size;
  min-width: $size;
}

.user-avatar-wrapper {
  &--size-xxs {
    @include avatarSizes(22px);
  }

  &--size-xs {
    @include avatarSizes(30px);
  }

  &--size-s {
    @include avatarSizes(36px);
  }

  &--size-sm {
    @include avatarSizes(40px);
  }

  &--size-sl {
    @include avatarSizes(46px);
  }

  &--size-m {
    @include avatarSizes(50px);
  }

  &--size-l {
    @include avatarSizes(86px);
  }
}

.user-avatar {
  border-radius: 100%;

  &--size-xxs {
    @include avatarSizes(22px);
  }

  &--size-xs {
    @include avatarSizes(30px);

    &:before {
      @include avatarSizes(30px);
    }
  }

  &--size-s {
    @include avatarSizes(36px);

    &:before {
      @include avatarSizes(36px);
    }
  }

  &--size-sm {
    @include avatarSizes(40px);

    &:before {
      @include avatarSizes(40px);
    }
  }

  &--size-sl {
    @include avatarSizes(46px);

    &:before {
      @include avatarSizes(46px);
    }
  }

  &--size-m {
    @include avatarSizes(50px);

    &:before {
      @include avatarSizes(50px);
    }
  }

  &--size-l {
    @include avatarSizes(86px);

    &:before {
      @include avatarSizes(86px);
    }
  }

  &:before {
    visibility: visible;
    content: ' ';
    display: block;
    position: absolute;
    border-radius: 100%;
    background-color: #c4c4c4;
  }
}

.named-avatar {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  //background: linear-gradient(0deg, #A7FCDD, #A7FCDD), #EAEDF2;

  &__content {
    //font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 100%;
    //color: #37B487;
  }

  &--size-xxs {
    @include avatarSizes(22px);
  }

  &--size-xs {
    @include avatarSizes(30px);

    &:before {
      @include avatarSizes(30px);
    }

    .named-avatar__content {
      font-size: 12px;
    }
  }

  &--size-s {
    @include avatarSizes(34px);

    &:before {
      @include avatarSizes(34px);
    }
  }

  &--size-sm {
    @include avatarSizes(40px);

    &:before {
      @include avatarSizes(40px);
    }
  }

  &--size-sl {
    @include avatarSizes(46px);

    &:before {
      @include avatarSizes(46px);
    }
  }

  &--size-m {
    @include avatarSizes(50px);

    &:before {
      @include avatarSizes(50px);
    }
  }

  &--size-l {
    @include avatarSizes(86px);

    &:before {
      @include avatarSizes(86px);
    }
  }
}
