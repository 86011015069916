.tooltip-searchable-content {
  display: flex;
  flex-direction: column;
  width: 378px;
  padding: 27px 0 0;
  background-color: #000000;
  border-radius: 8px;

  &-header {
    display: flex;
    justify-content: center;

    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #ffffff;
  }

  &-search {
    margin-top: 20px;
    padding: 0 20px;

    input {
      width: 100%;
      background-color: #000000;
      border: 1px solid #272727;
      border-radius: 6px;

      color: #c4c4c4;

      &::placeholder {
        color: #747474;
      }

      &:focus {
        border-color: #272727;
      }
    }
  }

  &-items {
    display: flex;
    flex-direction: column;

    margin-top: 20px;
    padding: 0 20px 7px;

    &--empty {
      margin: 20px 0 12px;
      padding: 0 20px 7px;
      color: #3A3B3F;
      font-size: 16px;
    }

    &-label {
      margin-bottom: 19px;
      font-weight: 700;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.02em;
      color: rgba(255, 255, 255, 0.37);
      text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }

    &-row {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin-bottom: 23px;

      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      color: #ffffff;

      text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

      .checkbox__field {
        background-color: inherit;
        border-color: #ffffff;
        cursor: pointer;
      }

      .checkbox__field:checked {
        background-color: #3424eb;
      }
    }
  }

  &-add-btn {
    display: flex;
    justify-content: center;
    padding: 15px 20px 20px;

    border-top: 1px solid rgba(255, 255, 255, 0.19);

    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #7974f7;

    span {
      cursor: pointer;
    }

    &-input {
      display: flex;
      align-items: center;
      gap: 8px;

      input {
        width: 254px;
        background-color: #000000;
        border: 1px solid #272727;
        border-radius: 6px;

        height: 34px;

        color: #c4c4c4;

        &::placeholder {
          color: #747474;
        }

        &:focus {
          border-color: #272727;
        }
      }

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 34px;
        height: 34px;

        border: 1px solid #272727;
        border-radius: 6px;
      }
    }
  }
}
