.billing-subscription-type-badge {
  display: inline-flex;

  padding: 4px 10px;
  border-radius: 3px;

  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #ffffff;

  &--purple {
    background-color: #3424eb;
  }
  &--green {
    background-color: #23e771;
  }
  &--yellow {
    background-color: #ffc300;
  }
  &--red {
    background-color: #ed483d;
  }
}
