@import "../../../vendors/colors";
@import "../../../vendors/mixins";

.template-select {
  margin: 46px 0 10px;

  &__menu {
    position: relative;
  }



  &__header {
    @include mulish-text;
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    padding: 20px;
  }

  &__option {
    display: flex;
    padding: 3px 8px;
    width: 100%;

    svg {
      flex-shrink: 0;
    }
  }
  &__option-text-wrapper {
    margin-left: 10px;
    width: 90%;
  }

  &__title,
  &__sub-title {
    @include mulish-text;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__title {
    font-size: 16px;
    margin-bottom: 6px;
    color: #fff;
  }

  &__sub-title {
    font-size: 12px;
    color: $grey-black-color;
  }

  &__footer {
    text-align: center;
    display: flex;
    justify-content: space-between;
  }

  &__footer-button {
    font-size: 14px;
    white-space: nowrap;
    color: $primary-main-color-lighter;

    &:hover {
      color: $primary-main-color-lighter
    }
  }

  &__placeholder {
    &-icon {
      display: inline-block;
      margin-top: 2px;
      vertical-align: top;
    }
  }
}
