@import 'src/vendors/colors';

.sequence-editor {
  display: flex;
  flex-direction: column;
  height: auto !important;

  &__header {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 8px;

    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
    color: #202430;

    ::-webkit-scrollbar {
      display: none;
    }

    .slate-editor {
      border-radius: 8px;
      border: 1px solid $secondary-grey-lines-color;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.04);
      min-height: 49px;

      &__editor--inline {
        min-height: 49px;
        max-width: 100%;
        padding-right: 5px;
        height: auto;

        .slate-editor__editor-wrapper-inline {
          padding-left: 27px;
        }
      }

      &.focused-editor {
        box-shadow: 0px 5px 13px rgba(0, 0, 0, 0.12);
        border-radius: 8px;
        border-color: #ABABAB;
      }

      &__wrapper-variables {
        .editor-variables-button-inline {
          margin-right: 14px;
        }
      }
    }

    .moderate-chance {
      padding: 10px 15px;
    }
  }

  &__step-content {
    flex: 1;
    display: flex;
    align-items: flex-end;
    background-color: #FBFCFD;

    .slate-editor {
      border-radius: 8px;
      border: 1px solid $secondary-grey-lines-color;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
      display: flex;
      flex-direction: column;

      &__controls {
        padding: 20px;
        margin: auto 0;
        margin-bottom: 0;

        .button {
          width: 22px;
          height: 22px;

          &:hover {
            cursor: pointer;
            border-radius: 5px;
            background: #EEF1FC;
          }
        }
      }

      &__controls-separator {
        margin: 0 10px;
      }
    }

    .slate-editor__editor {
      min-height: 455px;
      display: flex;
      flex-direction: column;
      padding: 15px 10px 10px 28px;
      overflow-y: inherit;
      height: calc(100vh - 398px);

      .editable-footer {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex-grow: 1;
        margin-top: 13px;

        .slate-editor__signature-placeholder {
          border-radius: 8px;
          border: 1px solid #E9ECF1;
          background: #FFF;
          box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.04);
          position: relative;

          &-email-open {
            width: 34px;
            height: 34px;
            display: none;
            align-items: center;
            justify-content: center;
            background-color: rgba(34, 28, 182, 0.07);
            border-radius: 6px;
            padding: 0;
            position: absolute;
            right: 25px;
            pointer-events: visible;

            svg {
              margin-right: 0;
            }

            &:hover {
              cursor: pointer;
            }
          }
        }

        &:hover {
          .slate-editor__signature-placeholder {
            border-color: #C3C9E7;
            box-shadow: 0px 3px 7.1px rgba(157, 170, 250, 0.24);

            &-email-open {
              display: flex;
            }
          }
        }
      }

      .editable {
        max-height: calc(100% - 75px);
        overflow-y: auto;
        padding-right: 31px;
      }

      .absolute-slate-variable {
        right: 14px;
        z-index: 11;
      }
    }
  }

  &__autosave {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    height: 31px;
    max-height: 31px;

    &-indicator {
      margin: 0 8px;

      .autosave-indicator {
        width: fit-content;
        padding: 8px 12px 9px;
        font-weight: normal;
      }
    }
  }
}

.sequence-editor__autosave.external {
  margin: 0 auto;
  margin-right: 0;
  margin-bottom: 30px;
}
