@import "src/vendors/mixins";
@import "src/vendors/colors";

.sequence-steps {
  overflow-y: auto;
  height: auto;

  &__wrapper {
    overflow: hidden; // for border-radius
    display: flex;
    flex-direction: column;
    height: 100%;
    background: #FAFAFA;
    border-radius: 8px;
  }

  .sequence-step {
    position: relative;
    padding: 19px 23px 19px 23px;
    overflow-x: hidden;
    border-bottom: 1px solid $secondary-grey-lines-color;
    cursor: pointer;
    white-space: nowrap;
    height: 110px;

    &--active {
      background-color: #F2F4FC;

      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 4px;
        right: 0;
        top: 1px;
        bottom: 1px;
        background-color: $primary-main-color;
        border-radius: 10px 0 0 10px;
      }
    }

    &__title {
      position: relative;
      @include mulish-text;
      font-size: 16px;
      line-height: 100%;
      display: flex;
      align-items: flex-end;
    }

    &__title-warning {
      height: 16px;
      position: absolute;
      left: -20px;
    }

    &__title-description {
      color: $grey-text-color;
      font-size: 14px;
      margin-left: 14px;
    }

    &__subject {
      @include mulish-text;
      margin-top: 14px;
      font-size: 14px;
      line-height: 100%;
      max-width: 344px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__template {
      margin-top: 10px;
      font-size: 12px;
      line-height: 100%;
      color: $grey-black-color;
      max-height: 40px;
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &__edit-delay-btn {
      margin-left: 5px;
      cursor: pointer;
      height: 20px;

      &:hover {
        @include svg-colors-path-stroke(#4E48DC);
      }
    }

    &:hover {
      background: #F2F4FC;
    }
  }

  &__create-btn {
    @include mulish-text;
    font-size: 18px;
    color: $grey-text-secondary;
    margin-top: auto;

    cursor: pointer;
    display: flex;
    justify-content: center;

    padding: 30px;
    border-top: 1px solid #E9ECF1;
    box-sizing: content-box;
    height: 20px;

    &:hover {
      color: $secondary-blue-color;
    }

    &--disabled:hover {
      cursor: not-allowed;
      color: $grey-text-secondary;
    }
  }

  &::-webkit-scrollbar{
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb{
    background: #E0E0E0;
    border-radius: 30px;
  }
  &::-webkit-scrollbar-thumb:hover{
    background: #B3AFB3;
  }
  &::-webkit-scrollbar-track{
    background: transparent;
    border-radius: 0px;
    box-shadow: inset 0px 0px 0px 0px #F0F0F0;
  }
}

.edit-delay-tooltip-wrapper {
  &.show {
    opacity: 1 !important;
  }

  .edit-delay-tooltip {
    box-sizing: border-box;
    position: relative;
    margin: -9px -22px;
    padding: 20px;
    width: 200px;
    background-color: $primary-black-color;

    &__title {
      @include mulish-text;
      font-size: 14px;
      color: $grey-text-secondary;
      margin-bottom: 8px;
    }

    .input__field {
      width: 100%;
    }

    &__buttons {
      margin-top: 8px;
      display: flex;
      justify-content: space-between;
    }

    &__button {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 46px;
      border-radius: 8.5px;
      color: #fff;
      box-shadow: none;
      border: none;
      cursor: pointer;

      &--save {
        width: 68px;
        background-color: #7974F6;
      }

      &--cancel {
        width: 81px;
        background-color: #181818;
      }
    }
  }
}
