@import "src/vendors/colors";

.automation-position-tooltip {
  background-color: #000;
  padding: 12px;
  border-radius: 8px;

  &__scroll-wrapper {
    display: flex;
    flex-direction: column;
    max-height: 289px;
    overflow-y: auto;

    &::-webkit-scrollbar{
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-thumb{
      background: #474747;
      border-radius: 30px;
    }
    &::-webkit-scrollbar-thumb:hover{
      background: #B3AFB3;
    }
    &::-webkit-scrollbar-track{
      background: transparent;
      border-radius: 0;
      box-shadow: inset 0 0 0 0 #F0F0F0;
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
    width: 230px;

    padding: 7px;
    margin-top: 8px;

    background-color: $secondary-black-color;
    border-radius: 6px;

    svg {
      cursor: pointer;
    }
  }
}