@import "../../../../vendors/mixins";

.use-existing-card {
  &__details {
    @include mulish-text;
    font-size: 16px;
    height: 72px;
    padding: 0 20px;

    border-radius: 8px;
    display: flex;
    align-items: center;
  }

  &__btn {
    margin-left: auto;
    width: 45px;
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    background: $grey-selection-color;
    border-radius: 9px;

    &:hover {
      box-shadow: 0 2px 10px #ece9e9;
    }
  }
}
