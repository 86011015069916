.campaigns-page {
  display: flex;
  height: 100vh;

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 68px;
  }
}
