@import 'src/vendors/mixins';
@import 'src/vendors/colors';

.toolbox-sidebar {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 100vh;
  width: 210px;
  padding: 0 0 17px;

  background-color: $grey-bg-color;

  &__header {
    padding: 17px 14px 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #7b7b7b;
  }

  &__content {
    padding: 8px 9px;
  }

  &__link {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    color: #202430;
    text-decoration: none;
    padding: 12px;
    border-radius: 8px;

    &--active {
      color: $secondary-blue-color;
      background-color: #eaedf2;
    }
  }

  &__link-icon {
    height: 16px;
    width: 16px;
    margin-right: 7px;

    svg {
      height: 16px;
      width: 16px;
    }
  }
}
