.header-search-input {
  height: 40px;
  display: flex;
  flex: 1;
  min-width: 190px;
  width: auto;

  input {
    padding-right: 30px;
    width: 240px;
  }

  input::placeholder {
    color: #c0c6cd;
  }

  &__container {
    position: relative;
  }

  &__delete-btn {
    position: absolute;
    right: 7px;
    top: 12px;

    cursor: pointer;

    svg path {
      fill: #DBDFE7;
    }

    &:hover svg path {
      fill: #ed483d;
    }
  }
}
