@import "src/vendors/colors";

.multi-switch {
  display: inline-flex;
  align-items: center;
  border: 1px solid rgba(219, 223, 230, 0.47);
  box-sizing: border-box;
  border-radius: 10px;
  padding: 4px;

  &__option {
    height: 30px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: $grey-text-secondary;
    border-radius: 7px;
    font-size: 12px;
    cursor: pointer;
    white-space: nowrap;

    &--active {
      background: rgba(34, 28, 182, 0.12);
      color: $primary-main-color;
      cursor: default;
    }

    &:not(&--active) {
      &:hover {
        color: black;
      }
    }
  }
}