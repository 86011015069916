.k-s-advanced-settings {
  display: grid;
  grid-auto-flow: column;
  grid-template: repeat(5, 30px) / repeat(2, 1fr);
  grid-row-gap: 18px;
  grid-column-gap: 10px;

  //&__three-rows {
  //  grid-template: repeat(3, 30px) / repeat(2, 1fr);
  //}

  &__item {
    display: inline-flex;
    align-items: center;

    &.max-label-width {
      max-width: 265px;
    }

    .checkbox {
      margin-right: 12px;
    }

    .input__field {
      width: 48px;
      height: 30px;
      margin: 0 10px;
      padding: 0 8px;
      text-align: center;

      background-color: transparent;
    }

    &-grid {
      display: inline-grid;
      grid-template: repeat(1, 1fr) / repeat(2, 90px);
    }
  }

  &__location-select {
    width: 180px;
    margin-left: 14px;
  }

  &__language-select {
    width: 120px;
    margin-left: 14px;

    .date-range-select__picker {
      bottom: 30px;
      top: unset;
    }
  }

  &__top-search-filter .input__field {
    margin-left: 16px;
  }
}
