@import 'src/vendors/colors';
@import 'src/vendors/mixins';

.slate-editor {
  width: 100%;
  position: relative;

  &.focused-editor {
    box-shadow: 0px 3px 7.1px rgba(157, 170, 250, 0.24) !important;
    border-radius: 8px !important;
    border-color: #C3C9E7 !important;
  }

  & * {
    line-height: 21px !important;
  }
  &__button-disabled,
  &__button-disabled:hover {
    opacity: 0.2;
  }

  &__link {
    &,
    &:hover {
      color: #221cb6 !important;
    }
  }

  &__ul:last-child {
    margin-bottom: 0;
  }

  &__editor {
    height: 455px;
    padding: 15px 10px 10px 28px;
    overflow-y: scroll;

    &--disabled {
      pointer-events: none;
    }

    &--with-vars {
      padding: 15px 40px 10px 28px;
    }

    &--with-signature {
      min-height: 300px;
    }

    &--inline {
      height: 38px;
      padding: 0;
      width: 100%;
      overflow-y: hidden;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__editor-wrapper-inline {
    flex: 1;
    padding-left: 28px;
    font-weight: 600;
  }

  &__signature {
    // this is for empty p tags, which are became none because of emptyness
    p {
      height: 14px;
    }

    &-placeholder {
      display: flex;
      align-items: center;
      padding: 30px 24px;
      background-color: rgba(189, 189, 189, 0.24);
      border-radius: 8px;

      pointer-events: none;
      cursor: default;

      svg {
        margin-right: 11px;
      }
      p {
        height: 16px;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        color: #000000;
      }
    }
  }

  &__referral-content {
    //margin-top: 8px;
    margin-bottom: 12px;

    p {
      font-size: 14px;
    }

    a {
      color: #221cb5;
    }
  }

  &__unsubscribe-content {
    margin-top: 12px;

    * {
      color: #8f9199 !important;
    }
    p {
      font-size: 14px;
    }
  }

  &__action-button {
    margin-right: 20px;
    height: 35px;
    border: 1px solid #221cb6;
    background-color: #ffffff;
    font-size: 14px;

    color: #221cb6;

    &:hover {
      background-color: #e9eeff;
    }

    svg {
      margin-right: 9px;
      path {
        stroke: #221cb6;
      }
    }
  }

  &__controls {
    display: flex;
    align-items: center;
    box-sizing: content-box;
    height: 20px;
    padding: 30px;
    margin-top: 10px;
    border-top: 1px solid #e9ecf1;

    //.button:not(:first-child) {
    //  padding: 10px;
    //  min-width: 0;
    //}
    //
    //.button:last-child {
    //  margin-left: auto;
    //}
  }

  &__controls-separator {
    height: 16px;
    width: 1px;
    background-color: $secondary-grey-lines-color;
    margin: 0 12px;
  }

  &__right-bottom-corner-buttons {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  &__revert-button {
    border-radius: 5px;
    width: 24px;
    height: 24px;

    &:hover {
      background: #E9EEFF;

      svg {
        @include svg-colors-path-stroke($secondary-blue-color);
      }
    }
  }

  &__spinner {

    svg {
      animation: spin 1000ms;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }

    @keyframes spin {
      from {
        transform: rotate(0turn);
      }
      to {
        transform: rotate(1turn);
      }
    }
  }

  &__element {
    &--var {
      color: $primary-main-color;
      cursor: pointer;
      margin: 0 5px;
      display: inline-block;
      background-color: $secondary-grey-lines-color;
      border-radius: 3px;
    }
  }

  &__tooltip {
    display: flex;
    align-items: center;
  }

  &__tooltip-remove-btn {
    height: 20px;
  }

  &__tooltip-input {
    .input__field {
      width: 150px;
      color: #fff;
    }
  }

  &__wrapper-variables {
    &--stick {
      position: absolute;
      top: -30px;
      right: -2px;
    }
  }

  &-flag-mark,
  &-ai {
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &__tap-block {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
    }

    &__button-disabled {
      opacity: 0.2;

      &:hover {
        cursor: not-allowed;
      }
    }


    &:hover {
      background-color: $button-light-blue-color;
      border-radius: 5px;

      .slate-editor-flag-mark__tap-block {
        svg {
          path {
            stroke: $primary-main-color;
          }
        }
      }
    }

    &__tooltip {
      background-color: black;
      border-radius: 8.5px;

      .input {
        margin-left: 5px !important;
      }

      &-header {
        padding: 20px;
        font-size: 16px;
        border-bottom: 1px solid #272727;


        &-title {
          display: flex;
          align-items: flex-start;
          gap: 10px;

          svg {
            margin-top: 2px;
          }

          &-content {
            display: flex;
            flex-direction: column;
            gap: 5px;

            p {
              font-size: 16px;
            }

            span {
              color: $grey-black-color;
              font-size: 12px;
            }
          }
        }

        &-item {
          border-top: 1px solid #272727;
          padding: 10px 20px;

          &:hover {
            background-color: $secondary-black-color;
          }
        }
      }

      &-body {
        max-height: 300px;
        overflow-y: auto;
      }

      &-footer {
        border-top: 1px solid #272727;
        position: sticky;
        bottom: 0;

        .button {
          &--ghost {
            width: 100%;
            justify-content: flex-start !important;
            padding: 14px 20px 19px;
            height: 47px !important;
            border-radius: 8px !important;

            &:hover {
              background-color: $secondary-black-color !important;
              border-radius: 8px;
            }
          }
        }
      }
    }
  }

  &-ai {
    &:hover {
      background-color: $button-light-blue-color;
      border-radius: 5px;

      .slate-editor-ai__tap-block {
        svg {
          path {

            &:first-child {
              stroke: $primary-main-color;
            }

            &:nth-child(2) {
              fill: $primary-main-color;
            }
          }
        }
      }
    }

    &__tooltip {
      min-height: 490px;
      min-width: 338px;

      &.extra-tooltip {
        .slate-editor-ai__tooltip-header {
          padding-left: 13px;
        }
      }

      .input {
        margin-left: 5px !important;
      }

      &-body {
        max-height: 490px;
        overflow-y: auto;
      }

      &-header {

        &-button {
          gap: 5px;
          padding-left: 0;
          padding-right: 0;
          min-width: 63px;
          height: 16px;

          &:hover {
            svg {
              path {
                fill: #4e48dc;
              }
            }
          }
        }

        &-option {
          padding: 8px 20px;
          border-top: 1px solid #272727;
          font-size: 16px;

          &:last-child {
            border-bottom: 1px solid #272727;
          }

          &:hover {
            background-color: $secondary-black-color;
          }
        }

        &-item {
          padding: 0;

          &:hover {
            background-color: black;
          }

          &-options {
            color: $grey-black-color;
            font-size: 16px;
            padding: 8px 20px;

            &:hover {
              cursor: default ;
            }

            &-element {
              border-top: 1px solid #272727;
              padding: 10px 20px;
              font-size: 16px;
              display: flex;
              align-items: center;
              gap: 8px;

              &:last-child {
                border-bottom: 1px solid #272727;
              }

              .lock-btn {
                margin: 0 0 0 auto;
                display: none;

                &:hover {
                  cursor: pointer;
                }
              }

              &--disabled {
                &:hover {
                  background-color: $secondary-black-color;

                  .lock-btn {
                    display: block;
                  }
                }
              }

              &:hover {
                background-color: $secondary-black-color;
              }
            }
          }
        }
      }
    }
  }
}

.editor-variables-button-inline {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 31px;
  height: 31px;
  border: 1px solid #dbdfe7;
  box-sizing: border-box;
  border-radius: 8.5px;
  margin-right: 5px;
  margin-left: 5px;

  &:hover {
    cursor: pointer;
    border: 1px solid #221cb6;

    svg {
      @include svg-colors-path-fill($primary-main-color);
    }
  }
}

.slate-editor-attachment-button {
  &__label {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  &__input-field {
    display: none;
  }
}

.slate-editor-image-button {
  &__label {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  &__input-field {
    display: none;
  }
}

.slate-editor-video-button,
.slate-editor-link-button {
  &__label {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  &:hover {
    cursor: pointer;
  }
}

.slate-editor-more-actions {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &__tap-block {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
  }

  &__tooltip {
    padding: 14px 0 20px 0;
  }

  &__tooltip-item {
    padding: 0 20px;
    height: 28px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: #181818;
    }

    svg {
      margin-right: 7px;
    }
  }

  &:hover {
    cursor: pointer;
    border-radius: 5px;
    background: $button-light-blue-color;
  }
}

.absolute-slate-variable {
  position: absolute;
  right: 5px;
  top: 50px;
}

.signature-editor {
  width: 100%;
  min-width: 445px;
  position: relative;
  border: 1px solid #dbdfe7;
  border-radius: 8.5px;
  height: 300px;
  display: flex;
  flex-direction: column;

  &__editor {
    height: 250px;
    padding: 24px 17px;
    overflow-y: scroll;
  }

  &__signature-textarea-editor {
    @include input-field;
    border: none;
    resize: none;
    width: 100%;
    height: 250px;
    padding: 15px 10px 10px 28px;

    &:focus {
      outline: none;
      border: none;
    }

    &::placeholder {
      pointer-events: none;
      width: 100%;
      max-width: 100%;
      display: block;
      color: #000;
      opacity: 0.333;
      user-select: none;
      text-decoration: none;
      line-height: 21px;
    }
  }

  &__controls {
    display: flex;
    align-items: center;
    height: 46px;
    padding: 0 10px;
    border-top: 1px solid #e9ecf1;
    margin-top: auto;

    &--disabled {
      .button {
        cursor: not-allowed;

        svg {
          @include svg-colors-path($grey-text-secondary);
        }
      }
    }
  }

  &__controls-separator {
    height: 16px;
    width: 1px;
    background-color: $secondary-grey-lines-color;
    margin: 0 12px;
  }

  &__controls-right {
    margin-left: auto;
    border-left: 1px solid #e9ecf1;
    padding-left: 12px;

    .multi-switch {
      border: none;
    }
  }

  &__controls-switch-container {
  }

  &__controls-switch-option {
    padding: 9px 14px;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;

    letter-spacing: 0.03em;
    text-transform: uppercase;

    color: #8f9199;

    border: 1px solid transparent;

    &:hover {
      color: #221cb6 !important;
    }

    &--active {
      border: 1px solid #221cb6;
      background: transparent !important;
      border-radius: 8px;
    }
  }
}

.editable-footer {
  margin: 6px 0;

  .slate-editor__signature-placeholder {
    &-email-open {
      width: 34px;
      height: 34px;
      display: none;
      align-items: center;
      justify-content: center;
      background-color: rgba(34, 28, 182, 0.07);
      border-radius: 6px;
      padding: 0;
      position: absolute;
      right: 25px;
      pointer-events: visible;

      svg {
        margin-right: 0;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  &:hover {
    .slate-editor__signature-placeholder {
      border-color: #C3C9E7;
      box-shadow: 0px 3px 7.1px rgba(157, 170, 250, 0.24);

      &-email-open {
        display: flex;
      }
    }
  }
}
