@import 'src/vendors/mixins';

.inbox-sidebar-mailboxes-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 40px;
  padding: 12px;
  border-radius: 8px;
  font-size: 14px;

  cursor: pointer;

  &--active {
    background: #eaedf2;
    color: #221cb6;
  }

  &__text {
    display: flex;
    align-items: center;
  }

  &__number {
    color: #8F9199;
    font-size: 16px;
    margin-left: 5px;
  }

  &__icon {
    display: flex;
    align-items: center;
    margin-right: 7px;
  }

  &__chevron {
    display: flex;
    align-items: center;
    margin-left: 5px;

    @include svg-colors-path(#8f9199);

    transform: rotate(180deg);
  }

  &--active &__icon svg {
    @include svg-colors-path(#221cb6);
  }

  &--open &__chevron {
    transform: rotate(0);
  }

  &--disabled {
    cursor: default;
  }

  &:hover {
    color: #221cb6;
    background-color: #eaedf2;

    svg {
      @include svg-colors-path(#221cb6);
    }
  }
}
