@import "src/vendors/colors";
@import "src/vendors/mixins";

.campaign-analytics-sidebar-group {
  padding-left: 30px;

  &__item {
    @include mulish-text;
    display: block;
    text-decoration: none;
    font-size: 16px;
    line-height: 20px;
    color: $grey-text-secondary;
    margin-bottom: 18px;

    &:hover {
      color: $grey-black-color;
    }

    &--active {
      color: $primary-main-color;
    }
  }
}