@import "../../vendors/colors";
@import "../../vendors/mixins";

.workspace-select {
  width: 51px;
  height: 51px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 11px;
  position: relative;
  font-size: 18px;
  text-transform: uppercase;
  color: #221CB6;
  cursor: pointer;


  &__short-content {
    box-sizing: border-box;
    height: 47px;
    width: 47px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    background: #DBE2FE;
    border-radius: 10px;

    &:hover {
      background: #221CB6;
      color: #FFFFFF;
    }
  }

  &__logo {
    width: 25px;
    height: 25px;
    border-radius: 100%;
  }
}