@import "src/vendors/colors";
@import "src/vendors/mixins";

.poor-number-input {
  position: relative;
  width: 100%;

  &__decrease-btn,
  &__increase-btn {
    @include mulish-text;
    width: 25px;
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $grey-icons-color;
    font-size: 16px;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:hover {
      color: $primary-main-color;
    }
  }

  &__decrease-btn {
    left: 10px;
  }

  &__increase-btn {
    right: 10px;
  }

  &__field {
    width: 100%;
    border: 1px solid #DBDFE7;
    border-radius: 8.5px;
    height: 38px;
    text-align: center;
  }
}