.billing-card {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0 4px 90px rgba(201, 201, 201, 0.25);
  border-radius: 8px;

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f3f3f3;
    padding: 14px 20px 14px 20px;
    min-height: 68px;
  }

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
  }

  &__subtitle {
    margin-top: 3px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #8d8f92;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 14px 20px 20px 20px;
    min-height: 93px;

    &-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      &-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: #000000;
      }

      &-subtitle {
        margin-top: 2px;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color: #8d8f92;
      }
    }

    &-chart {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;
      margin-top: 15px;
    }

    &-chart-bar-container {
      display: flex;
      width: 100%;
      margin: 0;
      height: 8px;
      background-color: #eeeeee;
    }
    &-chart-bar {
      height: 8px;
      background-color: #3424eb;
    }
  }

  &__chart-legend {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #8d8f92;

    svg {
      margin-top: 2px;
    }

    &-number {
      font-weight: 600;
      font-size: 24px;
      line-height: 24px;
      color: #221cb6;
    }
  }
}
