@import "src/vendors/mixins";
@import "src/vendors/colors";

.demo-popup {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;

  &__bg {
    height: 100vh;

    background-color: rgba(0, 0, 0, 0.36);

    animation: comein .3s linear forwards;
  }

  &__message-window {
    height: 900px;
    min-width: 1200px;
    max-width: 1400px;

    background-color: #FFFFFF;
    border-radius: 12px;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  &__close-icon {
    position: absolute;
    right: 12px;
    top: 12px;

    height: 24px; /* this can be anything */
    width: 24px;  /* ...but maintain 1:1 aspect ratio */
    display: flex;
    flex-direction: column;
    justify-content: center;

    cursor: pointer;

    &::before,
    &::after {
      position: absolute;
      content: '';
      width: 100%;
      height: 2px; /* cross thickness */
      background-color: black;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }

  &__title {
    font-weight: 600;
    font-size: 42px;
    line-height: 53px;
    margin-top: 25px;
  }

  &__description {
    margin-top: 18px;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;

    color: #8F9199;
  }

  &__clients-logo {
    margin-top: 120px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 30px;
  }
}

@keyframes comein {
  from {
    opacity: 0;
  }

  to {
    opacity: .3;
  }
}

@keyframes comein-message {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media screen and (max-width: 1481px) {
  .exit-popup {
    &__message-window {
      height: auto;
      width: 1000px;
      min-width: 1000px;
      max-width: 1000px;
    }
  }
}

@media screen and (max-height: 870px) {
  .exit-popup {
    &__close-icon {
      top: 15px;
      right: 20px;
      z-index: 1000000;

      &::before,
      &::after {
        background-color: black;
      }
    }
  }
}
