@import 'src/vendors/colors';
@import 'src/vendors/mixins';

.configurable-sidebar {
  width: 210px;
  height: 100vh;
  flex-shrink: 0;
  background-color: $grey-bg-color;

  display: flex;
  flex-direction: column;

  &__header {
    padding: 17px 14px 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #7b7b7b;
  }

  &__body {
    flex: 1;
    padding: 0 9px;
    overflow: auto;
  }

  &__footer {
    margin-top: auto;
  }

  &__group-title {
    @include mulish-text;
    font-size: 16px;
    line-height: 20px;
    color: $grey-black-color;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__group-action-icon {
    height: 20px;
    cursor: pointer;

    &:hover {
      svg {
        @include svg-colors-path-fill($secondary-black-color);
      }
    }
  }

  &__group {
    &:not(&:first-child) {
      margin-top: 30px;
    }
  }

  &__link-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    cursor: pointer;

    &--active {
      color: $secondary-blue-color;
      background: $grey-selection-color;
      border-radius: 8.5px;
    }
  }

  &__link {
    @include mulish-text;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
    overflow: hidden;
    width: 95%;
  }

  &__link-icon {
    margin-right: 7px;
    display: flex;
    align-items: center;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &__link-text {
    white-space: nowrap;

    &--cropped {
      width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__group-pages {
    padding-top: 12px;
  }
}
