@import 'src/vendors/colors';
@import 'src/vendors/mixins';
@import 'src/vendors/variables';

.inbox-threads-list {
  min-width: 270px;
  height: calc(100vh - #{$page-header-height});
  max-height: 100vh;
  overflow-y: auto;
  border-right: 1px solid $secondary-grey-lines-color;

  .inbox-threads-list-item {
    padding: 25px 15px 20px 10px;
    cursor: pointer;
    position: relative;

    &:hover {
      background-color: $grey-selection-color;
    }

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: #e9ecf1;
    }

    &--active {
      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 15px;
        bottom: 15px;
        width: 2px;
        background-color: $secondary-blue-color;
        border-radius: 10px 0 0 10px;
      }
    }

    &__header {
      display: flex;
      justify-content: space-between;
    }

    &__from-name {
      @include mulish-text;
      font-weight: 600;
      font-size: 14px;
      color: #202430;
    }

    &__subject {
      @include mulish-text;

      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;

      font-size: 14px;
      line-height: 17px;
      color: $grey-text-secondary;
      margin-top: 10px;

      overflow: hidden;
    }

    &__last-activity-time {
      @include mulish-text;
      font-size: 14px;
      color: $grey-text-secondary;
    }
  }
}
