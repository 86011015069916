@import 'src/vendors/mixins';
@import 'src/vendors/colors';

.rate-cell  {
  display: flex;
  align-items: center;

  &__diagram {
    width: 38px;
    height: 38px;
  }

  &__value {
    @include mulish-text;
    font-size: 12px;
    line-height: 130%;
    color: $grey-text-color;

    margin-left: 8px;
  }
}