@import 'src/vendors/colors';
@import 'src/vendors/mixins';

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.article-summary {
  display: flex;
  flex-direction: column;
  margin-left: 13px;
  height: auto;
  width: 317px;
  padding: 0 10px;
  overflow-y: auto;
  background: #fbfcfd;
  max-height: calc(100vh - 126px);

  &__title {
    display: flex;
    align-items: flex-start;
    @include mulish-text;
    font-size: 16px;
    line-height: 20px;
    margin-top: 30px;
    margin-left: 18px;

    .dropdown-btn {
      width: 24px;
      height: 24px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        svg {
          path {
            fill: #221CB6;
          }
        }
      }

      &--open {
        transform: rotate(180deg);
      }
    }
  }

  &__text {
    padding: 14px 18px;
    padding-bottom: 0;

    h3 {
      color: #202430;
      font-size: 14px;
      margin-bottom: 14px;
      font-weight: 600;
    }

    &-description {
      color: rgba(0, 0, 0, 0.58);
      font-size: 12px;
      margin-bottom: 8px;
    }

    &-uid {
      a {
        display: flex;
        align-items: center;

        svg {
          flex: none;
          margin-right: 8px;
        }
      }

      &-link {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
      }
    }

    & ~ .article-summary__list {
      margin-top: 0;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    background: #fbfcfd;
    width: 100%;

    &-container {
      max-height: 440px;
      overflow: auto;
    }
  }

  &__empty-content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 31px 16px 30px 16px;
    font-size: 14px;
    color: $grey-text-color;
  }

  &__list-item {
    display: flex;
    word-wrap: break-word;
    overflow-wrap: anywhere;

    padding: 21px 29px 31px 16px;

    font-weight: 400;
    font-size: 14px;
    line-height: 19px;

    color: #202430;

    .copy-icon {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 26px;
      height: 26px;
      border-radius: 6px;
      margin-top: -3px!important;
      margin-left: -6px;
      margin-right: 0;

      &:hover {
        svg path {
          stroke: #221CB6;
          fill: transparent;
        }
        background-color: #e9eeff;
      }
    }

    span:first-child {
      margin-top: 4px;
    }

    span:not(:first-child) {
      margin-left: 6px;
    }

    &:not(&:last-child) {
      border-bottom: 2px solid #e9ecf1;
    }
  }

  &__button {
    width: 220px;
    background-color: #dbe2fe;
    border-radius: 8px;
    margin: 29px auto;
    color: #251fb6;
    &:hover {
      opacity: 0.9;
    }
  }

  &__refresh-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
    border-radius: 6px;
    height: 26px;
    width: 26px;
    cursor: pointer;

    &--animated {
      animation: rotate linear 1.5s infinite;
    }

    &:hover {
      background-color: #e9eeff;
      svg path {
        stroke: #251fb6;
      }
    }
  }

  .podcast-summary-item {
    display: flex;
    flex-direction: column;

    &__info {
      display: flex;
    }

    &__image {
      width: 42px;
      height: 42px;
      border-radius: 8px;
      margin-right: 12px;
    }

    &__title {
      @include mulish-text;
      display: block;
      max-width: 195px;
      white-space: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: $secondary-blue-color;
      margin-bottom: 8px;
      text-decoration: none;

      &:hover {
        color: $secondary-light-blue-color;
      }
    }

    &__description {
    }
  }
}
