@import 'src/vendors/mixins';
@import 'src/vendors/colors';

.inbox-widget {
  &__footer {
    .button--ghost {
      min-width: 22px;
      height: 22px;

      &:hover {
        background-color: $button-light-blue-color;
        border-radius: 5px;

        svg {
          @include svg-colors-path-fill($primary-main-color);
        }
      }
    }
  }

  &__trash-icon {
    min-width: 22px;
    height: 22px;
    padding: 5px !important;

    &:hover {
      background-color: $button-light-blue-color;
      border-radius: 5px;

      svg {
        @include svg-colors-path($primary-main-color);
      }
    }
  }
}
