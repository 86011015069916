@import '../../../vendors/colors';
@import '../../../vendors/mixins';

.share-email-modal {
  height: 450px;
  display: flex;
  flex-direction: column;

  &__header {
    padding: 27px 24px 24px 24px;
    border-bottom: 1px solid #f4f4f4;
    display: flex;
    align-items: center;
  }

  &__title {
    @include mulish-text;
    font-weight: 600;
    font-size: 20px;
    color: #1b1e22;
    margin-right: 12px;
  }

  &__title-email {
    @include mulish-text;
    font-size: 14px;
    line-height: 18px;
    padding: 5px 8px;
    display: flex;
    align-items: center;
    color: $primary-main-color;
    background: $light-main-color;
    border-radius: 8.5px;
  }

  &__title-email-icon {
    margin-right: 6px;
    margin-top: -2px;
    height: 12px;
  }

  &__body {
    padding: 22px;
    overflow: auto;
  }

  &__workspace-select {
    margin-bottom: 24px;
  }

  &__workspace-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    .workspace-row {
      &__title {
        @include mulish-text;
        font-weight: 600;
        font-size: 14px;
        color: #1b1e22;
      }

      &__remove-icon {
        cursor: pointer;

        svg {
          @include svg-colors-path-fill(#fc0e0e);
        }
      }
    }
  }

  &__footer {
    padding: 22px;
    padding-top: 10px;
    margin-top: auto;
  }
}
