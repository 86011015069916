@import "src/vendors/mixins";
@import "src/vendors/colors";

.workspaces-sidebar {
  position: absolute;
  top: 0;
  left: 0;
  width: 295px;
  height: 100vh;
  background: #FFFFFF;
  box-shadow: 0 4px 50px rgba(18, 16, 68, .09);
  display: flex;
  flex-direction: column;
  z-index: 20;

  &__header {
    @include mulish-text;
    height: 80px;
    padding: 0 18px;
    border-bottom: 1px solid #F4F4F4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
  }

  &__close-icon {
    cursor: pointer;

    &:hover {
      opacity: .5;
    }
  }

  &__body {
    padding: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
  }

  &__search {
    padding: 0 18px;
    padding-top: 18px;
    position: sticky;
    top: 0;
    background: white;
    z-index: 10;
  }

  &__workspaces-list {
    margin-top: 20px;
  }

  &__workspace-row {
    display: flex;
    position: relative;
    align-items: center;
    cursor: pointer;
    padding: 5px 18px;

    &:hover {
      background-color: #EEF3FF;
    }
  }

  &__workspace-info {
    margin-left: 13px;
    max-width: 180px;
  }

  &__workspace-title {
    @include mulish-text;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 3px;

    max-width: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__workspace-members {
    @include mulish-text;
    font-size: 14px;
    color: $grey-black-color
  }

  &__warning-triangle {
    position: absolute;
    left: 12px;
    top: 0;
  }

  &__active-workspace {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 26px;
    background-color: rgba(35, 231, 113, .12);
    border-radius: 100%;
    margin-left: auto;
  }

  &__create-new-workspace {
    padding: 0 18px;
    margin-top: auto;
    display: flex;
    position: sticky;
    bottom: 0;
    background: white;
    padding-bottom: 18px;

    .button {
      width: 100%;
    }
  }
}
