@import "src/vendors/mixins";
@import "src/vendors/colors";

@mixin cardInfoField {
  border: 1px solid #DBDFE7;
  border-radius: 8.5px;
}

@mixin cardFieldLabel {
  font-weight: 600;
  font-size: 14px;
  color: $grey-text-secondary;
}

.card-widget {
  width: 400px;
  min-width: 400px;
  box-shadow: 0 4px 90px rgba(201, 201, 201, .25);
  border-radius: 8px;
  padding: 36px 36px 50px;
  margin-bottom: 38px;

  &__title {
    @include mulish-text;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }

  &__action-btn {
    cursor: pointer;
    margin-left: 7px;
    height: 20px;

    &--edit {
      &:hover {
        @include svg-colors-path-stroke($primary-main-color);
      }
    }

    &--cancel {
      &:hover {
        @include svg-colors-path-fill($alert-hover-color);
      }
    }
  }

  &__filled-grid {
    display: grid;
    grid-template-areas:
      "cardNumber cardNumber"
      "expiration cvv";
    grid-template-rows: repeat(2, 75px);
    grid-template-columns: 3fr 1fr;
    grid-column-gap: 13px;
    grid-row-gap: 30px;
  }

  &__filled-grid-card-number {
    grid-area: cardNumber;
  }

  &__filled-grid-expiration {
    grid-area: expiration;
  }

  &__filled-grid-cvv {
    grid-area: cvv;
  }

  &__unfilled-grid {
    display: grid;
    grid-template-areas:
      "cardNumberLabel cardNumberLabel"
      "cardNumber cardNumber"
      "expirationLabel cvvLabel"
      "expiration cvv";
    grid-template-rows: 20px 50px 20px 50px;
    grid-template-columns: 3fr 1fr;
    grid-column-gap: 13px;
    grid-row-gap: 10px;
  }

  &__unfilled-grid-card-number-label {
    @include cardFieldLabel;
    grid-area: cardNumberLabel;
  }

  &__unfilled-grid-expiration-label {
    @include cardFieldLabel;
    grid-area: expirationLabel;
  }

  &__unfilled-grid-cvv-label {
    @include cardFieldLabel;
    grid-area: cvvLabel;
  }

  &__unfilled-grid-card-number {
    grid-area: cardNumber;
    max-height: 40px;
    @include cardInfoField;
  }

  &__unfilled-grid-expiration {
    grid-area: expiration;
    max-height: 40px;
    @include cardInfoField;
  }

  &__unfilled-grid-cvv {
    grid-area: cvv;
    max-height: 40px;
    @include cardInfoField;
  }

  &__update-button {
    display: block;
    width: 100%;
    margin-top: 20px;
  }
}