.insights-filters {
  background-color: black;
  border-radius: 8px;
  width: 272px;

  &__header {
    display: flex;
    flex-direction: column;
    padding: 17px 20px 0px 20px;
  }

  &__search-input {
    display: flex;
    margin-bottom: 20px;

    .input {
      width: 100%;
      border: 1px solid #272727;
      border-radius: 6px;
      padding: 5px 13px;

      &__field {
        color: #FFFFFF;
      }
    }

  }

  &__toogle_buttons {
    margin-bottom: 20px;
  }

   &__body {
     padding-bottom: 9px;
     max-height: 600px;
     overflow-y: auto;
   }
}