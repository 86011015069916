@import 'src/vendors/mixins';
@import 'src/vendors/colors';

.onboarding-page {
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 45px 45px 20px;
    height: auto;
  }
  &__header-signout-button {
    background-color: $secondary-grey-lines-color;
    color: #575757;

    border-radius: 8px;

    white-space: nowrap;

    &:hover {
      color: #424242;
    }
  }

  &__logo {
    flex-shrink: 0;

    svg {
      width: 134px;
      height: auto;
    }
  }
}
