@import "src/vendors/mixins";
@import "src/vendors/colors";

.relationships-remove-modal {
  @include mulish-text;
  width: 500px;
  height: 220px;
  display: flex;
  flex-direction: column;

  &__header {
    @include modal-header;
  }

  &__body {
    flex: 1;
    padding: 24px;
  }

  &__footer {
    padding: 24px;
    display: flex;
    justify-content: space-between;
  }
}