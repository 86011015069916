@import 'src/vendors/colors';
@import 'src/vendors/mixins';

@mixin row-title {
  @include mulish-text;
  font-weight: 600;
  font-size: 14px;
  color: inherit;
}

.subscription-form {
  padding-top: 62px;
  padding-bottom: 40px;
  display: flex;
  justify-content: center;

  &__header-title {
    display: flex;
    align-items: center;
  }

  &__content {
    width: 700px;
  }

  &__params-row {
    display: grid;
    grid-template-columns: 325px 325px;
    grid-gap: 50px;
  }

  &__param-title {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;

    font-weight: 600;
    font-size: 14px;
    line-height: 14px;

    margin-bottom: 8px;

    color: #3a3b3f;

    &-with-tooltip {
      align-items: center;
      height: calc(100% - 20px);
      justify-content: flex-start;
      gap: 4px;
    }
  }

  &__param-field {
    .poor-number-input input {
      height: 48px;
    }
    padding-bottom: 10px;
  }

  &__param-unlimited-field {
    margin-bottom: 24px;
  }

  &__param-enterprise-field {
    margin-bottom: 10px;
  }

  &__param-unlimited-field,
  &__param-enterprise-field {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 48px;
    border-radius: 8px;
    border: 1px solid #dbdfe7;

    font-weight: 400;
    font-size: 16px;
    color: #3a3b3f;

    svg {
      margin-right: 7px;
      path {
        fill: #3a3b3f;
      }
    }
    //color: #FFFFFF;
  }

  &__param-label {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #8f9199;
  }

  &__billing-cycle-title {
    @include row-title;
    margin-bottom: 15px;
  }

  &__price,
  &__due-today-price {
    @include mulish-text;
    margin-top: 30px;
    padding: 0 35px;
    border: 1px solid #dbdfe7;
    border-radius: 8px;
    height: 154px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 16px;
  }

  &__price-value {
    @include mulish-text;
    font-weight: 600;
    font-size: 28px;
  }

  &__price-interval {
    @include mulish-text;
    font-weight: 600;
    font-size: 18px;
  }

  &__due-today-price {
    background: #f5f6fa;
    border: none;
    margin-top: 10px;
  }

  &__due-today-price-value {
    @include mulish-text;
    font-weight: 600;
    font-size: 64px;
    color: $primary-main-color;
  }

  &__payment-details-title {
    @include row-title;
    margin-top: 39px;
    margin-bottom: 10px;
  }

  &__apply-btn {
    width: 100%;
    margin-top: 8px;
    display: block;

    font-size: 14px;
    height: 43px;
    &--hidden {
      display: none;
    }
  }

  &__contact_us-btn {
    width: 100%;
    margin-top: 15px;
    display: block;

    font-size: 14px;
    height: 43px;
  }

  &__plans-btn {
    height: 43px;
    font-size: 14px;

    &:hover {
      background: #F5F6FA !important;
      color: $primary-main-color !important;
    }

  }

  &__card {
    display: flex;
    align-items: center;
    height: 78px;
    border-radius: 8px;
    background-color: #ffffff;
    border: 1px solid #dbdfe7;

    .use-card-to-pay {
      height: auto;
    }
  }

  &__note {
    margin-top: 32px;
    display: flex;
    width: 100%;
    justify-content: center;
    color: #8F9199;
    height: 18px;
    line-height: 18px;
    font-size: 12px;

    a {
      color: #8F9199;
      margin-left: 2px;
    }

    svg {
      margin-right: 3px;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 25px 0;
    color: $grey-text-secondary;
    font-weight: 600;
    font-size: 12px;
  }

  &__footer-icon {
    height: 18px;
    margin-right: 7px;
  }

  &__checkbox {
    margin-top: 30px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #000000;

    & > input {
      margin-right: 14px;
    }
  }

  &__terms {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #8f8f8f;
  }
}
