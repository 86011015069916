.react-time-picker__inputGroup__input {
    padding: 0;
}

.react-time-picker__inputGroup__input::-webkit-outer-spin-button,
.react-time-picker__inputGroup__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.react-time-picker__inputGroup__input[type=number] {
    -moz-appearance: textfield;
}

.react-time-picker__clear-button {
    display: none;
}

.react-time-picker__inputGroup__divider {
    margin-right: 2px;
}
