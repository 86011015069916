@import "src/vendors/mixins";
@import "src/vendors/colors";

.back-arrow-button {
  min-width: auto;
  padding: 0 10px;

  margin-right: 7px;

  transition: background-color 150ms ease;

  &:hover {
    svg {
      @include svg-colors-path-fill($grey-text-color);
    }
  }
}