@import 'src/vendors/mixins';
@import 'src/vendors/colors';

.block-header {
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $secondary-grey-lines-color;

  &_title {
    @include mulish-text;
    font-size: 18px;
    font-weight: 600;
    margin-right: 10px;

    &_select {
      width: 220px;
      margin: 0 auto 0 0;

      .css-eqy8aq-control {
        background-color: transparent;
      }

      .css-1hbfoiv-singleValue {
        color: #3424EB;
        font-weight: 600;
        font-size: 18px;
      }
    }
  }

  &-container {
    display: flex;
    align-items: center;

    &_buttons {
      display: flex;
      align-items: center;

      &_item {
        &:last-child {
          margin-left: 7px;
        }
      }
    }
  }
}

