@import '../../../vendors/mixins';
@import '../../../vendors/colors';

.team-filter {
  width: 100%;

  &__input-wrapper {
    margin: 13px;
    border: 1px solid #272727;
    border-radius: 8px;

    .input__field {
      color: #dadada;
      width: 100%;
    }
  }

  &__everybody-icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #DFE5FA;

    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      @include svg-colors-path-fill(#2752EA);
    }
  }

  &__everybody-title, &__person-item {
    @include mulish-text;
    cursor: pointer;
    color: #FFFFFF;
  }

  &__everybody-title {
    background-color: $secondary-black-color;
    padding: 10px 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
  }

  &__people-list {
    max-height: 260px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $grey-black-color;
      border-radius: 20px;
      border: solid .3px #272727;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }

  &__person-item {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-top: solid 1px #272727;
    font-weight: 400;
  }

  &__person-avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }

  &__person-name {
    margin-left: 12px;
  }

  &__chosen-person-icon {
    margin-left: auto;
  }
}