@import "../../vendors/colors";
@import "../../vendors/mixins";

.editor-email-meta {
  box-sizing: border-box;
  background-color: #fff;
  padding: 20px 0 25px 25px;
  border-bottom: 1px solid $secondary-grey-lines-color;

  &__row {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__row-buttons {
    margin-top: 12px;
    display: flex;
  }

  &__label {
    @include mulish-text;
    font-size: 14px;
    color: $grey-text-secondary;
    margin-right: 6px;
  }

  &__value {
    @include mulish-text;
    font-size: 14px;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    height: 22px;
    padding: 4px 6px;
    background: $grey-selection-color;
    border-radius: 5px;
    margin-right: 6px;

    svg {
      width: 14px;
      height: 14px;
      margin-left: 4px;
      cursor: pointer;
    }
  }

  &__remove-icon {
    height: 14px;
  }

  &__adding-button {
    @include mulish-text;
    font-size: 12px;
    margin-right: 12px;
    color: $secondary-blue-color;
    cursor: pointer;
  }

  &__chevron {
    cursor: pointer;

    svg {
      transform: rotateX(180deg);
    }
  }

  /* Overload styles */
  .input {
    &__field {
      height: 22px;
      padding-left: 0;
    }
  }

  .editor-email-meta-contacts {
    padding: 10px 0;

    &__contact {
      cursor: pointer;
      padding: 3px 10px;

      &:hover {
        background-color: $secondary-black-color;
      }

      &:not(&:last-child) {
        margin-bottom: 5px;
      }
    }
  }
}