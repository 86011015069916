@import 'src/vendors/colors';
@import 'src/vendors/mixins';

.slate-attachments {
  padding-bottom: 7px;
  margin-bottom: 7px;
  border-bottom: 1px solid $secondary-grey-lines-color;
  display: flex;
  align-items: center;
  gap: 9px;

  &__file-wrapper {
    position: relative;
  }

  &__file {
    height: 50px;
    max-width: 150px;
    background: $secondary-grey-lines-color;
    border-radius: 8px;
    padding: 6px 10px;
    position: relative;
  }

  &__file-title {
    @include mulish-text;
    height: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
    white-space: nowrap;
    flex-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__file-size {
    margin-top: 4px;
    @include mulish-text;
    color: rgba(0, 0, 0, 0.4);
    padding-left: 29px;
    font-size: 12px;
  }

  &__file-remove {
    position: absolute;
    top: -6px;
    right: -6px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      @include svg-colors-path-fill($alert-hover-color);
    }
  }

  &__loader {
  }
}
