@import 'src/vendors/mixins';
@import 'src/vendors/colors';

.change-billing-form {
  padding: 15px 20px 50px 20px;
  display: flex;

  &__title {
    @include mulish-text;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 25px;
    padding-top: 40px;
  }

  &__address {
    width: 60%;
    max-width: 550px;
    position: relative;
    margin-right: 50px;
  }

  &__address-grid {
    display: grid;
    grid-template-areas:
      'name name'
      'addressLine1 addressLine1'
      'addressLine2 addressLine2'
      'city zipCode'
      'state country'
      'tax tax';
    grid-template-rows: repeat(6, auto);
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 13px;
  }

  &__name {
    grid-area: name;
  }

  &__addressLine1 {
    grid-area: addressLine1;
  }

  &__addressLine2 {
    grid-area: addressLine2;
  }

  &__city {
    grid-area: city;
  }

  &__zip-code {
    grid-area: zipCode;
  }

  &__state {
    grid-area: state;
  }

  &__country {
    grid-area: country;
  }

  &__tax {
    grid-area: tax;
  }

  &__contacts {
    margin-top: 25px;
  }

  &__contacts-grid {
    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: 1fr;

    button {
      height: 48px;
    }
  }

  &__close-btn {
    cursor: pointer;
  }
}
