@import "../../vendors/colors";
@import "../../vendors/mixins";

.actions-tooltip-content {
  &__item {
    display: flex;
    align-items: center;
    padding: 7px 18px 7px 22px;

    &:first-child {
      margin-top: 14px;
    }

    &:last-child {
      margin-bottom: 14px;
    }

    span {
      white-space: nowrap;
    }

    &:hover {
      background-color: $secondary-black-color;
    }

    /* Overlap styles */
    .input {
      margin: 0;
      width: 100%;

      &__field {
        color: #fff;
        padding-left: 0;
      }
    }
  }

  &__item-icon {
    margin-right: 8px;
  }
}