@import 'src/vendors/mixins';
@import 'src/vendors/colors';

.step-create-workspace {

  &__row {
    margin-top: 2rem;
  }

  &__labels {
    display: flex;
    justify-content: space-between;
    @include mulish-text;
    color: $grey-text-secondary;

    &--main {
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
    }

    &--additional {
      font-size: 12px;
      font-weight: 400;
      line-height: 12px;
    }
  }

  &__input {
    margin-top: 8px;
    height: 48px;
  }

  &__button-continue {
    float: right;
    margin-top: 70px;
  }
}