@import "../../vendors/colors";
@import "../../vendors/mixins";

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .4);
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 120;
}

.modal {
  position: relative;
  border-radius: 8px;
  background: #FFFFFF;
  min-width: 400px;
  min-height: 220px;
  max-height: calc(100vh - 60px);
  overflow-y: auto;
  max-width: 1070px;
  width: 100%;

  &.fitWidthContent {
    width: auto;
  }

  &__cross-btn {
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
    z-index: 100;

    &:hover {
      svg {
        @include svg-colors-path-fill($grey-text-color);
      }
    }
  }
}
