@import 'src/vendors/colors';
@import 'src/vendors/mixins';

.tabs {
  .scroll-groups__children {
    padding-left: 0;
    padding-right: 0;
  }
  &__list {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $secondary-grey-lines-color;
    padding: 0 15px;
  }

  &__tab-title {
    @include mulish-text;
    font-size: 16px;
    color: $grey-text-secondary;
    padding-bottom: 12px;
    cursor: pointer;
    white-space: nowrap;

    &:not(&:last-child) {
      margin-right: 20px;
    }

    &--active {
      color: #202430;
      border-bottom: 2px solid $primary-main-color;
    }
  }

  &__active-content {
    padding: 20px;
  }
}
