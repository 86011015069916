.fixed-payment-footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  position: sticky;
  bottom: 0;
  left: 0;

  min-height: 72px;
  width: 100%;
  margin-top: 32px;
  padding-bottom: 10px;

  background-color: #ffffff;

  .comment-section {
    margin: 20px auto;
  }

  &__make-payment-block {
    width: 720px;
    height: 100%;

    box-shadow: 0 2px 20px #eaecf4;
    border-radius: 8px;

    margin-left: 10px;

    display: inline-flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 12px 0 0;

    &--grey {
      background-color: rgba(37, 31, 182, 0.04);
      box-shadow: none;
    }
  }

  &__make-payment-button {
    margin-left: 7px;
    white-space: nowrap;
    height: 40px;
    min-width: auto;
    font-size: 16px;

    &--hidden {
      display: none;
    }
  }
}
