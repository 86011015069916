.billing-cancellation-review-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 405px;
  padding: 40px 40px 32px;

  &__icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 110px;
    height: 110px;
    margin-bottom: 39px;
    background-color: #F0F4FF;
    border-radius: 50%;
  }

  &__title {
    margin-bottom: 7px;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #000000;
  }

  &__subtitle {
    margin-bottom: 30px;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    text-align: center;

    color: #615F5F;
  }

  button {
    margin-bottom: 12px;
  }
}
