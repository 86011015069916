@import 'src/vendors/mixins';
@import 'src/vendors/colors';

.last-invoice-card {
  box-shadow: 0 4px 90px rgba(201, 201, 201, 0.25);
  border-radius: 8px;

  &__header {
    @include card-header;
  }

  &__last-invoice {
    display: inline-flex;
    align-items: center;
  }

  &__last-invoice-number {
    @include mulish-text;
    font-size: 16px;
    line-height: 20px;
    color: $grey-text-color;
  }

  &__warning-triangle {
    display: inline-flex;
    margin-left: 10px;
    cursor: pointer;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &__last-invoice-link {
    text-decoration: none;
    color: #3424eb;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;

  }

  &__body {
    padding: 32px 20px;
  }

  &__body-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(&:last-child) {
      margin-bottom: 24px;
    }
  }

  &__body-label {
    @include mulish-text;
    font-size: 14px;
    line-height: 17px;
  }

  &__body-value {
    @include mulish-text;
    font-size: 14px;
    line-height: 17px;
    color: $grey-text-color;
  }
}
