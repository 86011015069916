@import 'src/vendors/colors';
@import 'src/vendors/mixins';

.billing {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__content {
    padding: 26px 30px;
    min-width: 900px;
    width: 100%;

    &-container {
      flex: 1;
      display: flex;
      overflow: auto;
    }
  }

  &__grid-top {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 25px;
  }

  &__grid-bottom {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 25px;
    margin-top: 25px;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: 25px;
    padding-top: 25px;
    border-top: 2px solid #f9f9f9;

    button {
      width: auto;
    }
  }

  &__footer-title {
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
  }

  &__footer-description {
    max-width: 700px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #8f8f8f;
  }
}
