@import "src/vendors/colors";
@import "src/vendors/mixins";

.settings-sidebar {
  &--disabled {
    pointer-events: none;
  }

  box-sizing: border-box;
  height: 100vh;
  background-color: $grey-bg-color;
}