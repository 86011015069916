.password-score {
  display: flex;
  justify-content: space-between;
  align-items: center;


  padding-right: 7px;
  &__label {
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    color: #8E95AA;
  }

  &__blocks-list {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__block {
    width: 20px;
    height: 5px;
    background-color: #dde4ee;
    border-radius: 35px;

    &:not(&:last-child) {
      margin-right: 12px;
    }

    -moz-transition: background-color 300ms ease-in;
    /* WebKit */
    -webkit-transition: background-color 300ms ease-in;
    /* Opera */
    -o-transition: background-color 300ms ease-in;
    /* Standard */
    transition: background-color 300ms ease-in;
  }

  &--bad {
    .password-score__label {
      color: #f64754;
    }
    .password-score__block {
      &:nth-child(1) {
        background-color: #f64754;
      }
    }
  }

  &--average {
    .password-score__label {
      color: #f2884f;
    }
    .password-score__block {
      &:nth-child(1),
      &:nth-child(2) {
        background-color: #f2884f;
      }
    }
  }

  &--strong {
    .password-score__label {
      color: #4fcf64;
    }
    .password-score__block {
      background-color: #4fcf64;
    }
  }
}
