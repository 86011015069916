.health-score-tile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 91px;

  border-radius: 9px;
  padding: 3px 5px 4px 11px;

  background-color: #e9fdf1;
  color: #23e771;
  font-weight: 700;
  font-size: 12px;

  &--warning {
    background-color: #fff9e8;
    color: #ffc300;

    div {
      background-color: #ffc300;
    }
  }

  &--danger {
    background-color: rgba(252, 14, 14, 0.1);
    color: #fc0e0e;

    div {
      background-color: #fc0e0e;
    }
  }

  &__number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #23e771;
    color: #ffffff;
    font-weight: 600;
    font-size: 13px;
  }
}
