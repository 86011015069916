.semrush-metrics-include-paid {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 54.2%;
  height: 100%;
  background-color: #ffffff;
  padding: 24px;
  border: 1px dashed rgba(0, 0, 0, 0.27);
  border-radius: 8px;

  span {
    width: 208px;
    height: 69px;
    margin-bottom: 16px;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: #202430;
  }

  a,
  button,
  a > button {
    width: 100%;
  }
}

.semrush-metrics-paid {
  position: relative;
}

.semrush-metrics-remove-filters {
  padding: 0;
  float: right;
  margin-top: 12px;
  margin-right: 5px;
  color: #7b7b7b;
}
