@import 'src/vendors/mixins';

.header-date-filter {
  @include mulish-text;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $grey-black-color;
  border: solid 1px #E8E8E8;
  border-radius: 8px;
  padding: 10px 10px 10px 14px;
  cursor: pointer;
  min-width: 235px;
  height: 40px;
  line-height: 19px;

  svg {
    margin-right: 0;
    margin-left: auto;
    margin-bottom: 1px;
    transform: rotate(180deg);
  }
}

.react-tooltip--header-date-filter {
  background-color: #000000 !important;

  &:after {
    left: 95% !important;
  }

  .rdrDateRangePickerWrapper {
    .rdrCalendarWrapper {
      .rdrMonthsVertical {
        .rdrDays {
          .rdrDay {
            color: white !important;
          }
        }
      }
    }
  }
}
