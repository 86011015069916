.footers {
  display: flex;
  align-items: center;
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  margin-top: 24px;
  padding-bottom: 24px;
  background-color: #fff;

  padding-top: 24px;
  border-top: 1px solid rgba(99, 99, 99, 0.21);

  &__continue-btn {
    margin-left: auto;
  }

  &__second-step-btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &__notify {
      display: flex;
      align-items: center;
    }

    &__notify-title {
      margin-left: 8px;
    }
  }
}
