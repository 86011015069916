@import "../../vendors/colors.scss";
@import "../../vendors/mixins.scss";

.advanced-search-list {
  z-index: 10000;
  height: 100vh;
  background: #FFFFFF;

  padding: 32px 48px;

  &__back-link {
    margin-right: 16px;

    &:hover svg {
      @include svg-colors-path-fill($secondary-blue-color);
    }
  }

  &__title {
    @include mulish-text;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
  }

  &-table {
    width: 100%;
    margin-top: 32px;
    border-collapse: collapse;
    border-radius: 8.5px 8.5px 0 0;

    &__thead {
      height: 50px;
      background: #EDEDF1;
    }

    &__th {
      @include mulish-text;
      font-size: 14px;
      line-height: 125%;
      color: $grey-text-secondary;
      text-align: left;
      padding: 16px 0 16px 16px;

      &:first-child {
        border-top-left-radius: 8.5px;
      }

      &:last-child {
        border-top-right-radius: 8.5px;
      }
    }

    &__td {
      @include mulish-text;
      padding: 16px 30px 16px 16px;
      font-size: 14px;
      line-height: 125%;
      border-bottom: 1px solid #DBDFE7;

      &-content {
        display: flex;
        align-items: center;
      }

      &:first-child {
        height: 38px;
        font-weight: bold;
        font-size: 14px;
        line-height: 125%;
        border-left: 1px solid #DBDFE7;
        white-space: nowrap;
        vertical-align: middle;
      }

      &:last-child {
        border-right: 1px solid #DBDFE7;
      }
    }
  }
}