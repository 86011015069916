html {
  --right-sidebar-width: 526px;
  --right-sidebar-animation-length: .3s;
}

.right-info-sidebar {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  z-index: 220;

  &__background {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background: #000000;
    opacity: .3;
    animation: comeinBackground var(--right-sidebar-animation-length) linear;
  }

  &__sidebar {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: var(--right-sidebar-width);
    background: #FFFFFF;
    box-shadow: 0px 4px 50px rgba(18, 16, 68, 0.09);

    display: flex;
    flex-direction: column;

    animation: comeinSidebar var(--right-sidebar-animation-length) linear;
  }

  &__cross-svg-close {
    position: absolute;
    top: 32px;
    right: 26px;

    cursor: pointer;
  }

  &__header {
    overflow: hidden;
    padding: 26px 76px 26px 18px;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    border-bottom: 1px solid #ECECEC;
    display: flex;
    align-items: center;

    .sidebar-crm-header {
      padding: 0;
      border-bottom: none;
    }
  }

  &__body {
    flex: 1;
  }
}

@keyframes comeinBackground {
  from {
    opacity: 0;
  }

  to {
    opacity: .3;
  }
}

@keyframes comeinSidebar {
  from {
    right: calc(var(--right-sidebar-width) * -1);
  }

  to {
    right: 0;
  }
}
