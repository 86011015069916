@import "src/vendors/mixins";
@import "src/vendors/colors";

.organization-info-sidebar {
  padding-top: 15px;

  &__row {
    margin-top: 20px;
    padding: 0 20px;
    position: relative;
    .input {
      width: 100%;

      input {
        padding: 13px 12px 12px;
      }
    }

    .basic-multi-select {
      & > div {
        min-height: 40px;
      }
    }

  }

  &__field-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    position: relative;

    .input {
      width: 100%;
      input {
        padding: 13px 12px 12px;
      }
    }
  }

  &__remove-field {
    margin-left: 10px;
    cursor: pointer;

    &:hover {
      svg {
        @include svg-colors-path-fill($alert-hover-color);
      }
    }
  }

  &__field-label {
    @include mulish-text;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: #8d8f92;
    margin-bottom: 8px;
    cursor: pointer;

    svg {
      @include svg-colors-path-fill($secondary-blue-color);
    }

    &:hover {
      svg {
        @include svg-colors-path-fill($secondary-light-blue-color);
      }
    }
  }

  &__add-field-btn {
    @include mulish-text;
    border: 1px solid $border-grey-color;
    border-radius: 8.5px;
    font-size: 16px;
    line-height: 16px;
    color: $grey-text-secondary;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
      border-color: $grey-text-color;
      color: $grey-black-color;
    }
  }
}

.organization-info-sidebar-add-field-tooltip {
  max-height: 300px;
  padding: 10px;

  overflow: auto;

  &__item {
    padding: 3px 10px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    cursor: pointer;

    svg {
      margin-right: 5px;
      height: 20px;
    }
  }

  &__create-custom-field-button {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    padding: 3px 10px;
    color: $secondary-light-blue-color;

    &:hover {
      cursor: pointer;
      //background-color: $grey-black-color;
      color: $third-light-blue-color;
    }
  }
}
