.spam-words {
  display: flex;

  margin-top: 10px;

  flex-wrap: wrap;

  &__title {
    font-size: 16px;
    margin-bottom: 10px;
    width: 100%;
  }

  &__word {
    padding: 5px;
    margin-right: 5px;
    margin-top: 5px;
    border-radius: 8px;
    border: 1px solid grey;
  }
}