@import "src/vendors/mixins";
@import "src/vendors/colors";

.people-export-sidebar{
  &__content {
    @include mulish-text;
    padding: 20px;
    line-height: 147%;
    font-size: 16px;
  }

  &__selected_people-text {
    color: $primary-main-color;
    font-weight: 600;
    cursor: pointer;
  }
}