@import '../../../../../../vendors/colors';
@import '../../../../../../vendors/mixins';

.file-select-step {
  &__title {
    margin-bottom: 22px;
    font-weight: 600;
    font-size: 20px;
    line-height: 100%;
    color: #000000;
  }

  &__delimiter-select-title,
  &__import-mode-select-title {
    @include mulish-text;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: $grey-black-color;
    margin-bottom: 2px;
  }

  &__delimiter-select-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #8f9199;
    margin-top: 0;
  }

  &__delimiter-select,
  &__import-mode-select {
    display: flex;
    align-items: center;
  }

  &__delimiter-option-custom-input input {
    width: 48px !important;
    height: 30px !important;
    padding: 0 8px;
    text-align: center;
  }

  &__delimiter-option {
    height: 30px;
  }

  &__delimiter-option,
  &__import-mode-option {
    align-items: center;
    @include mulish-text;
    font-size: 16px;
    margin-right: 16px;
    margin-bottom: 0;

    &:last-child {
      margin-right: 0;

      .input {
        margin-left: 8px;
      }
    }
  }

  &__days-contacted input {
    width: 48px !important;
    height: 30px !important;
    padding: 0 9px;
    margin: auto 5px;
    border: 1px solid #dbdfe7;
    background-color: transparent;
  }

  &__footer {
    position: sticky;
    left: 0;
    right: 0;
    bottom: 0;
    margin-top: 24px;
    padding-bottom: 24px;
    padding-top: 24px;
    display: flex;
    justify-content: flex-end;

    background-color: #ffffff;
    border-top: 1px solid rgba(99, 99, 99, 0.21);

    button {
      padding: 0;
    }
  }

  &__import-settings {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    margin-top: 8px;

    .checkbox {
      align-items: center;
      margin-top: 28px;
      font-size: 16px;
      line-height: 20px;

      &:first-of-type {
        margin-top: 18px;
      }
    }

    .checkbox__field {
      margin-right: 12px;
    }
  }
}

.file-select-step-upload-progress {
  background-color: #f5f6fa;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 180px;

  &__extends-icon {
    width: 48px;
    height: 48px;
    background-color: #e2e8f8;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    margin-right: 16px;
    position: relative;

    @include mulish-text;
    font-size: 16px;
    color: $secondary-blue-color;
  }

  &__remove-icon {
    position: absolute;
    top: -13px;
    left: -13px;
    width: 26px;
    height: 26px;
    background-color: #fff;
    border-radius: 100%;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.06);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
      height: 15px;
    }

    &:hover {
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.26);
    }
  }

  &__info {
    position: relative;
  }

  &__info-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  &__file-name {
    @include mulish-text;
    font-size: 16px;
    white-space: nowrap;
    max-width: 500px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__file-extension {
    color: $grey-black-color;
    text-transform: uppercase;
  }

  &__percentage {
    @include mulish-text;
    font-size: 16px;
    color: $grey-black-color;
    white-space: nowrap;
    margin-left: 15px;
  }

  &__bar {
    height: 3px;
    width: 100%;
    background: $secondary-grey-lines-color;
    border-radius: 8px;
    position: relative;
  }

  &__progress-bar {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background: $primary-main-color;
    border-radius: 8px;
  }
}
