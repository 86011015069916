@import 'src/vendors/mixins';
@import 'src/vendors/colors';

.selected-opportunities-relative-widget {
  @include mulish-text;

  position: sticky;
  top: 20px;

  height: calc(100vh - 285px);
  max-height: calc(100vh - 40px);
  width: 100%;
  max-width: 311px;
  min-width: 271px;

  background-color: $primary-grey-action-color;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 0;

  //transition: max-width 210ms ease;

  &--open {
    max-width: 100%;
    position: absolute;
    left: auto;
    right: 0;
    top: 50px;
  }

  &__title {
    font-size: 18px;
    padding: 24px 14px 14px;
  }

  &__items-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0 14px 14px;

    overflow: auto;
  }

  &__export-btn {
    background-color: #e9eeff;
    border: 1px solid transparent;
    color: #221cb6;
    svg path {
      fill: #221cb6;
    }

    .spinner {
      svg path {
        stroke: #221cb6;
        fill: transparent;
      }
    }

    &:hover {
      border: 1px solid #4e48dc;
      background-color: #e9eeff;
      color: #221cb6;
    }
  }

  &__groups-row {
    display: flex;
  }

  &__buttons-row {
    display: flex;
    gap: 8px;
    padding-right: 20px;
    border-bottom: 1px solid #e1e1e1;
  }
}

.selected-opportunities-table {
  position: relative;

  & .table-th {
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #e1e1e1;

    &:first-child {
      padding-left: 0;
    }
  }

  & .table-td {
    padding-top: 18px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e1e1e1;

    &:first-child {
      padding-left: 0;
    }
  }

  &__check-all {
    display: flex;
    align-items: flex-start;
    position: relative;
    span {
      position: absolute;
      top: 0;
      left: 24px;
      color: #7b7b7b;
    }
    &-checkbox {
      position: relative;
      height: 100%;
      margin-top: -32px;
    }
  }

  &__chart-cell {
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }

  &__align-top-td {
    vertical-align: top;
    &:first-of-type {
      width: 1%;
    }
  }

  &__align-middle-td {
    vertical-align: middle;
  }

  &__fixed-width-select {
    width: 21px;
  }

  &__centered_td {
    text-align: center;
  }

  &__no-padding_td {
    padding-right: 0 !important;
  }

  &__domain-rating {
    padding-right: 0 !important;
    width: 130px;
  }

  &__text-label {
    font-size: 16px;
    text-align: center;
    color: #000000;

    &:not(:first-child) {
      margin-left: 11px;
    }
  }

  &__traffic-label {
    padding: 10px;
    background: rgba(219, 226, 254, 0.42);
    border-radius: 5px;

    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #221cb6;
  }

  &__traffic-dropdown {
    width: 232px;

    &:after {
      right: 13px !important;
      left: auto !important;
      top: -5px !important;
    }

    &:not(.place-bottom):after {
      display: none;
    }

    &-content {
      display: flex;
      flex-direction: column;
      padding: 20px;

      &-type {
        display: flex;
        align-items: center;
        margin-bottom: 4px;
      }

      &-title {
        padding-left: 5px;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #ffffff;
      }

      &-subtitle {
        text-align: start;
        font-size: 16px;
        line-height: 16px;
        color: #8d8f92;

        &:not(:last-child) {
          margin-bottom: 16px;
        }
      }
    }
  }
}
