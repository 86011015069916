@import 'src/vendors/mixins.scss';
@import 'src/vendors/colors.scss';

.upsell-sidebar-footer {
  background: #DBE2FE59;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 25px 30px 20px;

  --button-height: 43px;

  &__price-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__price-label {
    font-weight: 600;
    font-size: 16px;
    line-height: 144%;
  }

  &__price {
    color: $primary-main-color;
    font-weight: 600;
    font-size: 40px;
    line-height: 58px;
  }

  &__price-loader {
    width: 50px;
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
  }


  &__buttons {
    margin-top: 17px;

    display: flex;
    justify-content: space-between;
  }

  &__cancel-button {

    width: 120px;
    height: var(--button-height);
    color: #8D8F92;
    background: #FFFFFF;

    margin-right: 12px;
  }

  &__pay-button {
    flex: 1;
    height: var(--button-height);
  }
}