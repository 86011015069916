@import "../../../vendors/mixins";
@import "../../../vendors/colors";

.campaign-filters-tooltip-content {
  background-color: $primary-black-color;
  border-radius: 8px;
  width: 600px;

  &__body {
    padding: 20px 16px;
  }

  &__footer {
    @include modal-footer;
    padding-top: 24px;
    border-top: 1px solid #282828;

    svg {
      margin-right: 1rem;
    }
  }

  &__btn-apply-filters {
    margin-left: 20px;
  }
}