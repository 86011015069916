@import 'src/vendors/colors';
@import 'src/vendors/mixins';

.collapsible-sidebar {
  box-sizing: border-box;

  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 0;
  padding: 29px 0 20px;

  overflow-y: auto;
  transition: .3s;

  &--opened {
    width: 307px;
    right: 0;
    top: 0;
    box-shadow: 0 4px 50px 0 #12104417;
  }

  &--fixed {
    box-sizing: border-box;
    position: fixed;
    width: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;

    transition: width 0.3s;
    overflow-y: auto;

    &--opened {
      width: 307px;
      right: 0;
      top: 0;
      box-shadow: 0 4px 50px 0 #12104417;
    }
  }

  &__header {
    border-bottom: 1px solid #f4f4f4;
    padding-bottom: 34px;
  }

  &__title {
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    line-height: 100%;
    color: #000000;
    display: flex;
    align-items: center;
    padding: 0 20px 0 20px;
    font-size: 24px;
    font-weight: 600;
  }

  &__subtitle {
    padding: 0 28px;
    margin-top: 20px;
    font-weight: 400;
    font-size: 16px;
    color: #8f8f8f;
    line-height: 125%;
  }

  &__close-btn {
    position: absolute;
    right: 10px;
    top: 5px;

    cursor: pointer;
    transform: rotate(180deg);

    & + span {
      margin-left: 16px;
    }

    &:hover {
      svg {
        @include svg-colors-path-stroke($grey-icons-color);
      }
    }
  }

  &__button {
    display: none;
    position: fixed;
    top: 50%;
    right: 0;
    height: 107px;
    width: 30px;
    transform: translateX(0);
    transition: transform 0.3s;

    cursor: pointer;
    color: #ffffff;

    z-index: 101;

    @media (max-width: 1550px) {
      display: block;
    }

    &--opened {
      transform: translateX(-307px);
    }

    &-content-wrapper {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 6px;
      display: flex;

      svg {
        width: 7px;
      }
    }

    &--opened &-content-wrapper {
      transform: translateY(-50%) rotate(180deg);
      transform-origin: center center;
    }

    &-content {
      margin-left: 4px;
      font-size: 12px;
    }
  }
}
