@import '../../vendors/mixins.scss';
@import '../../vendors/colors.scss';

.campaigns-header {
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0 32px 0 20px;
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 20px;
    right: 32px;
    height: 1px;
    background-color: #f4f4f4;
  }

  &__title {
    @include mulish-text;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
  }

  &__button {
    white-space: nowrap;

    &--smaller {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  &__optional {
    color: #7B7B7B;
    flex: 1;
    text-align: right;
    white-space: nowrap;
  }

  &__actions-wrapper {
    display: flex;
    align-items: center;
    margin-left: 50px;
    gap: 12px;
  }

  &__search {
    display: flex;
    flex: 1;
    min-width: 190px;
    width: auto;
    max-width: 240px;

    .input {
      &__field {
        width: 100%;
      }

      &__clear-icon {
        svg {
          path {
            fill: #DBDFE7;
          }
        }

        &:hover {
          svg {
            path {
              fill: #ed483d;
            }
          }
        }
      }
    }
  }
}
