.relationships-organizations {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__table-wrapper {
    flex: 1;
    overflow-y: auto;
    position: relative;
  }

  &__person-info-sidebar {
    width: 307px;
    padding-bottom: 20px;

    .contact-sidebar-crm--small {
      .contact-sidebar-crm-section__content {
        padding: 0;

        .contact-sidebar-crm__content-personal-info {
          gap: 10px;
        }
      }
    }
  }
}
