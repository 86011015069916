@import 'src/vendors/mixins';
@import 'src/vendors/colors';

html {
  --manual-confirm-contact-sidebar-width: 526px;
  --manual-confirm-contact-sidebar-animation-length: .3s;
}

.manual-confirm-contact-sidebar {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  z-index: 2;

  &__background {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background: #000000;
    opacity: .3;
    animation: comeinBackground var(--manual-confirm-contact-sidebar-animation-length) linear;
  }

  &__sidebar {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: var(--manual-confirm-contact-sidebar-width);
    background: #FFFFFF;
    box-shadow: 0 4px 50px rgba(18, 16, 68, 0.09);

    display: flex;
    flex-direction: column;

    animation: comeinSidebar var(--manual-confirm-contact-sidebar-animation-length) linear;
  }

  &__cross-svg-close {
    position: absolute;
    top: 32px;
    right: 26px;

    cursor: pointer;
  }

  &__header {
    overflow: hidden;
    padding: 26px 76px 26px 26px;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    border: 1px solid #ECECEC;
  }

  &__body {
    flex: 1;
  }

  &__confirm-header {
    @include mulish-text;
    font-weight: 600;
    font-size: 20px;
    margin-top: 32px;
    margin-bottom: 17px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__source-select-wrapper {
    display: flex;
    align-items: center;
  }

  &__empty-search,
  &__not_found {
    @include mulish-text;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 80px;
    font-weight: 600;
    font-size: 24px;
    line-height: 31px;
    text-align: center;
    color: #1b1b1b;
    margin-bottom: 20px;
  }

  &__empty-search-arrow-illustration {
    position: absolute;
    right: 10px;
    top: 5px;
  }

  &__empty-search-illustration,
  &__not_found-illustration {
    margin-bottom: 45px;
  }

  &__content {
    justify-content: space-between;
    display: flex;
  }

  &__search {
    flex: 1;
    padding-bottom: 30px;

    .right-sidebar {
      .person-sidebar {
        &__row {
          padding: 0 24px 0 23px;
          margin-top: 31px;

          &:first-child {
            margin-top: 0;
          }

          .input input {
            border-radius: 8.5px;
            min-height: 49px;
            border: 1px solid #dbdfe7;
            padding-left: 40px;
          }

          .css-2b097c-container {
            .css-eqy8aq-control {
              min-height: 49px;
              border-color: #dbdfe7;

              .css-1wa3eu0-placeholder {
                color: #acacac;
                font-weight: normal;
                font-size: 16px;
              }
            }

            .css-rzky46-control {
              min-height: 49px;
            }
          }
        }

        &__field-label {
          color: #8F9199;
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 8px;
        }
      }
    }
  }

  &__contacts {
    margin-top: 32px;
  }
}
