@import 'src/vendors/colors';
@import 'src/vendors/mixins';

.inbox-comments {
  display: flex;
  flex-direction: column;
  grid-row-gap: 15px;
  margin-bottom: 30px;
  max-height: 475px;
  overflow-y: auto;

  &__comment {
    display: flex;
    align-items: flex-start;
    gap: 9px;

    &-container {
      background-color: $button-light-blue-color;
      border-radius: 8.5px;
      padding: 13px 14px;
      position: relative;
      min-width: 200px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      grid-row-gap: 9px;

      &:after {
        content: '';
        position:absolute;
        width: 9px;
        height: 9px;
        left: -8px;
        top: 12px;
        transform: translate(50%,-50%) rotate(-45deg);
        background-color: $button-light-blue-color;
        box-shadow: 0 1px 8px transparent;
        border-radius: 2px;
      }
    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      &-title {
        margin-right: 30px;
        color: #202430;
        font-size: 16px;
      }

      &-date {
        color: $grey-text-secondary;
        font-size: 14px;
      }

      &-actions {
        button {
          min-width: 20px;
          width: 22px;
          height: 22px;
          padding: 0;

          &:hover {
            border-radius: 5px;
            background: $button-light-blue-color;

            svg {
              @include svg-colors-path-fill(#200E32);
            }
          }
        }

        &-container {
          .action-item {
            padding: 10px 15px;
            border-bottom: 1px solid #272727;
            border-radius: 8px;
            background-color: black;

            &:hover {
              background-color: #181818;
              cursor: pointer;
            }
          }
        }
      }

      &-content {
        display: flex;
        align-items: center;
      }
    }

    &-text {
      font-size: 14px;
      color: #202430;

      .inbox-comments__mentioned {
        color: $primary-main-color;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
}

.inbox-comments-form {
  height: 48px;
  position: relative;

  .input {
    width: 100%;

    &__control {
      input {
        width: 100%;
        padding: 15px 85px 15px 15px;
        border: 1px solid #DBDFE7;
        border-radius: 8.5px;
        height: 48px;
        font-size: 16px !important;

        &::placeholder {
          color: #BDBDBD;
        }
      }
    }

    &__suggestions {
      background-color: black !important;
      width: 100%;
      border-radius: 8px;

      li {
        border-top: 1px solid #272727;
        color: #FFFFFF;
        padding: 10px 15px;

        &:last-child {
          border-bottom: 1px solid #272727;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
        }

        &:first-child {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }

        &:hover {
          background-color: #181818;
        }
      }
    }
  }

  &__buttons {
    position: absolute;
    top: 14px;
    right: 10px;
    display: flex;
    align-items: center;

    button {
      min-width: 20px;
      width: 22px;
      height: 22px;
      padding: 0;

      &:hover {
        border-radius: 5px;
        background: $button-light-blue-color;

        svg {
          @include svg-colors-path-fill(#200E32);
        }
      }

      &.send-btn {
        &:hover {
          border-radius: 5px;
          background: $button-light-blue-color;

          svg {
            @include svg-colors-path-stroke(#200E32);
            path {
              fill: none;
            }
          }
        }
      }
    }
  }
}
