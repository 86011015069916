.insight-graphic-container {
  height: 100%;

  &_with-sidebar {
    height: inherit;
    display: flex;

    .insight-graphic-container_block {
      border-right: 1px solid #E9ECF1;
      width: 60%;
    }
  }

  &_sidebar {
    width: 40%;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &_item {
      background-color: white;
      border-radius: 8px;
      max-width: calc(50% - 5px);
      width: 100%;
      height: 110px;
      padding: 20px;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      &.clickable {
        &:hover {
          cursor: pointer;
          box-shadow: 0 7px 25px rgba(0, 0, 0, 0.1);
        }
      }

      &:hover {
        box-shadow: 0 7px 25px rgba(0, 0, 0, 0.1);
      }
    }

    &.grid-sidebar {
      display: grid;

      grid-template-rows: 110px 110px 110px;
      grid-template-columns: 1fr 1fr;

      gap: 10px;
      height: 100%;

      .grid-sidebar_item {
        max-width: 100%;
        height: auto;
        margin-bottom: 0;

        &:first-child {
          grid-row-start: 1;
          grid-column-start: 2;

          grid-row-end: 2;
          grid-column-end: 3;
        }

        &:nth-child(2) {
          grid-row-start: 2;
          grid-column-start: 2;

          grid-row-end: 3;
          grid-column-end: 3;
        }

        &:nth-child(3) {
          grid-row-start: 3;
          grid-column-start: 2;

          grid-row-end: 4;
          grid-column-end: 3;
        }

        &:nth-child(4) {
          grid-row-start: 1;
          grid-column-start: 1;

          grid-row-end: 2;
          grid-column-end: 2;
        }

        &:nth-child(5) {
          grid-row-start: 2;
          grid-column-start: 1;

          grid-row-end: 4;
          grid-column-end: 2;

          .custom-style {
            height: 100%;
            justify-content: center;
          }
        }
      }
    }
  }
}
