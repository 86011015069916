@import 'src/vendors/mixins';
@import 'src/vendors/colors';

.content-automation-search {
  flex: 1;

  &__settings-title {
    margin-bottom: 45px;
    font-weight: 600;
    font-size: 20px;
    line-height: 100%;
    color: #000000;
    text-align: center;

    h2 {
      font-size: 32px;
      font-weight: 600;
      margin-bottom: 15px;
    }

    p {
      font-size: 14px;
    }
  }

  &__body {
    padding-top: 39px;
  }

  &__footer {
    margin-top: 20px;
  }

  &-modal {
    display: flex;
    height: 925px;

    .modal__cross-btn {
      top: 18px;
      right: 18px;
    }

    &-sidebar {
      min-width: 262px;
      background-color: #FAFAFA;
      width: fit-content;
      max-width: 350px;
      padding: 35px 0;
      position: sticky;
      top: 0;

      &-title {
        color: rgba(0, 0, 0, 0.41);
        text-transform: uppercase;
        font-size: 12px;
        margin-bottom: 35px;
        padding-left: 28px;
        display: inline-block;
      }

      .search-types-tab {
        flex-direction: column;
        width: 100%;

        &__item {
          padding: 18px 28px;
          margin-right: 0;
          height: 60px;
          margin-bottom: 0;
          background-color: transparent;
          border: none;
          border-right: 2px solid transparent;
          border-radius: 0;

          &:hover {
            cursor: pointer;
            background-color: #E5E5EE;
            transition: .3s;
          }
          &--active {
            cursor: pointer;
            background-color: #E5E5EE;
            border-right: 2px solid #251FB6;
            transition: .3s;
          }

          &-text {
            font-size: 18px;
            margin-left: 12px;
            font-weight: 600;
          }

          svg {
            width: 20px;
            height: 20px;
          }


          &--blurred {
            filter: grayscale(1);

            span {
              color: grey;
            }

            &:hover {
              background: none;
              cursor: not-allowed;

              span {
                color: grey;
              }
            }
          }
        }
      }
    }

    &__body {
      padding: 35px 46px 35px 40px;
      width: 100%;

      .content-autosearch__step {
        border-bottom: 2px solid #E9ECF1;
        margin-right: 40px;

        &:last-child {
          margin-right: 0;
        }

        &--active {
          border-bottom: 2px solid #221CB6;
        }

        & + svg {
          display: none;
        }

        &-index {
          border-radius: 8px;
        }
      }
    }
  }
}

.content-search-step {
  display: flex;
  flex-direction: column;
  flex: 1;

  padding-right: 30px;

  &__searching {
    flex: 1;
  }

  &__options-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 19px;
  }
}

.content-search-results-container {
  max-height: unset;
  transition: max-height 160ms ease;

  overflow: hidden;

  &--collapsed {
    max-height: calc(100vh - 440px);
  }
}

.content-search-step-wrapper {
  display: flex;
  position: relative;
  height: 100%;
  padding-top: 50px;
  top: 50%;
  transform: translateY(calc(50% - 50px));

  &__toggler {
    display: inline-block;
    position: absolute;
    right: 0;
    top: -41px;
  }
}

.options-row {
  &__item {
    @include mulish-text;
    color: $grey-text-secondary;
    display: flex;
    align-items: center;
    font-size: 16px;
    text-decoration: none;

    &:hover,
    &--active {
      cursor: pointer;
      color: $secondary-blue-color;

      svg {
        @include svg-colors-path-fill($secondary-blue-color);
      }
    }
  }

  &__icon {
    margin-right: 8px;
    display: flex;
    align-items: center;
  }

  &__podcast-type-select {
    width: 125px;
  }
}
