@import "src/vendors/colors";
@import "src/vendors/mixins";

.unsubscribe-sidebar {
  padding: 0 20px;

  &__content {
    border: 1px solid #DBDFE7;
    border-radius: 8.5px;
    height: 300px;
    padding: 12px;
    overflow-y: auto;
  }

  &__field-placeholder {
    display: none;
  }

  &__field {
    margin-top: 25px;

    &:focus-visible {
      outline: none;
    }
  }

  &__pattern {
    background: #E9E9E9;
    border-radius: 6px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    padding: 0 5px;

    &:not(&:last-child) {
      margin-bottom: 10px;
    }
  }

  &__pattern-remove {
    svg {
      cursor: pointer;

      &:hover {
        opacity: .8;
      }
    }
  }

  &__pattern-text {
    max-width: 200px;
    overflow: hidden;
    margin-right: 7px;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 18px;
  }

  &__header {
    @include mulish-text;
    font-size: 14px;
    line-height: 19px;
    color: #928E8D;
    padding: 0 20px 20px 20px;
    border-bottom: 1px solid #F4F4F4;
  }

  &__field-label {
    @include mulish-text;
    font-weight: 600;
    font-size: 14px;
    color: $grey-text-secondary;
    margin-bottom: 10px;
  }

  &__field-value {
    font-weight: 800;
    color: $primary-black-color;
  }

  &__emails-field {
    border: 1px solid #DBDFE7;
    border-radius: 8.5px;
    height: 117px;
    padding: 12px;
    overflow-y: auto;

    cursor: text;
  }

  &__new-email-field {
    height: 24px;

    &:focus-visible {
      outline: none;
    }
  }

  &__email-item {
    background: #E9E9E9;
    border-radius: 6px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    padding: 0 5px;

    &:not(&:last-child) {
      margin-bottom: 10px;
    }
  }

  &__email-remove {
    svg {
      cursor: pointer;

      &:hover {
        opacity: .8;
      }
    }
  }

  &__email-text {
    max-width: 200px;
    overflow: hidden;
    margin-right: 7px;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 18px;
  }

  &__role-field {
    .radio {
      display: flex;
      height: auto;
    }
  }

  &__role-name {
    @include mulish-text;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 7px;
    display: inline-flex;
    align-items: center;
    gap: 8px;
  }

  &__role-desc {
    @include mulish-text;
    font-size: 14px;
    line-height: 17px;
    color: $grey-text-secondary;
  }
}
