@keyframes gradient {
  0% {
    background-position: 100% 50%;
  }
  50% {
    background-position: 50% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

.gradient-background-loader {
  background: linear-gradient(135deg, #fafafa 10%, #c1c1c1 50%, #fafafa 90%);
  background-size: 400% 400%;
  animation: gradient 3s ease infinite;
  position: relative;
}
