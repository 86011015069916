@import 'src/vendors/mixins';

.custom-fields {
  flex: 1;
  display: flex;
  flex-direction: column;

  &__content {
    //padding: 0 20px;
  }

  &__table {
    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 34px;
      height: 34px;
      border-radius: 50%;
      background-color: #e8eaed;
    }

    .table-td {
      cursor: default;
    }
    .table-th {
      padding-bottom: 4px;
    }

    &__row {
      height: 80px;

      &:hover {
        background-color: #eef3ff;
        cursor: pointer;
      }

      &:not(&:last-child) {
        border-bottom: 1px solid #f4f4f4;
      }
    }

    &__cell {
      &--name {
        display: flex;
        align-items: center;
      }

      &--name-details {
        display: flex;
        flex-direction: column;
        max-width: 250px;
        margin-left: 13px;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #8d8f92;
      }

      &--data {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #8d8f92;
      }
    }

    &__field-name {
      display: flex;
      align-items: center;

      margin-top: 1px;
      margin-bottom: 2px;
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      color: #1b1e22;
    }

    &__field-email {
      white-space: nowrap;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #8d8f92;

      text-overflow: ellipsis;
      overflow: hidden;
    }

    .table-td {
      @include mulish-text;
      color: #8d8f92;
      text-align: left;
      font-size: 14px;
    }
    //.table-td:first-child {
    //  width: 42px;
    //}
    .table-td:nth-of-type(2) {
      width: 220px;
    }

    .table-td:last-child {
      width: 100px;
    }
  }

  &__default-tag {
    margin-left: 4px;
    padding: 2px 5px;

    background-color: #f5f6fa;
    border-radius: 3px;

    font-weight: 600;
    font-size: 9px;
    line-height: 12px;
    text-align: center;

    color: #8d8f92;
  }
}
