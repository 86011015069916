.first-campaign-modal {
  width: 620px;
  height: 750px;
  padding: 45px 20px 25px;
  border-radius: 23px;
  background-color: #fbfbfb;
  position: absolute;
  top: 50px;

  @media screen and (max-width: 725px) {
    width: 100%;
    height: auto;
    min-height: 100%;
    border-radius: 0;
    background-color: transparent;
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;
    width: 100%;

    &__title {
      font-size: 30px;
      font-weight: 600;
      width: 284px;
      text-align: center;
    }

    &__sub-title {
      font-size: 14px;
      font-weight: 400;
      margin-top: 10px;
      margin-bottom: 20px;
      color: rgba(0, 0, 0, 0.71);
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__card {
      display: flex;
      background: rgba(196, 198, 205, 0.17);
      border: 1px solid #D9D9D9;
      border-radius: 10px;
      margin-bottom: 15px;
      width: 502px;
      height: 97px;
      cursor: pointer;

      &--selected {
        background: rgba(37, 31, 182, 0.1);
        border: 1px solid #221cb6;
        .first-campaign-modal__body__card__image__container {
          background: #221cb6;
          svg {
            path {
              stroke: #FFFFFF;
            }
          }
        }
      }

      &__image {
        padding: 20px;
        &__container {
          height: 53px;
          width: 53px;
          background: #FFFFFF;
          border-radius: 10px;
          box-shadow: 0 4px 4px rgba(0, 0, 0, 0.08);
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            color: #221cb6;
          }
        }
      }

      &:hover {
        background: rgba(37, 31, 182, 0.1);
        border: 1px solid #251fb6;
      }

      &__info {
        width: 280px;
        padding: 20px 0;

        &__title {
          font-size: 18px;
          font-weight: 600;
          line-height: 24px;
        }

        &__sub-title {
          font-size: 14px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.71);
        }
      }
    }
  }
  &__submit-button {
    width: 415px;
    height: 52px;
    margin-top: 12px;
  }
}
