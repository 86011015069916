.level-bar {
  height: 12px;
  width: 230px;
  background: #181818;
  border-radius: 300px;
  position: relative;
  margin-top: 8px;
  overflow: hidden;

  &__green-zone {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 0;
    border: 1px solid #fff;
    border-radius: 300px;
    z-index: 10;
  }

  &__value {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 0;
    background-color: red;
    border-radius: 300px;
  }
}