@import "src/vendors/mixins";
@import "src/vendors/colors";

.export-people-sidebar{
  &__header{
    color: #8F8F8F;
    padding: 20px;
    line-height: 147%;
    font-size: 16px;
  }

  &__selected_people-text {
    color: $primary-main-color;
    font-weight: 600;
  }

  &__filters {
    margin-top: 40px;
    padding: 20px;
  }

  &__filters-title {
    color: #8F8F8F;
  }

  &__filters-raw {
    display: flex;
    align-items: center;
    margin-top: 20px;

    &-text {
      margin-left: 14px;
    }
  }
}