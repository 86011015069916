.health-check-status {
  display: flex;
  align-items: center;
  padding: 5px 9px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  border-radius: 6px;

  background-color: rgba(252, 14, 14, 0.1);
  color: #fc0e0e;

  svg {
    margin-right: 6px;
    path {
      fill: #fc0e0e;
    }
  }

  &--success {
    background-color: rgba(35, 231, 113, 0.1);
    color: #23e771;
    svg path {
      fill: #23e771;
    }
  }
}
