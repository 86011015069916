@import 'src/vendors/mixins.scss';
@import 'src/vendors/colors.scss';

.textarea {
  position: relative;

  &__field {
    @include mulish-text;
    @include input-field;
    color: #000;
    font-size: 16px;
    padding: 11px 20px;
    height: 48px;
    min-height: 48px;
    //max-height: 136px;

    transition: height 250ms ease;
  }

  &--error {
    .textarea__field {
      border: 1px solid $alert-color;
      color: $alert-color;
    }
  }

  &--disabled {
    .textarea__field {
      color: $grey-text-color;
    }
  }

  &--not-resizable {
    .textarea__field {
      resize: none;
    }
  }

  &__error {
    display: none;
  }

  &__clear-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--clearable {
    .textarea__field {
      padding-right: 30px;
    }
  }

  &--full-width {
    .textarea__field {
      width: 100%;
    }
  }

  &--full-height {
    .textarea__field {
      height: 100%;
    }
  }

  &--transparent {
    .input {
      &__field {
        background-color: transparent;
        border: none;
      }
    }
  }
}
