.b-s-advanced-settings {
  display: grid;
  grid-auto-flow: column;
  grid-template: repeat(8, 30px) / repeat(2, 1fr);
  grid-row-gap: 18px;

  &__three-rows {
    grid-template: repeat(4, 30px) / repeat(2, 1fr);
  }

  &__four-rows {
    grid-template: repeat(4, 30px) / repeat(2, 1fr);
  }

  &__item {
    display: inline-flex;
    align-items: center;

    &.max-label-width {
      max-width: 265px;
    }

    &-grid {
      display: grid;
      grid-template-columns: 90px 111px;
    }

    .checkbox {
      margin-right: 12px;
    }

    .input__field {
      width: 52px;
      height: 30px;
      margin: 0 10px;
      padding: 0 8px;
      text-align: center;

      background-color: transparent;

      &:disabled {
        color: #8e95aa;
        cursor: default;
      }
    }
  }

  &__item-select {
    display: flex;
    justify-content: space-between;
    width: 211px;
  }

  &__top-search-filter {
    .input__field {
      margin-left: 16px;
    }
  }

  &__location-select {
    width: 180px;
    margin-left: 22px;
  }

  &__language-select {
    width: 120px;
    margin-left: 14px;
  }
}
