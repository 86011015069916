.filters-buttons {
  display: flex;
  justify-content: space-around;

  &__button {
    flex: auto;
    width: auto;
    border-radius: 4px;
  }

  &__button:not(:first-child) {
    margin-left: 8px;
  }
}

