@import 'src/vendors/mixins';

.relationships-folders {
  &__bar {
    margin-bottom: 0;
  }

  &__create-btn {
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 7px;

    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #7B7B7B;

    flex-grow: 0;
    flex-shrink: 0;

    margin-left: 10px;

    &--inactive:hover {
      background-color: #eef3ff;

      color: #221cb6;
      cursor: pointer;
    }
  }

  &__create-input {
    width: 133px;

    input {
      width: 100%;
      height: 36px;
      border: 1px solid #717171;
      border-radius: 7px;
      padding: 10px;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
    }

    &-btn {
      width: 39px;
      min-width: 39px;
      height: 36px;
      margin-left: 5px;
      padding: 0;
    }
  }

  &__folder {
    position: relative;
    flex-shrink: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 24px;

    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #7b7b7b;
    grid-column-gap: 15px;

    cursor: pointer;

    &:has(.relationships-folders__folder--active-btns) {
      padding: 12px;
    }

    &:hover {
      color: #221cb6;

      svg {
        @include svg-colors-path(#221cb6);
      }

      .relationships-folders__folder--active-btns {
        visibility: visible;
        opacity: 1;
      }
    }

    &--active {
      text-shadow: 0 0 0.3px #221cb6;
      color: #221cb6;

      &-btns {
        visibility: hidden;
        opacity: 0;

        .actions-menu {
          &__icon {
            svg {
              @include svg-colors-path(#BDBDBD);
            }
          }
        }
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        height: 2px;
        border-radius: 2px;
        background-color: #221cb6;
      }
    }
  }
}
