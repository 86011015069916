@import 'src/vendors/mixins';

.sidebar-trial-counter {
  display: flex;
  align-items: center;
  justify-content: center;

  &__icon {
    flex-shrink: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 8px;
    height: 8px;
    border-radius: 9px;
    cursor: pointer;
  }

  &:hover {
    color: #fff;
    background-color: #FC8619;

    .sidebar-trial-counter__icon:hover {
      @include svg-colors-path-fill(white);
    }
  }
}
