@import "src/vendors/colors";
@import 'src/vendors/mixins';

.label {
  border-radius: 20px;
  padding: 2px 5px;
  min-width: 20px;
  display: inline-flex;
  justify-content: center;

  &-color-blue {
    background-color: #DDDEF6;
    color: $primary-main-color;

    &.label-hover {
      &:hover {
        cursor: pointer;
        background-color: $primary-main-color;
        color: #fff;
      }
    }
  }

  &-color-orange {
    background-color: #FEDDB7;
    color: #FC8619;

    &.label-hover {
      &:hover {
        cursor: pointer;
        color: #fff;
        background-color: $secondary-orange-color;

        .sidebar-trial-counter__icon {
          @include svg-colors-path-fill(white);
        }
      }
    }
  }

  &-color-green {
    background-color: rgba(88, 205, 149, 0.1);
    color: #13A861;

    &.label-hover {
      &:hover {
        cursor: pointer;
        color: #fff;
        background-color: #13A861;

        .sidebar-trial-counter__icon {
          @include svg-colors-path-fill(white);
        }
      }
    }
  }

  &-color-gray {
    background-color: rgba(189, 189, 189, 0.22);
    color: #8F9199;

    &.label-hover {
      &:hover {
        cursor: pointer;
        color: #fff;
        background-color: #8F9199;

        .sidebar-trial-counter__icon {
          @include svg-colors-path-fill(white);
        }
      }
    }
  }

  &-color-black {
    background-color: rgba(32, 36, 48, 0.27);
    color: #202430;
  }

  &-font-size-small {
    font-size: 10px;
    font-weight: 600;
    line-height: 10px;
  }

  &-position-moved-to-top {
    position: relative;
    top: -6px;
  }
}