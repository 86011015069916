.podcast-search {
  &__select-source-item {
    display: flex;
    align-items: center;
  }

  &__select-source-item-text {
    margin-left: 24px;
    font-weight: 600;
    font-size: 20px;
  }

  &__title {
    margin-bottom: 22px;
    font-weight: 600;
    font-size: 20px;
    line-height: 100%;
    color: #000000;
  }
}
