.ai-agents-table-wrapper {
    width: 100%;
    height: 100%;
    padding: 0 37px 27px;

    .ai-agents-logs-table {
        height: auto;

        .table__thead {
            position: sticky;
            top: 0;
            background: white;
            z-index: 8;

            .table__tr {
                border-bottom: none;

                .table__th {
                    padding-top: 25px;
                }
            }
        }

        .table__tbody {
            .table__tr {
                border-bottom: 1px solid #f4f4f4;

                .table__td {
                    padding-top: 25px;
                    padding-bottom: 25px;

                    .subtitle-cell {
                        color: #525456;
                        font-size: 14px;
                        font-family: Mulish, serif;

                        .bordered {
                            border-radius: 4px;
                            padding: 3px 4px;
                            border: 1px solid #f4f4f4;
                        }

                        .bordered.blue {
                            background: #EAEDF2;
                            color: #3424EB;
                            border: none;
                        }
                    }

                    .credit-used,
                    .blue-text {
                        color: #3424EB;
                        font-weight: bold;
                    }

                    .date-time {
                        color: #393939;
                    }
                }
            }
        }

        .status-text {
            padding: 5px;
            white-space: nowrap;
        }
    }

    .ai-agents-table__name {
        display: flex;
        align-items: center;
    }
}