.contacts-sidebar-section-opportunities {
  display: flex;
  flex-direction: column;
  gap: 12px;

  margin: 0 -20px -28px;
  padding: 0 20px 28px;

  &--empty {
    color: #7b7b7b;
    font-size: 14px;
  }

  &__opportunity {
    display: flex;
    flex-direction: column;

    gap: 12px;

    padding: 19px 20px 21px;
    border: 1px solid #dce1e4;
    border-radius: 8px;

    background-color: #ffffff;

    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #000000;

    &-title {
      display: flex;
      align-items: center;

      margin-bottom: 5px;

      font-weight: 600;
      font-size: 16px;
      line-height: 19px;

      span {
        max-width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      svg {
        margin-left: 8px;
      }

      cursor: pointer;

      &:hover {
        color: #221cb6;
      }
    }

    &-people {
      display: flex;
      align-items: center;

      &-highlight {
        color: #221cb6;
        cursor: pointer;
      }

      span {
        max-width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      svg {
        margin-right: 10px;
      }

      &:hover {
        svg path {
          stroke: #221cb6;
        }
        color: #221cb6;
      }

      cursor: pointer;
    }

    &-status {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      svg {
        margin-right: 10px;
      }
    }

    &-date {
      display: flex;
      align-items: center;

      svg {
        margin-right: 12px;
      }
    }

    &-next-action {
      display: flex;
      align-items: center;

      svg {
        margin-right: 10px;
      }
    }
  }
}
