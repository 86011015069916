@import 'src/vendors/colors';
@import 'src/vendors/mixins';

.campaign-builder-content-filter {
  margin-bottom: 19px;

  &__filter-info {
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    margin-bottom: 18px;

    &:hover {
      cursor: pointer;

      svg {
        @include svg-colors-path-fill($secondary-blue-color);
      }
    }
  }

  &__label {
    @include mulish-text;
    font-size: 16px;
    white-space: nowrap;
    margin-left: 2px;
  }

  &__add-icon {
    margin-left: auto;
  }
}