@import "src/vendors/mixins";
@import "src/vendors/colors";

.billing-history {
  &__header-title {
    display: flex;
    align-items: center;
  }

  &__header-actions {
    display: flex;
    align-items: center;
  }

  &__header-date-filter {
    color: $primary-black-color;
    background: $primary-grey-action-color;
  }

  &__table {
    margin-top: 12px;
    padding: 0 23px;

    .billing-history-table {
      &__row {
        border-bottom: 1px solid #F4F4F4;

        .table-td {
          height: 83px;
        }
      }

      &__date-time {
        color: $grey-text-color;
      }

      &__details-btn {
        width: 93px;
        color: $grey-black-color;
        background: $primary-grey-action-color;

        &:hover {
          color: $primary-black-color;
        }
      }
    }
  }
}