@import 'src/vendors/colors';
@import 'src/vendors/mixins';

@keyframes slide-in {
  from {
    right: -526px;
  }
  to {
    right: 0;
  }
}

.integration-editing-sidebar {
  position: fixed;
  width: 526px;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #fff;
  box-shadow: 0 4px 50px rgba(18, 16, 68, 0.09);
  padding-top: 30px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  z-index: 1;

  animation: slide-in 160ms ease-in-out;

  &__title {
    display: flex;
    align-items: center;
    padding: 0 20px 30px 20px;
    font-size: 24px;
    font-weight: 600;
    border-bottom: 1px solid #f4f4f4;
  }

  &__learn-more-button {
    width: 100%;
    margin-bottom: 8px;
  }

  &__close-btn {
    margin-right: 16px;
    cursor: pointer;

    &:hover {
      svg {
        @include svg-colors-path-stroke($grey-icons-color);
      }
    }
  }

  &__row {
    position: relative;
    margin-top: 16px;
    margin-bottom: 7px;
    padding: 0 20px;
  }

  &__label {
    font-weight: 600;
    font-size: 14px;
    color: $grey-text-secondary;
    margin-bottom: 8px;

    &--flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__tag-row {
    display: flex;
    flex-wrap: wrap;
    margin-top: 12px;
  }

  &__tag {
    padding: 7px 7px 6px;
    margin-bottom: 14px;
    background-color: $secondary-grey-action-color;
    border-radius: 6px;
    font-size: 14px;

    &:not(:last-of-type) {
      margin-right: 8px;
    }
  }

  &__connected-by {
    display: flex;
    align-items: center;
  }

  &__connected-by-icon {
    margin-right: 10px;
  }

  &__connected-by-details {
    display: flex;
    flex-direction: column;
  }

  &__connected-by-name {
    color: #000000;
    line-height: 16px;
  }

  &__connected-by-date {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.34);
  }

  &__footer {
    margin-top: auto;
    padding: 20px 20px 10px 20px;
    display: flex;
  }

  &__footer-right {
    margin-left: auto;
    display: flex;

    .button {
      white-space: nowrap;
      width: auto;
      height: 40px;
      margin-right: 8px;
    }
  }

  .input--disabled {
    .input__field {
      color: $grey-text-color;
      & svg {
        color: #bdbdbd;
      }
    }
  }

  .textarea {
    .textarea__field {
      line-height: 29px;
      height: 202px;
    }
  }
}
