@import "../../../vendors/colors";
@import "../../../vendors/mixins";

.schedule-days-list {
  display: flex;

  &__day {
    @include mulish-text;
    font-size: 14px;
    width: 45px;
    min-height: 27px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $grey-selection-color;
    border-radius: 6px;

    cursor: default;

    &--active {
      background: $secondary-blue-color;
      color: #fff;
    }

    &:not(&:last-child) {
      margin-right: 6px;
    }
  }
}