@import "src/vendors/colors";
@import "src/vendors/mixins";

.people-header-actions {
  display: flex;
  align-items: center;
  gap: 12px;

  &__more-actions-btn {
    color: $grey-black-color;
    background-color: #F5F5F5;
    white-space: nowrap;

    svg {
      height: 16px;
      margin-right: 16px;
      margin-left: 8px;
    }

    &:hover {
      background-color: #F5F5F5;
      opacity: .8;
    }
  }

  &__export-users-btn {

    svg {
      margin-right: 10px;
    }
  }

  &__remove-btn {
    svg {
      margin-right: 8px;
    }
  }

  &__separator {
    width: 2px;
    height: 45px;
    background: #F4F4F4;
  }

  &__select-column {
    cursor: pointer;

    &:hover {
      @include svg-colors-path-fill($secondary-blue-color)
    }
  }

  /* Overlap styles */
  .button {
    height: 40px;
    padding: 12px;
    width: auto;
  }

  .input {
    //margin-right: 10px;

    &__field {

    }
  }
}

@media (screen and max-width: 1440px) {
  .people-header-actions {
    &__more-actions-btn {
      svg {
        margin: 0;
      }
    }

    &__export-users-btn {
      svg {
        margin-right: 0;
      }
    }

    &__remove-btn {
      svg {
        margin-right: 0;
      }
    }
  }
}

