@import "../../../vendors/colors";
@import "../../../vendors/mixins";

.schedule-edition {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  padding-right: 29px;
  padding-left: 29px;
  background-color: #fff;

  user-select: none;

  &__header {
    @include mulish-text;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    height: 85px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #F4F4F4;
  }

  &__title-row {
    margin-top: 43px;
    @include mulish-text;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    display: flex;
    align-items: center;
  }

  .schedule-edition-title-row {
    margin-bottom: 52px;

    &__edit-btn {
      height: 20px;
      margin-left: 10px;
      cursor: pointer;

      svg {
        @include svg-colors-path-stroke($grey-icons-color);
      }

      &:hover {
        svg {
          @include svg-colors-path-stroke($secondary-grey-lines-color);
        }
      }
    }

    &__timezone-select {
      width: 355px;
      margin-right: 14px;
    }

    &__right {
      display: flex;
      align-items: center;
      margin-left: auto;

      .button {
        width: 96px;

        &:last-child {
          margin-left: 8px;
        }
      }
    }

    .input {
      &__field {
        padding-left: 0;
        width: 150px;
      }
    }
  }
}

.schedule-edition-days {
  &__grid-row {
    height: 48px;
    display: grid;
    grid-template-columns: 3fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    margin-bottom: 12px;
  }

  .schedule-edition-days-hours {
    height: auto;

    &__hour {
      @include mulish-text;
      font-size: 12px;
    }

    &__day-time {
      @include mulish-text;
      font-size: 10px;
      color: $grey-icons-color;
    }
  }

  .schedule-days-grid-row {
    &__day-name {
      height: 48px;
      display: flex;
      align-items: center;

      .checkbox {
        margin-bottom: 0;
        margin-right: 5px;
      }
    }

    &__day {
      position: relative;
      background-color: $primary-grey-action-color;
      height: 48px;
      cursor: pointer;

      &:hover {
        opacity: .6;
      }

      &:after {
        position: absolute;
        display: inline-block;
        content: '';
        top: 8px;
        bottom: 8px;
        right: 0;
        width: 1px;
        background-color: $secondary-grey-action-color;
      }

      &--active {
        background-color: $secondary-blue-color;

        &:after {
          background-color: #423CDC;
        }
      }

      &--highlighted {
        background-color: $grey-black-color;

        &:after {
          background-color: $secondary-black-color;
        }
      }

      &:nth-child(2) {
        border-radius: 8px 0 0 8px;
      }

      &:last-child {
        border-radius: 0 8px 8px 0;

        &:after {
          display: none;
        }
      }
    }
  }
}