.sidebar-footer {
  margin-top: auto;
  margin-bottom: 10px;
  position: relative;
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &::before {
    content: "";
    position: absolute;
    left: -20px;
    right: -20px;
    height: 1px;
    background-color: #BDBDBD;
    top: 0;
    opacity: 0.17;
  }

  &--opened {
    padding-right: 20px;
  }

  &__profile {
    margin-top: 15px;
    padding: 15px 0 12px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: -20px;
      right: -20px;
      height: 1px;
      background-color: #BDBDBD;
      top: 0;
      opacity: 0.17;
    }

    &--opened {
      justify-content: flex-start;
    }
  }

  &__profile-avatar {
    position: relative;
    cursor: pointer;

    .named-avatar__content {
      font-size: 14px;
    }

    .logout-on-hover-avatar, .background-on-hover-avatar {
      display: none;
    }

    &:hover {
      .background-on-hover-avatar {
        position: absolute;
        left: 0;
        top: 0;
        background-color: #FFEEEE;
        opacity: 1;
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 50%;
      }
      .logout-on-hover-avatar {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-40%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &__profile-name {
    margin-left: 10px;
    margin-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5rem;
  }

  &__profile-logout-button {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
}
