.trial-period-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-top: 25px;
  padding: 22px 20px;

  box-shadow: 0 4px 90px rgba(201, 201, 201, 0.25);
  border-radius: 8px;

  a {
    text-decoration: none;
  }

  &__text-container {
    display: flex;
    align-items: center;
    gap: 14px;
  }

  &__warning-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background-color: #ffeeee;
    border-radius: 9px;
  }

  &__text {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #000000;
  }
}
