@import 'src/vendors/mixins';
@import 'src/vendors/colors';

.thread-emails {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  min-width: 500px;
  padding: 0 20px 20px;

  .thread-email-info {
    margin-top: 20px;
    background-color: #ffffff;
    padding: 15px 0 0;
    border-radius: 8px;
    position: relative;

    &--preview {
      padding: 0;

      &:hover {
        background-color: #eaedf2;
      }

      .thread-email-info__header {
        align-items: flex-start;
        border-bottom: 0;
      }
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px;
      border-bottom: 1px solid $secondary-grey-lines-color;
      user-select: none;
    }

    &__header-left {
      display: flex;
      align-items: center;
    }

    &__from-avatar {
      margin-right: 15px;
    }

    &__from-name {
      @include mulish-text;
      font-weight: 600;
      font-size: 16px;
      color: #202430;
      margin-right: 8px;
    }

    &__from-info {
      margin-bottom: 8px;
    }

    &__cc-info {
      margin-top: 8px;
    }

    &__from-email,
    &__to-name,
    &__to-email {
      @include mulish-text;
      font-size: 14px;
      color: $grey-text-secondary;
    }

    &__to-name {
      margin-right: 8px;
    }

    &__date {
      @include mulish-text;
      font-size: 12px;
      line-height: 16px;
      color: $grey-text-secondary;
      white-space: nowrap;
    }

    &__content {
      padding: 22px 28px 15px;

      img {
        max-width: 100%;
      }
    }

    &__actions {
      top: 5px;
      right: 5px;
      position: absolute;
      display: flex;
      justify-content: center;
    }

    &__reply-btn,
    &__forward-btn {
      height: 20px;
      width: 20px;
      min-width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      border-radius: 2px;

      &:hover {
        svg {
          @include svg-colors-path($primary-main-color);
        }
      }

      svg {
        height: 14px;
        min-height: 14px;
        width: 14px;
        min-width: 14px;
        margin-right: 0;
      }
    }

    &__reply-btn {
      margin-right: 10px;
    }

    &__forward-btn {
      svg {
        transform: scaleX(-1);
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    background-color: #fff;
    border-radius: 0 0 8px 8px;
    padding: 15px 0;
    border-top: 1px solid $secondary-grey-lines-color;
  }

  &__reply-btn,
  &__forward-btn {
    height: 35px;

    svg {
      margin-right: 5px;
      height: 14px;
      min-height: 14px;
      width: 14px;
      min-width: 14px;
    }
  }

  &__reply-btn {
    margin-right: 10px;
    padding-left: 15px;
    //background-color: #2752eb;
  }

  &__forward-btn {
    padding-left: 15px;
    color: #2752eb;
  }

  &__bottom_anchor {
    float: left;
    clear: both;
  }
}
