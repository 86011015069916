@mixin small-action-header-button {
  padding: 0;
  width: 44px;
  min-width: 44px;

  svg {
    margin-right: 0;
  }
}

.unsubscribe-page-actions {
  &__header-actions {
    display: flex;
    align-items: center;
    gap: 12px;

    .seperator {
      width: 2px;
      height: 40px;
      background-color: #F4F4F4;
    }

    &-block {
      display: flex;
      gap: 9px;
    }
  }

  &__add-button,
  &__remove-button,
  &__export-button {
    height: 40px;
  }

  &__add-button {
    @include small-action-header-button;
  }

  &__export-button {

    &:hover {
      svg {
        path {
          fill: #ffffff;
        }
      }
    }
  }
}

@media (screen and max-width: 1440px) {
  .unsubscribe-page-actions {
    &__add-button,
    &__remove-button,
    &__export-button {
      @include small-action-header-button;
    }
  }
}
