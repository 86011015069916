@import "../../../../vendors/mixins";

.filters-tooltip-raw {
  display: flex;
  align-items: center;
  @include mulish-text;
  height: 40px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  .select {
    flex: 1;
    width: auto;
  }

  &__select {

    &--joins, &--fields, &--operations {
      margin-right: 10px;
    }
    &--joins {
      width: auto;
      min-width: 100px;
    }
    &--fields {
      width: auto;
      min-width: 120px;
    }
    &--operations {
      width: auto;
      min-width: 100px;
    }
  }

  &__input {
    flex: 1;
    border: 1px solid #282828;
    border-radius: 8px;

    .input__field {
      color: #FFFFFF;
      width: 100%;
      height: 36px;
    }
  }

  &__cross {
    margin-left: 10px;
    cursor: pointer;
  }

  .date-range-select {
    &__picker {
      .rdrCalendarWrapper {
        .rdrMonthsVertical {
          .rdrDays {
            .rdrDay {
              color: white !important;
            }
          }
        }
      }
    }
  }
}

.campaign-report-filter {
  .filters-tooltip-raw {
    &.is-additional-row {
      .select {
        width: fit-content;

        &:first-child {
          flex-grow: 0;
        }

        &:nth-child(2) {
          flex-grow: 3;
        }

        &:nth-child(4) {
          flex-grow: 2;
        }
      }
    }
  }
}
