.slate-editor-variable {
  min-width: 20px;
  min-height: 20px;
  padding: 3px 8px;
  margin: 0 1px;
  vertical-align: baseline;
  display: inline-block;

  font-size: 13px;
  line-height: 19px;
  position: relative;

  background: #EDEDED;
  border: 1px solid rgba(0, 0, 0, 0.19);
  border-radius: 7px;

  &--clickable {
    cursor: pointer;
  }

  &--empty {
    padding: 0;
    min-width: 20px;
    min-height: 20px;
    margin-bottom: -5px;
  }

  &--temp-variable {
    //padding: 3px 8px;

    background-color: rgba(40, 34, 183, 0.12);
    border-radius: 7px;

    color: #251fb6;

    cursor: default!important;
    pointer-events: none;
  }

  &--ai-variable {
    border-radius: 7px;
    padding-right: 24px;

    &::after {
      content: '';
      display: inline-block;
      width: 14px;
      height: 14px;
      background-image: url('./ai_variable.svg');
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      top: 48%;
      transform: translateY(-50%);
      right: 5px;
    }
  }
}
