@import 'src/vendors/colors';
@import 'src/vendors/mixins';

.onboarding-default-payment {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__content {
    width: 554px;
    margin-top: 57px;
    margin-bottom: 30px;
  }

  &__subscription-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 620px;
    height: 78px;
    margin-bottom: 18px;
    font-size: 16px;
    padding: 0 25px;
    border: 1px solid rgba(219, 223, 231, 0.47);
    border-radius: 8px;
  }

  &__subscription-info-details {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &-price {
      margin-bottom: 7px;
      font-weight: 600;
      font-size: 28px;
      line-height: 28px;
      text-align: center;
      color: #000000;
    }

    &-date {
      font-weight: 600;
      font-size: 12px;
      line-height: 12px;
      text-align: center;
      color: #8f9199;
    }
  }

  &__payment-info {
    width: 620px;
    height: 78px;
    background: #f5f6fa;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;
    font-size: 16px;
    padding: 0 25px;
  }

  &__payment-price {
    @include mulish-text;
    font-weight: 600;
    font-size: 28px;
    color: $primary-main-color;
  }

  &__payment-label {
    margin-bottom: 5px;
    padding-left: 5px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.1px;
    color: #525979;
  }

  &__payment-details-text {
    display: flex;
    align-items: center;
    @include mulish-text;
    font-weight: 600;
    font-size: 14px;
    color: #3a3b3f;
    margin-bottom: 8px;
  }

  &__payment-details-text-icon {
    display: flex;
    align-items: center;
    margin-left: 7px;
    cursor: pointer;
  }

  &__payment-details-tooltip {
    width: 280px;

    ul {
      padding-left: 30px;
      list-style: disc;

      li {
        line-height: 18px;
        padding: 5px 0;
      }
    }
  }

  .onboarding-default-payment-form {
    &__fields {
      display: flex;
      align-items: center;
      height: 72px;
      background: #ffffff;
      box-shadow: 0 2px 20px #eaecf4;
      border-radius: 8px;
    }

    &__input-field-wrapper {
      flex-grow: 1;
      display: flex;
      align-items: center;
      padding-left: 10px;
      @media screen and (max-width: 725px) {
        width: auto;
        font-size: 14px;
        #card-image {
          display: none;
        }
      }
    }

    &__input-field {
      height: 72px;
      font-size: 16px;

      @media screen and (max-width: 725px) {
        width: auto;

        font-size: 14px;
      }
      &-cvv {
        height: 72px;
        width: 60px;
        margin-right: 10px;
        @media screen and (max-width: 725px) {
          width: 60px;
          min-width: 60px;
          input {
            font-size: 14px;
          }
        }
      }

      &-date {
        height: 72px;
        width: 90px;
        @media screen and (max-width: 725px) {
          width: 66px;
          min-width: 66px;
          font-size: 14px;
        }
      }
    }

    &__details-btn {
      color: $grey-text-secondary;
      width: 620px;
      height: 48px;

      border: 1px solid rgba(141, 143, 146, 0.19);
      border-radius: 8.5px;

      margin-top: 2rem;

      &:hover {
        background-color: rgba(234, 234, 234, 0.19);
      }
    }

    &__submit-btn {
      margin-top: 19px;
      margin-bottom: 21px;
      width: 554px;
      height: 48px;

      &--hidden {
        opacity: 0;
      }
    }

    &__loader-after-click-to-pay {
      margin: 20px;
    }
  }

  &__bottom-promises-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__bottom-promise {
    display: flex;
    justify-content: center;

    margin-bottom: 12px;

    &-text {
      margin-left: 7px;
      color: $grey-text-secondary;
      font-size: 12px;
      font-weight: 600;
    }
  }
}
