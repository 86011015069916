.opportunity-contacts-sidebar-section-contacts {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__website {
    display: flex;
    flex-direction: column;

    padding: 19px 20px 21px;
    border: 1px solid #dce1e4;
    border-radius: 8px;

    background-color: #ffffff;

    //&:hover {
    //  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.1);
    //}

    &-title {
      margin-bottom: 19px;

      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      color: #000000;
    }

    &-url {
      display: flex;
      align-items: center;

      width: 100%;

      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #000000;
      word-break: break-all;

      cursor: pointer;

      svg {
        flex-shrink: 0;
        margin-right: 9px;
      }

      &:hover {
        color: #251fb6;

        svg path {
          stroke: #251fb6;
        }
      }
    }

    &-metrics {
      margin-top: 17px;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #000000;
    }
  }

  &__contacts {
    display: flex;
    flex-direction: column;

    gap: 12px;

    margin: 0 -20px -28px;
    padding: 0 20px 28px;

    &-contact {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;

      width: 100%;

      gap: 12px;

      padding: 19px 20px 21px;
      border: 1px solid #dce1e4;
      border-radius: 8px;

      background-color: #ffffff;

      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #000000;

      cursor: pointer;

      transition: box-shadow 160ms ease;

      &-title {
        display: flex;
        align-items: center;

        margin-bottom: 6px;

        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: #000000;
        padding-right: 18px;
        position: relative;

        span {
          width: fit-content;

          &:hover {
            color: #251fb6;
          }
        }

        &-social {
          margin-left: 8px;
          margin-bottom: -5px;
        }

        &-close {
          width: 13px;
          height: 13px;
          position: absolute;
          right: 0;

          &:hover {
            svg {
              path {
                fill: #ED483D;
              }
            }
          }
        }
      }

      &-data {
        display: flex;
        align-items: center;

        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #000000;

        &-email {
          width: 100%;
          word-break: break-all;
        }

        &-email-tooltip {
          display: flex;
          flex-direction: column;
          padding: 12px 8px;
          gap: 4px;
        }

        &-highlight {
          color: #221cb6;
        }

        .email-accuracy {
          width: 8px;
          height: 8px;
          margin-left: 5px;
          flex-shrink: 0;
        }

        svg {
          align-self: flex-start;
          margin-top: 1px;

          margin-right: 9px;
          flex-shrink: 0;
        }
      }
    }
  }
}
