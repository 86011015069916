@import 'src/vendors/mixins';

.external-unsubscribe-page {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  min-width: 320px;
  min-height: 100vh;
  padding: 90px 60px 0;

  font-family: 'Oakes Grotesk', sans-serif;
  font-style: normal;
  font-weight: 500;

  overflow: hidden;

  @media (screen and max-width: 915px) {
    padding: 60px 15px 0;
  }

  &__gradient-left {
    position: absolute;
    top: 40%;
    left: -5%;
    width: 100%;
    max-width: 704px;
    max-height: 100%;

    pointer-events: none;
    z-index: 0;

    @media (screen and max-width: 1280px) {
      left: -35%;
      max-width: 100%;
    }

    @media (screen and max-width: 520px) {
      left: -50%;
    }
  }

  &__gradient-right {
    position: absolute;
    top: 0;
    right: 0;
    max-height: 100%;

    pointer-events: none;
    z-index: 0;
  }

  &__logo-container {
    width: auto;
    margin-bottom: 48px;
  }

  &__logo {
    height: 54px;

    @media (screen and max-width: 915px) {
      height: 50px;
    }

    @media (screen and max-width: 520px) {
      height: 40px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;

    max-width: 650px;
    width: 100%;
    padding-bottom: 30px;
  }

  &__message {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 38px;
    line-height: 59px;
    text-align: center;
    letter-spacing: -1px;
    color: #111a45;

    @media (screen and max-width: 915px) {
      line-height: 52px;
      letter-spacing: -0.5px;
    }

    @media (screen and max-width: 540px) {
      font-size: 26px;
      line-height: 36px;
      letter-spacing: -0.5px;
    }
  }

  &__sub-title {
    max-width: 90%;
    width: 100%;

    font-size: 16px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.5px;
    color: #525979;

    @media (screen and max-width: 540px) {
      font-size: 14px;
      line-height: 18px;
      margin-top: 4px;
    }

    &-email {
      color: #251fb6;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    margin: 50px 0 41px;

    @media (screen and max-width: 915px) {
      margin: 30px 0 0;
    }
  }

  &__list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 15px 0;

    @media (screen and max-width: 520px) {
      padding: 20px 0;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #eeeeee;
    }

    &-text-wrapper {
      display: flex;
      flex-direction: column;
    }

    &-title {
      font-size: 19px;
      line-height: 40px;
      letter-spacing: 0.5px;
      color: #111a45;

      @media (screen and max-width: 520px) {
        line-height: 26px;
        margin-bottom: 6px;
      }
    }

    &-subtitle {
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 0.5px;
      color: #8e95aa;
    }
  }

  &__list-item-toggle {
    margin-left: 60px;

    @media (screen and max-width: 520px) {
      margin-left: 30px;
    }
  }

  &__button-save {
    font-family: Oakes Grotesk, serif;
    width: 100%;
    height: 60px;
    margin-bottom: 30px;

    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.5px;
  }

  &__button-unsubscribe-all {
    font-family: Oakes Grotesk, serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.5px;
    color: #251fb6;
    position: relative;
    text-decoration: none;

    margin: 0 auto;

    cursor: pointer;

    &:after {
      display: block;
      position: absolute;
      content: '';
      left: -2px;
      right: -2px;
      bottom: 5px;
      height: 12px;
      background: rgba(37, 31, 182, 0.09);
      transition: all 0.3s ease 0s;
      transform-origin: center;
    }

    &--loading,
    &:hover {
      &:after {
        left: -17px;
        right: -17px;
        bottom: -6px;
        height: 45px;
        border-radius: 11px;
      }
    }
  }

  &__slider-container {
    width: 46px;
    height: 28px;
  }

  &__slider-container--active {
    background: linear-gradient(69.17deg, #7f7aeb 15.42%, #221cb6 80.43%);
  }

  &__slider {
    width: 22px;
    height: 22px;
    top: 3px;
    left: 3px;
  }

  &__slider--active {
    background-color: #ffffff;
    left: auto !important;
    right: 3px;
  }
}
