@import 'src/vendors/colors';
@import 'src/vendors/mixins';

.actions-menu {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid rgba(214, 214, 214, 0.35);

  &:hover {
    background-color: #eef3ff;
    color: #221cb6;
    cursor: pointer;

    .actions-menu__icon {
      svg {
        @include svg-colors-path(#221cb6);
      }
    }
  }

  &__icon {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      height: 16px;
    }
  }

  .react-tooltip:not(.react-tooltip--position-fixed) {
    position: absolute;
    top: auto !important;
    bottom: auto !important;
    left: -152px !important;
    border-radius: 8px;
  }
}

.__react_component_tooltip {
  &.react-tooltip--position-fixed {
    position: fixed;
    border-radius: 8px;
    color: #fff;
    background: #222;
    padding: 0 !important;

    &.show {
      opacity: 1 !important;
    }

    .actions-tooltip-content {
      &:hover {
        cursor: pointer ;
      }
    }
  }
}
