@import 'src/vendors/colors';

.opportunity-inspect {
  width: calc(100vw - 320px);
  height: 100vh;

  &__action-bar {
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom:  1px solid #E8E8E8;

      .pagination__select {
        margin: 0 auto;
        margin-left: 25px;
        gap: 8px;
        color: $grey-black-color;

        &-container {
          display: flex;
          align-items: center;
          padding: 5px;
          border-radius: 8px;
          border: 1px solid #E8E8E8;
        }

        button {
          min-width: 40px;
          padding: 0;

          &:hover {
            svg {
              path {
                fill: #ffffff;
              }
            }
          }
        }

        .rotate {
          transform: rotate(180deg);
        }
      }

      .buttons-container {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-right: 8px;

        .revert-button {
          min-width: 40px;
          background-color: $button-light-blue-color;
          padding: 0;
          border-radius: 8px;

          &:hover {
            background-color: $secondary-blue-color;
             svg {
               path {
                 stroke: #ffffff;
               }
             }
          }
        }

        .button--show-more {
          min-width: 40px;
          padding: 0;
          background-color: transparent;

          &:hover,
          &:focus {
            border: 1px solid #221CB6;
            background: #F5F5F5;

            svg {
              path {
                fill: #221CB6;
              }
            }
          }
        }
      }

      .__react_component_tooltip.show {
        &.dropdown-tooltip {
          border-radius: 8px;
          opacity: 1;
          padding: 9px;
        }
      }


      .dropdown-list {
        overflow: auto;
        max-height: 300px;
        list-style: none;
        padding-left: 0;
        margin: 0;

        &__item {
          display: flex;
          align-items: center;
          padding: 7px;
          cursor: pointer;

          span {
            margin-left: 8px;
          }

          &:hover {
            opacity: 0.8;
          }

          &-disabled {
            pointer-events: none;
            cursor: default;
          }
        }
      }
    }

    &__navigation {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background: #EDEEF1;
      padding: 9px 9px 9px 0;

      &-buttons {
        padding: 0 10px;

        .button {
          min-width: 26px;
          height: 26px;
          padding: 0;
          margin: 0 7px;

          &:hover {
            border-radius: 5px;
            background: #E9EEFF;

            svg {
              path {
                stroke: #251FB6;
              }
            }
          }

          &--disabled {
            svg {
              path {
                fill: #949494;
                stroke: #949494;
              }
            }

            &:hover {
              background: transparent;

              svg {
                path {
                  fill: #949494;
                  stroke: #949494;
                }
              }
            }
          }
        }
      }

      &-address {
        background-color: #ffffff;
        border-radius: 6px;
        flex: 1;
        display: flex;
        align-items: center;

        .lock-icon {
          margin: 10px 4px 10px 14px;
        }

        .content-search-editor-v2 {
          flex: 1;

          &__query-editor-wrapper {
            border: none;

            .content-search-editor-v2__query-editor {
              & > div {
                padding: 10px 0 10px 4px !important;

                .content-search-editor-v2__query-text {
                  padding-left: 0;
                  font-size: 13px;
                  font-weight: 600;
                  line-height: normal;

                  &:before {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &__back-button {
    background-color: #ffffff;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.06);
    color: #444444;

    margin-right: 20px;

    &:hover {
      background-color: $button-light-blue-color;
      border: 1px solid $secondary-blue-color;
      color: $secondary-blue-color;
    }

    &-close {
      background-color: transparent;
      width: 78px;
      height: 78px;
      border-radius: 0;
      border-right: 1px solid #E8E8E8;

      &:hover {
        svg {
          path {
            stroke: $button-light-blue-color;
          }
        }
      }
    }
  }

  &__preview-section {
    position: relative;
    height: 100vh;

    .loader__full-screen-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: auto;
      height: auto;
      background: rgba(255, 255, 255, 0.7);
    }
  }
}

.opportunity-inspect-modal {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  &_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 49px;
    max-width: 493px;
    background-color: #FFFFFF;
    width: 100%;
    position: relative;
    top: 50%;
    left: 50%;
    border-radius: 19px;
    transform: translateY(-50%) translateX(-50%);

    &-image {
      margin-bottom: 20px;
      width: 156px;
      height: 183px;
    }

    &-title {
      color: #000;
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
      margin-top: 0;
    }

    &-info {
      color: #000;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      max-width: 238px;
      line-height: 178.5%;
      margin-bottom: 23px;
    }

    &-button {
      width: 186px;
      height: 45px;
    }
  }

  &_background {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(79, 79, 79, 0.29);
    backdrop-filter: blur(5px);
  }
}
