@keyframes rotate {
  from {
    transform: rotate(360deg);
  }
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    transition: all 0.5s ease;
    animation: rotate 1s linear infinite;
  }
}
