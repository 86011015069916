.billing-cancellation-modal {
  display: flex;
  flex-direction: column;
  padding: 50px 40px 47px;

  &__title {
    margin-bottom: 7px;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #000000;
  }

  &__subtitle {
    font-size: 15px;
    line-height: 21px;
    color: #615f5f;

    &-highlight {
      color: #221cb6;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;

    gap: 17px;

    margin: 50px 0 48px;
  }

  &__content-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 185px;
    padding: 36px 15px 35px;
    border: 1px solid #f0f0f0;
    border-radius: 8px;

    text-align: center;

    &-title {
      margin-bottom: 12px;
      font-weight: 600;
      font-size: 16px;
      color: #000000;
    }

    &-value {
      font-weight: 600;
      font-size: 24px;
      line-height: 24px;
      color: #221cb6;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;

    button {
      width: auto;
    }
  }
}
