@import "src/vendors/colors";
@import "src/vendors/mixins";

.linked-steps {
  padding: 0 30px;
  display: flex;
  justify-content: center;

  &__step {
    @include mulish-text;
    font-size: 16px;
    color: $grey-black-color;
    padding-bottom: 22px;
    border-bottom: 2px solid $secondary-grey-lines-color;
    width: 200px;
    text-decoration: none;
    display: flex;
    justify-content: space-between;

    &:hover {
      color: #000;
      border-color: $grey-black-color;
    }



    &:not(&:last-child) {
      margin-right: 32px;
    }

    &--disabled {
      pointer-events: none;

      &:hover {
        color: $grey-black-color;
        border-bottom: 2px solid $secondary-grey-lines-color;
      }
    }

    &--active {
      color: #000;
      border-color: $secondary-blue-color;
    }
  }
}