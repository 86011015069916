@import "src/vendors/colors";
@import "src/vendors/mixins";

.file-attachment {
  height: 50px;
  max-width: 150px;
  margin-right: 9px;
  background: $secondary-grey-lines-color;
  border-radius: 8px;
  padding: 6px 10px;
  position: relative;
  cursor: pointer;

  &:hover {
    opacity: .7;
  }

  &__file-wrapper {
    position: relative;
  }

  &__file-title {
    height: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
    white-space: nowrap;
    flex-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__file-size {
    margin-top: 4px;
    @include mulish-text;
    color: rgba(0, 0, 0, .4);
    padding-left: 29px;
    font-size: 12px;
  }

  &__file-remove {
    position: absolute;
    top: -6px;
    right: -6px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      @include svg-colors-path-fill($alert-hover-color);
    }
  }
}
