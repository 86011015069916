$primary-main-color: #221CB6;
$primary-main-color-light: #807beb;
$primary-main-color-lighter: #aca9f5;
$primary-main-color-lightest: #f0effa;
$secondary-main-color: #9324eb;
$light-main-color: #eef3ff;

$primary-black-color: #000000;
$secondary-black-color: #181818;
$grey-black-color: #7b7b7b;

$grey-text-color: #8d8f92;
$grey-text-secondary: #8f9199;

$primary-grey-lines-color: #f9f9f9;
$secondary-grey-lines-color: #e9ecf1;

$grey-icons-color: #c4c6cd;
$grey-bg-color: #fbfbfb;

$primary-grey-action-color: #f5f5f5;
$secondary-grey-action-color: #e9e9e9;
$grey-selection-color: #eaedf2;

$alert-color: #ed483d;
$alert-hover-color: #f77b73;
$alert-bg-color: #ffeeee;
$success-color: #23e771;

$warning-bg-color: #fc8e0e;

$secondary-light-blue-color: #2aa4cb;
$secondary-blue-color: #221cb6;
$secondary-orange-color: #fc8619;
$secondary-green-color: #58cd95;
$secondary-purple-color: #cd58c3;

$third-light-blue-color: #79dfff;

$border-grey-color: #f0f0f0;

$button-light-blue-color: #e9eeff;
