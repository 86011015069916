@import 'src/vendors/colors';
@import 'src/vendors/mixins';

.billing-type-checkboxes {
  display: grid;
  grid-template-columns: 325px 325px;
  grid-template-rows: 50px;
  grid-gap: 50px;

  &__item-container {
    @include mulish-text;
    font-size: 16px;
    position: relative;
    height: 48px;
    display: flex;
    align-items: center;
    padding: 0 15px;
    box-shadow: 0 2px 20px #eaecf4;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      box-shadow: 0 2px 20px #cacdd4;
    }

    &--active {
      color: #fff;
      background-color: $primary-main-color;
    }

    &--disabled {
      box-shadow: none;
      color: rgba(0, 0, 0, 0.29);
      background-color: #f5f6fa;
      pointer-events: none;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    width: 100%;

    &:before {
      display: inline-block;
      content: '';
      width: 16px;
      height: 16px;
      margin-right: 8px;

      background: #f5f6fa;
      border: 1px solid #e3e3e3;
      border-radius: 4px;
      flex-shrink: 0;
    }

    &--active {
      &:before {
        background-color: #fff;
        border-color: #fff;
        background-image: url('./assets/check.svg');
        background-position: 50% 50%;
        background-repeat: no-repeat;
      }

      .billing-type-checkboxes__sale {
        color: #251fb6;
        background-color: #ffffff;
      }
    }
  }

  &__sale-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__sale {
    font-weight: 700;
    font-size: 11px;
    line-height: 11px;

    height: 25px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    margin-left: auto;

    background-color: #251fb6;
    border-radius: 33px;
  }
}
