.personalize-opportunities {
  max-width: 340px;
  width: 100%;
  background-color: #FBFBFB;
  margin-right: 10px;
  border-radius: 8px;
  transition: max-width 0.4s;

  &.full {
    max-width: 403px;
  }

  @media (min-width: 1500px) {
    max-width: 403px;
  }

  &__extend {
    position: absolute;
    right: -13px;
    top: 23px;
    border: 2px solid #000;
    width: 25px;
    height: 25px;
    padding: 4px;
    border-radius: 50%;
    cursor: pointer;
    transition: opacity 0.1s;

    @media (min-width: 1270px) {
      display: none;
    }

    &:hover {
      opacity: .6;
    }

    &.rotate {
      transform: rotateY(180deg);
    }
  }

  &__header {
    position: relative;
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E9ECF1;

    &-search {
      display: flex;

      .campaigns-header__search {
        min-width: 100px;
        max-width: 100px;

        .input__field {
          margin-right: 10px;
        }
      }

      .header-filters {
        &__tooltip-button {
          svg {
            margin-right: 0;
          }
        }

        .__react_component_tooltip {
          left: 0 !important;
          position: absolute;
          top: 60px !important;

          &:after {
            left: 25px !important;
            top: -5px !important;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
          }
        }
      }
    }

    &-check {
      display: flex;

      p {
        margin-left: 8px;
        color: #8F9199;
      }
    }
  }

  &__content {
    overflow-y: auto;
    max-height: calc(100vh - 200px);
    overflow-x: hidden;

    &-list {
      list-style-type: none;
      padding-left: 0;
      overflow-y: auto;

      &__item {
        padding: 20px 26px;
        cursor: pointer;
        position: relative;


        &-content {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .item-content {
            width: 100%;
          }

          .info-icons {
            position: absolute;
            right: 5px;
            top: 30px;
          }
        }

        &:hover,
        &.active {
          background: #F2F4FC;
        }

        &.active {
          position: relative;

          &:after {
            content: "";
            display: block;
            position: absolute;
            width: 4px;
            right: 0;
            top: 1px;
            bottom: 1px;
            background-color: #221CB6;
            border-radius: 10px 0 0 10px;
          }
        }

        .item-content {
          .content-checkbox {
            display: flex;
            margin-bottom: 10px;
            gap: 8px;

            p {
              color: #202430;
              font-size: 15px;
              font-weight: 600;
              white-space: nowrap;
              max-width: 328px;
              text-overflow: ellipsis;
              overflow: hidden;
              margin-left: 0;
            }
          }

          a {
            margin-left: 24px;
            display: flex;
            align-items: center;

            svg {
              flex: none;
            }

            p {
              max-width: 315px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin-left: 6px;
            }
          }

          .btn-edited {
            padding: 4px 6px;
            border-radius: 5px;
            border: 1px solid #E3E3E3;
            color: rgba(0, 0, 0, 0.23);
            font-size: 11px;
            margin-top: 12px;
            display: flex;
            width: fit-content;
            margin-left: 24px;
          }
        }
      }
    }
  }

  &__trash-button {
    min-width: auto;
    width: 40px;
    margin-right: 10px;
    padding-left: 12px;
    padding-right: 12px;
  }
}
