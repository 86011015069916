.editor-emoji-button {
  font-size: 16px;
  cursor: pointer;
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    border-radius: 5px;
    background: #E9EEFF;
  }
}

.emoji-button-tooltip {
  display: grid;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 500px;
  height: 500px;
  grid-template-columns: repeat(12, 22px);
  grid-column-gap: 8px;
  grid-row-gap: 5px;
  padding-top: 5px;

  &__item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    height: 22px;
    min-width: 22px;
    cursor: pointer;

    &:hover {
      background-color: #000000;
      border-radius: 3px;
    }
  }
}
