@import "src/vendors/mixins";
@import "src/vendors/colors";

.inbox-filters {
  &__wrapper-filter-btn {
    position: relative;

    .inbox-filters-tooltip__title-reset {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 10;

      svg {
        @include svg-colors-path-fill($alert-color);
      }

      &:hover {
        svg {
          @include svg-colors-path-fill($alert-hover-color);
        }
      }
    }
  }

  &__filter-btn {
    cursor: pointer;
    padding: 11px 14px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    height: 40px;
    min-width: 93px;
    font-size: 16px;

    &--active {
      background-color: #F5F5F5;
      color: $grey-black-color;
      position: relative;

      svg {
        @include svg-colors-path(#BDBDBD)
      }

      &:hover {
        color: #474747;

        .inbox-filters-tooltip__icon {
          svg {
            @include svg-colors-path(#474747);
          }
        }
      }
    }
  }
}

.inbox-filters-tooltip {
  padding: 17px;

  &__title {
    font-size: 16px;
    padding-bottom: 17px;

    border-bottom: 1px solid #272727;
    display: inline-flex;
    align-items: center;
    gap: 6px;
    width: 100%;

    &-reset {
      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
  }

  &__content {
    color: #7B7B7B;
  }

  &__row {
    margin-top: 20px;

    .inbox-filter-input
    .input__field {
      background-color: transparent;
      border: 1px solid rgba(141, 143, 146, 0.27);
      box-sizing: border-box;
      border-radius: 6px;
      width: 100%;
      height: 45px;

      color: white;

      &::placeholder {
        color: #5E5E5E;
      }
    }
  }

  &__row-title {
    width: 100px;
    margin-bottom: 8px;
  }

  &__only-respona-text {
    width: 151px;
    line-height: 20px;
  }

  &__only-respona-toogler {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: 20px;
  }

  &__footer {
    display: flex;
    padding: 20px 0;
    background-color: #000000;
    position: sticky;
    bottom: 20px;
  }

  &__reset-btn {
    margin-left: 10px;
  }
}

#inbox-filters-tooltip {
  background-color: #000000 !important;
  border-radius: 8px !important;

  width: 284px !important;
  min-height: 100px !important;

  height: calc(100% - 50px);
  max-height: 738px;
  overflow-y: auto;

  &:after {
    left: 95% !important;
  }
}
