.campaign-report-header {
  display: flex;
  align-items: center;

  &__button {
    margin-left: 10px;
  }
}

.campaign-report-graphics {
  overflow-y: auto;
  max-height: calc(100vh - 68px);

  &_item {
    padding: 20px 20px 0;
  }
}

.report-graphic-container {
  width: 100%;
  max-width: 1500px;
  background-color: #F9F9F9;
  border-radius: 10px;
  margin-bottom: 10px;
}