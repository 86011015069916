@import 'src/vendors/colors';
@import 'src/vendors/mixins';

.upsell-sidebar {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__body {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 20px 20px 15px;
  }

  &__footer {
    background: #dbe2fe59;
  }

  &__bottom-description {
    font-size: 12px;
    color: $grey-text-secondary;

    display: flex;
    align-items: center;

    margin: auto auto 0;

    svg {
      margin-right: 6px;
      @include svg-colors-path(#8f9199);
    }
  }
}
