@import 'src/vendors/mixins';

.settings-members {
  padding-bottom: 20px;

  &__header-items {
    display: flex;
    gap: 12px;
  }

  &__header-search {
    position: relative;

    & input {
      width: 240px;

      &::placeholder {
        color: #c0c6cd;
      }
    }
  }

  &__toogler {
    margin-right: 20px;
  }

  &__table {
    margin-top: 13px;

    &__row {
      height: 80px;

      &--with-hover:hover {
        cursor: pointer;
        background-color: #eef3ff;
      }

      &:not(&:last-child) {
        border-bottom: 1px solid #f4f4f4;
      }

      .table-td:last-child {
        padding-right: 0;
      }
    }

    &__cell {
      &--name {
        display: flex;
        align-items: center;
      }

      &--name-details {
        display: flex;
        flex-direction: column;
        max-width: 250px;
        margin-left: 13px;
      }

      &--data {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #8d8f92;
      }

      &--status {
        padding: 5px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #ffffff;

        border-radius: 6px;
        flex-grow: 0;

        &--active {
          background-color: #23e771;
        }

        &--expired {
          background-color: #ed483d;
        }
      }
    }

    &__member-name {
      margin-top: 1px;
      margin-bottom: 2px;
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      color: #1b1e22;
    }

    &__member-email {
      white-space: nowrap;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #8d8f92;

      text-overflow: ellipsis;
      overflow: hidden;
    }

    .table-td {
      @include mulish-text;
      color: #8d8f92;
      text-align: left;
      font-size: 14px;

      .actions-menu .react-tooltip:not(.react-tooltip--position-fixed) {
        left: -120px !important;
      }
    }

    &-member-name {
      @include mulish-text;
      font-weight: 600;
      font-size: 14px;
      color: #1b1e22;
      margin-left: 12px;
    }
  }
}
