.relationships-create-person-sidebar {
  &__header {
    position: relative;
    top: 0;
    width: 100%;
    padding: 29px 20px;

    border-bottom: 1px solid #F4F4F4;

    font-weight: 600;
    font-size: 24px;
    line-height: 30px;

    color: #000000;

    z-index: 1;
  }
}
