@import '../../vendors/mixins';

.confirm-modal {
  padding: 32px 22px 23px;
  width: 442px;

  &__close-btn {
    top: 32px;
    right: 22px;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #1b1e22;
  }

  &__message {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #8f9199;
  }

  &__footer {
    display: flex;
    grid-gap: 10px;
    margin-top: 27px;

    button {
      flex: 1;
    }
  }
}
