@import 'src/vendors/colors';
@import 'src/vendors/mixins';

.threads-list-item {
  padding: 20px;
  position: relative;
  cursor: pointer;

  &:hover {
    background-color: $grey-selection-color;
  }

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #e9ecf1;
  }

  &--active {
    background-color: #F2F4FC;

    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 15px;
      bottom: 15px;
      width: 3px;
      background-color: $secondary-blue-color;
      border-radius: 10px 0 0 10px;
    }
  }

  &__header {
    display: flex;
    align-items: center;

    &-subject-container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      svg {
        flex: none;
      }

      .threads-list-item__subject {
        color: #2C2C2C;
        font-weight: normal;
        font-size: 14px;
      }
    }

    .named-avatar {
      margin-right: 10px;
    }

    &-tags {
      margin-top: 10px;
    }
  }

  &__from-name {
    @include mulish-text;
    font-weight: 600;
    font-size: 14px;
    color: #202430;
    width: fit-content;

    white-space: nowrap;
    margin-right: 5px;
    overflow: hidden;
    text-overflow: ellipsis;

    &--noviewed {
      font-weight: 800;
      width: fit-content;
      margin-right: 5px;
    }
  }

  &__no-viewed-circle {
    flex-shrink: 0;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #221cb6;
    margin-left: 5px;
    margin-right: 5px;
  }

  &__subject {
    @include mulish-text;

    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;

    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: $grey-text-secondary;
    margin-top: 10px;

    overflow: hidden;
    &--noviewed {
      font-weight: 800;
    }
  }

  &__snippet {
    @include mulish-text;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    font-size: 10px;
    line-height: 17px;
    color: $grey-text-secondary;
    margin-top: 10px;
    font-style: italic;

    overflow: hidden;
  }

  &__container {
    display: flex;
    align-items: center;
    grid-column-gap: 15px;
  }

  &__draft,
  &__snooze {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-top: 8px;

    &-content {
      color: #626262;
      max-width: 180px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__last-activity-time {
    margin-left: auto;
    @include mulish-text;
    font-size: 14px;
    color: $grey-text-secondary;
    white-space: nowrap;
  }
}
