.text-with-icon {
  display: inline-flex;
  align-items: center;

  border-radius: 6px;

  padding: 5px 5px 5px 7px;
  margin-right: -5px;

  text-decoration: none;
  color: #3424eb;
  font-size: 16px;
  line-height: 20px;

  transition: background-color 200ms ease;

  cursor: pointer;

  &--disabled {
    background-color: #f5f6fa;
    color: #bfc2cc;
    cursor: default;
  }

  &:hover {
    background-color: #eef3ff;
  }

  &__icon {
    display: flex;
    align-items: center;
    margin-right: 6px;
    max-height: 20px;
  }
}
