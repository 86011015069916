.campaign-template-editor {
  position: relative;
  background-color: #ffffff;
  border-radius: 8px;

  margin-bottom: 90px;

  &__subject {
    display: flex;
    align-items: center;
    padding: 10px 10px 10px 23px;

    border-bottom: 1px solid #F4F4F4;

    &-header {
      padding-right: 9px;

      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: #8F9199;
    }

    .slate-editor__editor-wrapper-inline {
      padding-left: 0;
    }

  }

  .slate-editor__editor--with-vars {
    padding-top: 21px;
    padding-left: 23px;
  }
}