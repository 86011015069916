@import 'src/vendors/mixins';
@import 'src/vendors/colors';

.contacts-result {
  margin-top: 35px;

  &__title {
    @include mulish-text;
    font-weight: 600;
    font-size: 16px;
  }

  .pagination-full-width {
    padding: 0;
  }

  .contacts-result-table {
    width: 100%;
    margin-top: 30px;
    padding-bottom: 30px;

    &__email-row {
      display: flex;
      align-items: center;
    }

    &__email-string {
      padding-left: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.5rem;
    }

    &__body {
      padding-top: 16px;
      box-sizing: border-box;
      border-bottom: 1px solid $secondary-grey-lines-color;
    }

    &__head-row {
      box-sizing: border-box;
      padding-bottom: 16px;
      border-bottom: 1px solid $secondary-grey-lines-color;
    }

    &__body-row {
      padding-bottom: 20px;
    }

    .table-td {
      width: 1px;
      max-width: 40vw;
    }

    .table-td:last-child,
    .table-th:last-child {
      padding-right: 0;
    }

    &__th {
      @include mulish-text;
      font-size: 14px;
      line-height: 130%;
      color: $grey-text-color;
      box-sizing: border-box;
      padding-right: 5px;

      &--name {
        width: 190px;
      }

      &--company {
        width: 100px;
      }

      &--email {
        width: 260px;
      }

      &--status {
        padding: 0;
        width: auto;

        margin-left: auto;
      }
    }

    &__td {
      box-sizing: border-box;
      padding-right: 5px;

      &--name {
        width: 190px;
      }

      &--company {
        width: 100px;
      }

      &--email {
        width: auto;
      }

      &--status {
        min-width: 85px;
        margin-left: auto;
        max-width: 120px;
      }
    }

    &__name {
      @include mulish-text;
      font-size: 14px;
      line-height: 125%;
    }

    &__job-position,
    &__company,
    &__location {
      @include mulish-text;
      font-size: 12px;
      line-height: 130%;
      color: $grey-text-color;
    }

    &__status {
      @include mulish-text;
      width: 85px;
      height: 35px;
      font-size: 14px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;

      border-radius: 8.5px;

      .button {
        width: 95px;
      }

      &--not-found {
        color: $grey-text-color;
        background: $primary-grey-action-color;
      }

      &--not-requested {
        color: #8d8f92;
        background: none;
      }

      &--complete {
        color: #fff;
        background: #23e771;
      }

      &--get-emails,
      &--searching {
        color: $primary-main-color;
        border: 1px solid #251fb6;

        &:hover {
          color: #fff;
        }
      }

      &--searching {
        width: 120px;

        &:hover {
          cursor: not-allowed;
          color: $primary-main-color;
          border: 1px solid #251fb6;
          background: transparent;
        }
      }

      &--assign {
        color: #fff;
      }
    }

    .table__td {
      padding-bottom: 20px;
    }
  }

  .contacts-result-emails {
    @include mulish-text;
    display: inline-block;
    font-size: 12px;
    line-height: 130%;
    color: $secondary-blue-color;
    cursor: pointer;

    &:hover {
      color: #bdbafe;
    }
  }

  .contacts-result-emails-tooltip {
    padding: 20px;

    &__email {
      @include mulish-text;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #fff;

      &:not(&:last-child) {
        margin-bottom: 13px;
      }
    }
  }
}
