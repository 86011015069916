@import 'src/vendors/colors';
@import 'src/vendors/mixins';

.pipeline-deal-header {
  &__name {
    font-weight: 600;
    font-size: 24px;
    margin: 0 0 8px 0;
  }

  &__website {
    font-size: 16px;
    color: $grey-text-secondary;
    line-height: 18px;
  }
}

.pipeline-deal {
  display: flex;

  &__content {
    flex: 1;
    padding: 20px 40px 0 15px;
  }

  &__tab-header {
    display: flex;
    align-items: center;

    svg {
      height: 16px;
      margin-right: 8px;
    }

    &--active {
      svg {
        @include svg-colors-path($primary-main-color);
      }
    }
  }
}