@import '../../vendors/mixins';
@import '../../vendors/colors';

.pagination-wrapper {
  position: sticky;
  bottom: 0;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 20px;
}

.pagination-full-width {
  display: flex;
  align-content: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
}

.pagination {
  &__switch-buttons {
    display: flex;
    align-items: center;

    &-button {
      width: 62px;
      height: 30px;
      margin-right: 7px;

      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: #ffffff;
      padding: 3px 0 2px;
      background-color: #251fb6;
      border-radius: 8px;

      text-align: center;
      cursor: pointer;

      &:hover {
        background-color: #4e48dc;
      }

      &--disabled {
        background-color: #f5f5f5 !important;
        color: #535353;
        cursor: not-allowed;
        //pointer-events: none;
      }
    }
  }

  &__select {
    display: flex;
    align-items: center;

    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #202430;

    .input {
      width: 62px;
      margin: 0 6px;

      &:last-child {
        margin-right: 0px;
      }

      input {
        width: 100%;
        height: 28px;
        padding: 6px 10px;
        font-size: 16px;
        line-height: 16px;
        color: #202430;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        &[type='number'] {
          -moz-appearance: textfield;
        }
      }
    }

    &-last-page {
      color: #251fb6;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

// OLD PAGINATION STYLES

.pagination {
  display: flex;
  justify-content: center;

  &__button {
    box-sizing: border-box;
    background: none;
    box-shadow: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &:not(&--not-button) {
      &:not(&.pagination__button--active) {
        &:hover {
          background: #f5f5f5;
          border-radius: 8px;
        }
      }
    }

    &:not(&:last-child) {
      margin-right: 5px;
    }

    &--not-button {
      cursor: auto;
    }

    &--page-btn {
      &.pagination__button--active {
        background: #251fb6;
        border-radius: 8px;
        color: #fff;
        cursor: auto;
      }
    }

    &--edge-btn {
      border: 1px solid #9ba8f5;
      border-radius: 8px;

      &:hover {
        background: #e9eeff !important;
      }
    }

    &--disabled {
      border-color: $secondary-grey-action-color;
      cursor: not-allowed;

      svg {
        @include svg-colors-path-stroke($secondary-grey-action-color);
      }

      &:hover {
        background: none !important;
      }
    }
  }
}

.pagination-limit {
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  bottom: 0;
  right: 30px;
}
