@import 'src/vendors/mixins';
@import 'src/vendors/colors';

.analytic-opportunities-table {
  margin-top: 15px;

  &__next-activity-th {
    display: inline-flex;
    align-items: center;
  }

  &-emails-chevron svg {
    transform: rotate(180deg);
    margin-left: 5px;
  }

  &-emails-tooltip {
    padding: 20px;
  }

  &-tooltip__email {
    @include mulish-text;
    font-size: 14px;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 10px;
  }
}

.analytic-opportunities {
  &__name {
    @include mulish-text;
    font-weight: 600;
    font-size: 14px;
    color: #1b1e22;
  }

  &__status-container {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    width: 160px;
  }

  &__status-icon {
    margin-right: 4px;
  }

  &__status {
    flex-shrink: 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #8d8f92;
  }

  &__rate-cell {
    display: flex;
    align-items: center;
    @include mulish-text;
    font-weight: 600;
    font-size: 12px;
    line-height: 120%;
    color: $grey-text-color;
  }

  &__rate-number {
    margin-left: 10px;
  }
}

.opportunities-analytic-header-actions {
  display: flex;
  gap: 12px;

  &__export-users-btn {
    height: 40px;
    width: auto;

    svg {
      margin-right: 10px;
    }
  }
}

.opportunities-table-emails-tooltip {
  &__email {
    display: flex;
    align-items: center;

    @include mulish-text;
    font-size: 14px;
    color: #fff;
    padding: 5px 0;
  }
}

@media (screen and max-width: 1440px) {
  .opportunities-analytic-header-actions {
    &__export-users-btn {
      svg {
        margin-right: 0;
      }
    }
  }
}
