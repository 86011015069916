@import 'src/vendors/colors';
@import 'src/vendors/mixins';

.inbox-mails-sidebar {
  width: 300px;
  height: calc(100vh - 68px);
  display: flex;
  flex-direction: column;

  &__status-and-sort {
    display: flex;
    justify-content: space-between;
    padding: 0px 20px;
    margin-bottom: 15px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &__status {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E9ECF1;
  }

  &__sort {
    width: 117px;
  }

  &__status,
  &__sort  {
    .select {
      .inbox__menu-list {
        .inbox__option {
          border-radius: 8px;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  &__status-item {
    line-height: 14px;
    font-size: 14px;
    cursor: pointer;
    padding: 14px 0;

    &--active {
      color: blue;
      border-bottom: 2px solid blue;
      padding-bottom: 12px;
    }
  }

  &__refresh-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    margin-left: 6px;
    border: 1px solid #dbdfe7;

    border-radius: 8px;

    cursor: pointer;

    svg path {
      fill: #c4c6cd;
    }

    &:hover {
      border-color: #e9eeff;

      svg path {
        fill: #2752eb;
      }
    }
  }
}
