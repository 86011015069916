@import "src/vendors/colors";

.colored-toggler {
  position: relative;
  width: 40px;
  height: 24px;
  border-radius: 31px;

  cursor: pointer;

  &__big-size {
    width: 88px;
    display: flex;

    .colored-toggler__slider--active {
      left: 66px;
    }
  }

  &__title {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    white-space: nowrap;
    margin: 5px 8px;
    position: absolute;
    left: 0;

    &.right {
      right: 0;
      left: inherit;
    }
  }

  &__slider {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 3px;
    left: 3px;
    width: 18px;
    height: 18px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, .06);
    border-radius: 100%;
    transition: 1s;

    &--loading {
      cursor: not-allowed;

      &:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: " ";
        display: block;
        border-radius: 50%;
        background: transparent;
        border: 2px solid $primary-main-color;
        border-color: $primary-main-color transparent transparent transparent;
        animation: lds-dual-ring 1.2s linear infinite;
      }
    }

    &--active {
      transition: 1s;
      left: 19px;
    }
  }
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
