.metrics-step {
  --width: 700px;
  width: var(--width);
  margin: 0 auto 50px;

  &__settings {
    background: #f5f6fa;
    border-radius: 17px;

    display: flow-root;
    padding: 35px 56px 32px;
  }

  &__settings--border-left {
    border-top-right-radius: 0;
  }

  &__settings--border-right {
    border-top-left-radius: 0;
  }

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 40px;
  }

  &__body {
    span {
      font-weight: 400;
      font-size: 16px;
    }
  }

  &__add-other-metrics {
    color: #7b7b7b;
    font-size: 18px;
    text-align: center;

    padding: 27px;
    margin-top: 20px;

    cursor: pointer;

    border: 1px dashed #bfc2cc;
    border-radius: 17px;
  }
}
