@import 'src/vendors/mixins';
@import 'src/vendors/colors';

.mime-html-message {

  div[dir="ltr"] {
    style ~ div {
      white-space: break-spaces;
      margin: 0px;
      line-height: 1.5;
    }
  }

  &__show-more-btn {
    padding: 0;
    min-width: 0;

    &:hover {
      svg {
        @include svg-colors-path($primary-black-color);
      }
    }
  }
}
