@import "src/vendors/mixins";

.onboarding-page-header {
  margin-top: 60px;

  display: flex;
  flex-direction: column;

  align-items: center;

  &__icon {
    margin-bottom: 17px;
  }

  &__title,
  &__subtitle {
    @include mulish-text;
  }

  &__title {
    font-weight: 600;
    font-size: 34px;
    color: #1B1E22;
    margin-bottom: 16px;
  }

  &__subtitle {
    font-size: 14px;
  }
}