.select {
  position: relative;

  &__icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.select-grey {

    .css-eqy8aq-control {
      background: #F0F0F0;
      border-color: #F0F0F0;
    }

    .select__icon {
      svg {
        path {
          stroke: #8F9199;
        }
      }
    }
  }
}
