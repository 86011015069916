@import "src/vendors/colors";

.video-frame-wrapper {
  width: 640px;
  height: 360px;
  position: relative;
  border-radius: 14px;
}

.video-frame {
  width: 100%;
  height: 100%;
  border-radius: 14px;

  /*
    This is play video button like in Figma
    maybe will be usefull leter
   */
  //&__play-button {
  //  cursor: pointer;
  //  width: 86px;
  //  height: 86px;
  //  border-radius: 50%;
  //
  //  background-color: #FFFFFF;
  //  box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.25);
  //
  //  position: absolute;
  //  left: 50%;
  //  top: 50%;
  //  transform: translate(-50%, -50%);
  //
  //  &::after {
  //    content: "";
  //    position: absolute;
  //    width: 0;
  //    height: 0;
  //    left: 55%;
  //    top: 50%;
  //    transform: translate(-50%, -50%);
  //    border-top: transparent 10px solid;
  //    border-left: #251FB6 13px solid;
  //    border-bottom: transparent 10px solid;
  //  }
  //}
}