@import 'src/vendors/mixins.scss';
@import 'src/vendors/colors.scss';

.input {
  position: relative;

  &__field {
    @include mulish-text;
    @include input-field;
    color: #404040;
    font-size: 16px;
    padding-left: 19px;
    &::before {
      content: attr(data-placeholder);

      position: relative;
    }

    &::placeholder {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: #C0C6CD;
    }
  }

  &--error {
    .input__field {
      border: 1px solid $alert-color;
      color: $alert-color;
    }
  }

  &__error {
    display: block;
    margin: 4px 0 0 8px;

    font-size: 12px;
    color: #ed483d;
  }

  &__icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__clear-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--inline {
    margin: 0 6px;
    display: inline-block;

    .input__field {
      width: 50px;
      height: 24px;
      padding-left: 7px;
      border-radius: 5px;
    }
  }

  &--with-icon {
    .input__field {
      padding-left: 40px !important;
    }
  }

  &--clearable {
    .input__field {
      padding-right: 30px;
    }
  }

  &--full-width {
    .input__field {
      width: 100%;
    }
  }

  &--full-height {
    .input__field {
      height: 100%;
    }
  }

  &__before-text {
    position: absolute;
    display: flex;
    align-items: center;
    height: 100%;
    top: 0;
    left: 15px;

    @include mulish-text;
    font-size: 16px;
    color: $grey-text-secondary;

    pointer-events: none;
  }

  &--with-after-text {
    .input {
      &__field {
        padding-right: 70px;
      }

      &__after-text {
        position: absolute;
        @include mulish-text;
        right: 10px;
        top: 10px;

        font-size: 16px;
        line-height: 100%;
        color: $grey-text-secondary;
      }
    }
  }

  &--transparent {
    .input {
      &__field {
        background-color: transparent;
        border: none;
      }
    }
  }
}

.number-input {
  &--without-arrows {
    /*
      Disable in number input right arrows
    */
    .input__field {
      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      /* Firefox */
      -moz-appearance: textfield;
    }
  }
}
