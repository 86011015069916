@import 'src/vendors/colors';

.editor-variables-list {
  height: 360px;
  width: 240px;
  background-color: #000000;
  border-radius: 8px;
  overflow: auto;

  &--inner {
    padding: 24px 26px;
  }

  &--with-sticky-button {
    .editor-variables-list--inner {
      padding-bottom: 18px;
    }
  }

  &__search-input input {
    height: 45px;
    width: 100%;
    background-color: #000000;
    border: 1px solid #272727;
    border-radius: 6px;

    color: #ffffff;

    &:focus {
      border-color: #272727;
    }
  }

  &__category {
    padding-top: 20px;
  }

  &__category-title {
    margin-bottom: 5px;
    font-weight: 700;
    font-size: 10px;
    line-height: 19px;
    letter-spacing: 0.075em;
    text-transform: uppercase;

    color: rgba(255, 255, 255, 0.59);
  }

  &_button {
    color: #7974F6;
    font-size: 14px;
    position: sticky;
    bottom: 0;
    background-color: #000;
    padding: 12px;
    font-weight: 400;
    text-align: center;

    &:before {
      content: '';
      background: rgba(255, 255, 255, 0.19);
      height: 1px;
      left: 0;
      right: 0;
      top: 0;
      position: absolute;
    }

    &:hover {
      cursor: pointer;
      color: rgba(121, 116, 246, 0.8);
    }
  }

  &__item {
    font-weight: 400;
    font-size: 14px;
    line-height: 31px;
    color: #ffffff;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
}
