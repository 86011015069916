.campaign-analytics-opportunity {
  margin-top: 20px;
  background-color: #FBFBFB;
  padding: 24px 21px;
  border-radius: 10px;

  &__title {
    font-size: 18px;
    font-weight: 600;
    margin-top: 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    .search-form,
    .header-list-buttons {
      display: flex;
      align-items: center;
    }

    .search-form {
      .header-filters {
        position: relative;

        .__react_component_tooltip {
          left: -5px !important;
          top: 50px !important;
          position: absolute;
        }

        &__content-wrapper {
          &:after {
            left: 9px !important;
            top: -5px !important;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom-style: solid;
            border-bottom-width: 5px;
            border-bottom-color: #000000 !important;
          }
        }
      }
    }

    .header-list-buttons {
      gap: 8px;
    }
  }

  &__content {
    &-table {
      width: 100%;
      border-collapse: collapse;

      thead {
        border-top: 1px solid #E1E1E1;
        border-bottom: 1px solid #E1E1E1;

        tr {
          th {
            color: #8F9199;
            font-size: 14px;
            font-weight: 500;
            text-align: left;
            padding: 16px 10px;

            .table-header-title {
              display: flex;
              align-items: center;

              span {
                margin-left: 5px;
              }
            }
          }
        }
      }

      tbody {
        tr {
          border-bottom: 1px solid #E1E1E1;
          td {
            padding: 16px 10px;
            vertical-align: top;

            &:first-child {
              width: 30%;
            }
          }

          &:hover {
            background: #F2F4FC;
            cursor: pointer;
          }
        }

        .table-content {
          padding-right: 30px;

          &_header {
            display: flex;
            align-items: center;
            margin-bottom: 8px;

            h4 {
              margin: 0 0 0 8px;
              font-weight: 600;
              color: #202430;
            }
          }

          &_text {
            color: rgba(0, 0, 0, 0.58);
            font-size: 12px;
            font-weight: 400;
            margin-left: 24px;
            margin-bottom: 8px;
          }

          .social-links-a {
            display: inline-flex;
            margin-left: 4px;
            svg {
              margin-right: 4px;
              flex: none;
            }

            color: #221CB6;
          }

          .link-icon-a {
            display: inline-flex;
            margin-left: 24px;
            align-items: center;

            .link-content {
              display: inline-block;
              width: 450px;
              max-width: 95%;
              white-space: nowrap;
              overflow: hidden !important;
              text-overflow: ellipsis;
           }

            svg {
              margin-right: 5px;
              flex: none;
            }

            color: #221CB6;
          }

          &_status {
            height: 18px;
            padding: 2px 6px;
            background: rgba(123, 123, 123, 0.32);
            border-radius: 3px;
            width: fit-content;
            display: inline-flex;

            span {
             color: #FFF;
             font-size: 12px;
           }

            &.not_verified {
              background-color: #FC8E0E;
            }

            &.verified {
              background-color: #23E771;
            }

            &.flagged {
              background-color: #ED483D;
            }
          }

          &_source {
            display: flex;
            align-items: center;

            p {
              margin-left: 7px;
            }
          }

          &_contacts {
            &-list {
              list-style-type: none;
              padding: 0;
              margin: 0 0 5px;

              &:not(:first-child) {
                margin-top: 10px;
              }

              li {

                &.name-container {
                  display: flex;
                  align-items: center;
                }

                .name {
                  color: #393939;
                  font-size: 14px;
                  margin-right: 5px;
                }

                .status {
                  display: block;
                  max-width: 250px;
                  color: rgba(57, 57, 57, 0.54);
                  font-size: 12px;
                }

                .email {
                  font-size: 11px;
                  color: #393939;
                  text-decoration-line: underline;
                }
              }
            }

            .more {
              color: #221CB6;
              font-size: 11px;

              &:hover {
                cursor: pointer;
              }
            }
          }

          &_metrics {
            &-text {
              color: rgba(57, 57, 57, 0.54);
              font-size: 12px;
              margin: 0 6px;
            }

            ul {
              list-style-type: none;
              margin: 0;
              padding-left: 0;

              li {
                display: flex;
                align-items: center;

                &:not(:last-child) {
                  margin-bottom: 15px;
                }

                img {
                  width: 16px;
                  height: 16px;
                }
              }
            }
          }

          &_date {
            display: flex;
            flex-direction: column;

            .date {
              font-size: 14px;
              color: #393939;

              .time {
                margin-left: 5px;
              }
            }

            .person {
              font-size: 12px;
              color: rgba(57, 57, 57, 0.54);
              margin-top: 10px;
            }
          }
        }
      }

      .btn-edited {
        padding: 4px 6px;
        border-radius: 5px;
        border: 1px solid #E3E3E3;
        color: rgba(0, 0, 0, 0.23);
        font-size: 11px;
        margin-top: 12px;
        display: flex;
        width: fit-content;
        margin-left: 24px;
      }
    }

    &-bot {
      margin: 90px auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      &-title {
        display: inline-flex;
        flex-direction: column;
        margin: 53px 0 30px;

        span {
          color: #000;
          text-align: center;
          font-size: 36px;
          font-style: normal;
          font-weight: 500;
          line-height: 123%;
        }
      }

      &-text {
        color: rgba(0, 0, 0, 0.58);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150.5%;
        max-width: 325px;
      }
    }
  }
}
