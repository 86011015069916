@import 'src/vendors/mixins';

.campaign-template {
  flex: 1;
  display: flex;
  flex-direction: row;

  overflow: hidden;

  height: 100%;

  .slate-editor__editor {
    height: auto;
  }

  &__container {
    display: flex;
    flex-direction: column;

    height: 100vh;

    overflow: hidden;

    .page-header__title {
      display: flex;
      align-items: flex-end;
    }
  }

  &__header {
    display: flex;
    align-items: center;

    button span {
      margin-right: 22px;
    }
  }

  &__sidebar {
    display: flex;
    flex-direction: column;
    gap: 37px;

    padding: 22px 29px;
    width: 526px;

    background-color: #f2f2f2;

    &-video {
      flex-shrink: 0;

      position: relative;
      width: 468px;
      height: 263px;

      background-color: #ffffff;

      border-radius: 7px;
      overflow: hidden;
    }

    &-description {
      width: 100%;
      font-weight: 400;
      font-size: 14px;
      line-height: 26px;
      color: #7b7b7b;
    }

    &-sequence {
      display: flex;
      flex-direction: column;

      &-label {
        margin-bottom: 10px;

        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #7b7b7b;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 32px;

    padding: 35px 40px;

    &--container {
      flex: 1;
      height: calc(100vh - $page-header-height);
      overflow: auto;
      padding-bottom: 20px;
    }

    .content-search-editor-v2__query-editor-wrapper {
      border-radius: 8px;
      max-height: 295px;

      &.visible {
        overflow: visible;

        .campain-select__menu {
          overflow: hidden;

          .campain-select__option--is-disabled {
            color: #7B7B7B;

            &:hover {
              background-color: inherit;
            }
          }
        }
      }
    }

    .content-search-editor-v2__query-editor > div:not(:last-child) {
      padding: 5px 17px !important;
    }

    .content-search-editor-v2__query-text {
      font-size: 16px;
      padding-left: 30px;
      &:before {
        font-size: 16px;
      }
    }
  }
}
