@import 'src/vendors/colors';
@import 'src/vendors/mixins';

.organizations-header-actions {
  display: flex;
  align-items: center;
  gap: 12px;

  .button {
    height: 40px;
  }

  &__more-actions-btn {
    color: $grey-black-color;
    background-color: #f5f5f5;
    white-space: nowrap;
    height: 40px;
    padding-left: 12px;


    svg {
      height: 16px;
      margin-right: 16px;
      margin-left: 8px;
    }

    &:hover {
      background-color: #f5f5f5;
      color: #474747;

      svg {
        filter: none;

        path {
          fill: #474747;
        }
      }
    }
  }

  &__remove-btn {
    height: 45px;

    svg {
      margin-right: 8px;
    }
  }

  &__add-user-btn {
    margin-left: 16px;
    height: 45px;
  }

  &__separator {
    width: 2px;
    height: 45px;
    background: #f4f4f4;
  }

  &__select-column {
    margin-left: 20px;
    cursor: pointer;

    &:hover {
      @include svg-colors-path-fill($secondary-blue-color);
    }
  }

  &__export-users-btn {
    height: 45px;


    svg {
      margin-right: 0!important;
    }

    span {
      margin-left: 7px;
    }
  }

  /* Overlap styles */
  .button {
    padding: 12px;
    width: auto;
  }

  .input {
    &__field {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1440px) {
  .organizations-header-actions {
    &__remove-btn {
      width: auto;

      svg {
        margin-right: 0;
      }
    }

    &__more-actions-btn {
      width: auto;

      svg {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }
}
