.keywords-source-select {
  display: grid;
  grid-template-columns: repeat(auto-fill, 218px);
  grid-gap: 17px;
  margin-bottom: 50px;

  &__title {
    margin-bottom: 22px;
    font-weight: 600;
    font-size: 20px;
    line-height: 100%;
    color: #000000;
  }
}
