@import 'src/vendors/mixins';

.campaigns-sidebar {
  background-color: #ffffff;
  padding: 0 20px;
  position: sticky;
  top: 68px;
  z-index: 10;
  max-width: calc(100vw - 100px);
  overflow: auto;

  .configurable-sidebar__header {
    padding: 0;
  }

  &__header-container {
    display: flex;
    flex-direction: column;
  }

  &__header {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px;
    padding: 0 5px;
    color: #7b7b7b;
  }
}
