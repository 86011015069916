.notifications-sidebar {
  position: absolute;
  top: 0;
  left: 0;
  width: 295px;
  height: 100vh;
  background: #FFFFFF;
  box-shadow: 0 4px 50px rgba(18, 16, 68, .09);
  display: flex;
  flex-direction: column;
  z-index: 10;

  &__header {
    height: 80px;
    padding: 0 18px;
    border-bottom: 1px solid #F4F4F4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
  }

  &__close-icon {
    cursor: pointer;

    &:hover {
      opacity: .5;
    }
  }

  &__body {
    padding: 34px 18px;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__notification {
  margin-bottom: 35px;
  }

  &__notification-title {
    margin: 0;
    line-height: 1.5rem;
  }

  &__notification-text {
    margin-top: 8px;
    text-decoration: underline;
    color: #928E8D;
    cursor: pointer;
  }
}
