@import 'src/vendors/mixins';

.login-page-content {
  position: relative;
  max-width: 100%;
  min-width: 346px;
  padding-top: 100px;
  z-index: 1;

  &__title-svg {
    width: 100%;
    max-width: 507px;
    min-width: 300px;
    height: 157px;

    padding-right: 30px;
    margin-bottom: 50px;
  }

  &__title {
    font-family: OakesGrotesk, sans-serif;
    font-weight: 600;
    font-size: 76px;
    line-height: 96px;
    color: #000;

    &-gradient {
      @include auth-header-gradient;
    }
  }

  &__form-wrapper {
    width: 415px;
  }

  &__google-login-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    color: rgba(0, 0, 0, 0.54);

    &:hover {
      background: #ececec;
    }
  }

  &__google-icon {
    margin-right: 7px;
  }

  &__forgot-password-link {
    text-align: right;
    margin-top: 10px;
    margin-bottom: 30px;
  }

  &__link {
    transition: all 0.3s ease 0s;
    font-family: OakesGrotesk, sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: -0.1px;
    color: #8e95aa;

    &:hover {
      color: $primary-main-color;
    }
  }

  &__login-button {
    width: 100%;
    height: 62px;
    margin-bottom: 8px;
  }

  &__footer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  &__footer-link {
    font-family: Oakes Grotesk, serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 36px;
    letter-spacing: 0.5px;
    color: #251fb6;
    position: relative;
    text-decoration: none;

    &:after {
      display: block;
      position: absolute;
      content: '';
      left: -2px;
      right: -2px;
      bottom: 5px;
      height: 12px;
      background: rgba(37, 31, 182, 0.09);
      transition: all 0.3s ease 0s;
    }

    &:hover {
      &:after {
        height: 22px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .login-page-content {
    padding-top: 30px;

    &__title {
      font-size: 46px;
      line-height: 1.4;
    }

    &__form-wrapper {
      width: auto;
    }

    &__footer {
      width: 100%;
      margin-top: 20px;
      line-height: 1.5rem;
    }
  }
}

.login-page {
  flex: 1;

  padding: 50px;
  overflow: auto;
  align-items: baseline;

  display: flex;
  justify-content: center;

  width: 100vw;
  min-width: 320px;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.36);

  @media screen and (max-width: 725px) {
    padding: 0;
    background-color: transparent;
  }

  &__gradient {
    position: absolute;
    right: 0;
    width: 100%;
    z-index: -1;

    @media screen and (min-width: 725px) {
      display: none;
    }
  }
}

.login-modal {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-bottom: 37px;

  border-radius: 23px;

  background-color: #fbfbfb;

  @media screen and (max-width: 725px) {
    width: 100%;
    height: auto;
    min-height: 100%;
    border-radius: 0;
    background-color: transparent;
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.login-page__container {
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  @media screen and (max-width: 725px) {
    overflow-x: auto;
    background-color: #ffffff;
  }
}

.login-background {
  position: absolute;
  width: 100vw;
  height: 100vh;

  z-index: 1;

  &-container {
    position: fixed;
    display: flex;
    flex-shrink: 0;

    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    overflow: hidden;
    z-index: -1;

    @media screen and (max-width: 725px) {
      display: none;
    }
  }
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 69px 100px 20px;

  will-change: height;
  transition: height 200ms ease;
  box-sizing: content-box;

  @media screen and (max-width: 725px) {
    width: calc(100% - 40px);
    padding: 54px 20px !important;
    height: auto !important;
  }

  &--narrow {
    padding: 37px 33px 43px;
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    animation: appear 300ms;

    @media screen and (max-width: 725px) {
      width: 100%;
      animation: appear 300ms;
      max-width: 419px;
    }
  }
  &__logo {
    width: 172px;
    height: 40px;
    margin-bottom: 36px;
    cursor: pointer;
  }

  &__title {
    font-weight: 600;
    font-size: 30px;
    line-height: 30px;
    text-align: center;
    //letter-spacing: -0.005em;
    margin-bottom: 21px;

    color: #1b1e22;

    @media screen and (max-width: 725px) {
      font-size: 24px;
    }
  }

  &__subtitle {
    width: 280px;
    margin-bottom: 30px;

    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    text-align: center;

    color: rgba(0, 0, 0, 0.71);

    &-link {
      color: rgba(0, 0, 0, 0.71);

      &:hover {
        color: #251fb6;
      }
    }
  }

  &__label {
    display: flex;
    align-items: center;
    align-self: flex-start;

    width: 100%;

    padding-left: 3px;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.1px;

    /* #525979 */

    color: #525979;

    & > div {
      margin-left: 5px;
    }

    &-link {
      margin-left: auto;
      margin-right: 5px;
    }

    &-blue {
      margin-left: 0;
      color: #251fb6;
    }

    &-link {
      margin-left: auto;

      font-weight: 600;
      font-size: 12px;
      line-height: 17px;

      letter-spacing: -0.1px;
      color: #8e95aa;

      cursor: pointer;

      &:hover {
        text-decoration-line: underline;
      }
    }
  }

  &__button-column {
    display: flex;
    flex-direction: column;
    gap: 10px;

    width: 100%;

    margin-bottom: 31px;
  }

  &__input-column {
    display: flex;
    flex-direction: column;
    width: 100%;

    margin-bottom: 8px;

    .input:not(:last-child) input {
      margin-bottom: 20px;
    }
  }

  &__onboarding_session-block {
    font-family: 'Mulish', serif;
    width: 419px;
    height: 289px;
    border-radius: 13px;
    background: rgba(196, 198, 205, 0.17);

    &__text {
      font-style: italic;
      font-weight: 400;
      font-size: 24px;
      line-height: 135.5%;
      letter-spacing: -0.005em;
      padding: 25px 35px;
    }

    &__farzad {
      border-top: 1px solid rgba(170, 175, 185, 0.13);
      display: flex;
      align-items: center;
      height: 110px;
      padding: 0 25px;

      &__text {
        flex-flow: column;
        display: flex;
        margin-left: 20px;
      }

      &__name {
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
      }

      &__position {
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: rgba(66, 79, 101, 0.41);
      }
    }
  }

  &__button {
    width: 419px;
    height: 58px;

    background-color: #fff;

    box-shadow: 0 4px 13px rgba(0, 0, 0, 0.03);
    border-radius: 10px;

    color: #111a45;
    font-weight: 600;

    transition: box-shadow 200ms ease;

    @media screen and (max-width: 725px) {
      width: 100%;
      padding-left: 14px;
      padding-right: 14px;
    }

    &:hover {
      background-color: #ffffff;
      box-shadow: 0 4px 13px rgba(0, 0, 0, 0.07);
    }

    svg {
      margin-top: -3px;
      margin-right: 0;
    }

    &-img {
      width: 24px;
      height: 24px;

      margin-right: 23px;
    }
    &-text {
      flex-direction: column;
      display: flex;
      align-items: start;
      margin-right: -68px;
      padding-left: 13px;
    }
    &-sub-text {
      font-size: 12px;
      color: #8E95AA;
    }
  }

  &__login-button {
    width: 419px;
    height: 52px;

    margin-top: 22px;

    background-color: #251fb6;
    transition: box-shadow 300ms ease;

    @media screen and (max-width: 725px) {
      width: 100%;
    }

    &.button--disabled:hover {
      box-shadow: none;
    }

    &:hover {
      background-color: #251fb6;
      box-shadow: 0 22px 40px -17px rgba(37, 31, 182, 0.32);
    }
  }

  &__divider {
    flex-grow: 1;
    height: 1px;
    background-color: #8e95aa;
  }

  &__divider-row {
    display: flex;
    align-items: center;

    width: 100%;

    margin-bottom: 32px;

    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
    text-align: center;

    color: #8e95aa;

    span {
      opacity: 0.6;
      margin: 0 20px;
    }
  }

  &__input {
    width: 100%;
    input {
      width: 100%;
      height: 57px;
      padding: 18px 23px 19px;
      border: 1px solid rgba(219, 223, 231, 0.47);

      &::placeholder {
        color: #8e95aa;

        opacity: 0.5;
      }
    }

    &-password {
      input {
        &::placeholder {
          line-height: 30px;
        }
      }
    }

    &-message {
      margin-top: -15px;
    }

    .question-tooltip {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 28px;
      height: 28px;

      background-color: rgba(255, 195, 0, 0.25);
      border-radius: 50%;
      svg {
        width: 12px;
        height: 12px;
      }
    }

    .input__after-text {
      top: 15px;
      right: 16px;
    }
  }

  &__switch-step {
    margin-top: 12px;

    font-weight: 600;
    font-size: 16px;
    line-height: 36px;
    letter-spacing: -0.1px;

    color: #525979;

    span {
      color: #251fb6;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__code {
    &-row {
      display: flex;
      align-items: center;
      justify-content: space-between;

      width: 100%;

      padding: 0 5px;

      margin-top: 5px;
    }
    &-button {
      font-weight: 600;
      font-size: 16px;
      line-height: 36px;

      letter-spacing: -0.1px;

      color: #251fb6;

      cursor: pointer;

      &--disabled {
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.5;
      }

      &:hover {
        color: #4e48dc;
      }
    }
  }

  &__logout-button {
    display: flex;
    align-items: center;
    justify-content: center;

    position: fixed;
    bottom: 20px;
    left: 20px;

    width: 46px;
    height: 46px;
    border-radius: 11px;
    background-color: #dbe2fe;

    cursor: pointer;

    transition: background-color 160ms ease;

    &:hover {
      box-shadow: 0 4px 13px rgba(0, 0, 0, 0.03);
    }

    .question-tooltip__svg-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 100%;
      height: 100%;
    }

    & .question-tooltip__react-tooltip::after {
      border-bottom-color: transparent !important;
    }

    & .question-tooltip__text {
      background-color: #222;
    }

    svg {
      margin-right: -4px;
      width: 18px;
      height: 18px;
    }
  }
}

.login-payment-container {
  width: 554px;

  @media screen and (max-width: 725px) {
    width: 100%;
  }

  .onboarding-page-header {
    margin-top: 0;
  }

  .onboarding-step-icon {
    width: 105px;
    height: 105px;

    svg {
      width: 50px;
      height: 43px;
    }
  }

  .onboarding-page-header__title {
    margin-top: 9px;
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 30px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.005em;

    color: #1b1e22;
  }

  .onboarding-page-header__subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    text-align: center;

    color: rgba(0, 0, 0, 0.71);
  }

  .onboarding-default-payment__content {
    margin: 28px 0 0;
    @media screen and (max-width: 725px) {
      width: 100%;
    }
  }

  .onboarding-default-payment__subscription-info {
    width: 554px;
    @media screen and (max-width: 725px) {
      width: 100%;
      padding: 0 15px;
      &-details {
        width: 80%;
        &-date {
          text-align: right;
        }
      }
    }
  }

  .onboarding-default-payment__payment-info {
    width: 554px;
    @media screen and (max-width: 725px) {
      width: 100%;
      padding: 0 15px;
    }
  }

  .onboarding-default-payment-form__submit-btn {
    @media screen and (max-width: 725px) {
      width: 100%;
    }
  }

  .onboarding-page-header__title {
    @media screen and (max-width: 725px) {
      font-size: 24px;
    }
  }
}
