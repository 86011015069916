@import "src/vendors/colors";

.email-accuracy {
  display: inline-block;
  border-radius: 100%;
  margin-right: 10px;
  font-size: 12px;
  flex-shrink: 0;

  &--size-s {
    width: 10px;
    height: 10px;
  }

  &--size-m {
    width: 16px;
    height: 16px;
  }

  &--size-l {
    width: 24px;
    height: 24px;
  }

  &--undefined {
    background-color: $grey-icons-color;
  }

  &--low {
    background-color: $alert-color;
  }

  &--medium {
    background-color: $secondary-orange-color;
  }

  &--high {
    background-color: $success-color;
  }

  &--has-order {
    background-color: unset;
    border: 1px solid;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &.email-accuracy--undefined {
      border-color: $grey-icons-color;
      color: $grey-icons-color;
    }

    &.email-accuracy--low {
      border-color: $alert-color;
      color: $alert-color;
    }

    &.email-accuracy--medium {
      border-color: $secondary-orange-color;
      color: $secondary-orange-color;
    }

    &.email-accuracy--high {
      border-color: $success-color;
      color: $success-color;
    }
  }
}