@import 'src/vendors/mixins';
@import 'src/vendors/colors';

.subscription-card {
  &__change-link {
    text-decoration: none;
    color: #3424eb;
    font-size: 16px;
    line-height: 20px;

  }

  &__info {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 19px;
  }

  &__info-title {
    margin-bottom: 2px;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #000000;
  }

  &__info-type {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #8d8f92;
  }

  &__info-amount {
    @include mulish-text;
    font-weight: 600;
    font-size: 24px;
    color: $secondary-blue-color;
  }
}
