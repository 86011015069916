@import 'src/vendors/colors';
@import 'src/vendors/mixins';

.subscription-activation-sidebar {
  height: 100%;
  max-height: calc(100vh - 84px);
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  &__body {
    flex: 1;
    padding: 20px;

    &__description {
      font-weight: 600;
      font-size: 18px;
    }

    &__price-block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 30px 0 20px;
      width: 100%;
      height: 154px;
      border: 1px solid #DBDFE7;
      border-radius: 8.5px;
      padding: 35px 35px 35px 34px;

      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
    }

    &__price {
      font-size: 64px;
    }

    &__period {
      font-size: 20px;
      font-weight: 600;
    }

    &__price-description {
      line-height: 32px;
    }

    &__price-mark {
      height: 64px;
      line-height: 64px;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 30px;
    border-top: 1px solid #dbdfe7;
    margin-top: 12px;
  }
}
