@import '../../vendors/colors';
@import '../../vendors/mixins';

.boolean-toggler {
  display: flex;
  align-items: center;
  box-sizing: border-box;

  border: 1px solid #f5f5f5;
  border-radius: 8px;
  background-color: #f5f5f5;

  cursor: pointer;

  &--reversed {
    flex-direction: row-reverse;
  }

  &__button {
    white-space: nowrap;
    box-sizing: border-box;
    @include mulish-text;
    font-size: 14px;
    //padding: 14px 24px;
    padding: 14px 15px;
    border-radius: 8px;

    &--active {
      background-color: #EEF3FF;
      border: 1px solid #221DB6;
      border-radius: 8px;


      color: #251fb6;
    }
  }
}
