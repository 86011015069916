.review-step-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 106px;
  background: #FBFBFB;
  border-radius: 10px;
  margin-top: 37px;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.08);

  &:not(:last-child){
    margin-bottom: 10px;
  }
}

.review-step {
  padding: 13px 20px;
  border-radius: 10px;
  position: relative;
  transition: opacity 0.6s, padding 0.6s;

  &.hidden {
    opacity: 0;
    padding: 0 20px;
    box-sizing: border-box;
    overflow: hidden;
    transition: opacity 0.3s, padding 0.3s;
    z-index: -1;
  }

  .review-step-list__item-confirm-delete {
    transition: opacity 0.6s, padding 0.6s;
    padding: 13px 20px;

    &.hidden {
      opacity: 0;
      padding: 0 20px;
      box-sizing: border-box;
      overflow: hidden;
      transition: opacity 0.3s, padding 0.3s;
      z-index: -1;
    }
  }

  &__cards {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
  }

  &__process {

    .progress-bar {
      display: flex;
      align-items: center;

      &__title {
        margin-top: 0;
        display: flex;
        justify-content: flex-end;
        flex-direction: row-reverse;

        & > span:first-child {
          display: none;
        }
      }

      &__percents {
        margin-right: 10px;
        font-size: 14px;
      }

      &__bar-wrapper {
        width: calc(100% - 53px);
        margin-top: 0;
      }
    }
  }

  &-delete-button {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 23px;
    top: 27px;

    svg {
      path {
        fill: #ED483D;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.review-card {
  height: 48px;
  flex: 1;
  display: flex;
  align-items: center;

  &__title {
    font-weight: 600;
    font-size: 14px;
    color: #8F9199;

    margin-right: 5px;

    &.ml-5 {
      margin-left: 5px;
    }
  }

  &__icon {
    &-sircle {
      width: 23px;
      height: 23px;
      background-color: #E1E1E1;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__number {
    margin-left: 0;
  }
}
