@import 'src/vendors/colors';

.owner-sharing-circle {
  position: relative;
  width: 36px;
  height: 36px;

  &:hover {
    .owner-sharing-circle__svg-pen-wrapper {
      opacity: 1;
    }
  }

  &__svg-pen-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 36px;
    height: 36px;
    background-color: $primary-main-color;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    opacity: 0;

    transition: opacity .2s ease-in-out;

    svg {
      stroke-width: .3px;
    }
  }
}
