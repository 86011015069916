@import 'src/vendors/mixins.scss';
@import 'src/vendors/colors.scss';

.table {
  width: 100%;
  border-collapse: collapse;
}

.table-th {
  @include mulish-text;

  color: $grey-text-color;
  text-align: left;
  font-size: 14px;
  line-height: 130%;
  white-space: nowrap;
  padding: 0 5px 16px 5px;
}

.table-td,
.table-th {
  padding-left: 5px;

  &:first-child {
    padding-left: 20px;
  }

  &:last-child {
    padding-right: 30px;
  }
}

.table-tr {
  &--is-selected {
    background-color: $light-main-color;
  }
}