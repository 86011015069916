.inbox-content {
  display: flex;
  flex-direction: column;

  &__wrapper {
    display: flex;
    height: calc(100vh - 68px);
  }

  &__thread-container {
    display: flex;
    flex: 1;

    overflow: hidden;
  }

  .collapsible-sidebar {
    padding-top: 0;
    height: 100%;
    z-index: 11;

  }
  .collapsible-sidebar--fixed--opened {
    width: 307px;
    overflow: auto;
  }
}
