.select-raw {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 15px;

  &__label {
    color: #1b1e22;
    font-weight: bold;
  }

  &__select-wrapper {
    width: 422px;
  }
}

.listen-notes-metrics {
  &__add-date-button {
    display: flex;
    margin-left: auto;
    margin: 10px auto 15px;
    padding: 0;
  }
}

.listen-notes-metrics-warning {
  display: flex;
  align-items: center;
  padding: 14px 17px;
  border-radius: 12px;
  margin-top: 33px;
  background-color: #fff2e2;
  font-size: 16px;
  line-height: 141%;
  color: #3c3c3c;

  &__icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-right: 14px;
    border-radius: 50%;
    background-color: #fc8e0e;

    & > svg {
      width: 16px;
      height: 16px;
    }
  }
}
