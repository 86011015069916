@import 'src/vendors/mixins.scss';
@import 'src/vendors/colors.scss';

.checkbox {
  @include mulish-text;
  font-size: 14px;
  line-height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  height: 16px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &--disabled &__field {
    border-color: #c6c6c6;
  }

  &--disabled span {
    cursor: default;
    opacity: 0.5;
  }

  &__field {
    --active: #251fb6;
    --disabled: #c6c6c6;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 16px;
    width: 16px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid #E3E3E3;
    background: var(--b, #F5F6FA);
    transition: background 0.7s, border-color 0.7s;
    border-radius: 4px;

    &:disabled {
      cursor: default;
    }

    &:after {
      width: 3px;
      height: 6px;
      border: 1.5px solid #fff;
      border-top: 0;
      border-left: 0;
      left: 5px;
      top: 2px;
      content: '';
      display: block;
      position: absolute;
      transition: transform var(--d-t, 0.8s) var(--d-t-e, ease), opacity var(--d-o, 0.8s);
      transform: rotate(var(--r, 20deg));
      opacity: var(--o, 0);
    }

    &:checked {
      --r: 43deg;
      --b: var(--active);
      --bc: var(--active);
      --d-o: 0.3s;
      --d-t: 0.6s;
      --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
      --o: 1;
    }

    &:disabled:checked {
      --b: var(--disabled);
      --bc: var(--disabled);
    }

    &:hover {
      &:not(:checked) {
        --bc: #2aa4cb;
      }
    }
  }

  &.custom-yes-no {
    height: 27px;

    .checkbox__field {
      width: 38px;
      height: 27px;
      border: none;
      background-color: rgba(237, 72, 61, 0.08);

      &:after {
        content: 'NO';
        color: #ED483D;
        opacity: 1;
        top: 5px;
        left: 50%;
        transform: rotate(0) translateX(-10px);
        transition: none;
        min-width: 30px;
      }

      &:checked {
        background-color: rgba(38, 199, 122, 0.08);

        &:after {
          content: 'YES';
          transform: rotate(0) translateX(-13px);
          left: 50%;
          border: none;
          color: #26C77A;
          min-width: 30px;
        }
      }
    }
  }
}
