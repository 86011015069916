@import 'src/vendors/colors';
@import 'src/vendors/mixins';

.threads-list {
  min-width: 270px;
  height: calc(100vh - #{$page-header-height});
  max-height: 100vh;
  overflow-y: auto;

  &__loading-trigger {
    width: 100%;
    height: 50px;
  }

  &__empty-message {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding-bottom: 40px;
    span {
      margin-top: 17px;
      font-weight: 400;
      font-size: 16px;
      color: #8f9199;
    }
  }
}
