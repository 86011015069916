.from-to-row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 15px;

  &__label {
    display: flex;
    align-items: center;
    color: #1B1E22;
    font-weight: bold;
    width: 50%;
    font-size: 16px;
  }

  &__double-input {
    display: flex;
    flex: 1;

    .input {
      width: 50%;

      .input__field {
        width: auto;
        height: auto;
        padding: 15px;
      }

      &:first-child {
        .input__field {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }
      }

      &:not(:first-child) {
        .input__field {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
        }
      }
    }


  }
}