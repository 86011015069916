@import 'src/vendors/colors';
@import 'src/vendors/mixins';

.analytics-email-report {
  padding: 25px;

  &__cards-row {
    display: flex;
    justify-content: space-around;
  }

  &__empty {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    color: $grey-text-color;
  }
}

.analytics-email-report-card {
  flex-grow: 1;
  padding: 20px 0;
  min-width: 190px;
  height: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $primary-grey-lines-color;
  border-radius: 8px;
  @include mulish-text;

  &:not(&:last-child) {
    margin-right: 10px;
  }

  &__title {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 20px;
  }

  &__diagram {
    margin-bottom: 12px;
    height: 38px;
  }

  &__value {
    color: var(--card-color);
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  &__total {
    font-weight: 600;
    font-size: 14px;
    color: $grey-text-color;
  }

  &__basic {
    @include mulish-text;
    font-size: 36px;
    font-weight: 500;
  }

  .analytics-report-container-values {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &.custom-style {
    background-color: transparent;
    min-width: 50px;
    padding: 0;
    align-items: flex-start;
    position: relative;

    .title-base {
      @include mulish-text;
      color: #A0A0A0;
    }

    .analytics-report-container-basic {
      display: flex;
      align-items: center;

      .analytics-email-report-card__diagram {
        margin-bottom: 0;
      }

      .analytics-email-report-card__value {
        margin-bottom: 5px;
        margin-top: 0;
      }

      .analytics-email-report-card__total {
        font-size: 13px;
      }

      &-values {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        margin-left: 12px;
      }
    }
  }
}

.email-report-chart-legend {
  list-style-type: none;
  display: flex;
  justify-content: space-between;

  &__item {
    position: relative;

    &:after {
      content: '';
      width: 14px;
      height: 14px;
      background-color: var(--legend-item-color);
      position: absolute;
      top: 1px;
      left: -20px;
      border-radius: 100%;
    }
  }
}

.analytics-email-report-daily-chart {
  position: relative;
  margin-top: 20px;
  background: $primary-grey-lines-color;
  border-radius: 8px;

  &__header {
    height: 80px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 36px;
    border-bottom: 1px solid $secondary-grey-lines-color;
  }

  &__title {
    @include mulish-text;
    font-weight: 600;
    font-size: 18px;
  }

  &__chart-toggler {
    margin-left: auto;
  }

  &__chart {
    margin-top: 24px;
    padding-right: 20px;
  }
}

.analytics-email-report-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 25px 0;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: #000000;
}
