@import 'src/vendors/mixins.scss';
@import 'src/vendors/colors.scss';

.question-tooltip {
  &__svg-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__react-tooltip {

    .question-tooltip__text {
      background: black;
    }

    &::after {
      border-bottom-color: #3A3B3F !important;
    }
  }
  &__text {
    @include mulish-text;
    color: #FFFFFF;
    max-width: 252px;
    padding: 20px;
    border-radius: 8px;
    background-color: #3A3B3F;
    line-height: 17.5px;
  }
}

.without-after {
  &::after {
    display: none;
  }
}
