@import 'src/vendors/variables';
@import 'src/vendors/mixins';
@import 'src/vendors/colors';

.profile-settings {
  display: flex;
  height: calc(100vh - #{$page-header-height});
  padding: 26px 30px;

  overflow: auto;

  &__block {
    min-width: 509px;

    margin-bottom: 19px;
    padding: 37px 37px 29px 29px;

    border-radius: 8px;
    box-shadow: 0px 4px 90px 0px #c9c9c940;

    display: flex;
    flex-direction: column;
  }

  &__block-title {
    @include mulish-text;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0 0 25px 0;
  }

  &__input {
    @include mulish-text;
    font-size: 16px;
    padding: 15px 15px 17px 18px;
    height: 48px;
    width: 100%;

    border: 1px solid #dbdfe7;
    border-radius: 8.5px;

    outline-color: #dbdfe7;
  }

  &__label {
    @include mulish-text;
    color: $grey-text-secondary;
    margin-bottom: 7px;
  }

  &__personal-info-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 509px;
  }

  &__personal-info {
    height: 273px;
  }

  &__personal-info-content {
    flex: 1;
    display: flex;
    align-items: center;
  }

  &__personal-info-inputs-block {
    margin-left: 43px;

    height: 100%;
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__username-info {
    padding-top: 24px;
    height: auto;
  }

  &__password-info {
    padding-top: 24px;
    height: 169px;
  }

  &__password-body {
    display: flex;
  }

  &__password-inputs {
    width: 285px;
  }

  &__password-block-right-column {
    flex: 1;

    margin-left: 11px;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__password-button {
    margin-top: 21px;

    width: 150px;
    height: 48px;

    @include mulish-text;
    font-size: 14px;
    font-weight: 400;

    padding: 0;
    color: $primary-main-color;
  }

  &__password-error {
    @include mulish-text;
    color: red;
    padding: 8px;
    margin-top: 14px;
    background: #fff7f8;
    border: 1px solid;
    width: 150px;
    border-radius: 8px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__email-subscriptions-wrapper {
    margin-left: 24px;
    height: 647px;
    width: 509px;
  }

  &__email-subscriptions {
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  &__email-subscription-item:not(:last-child) {
    border-bottom: 1px solid #eeeeee;
  }

  &__email-subscription-item {
    padding-top: 21px;
    padding-bottom: 21px;

    display: flex;
  }

  &__email-subscription-item-header {
    @include mulish-text;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 7px;
  }

  &__email-subscription-item-text {
    @include mulish-text;
    color: $grey-text-secondary;
    font-weight: 400;

    width: 348px;
  }

  &__email-subscription-item-toogler {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
}
