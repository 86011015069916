@import 'src/vendors/colors';
@import 'src/vendors/mixins';


.campaigns-sidebar {
  .configurable-campaign-sidebar {
    flex-shrink: 0;
    background-color: #ffffff;
    display: flex;
    border-bottom: 2px solid #F9F9F9;
    align-items: flex-end;

    &__header {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #7b7b7b;
    }

    &__body {
      flex: 1;
      display: flex;

      .configurable-campaign-sidebar__group {
        display: flex;
      }
    }

    &__footer {
      margin-top: auto;
    }

    &__group-title {
      @include mulish-text;
      font-size: 16px;
      line-height: 20px;
      color: $grey-black-color;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 6px;
      cursor: pointer;
      margin-left: 20px;
      white-space: nowrap;
      flex-shrink: 0;

      &:hover {
        color: $secondary-blue-color;

        .configurable-campaign-sidebar__group-action-icon {
          svg {
            path {
              fill: $secondary-blue-color;
            }
          }
        }
      }
    }

    &__group-action-icon {
      height: 20px;
    }

    &__group {
      &:not(&:first-child) {
        margin-top: 30px;
      }
    }

    &__link-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px;
      cursor: pointer;
      flex-shrink: 0;

      &--active {
        color: $secondary-blue-color;
        position: relative;
        background-color: transparent;

        &:before {
          content: '';
          position: absolute;
          height: 2px;
          background-color: $secondary-blue-color;
          left: 0;
          right: 0;
          bottom: -2px;
        }

        .configurable-campaign-sidebar__link-text {
          color: $secondary-blue-color;
        }
      }

      .actions-menu {
        visibility: hidden;
        opacity: 0;

        .react-tooltip--actions {
          left: -32px !important;
          top: 40px !important;

          &:after {
            display: none;
          }
        }
      }

      &:hover {
        .actions-menu {
          visibility: visible;
          opacity: 1;
        }
      }

      .configurable-campaign-sidebar__link-actions {
        margin-left: 10px;
      }
    }

    &__link {
      @include mulish-text;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      text-decoration: none;
      width: 100%;
      justify-content: center;
      text-align: center;
      padding: 5px;
      color: #7B7B7B;

      &:hover {
        color: $secondary-blue-color;

        .configurable-campaign-sidebar__link-icon {
          svg {
            path {
              fill: $secondary-blue-color;
            }
          }
        }
      }
    }

    &__link-button {
      width: 39px;
      min-width: 39px;
      height: 36px;
      padding: 0;
      margin-left: 5px;
    }

    &__link-icon {
      margin-right: 7px;
      display: flex;
      align-items: center;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    &__link-text {
      white-space: nowrap;

      &--cropped {
        max-width: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
        width: fit-content;
      }
    }

    &__group-pages {
      display: flex;
    }

    &-folders {
      &__create-btn {
        display: flex;
        width: 100%;
        align-items: center;

        .folder-sidebar-link {
          min-width: 133px;
        }
      }
    }
  }
}
