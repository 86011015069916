.sort-buttons {
  display: flex;
  flex-direction: column;

  .up,
  .down {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 7px;
    padding: 2px;
    cursor: pointer;

    &.hidden {
      visibility: hidden;
    }

    &:hover {
      svg {
        path {
          fill: #3424EB;
        }
      }
    }
  }

  .up {
    margin-bottom: 2px;
  }
}
