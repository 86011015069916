.ai-agents-page {
    .ai-agents-page__content-activity-log {
        flex-direction: row !important;
        display: flex;

        .ai-agents-page__content-body {
            justify-content: flex-start;
            height: calc(100vh - 68px);
            padding-top: 0;
            overflow: auto;
            padding-bottom: 0;
        }

    }
}