.sidebar-crm-header {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 20px 18px 19px;
  border-bottom: 1px solid #ececec;
  width: 100%;

  &-container {
    padding: 19px 17px 18px;
  }

  &-column {
    display: flex;
    flex-direction: column;
    gap: 5px;

    max-width: 90%;
  }

  &-avatar {
    flex-shrink: 0;

    width: 55px;
    height: 55px;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    color: #221cb6;
  }

  &-name {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #000000;

    &-organization {
      color: #221cb6;
      padding-right: 45px;

      span {
        margin-right: 10px;
        word-break: break-all;
      }

      svg {
        flex-shrink: 0;
      }
      cursor: pointer;
    }
  }

  &-company {
    display: flex;
    align-items: center;

    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    padding-left: 0;
    color: #221cb6;

    cursor: pointer;

    svg {
      opacity: 0;
      margin-left: 6px;
    }

    &:hover {
      opacity: 0.8;
      svg {
        opacity: 1;
      }
    }
  }

  &__company-people {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    gap: 10px;

    padding: 16px 12px 15px;

    border-bottom: 1px solid #ececec;

    &-scroll {
      border-bottom: none;

      .scroll-groups__children {
        padding-left: 0;
        padding-right: 0;
      }

      .scroll-groups__arrow-container {
        width: 20px;
        height: 20px;
        background-color: #000000;
        padding: 0;

        svg {
          height: 9px;
          width: 9px;
          path {
            fill: #ffffff;
          }
        }
      }

      .scroll-groups__arrow--left {
        height: 70px;
        width: 30px;
        background: linear-gradient(90deg, white 60%, transparent);
        justify-content: flex-start;
        margin-bottom: 2px;
      }

      .scroll-groups__arrow--right {
        height: 70px;
        width: 30px;
        background: linear-gradient(90deg, transparent, white 60%);
        justify-content: flex-end;
        margin-bottom: 2px;
      }
    }

    &-avatar {
      position: relative;
      flex-shrink: 0;

      border-radius: 50%;

      transition: box-shadow 160ms ease;
      cursor: pointer;
      &:hover {
        box-shadow: 0 4px 15px rgba(17, 34, 17, 0.15);
      }

      &--selected {
        box-shadow: 0 4px 25px rgba(17, 34, 17, 0.25);
        pointer-events: none;
        &:hover {
          cursor: default;
          box-shadow: 0 4px 25px rgba(17, 34, 17, 0.25);
        }

        &::after {
          content: '';
          position: absolute;
          bottom: -16px;
          left: -5px;
          width: 50px;
          height: 1px;
          background-color: #221cb6;
        }
      }
    }

    &-avatar-more-people {
      background-color: #f0effa !important;
      div {
        color: #7b7b7b !important;
      }

      &:hover {
        div {
          color: #251fb6 !important;
        }
        background-color: #f0effa !important;
      }
    }
  }

  &__tooltip {
    &-content {
      width: 234px;
      height: auto;
      padding: 4px 0;
      border-radius: 8px;
      background-color: #000000;

      overflow: hidden;
    }

    &:after {
      left: 91% !important;
      top: -3px !important;
      border-bottom-color: #000000 !important;
    }

    &-item {
      display: flex;
      align-items: center;
      padding: 10px;

      cursor: pointer;

      &:hover {
        background-color: #272727;
      }

      &:not(:last-child) {
        border-bottom: 1px solid #272727;
      }

      .named-avatar {
        width: 24px;
        height: 24px;
        div {
          font-size: 10px;
        }
      }

      &-text {
        margin-left: 8px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #ffffff;
      }
    }
  }
}
