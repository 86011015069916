.page-wrapper {
  display: flex;
  height: 100vh;

  &--disabled {
    pointer-events: none;
  }

  &__content {
    overflow-y: auto;
    position: relative;
    flex: 1;

    &.padding-fixed {
      padding-top: 68px;
    }

    &.active-personalize {
      overflow-y: hidden;
    }
  }
}
