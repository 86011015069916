@import 'src/vendors/colors';
@import 'src/vendors/mixins';

.analytic-settings {
  padding: 20px;

  &__form {
    display: flex;
  }

  &__column {
    width: 315px;

    &:first-child {
      margin-right: 60px;
    }
  }

  &__row {
    &:not(&:last-child) {
      margin-bottom: 40px;
    }
  }

  &__label {
    display: flex;
    align-items: center;

    @include mulish-text;
    font-weight: 600;
    font-size: 14px;
    color: $grey-text-secondary;
    margin-bottom: 12px;
  }

  &__label-tooltip {
    margin-left: 8px;
  }

  &__field {
    * {
      max-width: unset!important;
    }
  }

  &__footer {
    margin-top: 40px;
  }
}
