@import 'src/vendors/mixins';
@import 'src/vendors/colors';

.pipeline-column {
  width: 290px;
  min-width: 290px;
  background: #ffffff;
  border-radius: 8px;
  position: relative;
  border: 2px solid #F9F9F9;

  &:has(.pipeline-column__sortable-list--hovered) {
    background: rgba(219, 226, 254, 0.25);
    border-color: rgba(34, 28, 182, 0.27);
  }

  &:not(&:last-child) {
    margin-right: 20px;
  }

  &__header {
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    padding: 20px 20px 0;

    &-title {
      display: flex;
      align-items: center;
      grid-column-gap: 12px;

      &-counter {
        padding: 2px 6px;
        background-color: #DBE2FE;
        color: $primary-main-color;
        border-radius: 3px;
        text-align: center;
        font-size: 12px;
        min-width: 30px;
        margin-right: 5px;
      }

      &__add-btn {
        width: 20px;
        height: 20px;
        border-radius: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 0;
        min-width: 20px;

        &:hover {
          border-color: $primary-main-color;

          svg {
            @include svg-colors-path($primary-main-color);
          }
        }
      }
    }

    &-tooltip {
      max-width: 175px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: normal;

      &-content {
        position: absolute !important;
        opacity: 1 !important;
        max-width: 255px;
        border-radius: 12px !important;
        background: #000000 !important;
        padding: 13px 18px !important;

        &.company-content {
          padding: 0 !important;
        }

        .input {
          &__field {
            width: 100%;
            border: 1px solid rgba(217, 217, 217, 0.25);
            padding-left: 10px;
            color: #fff;

            &:focus {
              border-color: #fff;
            }
          }
        }

        &-header {
          margin-bottom: 12px;
          padding: 14px 16px 0 14px;
        }

        &-body {
          &-toggler,
          &-counter {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 18px;
          }

          &-counter {
            label {
              font-size: 12px;
              color: $grey-text-color;
            }

            .input {
              margin-top: 7px;

              &__field,
              &__after-text {
                font-size: 12px;
              }

              &__after-text {
                color: $grey-text-color;
                top: 13px;
              }
            }
          }

          &-button {
            display: flex;
            align-items: center;
            grid-column-gap: 6px;
            padding: 11px 22px 15px 22px;

            &:hover {
              cursor: pointer;
              opacity: 0.8;
            }
          }

          .company-list {
            margin: 0 14px;
            border-bottom: 1px solid rgba(58, 58, 58, 0.6);
            max-height: 500px;
            overflow-y: auto;

            &__sub-list {
              margin-bottom: 17px;

              &-title {
                color: $grey-text-secondary;
                font-size: 10px;
                text-transform: uppercase;
                margin-left: 8px;
                margin-bottom: 6px;
              }

              &-item {
                padding: 8px;
                border-radius: 11px;
                width: 100%;
                min-height: 49px;
                display: flex;
                align-items: center;
                grid-column-gap: 6px;

                &:not(:last-child) {
                  margin-bottom: 4px;
                }

                &-logo {
                  border-radius: 6px;
                  width: 31px;
                  height: 31px;
                }

                &-info {
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;

                  &-website {
                    font-size: 14px;
                    color: #FFFFFF;
                  }

                  &-link {
                    font-size: 12px;
                    color: $grey-text-secondary;
                  }
                }

                &:hover {
                  cursor: pointer;
                  background-color: rgba(217, 217, 217, 0.12);
                }
              }
            }

            &__footer {
              color: #fff;
              font-size: 20px;
              padding: 20px;
            }
          }

          &-section {
            margin-bottom: 12px;

            label {
              color: #8D8F92;
              font-size: 12px;
            }

            .select {
              margin-top: 7px;
            }
          }
        }

        &-footer {
          &-buttons {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          &-delete {
            padding: 0;
            color: $alert-color;
            font-size: 14px;

            svg {
              @include svg-colors-path($alert-color);
            }

            &:hover {
              color: $alert-hover-color;

              svg {
                @include svg-colors-path($alert-hover-color);
              }
            }
          }
        }

        &.edit-tooltip {
          padding-bottom: 5px !important;
          width: 100%;

          .pipeline-column__header-tooltip-content-header {
            padding: 0;
          }
        }
      }
    }
  }

  &__sortable-list-container {
    overflow: auto;
    height: 100%;
    max-height: calc(100% - 75px);
  }

  &__sortable-list {
    height: 100%;
    padding: 10px 20px 20px;
  }
}
