.earn-free-credits {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0 4px 90px rgba(201, 201, 201, 0.25);
  border-radius: 8px;
  margin-bottom: 27px;
  width: 100%;

  &__info {
    display: flex;
    width: 100%;
    padding: 40px 24px 16px 39px;
    border-bottom: 1px solid #f3f3f3;

    &-text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 364px;

      &-title {
        display: flex;
        align-items: flex-end;
        gap: 14px;
        margin-bottom: 6px;
        font-weight: 600;
        font-size: 22px;
        line-height: 26px;

        color: #000000;
      }

      &-highlight {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #221cb6;
      }

      &-subtitle {
        margin-top: 14px;
        //margin-bottom: 20px;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #8f8f8f;
      }
    }

    &-link {
      display: flex;
      margin-left: auto;

      input {
        width: 264px;
        height: 48px;
        padding-right: 19px;
        background-color: #ffffff;
        border-color: #dbdfe7;
        color: #8f9199;
      }

      &-button {
        margin-top: 20px;
        font-size: 14px;
        width: 154px;
        margin-left: 12px;
      }
    }
  }

  &__stats-row {
    display: flex;
    width: 100%;
  }

  &__stats-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 121px;
    flex: 1;

    &:not(:last-of-type) {
      border-right: 1px solid #f3f3f3;
    }

    &-text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &-title {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      font-weight: 600;
      font-size: 18px;
      line-height: 18px;
      color: #000000;

      span {
        margin-left: 6px;
      }
    }

    &-value {
      font-weight: 600;
      font-size: 24px;
      line-height: 24px;
      color: #221cb6;
    }
  }

  &__review-row {
    display: flex;
    gap: 20px;
  }

  &__review-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    min-height: 264px;

    padding: 30px 22px 25px;

    background-color: #ffffff;
    box-shadow: 0 4px 90px rgba(201, 201, 201, 0.25);
    border-radius: 8px;

    flex: 1;

    &-title {
      display: flex;
      align-items: center;

      font-weight: 600;
      font-size: 22px;
      line-height: 26px;
      margin-bottom: 7px;
      padding: 0 6px;

      color: #000000;

      svg {
        margin-right: 10px;
      }
    }

    &-button {
      margin-top: auto;
      width: 100%;
      font-size: 14px;
    }
  }

  &__button {
    font-size: 14px;
    color: #231db6;
    border: 1px solid rgba(34, 28, 182, 0.53);
    background-color: transparent;

    &:hover {
      background-color: #231db6;
      border-color: #231db6;
      color: #ffffff;
    }
  }
}
