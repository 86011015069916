@import 'src/vendors/mixins';

.sidebar {
  position: relative;
  padding-left: 20px;
  box-sizing: border-box;
  transition: width 0.1s cubic-bezier(0, 0.5, 0.5, 1) 0.01s;
  display: flex;
  flex-direction: column;

  border-right: 1px solid #f9f9f9;

  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &:hover {
    &::-webkit-scrollbar {
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #eeeeee;
    }
  }

  &__header {
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    width: 100%;
    position: sticky;
    top: 0;
    background-color: white;
    justify-content: center;
    z-index: 1;
  }

  &-footer {
    position: sticky;
    width: 100%;
    background-color: white;
    bottom: 10px;
    z-index: 10;
  }

  &__header-text {
    @include respona-logo-text;
  }

  &__respona-big-logo {
    width: 99px;
  }

  &__logo {
    height: 24px;

    svg {
      height: 24px;
      width: 24px;
    }

    &--respona {
      display: inline-block;
    }

    &--open {
      display: none;

      svg {
        transform: rotate(180deg);
      }
    }
  }

  &__header-hide-btn {
    margin-left: 30px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  &__workspace {
    position: relative;
    padding: 10px 0;

    &--disabled {
      pointer-events: none;
    }
  }

  &__pages {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      height: 1px;
      background-color: #BDBDBD;
      top: 0;
      opacity: 0.17;
    }

    &--disabled {
      pointer-events: none;
    }
  }

  &__gnc-footer {
    padding-right: 20px;
    padding-bottom: 10px;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__link {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #BDBDBD;
    text-decoration: none;
    display: flex;
    align-items: center;
    height: 62px;
  }

  .sidebar-link {
    &--active {
      color: #221cb6;
      position: relative;
      background-color: #DBE2FE;

      &:after {
        content: '';
        display: inline-block;
        width: 3px;
        position: absolute;
        top: 2px;
        right: 0;
        bottom: 2px;
        background-color: #221cb6;
        border-radius: 10px;
      }

      .sidebar-link__icon {
        filter: grayscale(0) opacity(1);
      }
    }

    &__beta-tag {
      margin-left: 10px;
      padding: 6px 10px;

      border: 1px solid rgba(189, 189, 189, 0.17);
      border-radius: 8px;

      font-family: 'Oakes Grotesk';
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      text-align: center;
      letter-spacing: 0.5px;

      color: #BDBDBD;
    }

    &__icon {
      margin-right: 12px;
      height: 24px;
      width: 24px;
    }

    &__tooltip-content {
      padding: 15px;
      font-size: 16px;
    }
  }
}

.sidebar--closed {
  width: 95px;
  align-items: center;
  padding-left: 0;

  .sidebar {
    &__pages {
      width: 100%;
    }

    &__link {
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      white-space: nowrap;

      &:hover {
        color: $primary-main-color;

        .sidebar-link__icon {
          filter: grayscale(0) opacity(1);
        }
      }
    }
  }

  .sidebar-link__icon {
    margin-right: 0;
    margin-bottom: 5px;
    filter: grayscale(100%) opacity(0.3);
  }

  .sidebar-link--active:hover {
    border-right: 0px solid $primary-main-color;
  }

  /*.sidebar__footer {
    height: 125px;
    flex-direction: column-reverse;
    padding-right: 0;
  }

  .sidebar__gnc-footer {
    padding-right: 0;
    flex-direction: column;
    height: 110px;
  }*/
}

.sidebar--opened {
  width: 190px;
  min-width: 190px;

  &.sidebar {
    .sidebar__logo--open {
      display: none;
    }

    .sidebar__logo--respona {
      display: inline-block;
    }
  }
}
