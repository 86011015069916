@import 'src/vendors/colors';

.backlinks-search {
  &__select-source-item {
    display: flex;
    flex-direction: column;
    align-items: center;

    &--ahrefs {
      svg {
        width: 100px;
      }
    }
  }
  &__select-source-item-text {
    font-size: 11px;
    color: $grey-text-secondary;
    margin-top: 11px;
  }
  &__select-source-item-subtext {
    font-size: 11px;
    color: rgba(32, 36, 48, 0.37);
  }

  &__title {
    margin-bottom: 22px;
    font-weight: 600;
    font-size: 20px;
    line-height: 100%;
    color: #000000;
  }
}
