@import 'src/vendors/mixins';

.sidebar-crm {
  width: 526px;

  &__header {
    padding: 12px 18px 12px;
    &-title {
      font-size: 16px;
      line-height: 16px;
    }
    &-collapse-btn svg {
      width: 10px;
    }
  }

  &--small {
    &.box-shadow {
      box-shadow: rgba(18, 16, 68, 0.1) 0px 4px 17px 0px;
    }

    .sidebar-crm-section__content {
      padding: 0;

      .sidebar-crm__content-personal-info {
        gap: 10px;
      }
    }
  }

  &__container {
    z-index: 10;
  }

  &__body {
    overflow: auto;
  }

  &__close-icon {
    top: 38px;
  }
}
