@import "src/vendors/variables";

.insights-page {
  display: flex;

  &__content {
    flex: 1;
    max-height: 100vh;
    overflow-y: auto;
    position: relative;
  }
}
