.opportunity-sibebar {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  width: 100%;

  &.fit-width {
    width: fit-content;
  }

  &-inspect {
    height: 100vh;
    background: #fff;
    animation: comeinBackground var(--right-sidebar-animation-length) linear;
  }

  &-content {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 320px;
    overflow-y: auto;
    background: #fff;
    animation: comeinSidebar var(--manual-confirm-contact-sidebar-animation-length) linear;
    border-left: 1px solid #ECECEC;
  }
}

@keyframes comeinBackground {
  from {
    opacity: 0;
  }

  to {
    opacity: .3;
  }
}

@keyframes comeinSidebar {
  from {
    right: calc(var(--right-sidebar-width) * -1);
  }

  to {
    right: 0;
  }
}
