@import "src/vendors/colors";
@import "src/vendors/mixins";

.filter-value {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 5px;
  border-radius: 8px;
  background-color: $grey-selection-color;
  height: 40px;
  margin-top: 10px;

  &__value {
    @include mulish-text;
    font-size: 16px;
    margin-left: 10px;
  }

  &__remove-btn {
    cursor: pointer;

    &:hover {
      opacity: .8;
    }
  }
}