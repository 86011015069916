@import "src/vendors/colors";

.slate-image-wrapper {
  position: relative;
  display: inline-block;
  border: 1px solid black;

  .slate-image {
    display: flex;
    max-width: 100%;
    position: relative;

    &--focused {
      box-shadow: 0 0 0 2px $secondary-blue-color;
    }
  }

  &__resizer {
    display: none;
  }

  &--focused {
    .slate-image-wrapper__resizer {
      display: block;
      position: absolute;
      bottom: -6px;
      right: -6px;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      background-color: $secondary-blue-color;
      cursor: nw-resize;
    }
  }
}