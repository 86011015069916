@import "src/vendors/colors";
@import "src/vendors/mixins";

.player {
  margin-top: 10px;

  &--inline {
    display: flex;
    align-items: center;
  }

  &__underline-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }

  &__controls {
    margin-right: 12px;

    svg {
      height: 20px;
      width: 20px;
    }
  }
}