.profile-avatar {
  position: relative;
  width: 105px;
  height: 105px;

  &__wrapper {
    width: 100%;
    height: 100%;
  }

  &__image {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    box-shadow: 1px 4px 9px 0 #03030340;

    .named-avatar__content {
      font-size: 28px;
    }
  }

  &__edit-svg {
    position: absolute;
    right: 3px;
    bottom: 0;
    cursor: pointer;

    &-label {
      cursor: pointer;
    }
  }

  &__input-field {
    display: none;
  }
}
