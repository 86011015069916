.campaigns-sidebar-header {
  display: flex;
  flex-direction: column;
  padding: 15px;

  &__search-input {
    margin-bottom: 13px;

    .input__field {
      width: 100%;
      height: 45px;

      &::placeholder {
        color: #C0C6CD;
      }
    }
  }

  &__bottom-line {
    height: 1px;
    width: 100%;
    margin-top: 12px;
    background-color: #EDEDED;
  }
}
