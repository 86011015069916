.campaign-analytics-sequence {
  position: relative;
  display: flex;
  justify-content: space-between;

  height: calc(100% - 86px);

  &__steps,
  &__editor {
    width: 48%;
  }

  &__steps {
    border-right: 1px solid #e9ecf1;
    margin-right: -1px;
    padding-bottom: 30px;
  }
}
