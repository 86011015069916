.inbox-page {
  display: flex;
  width: 100%;
  overflow: hidden;

  &__content {
    flex: 1;
    width: 100%;
    overflow: hidden;
  }

  &__empty-illustration-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    img {
      height: 467px;
    }
  }

  &__empty-illustration-text {
    margin: -80px auto 45px;

    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #000000;
  }
}
