@import 'src/vendors/mixins';
@import 'src/vendors/colors';

.toolbox-email-finder {
  width: calc(100% - 210px);
  height: 100%;
  flex: 1;

  .table-td:first-of-type,
  .table-th:first-of-type {
    padding-left: 0;
  }

  &__bulk-notice {
    margin-top: 20px;

    font-weight: 600;
    font-size: 14px;
    line-height: 14px;

    color: #8f9199;

    a {
      color: #221cb6;
      text-decoration: none;
    }
  }

  &__content {
    height: calc(100% - 68px);
    overflow: auto;
    padding: 26px 20px 0;
    min-width: 700px;
  }

  &__header {
    @include mulish-text;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__source-select {
    display: flex;
    align-items: center;
  }
}
