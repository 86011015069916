@import '../../vendors/colors';
@import '../../vendors/mixins';

.radio {
  @include mulish-text;
  font-size: 14px;
  line-height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 24px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &__field {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .radio__checkmark {
      background-color: #fff;
      border: 1px solid #3424eb;

      &-inner {
        border-radius: 100%;
        background-color: #3424eb;
        background-size: cover;
        display: inline-block;
        position: absolute;
        top: 2px;
        left: 2px;
        bottom: 2px;
        margin: auto;
        width: 12px;
        height: 12px;
      }
    }
  }

  &__checkmark {
    background: #f5f6fa;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: inline-block;
    height: 16px;
    width: 16px;
    border: 1px solid #e3e3e3;
    box-sizing: border-box;
    border-radius: 100%;

    &-inner {
      display: none;
    }
  }
}
