.metrics-source-toggle {
  position: relative;

  display: flex;
  align-items: center;
  width: 100%;

  border-radius: 10px;

  background: linear-gradient(180deg, #fff 50%, #f5f6fa 50%);

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;

    width: auto;
    flex: 1;
    height: 48px;

    cursor: pointer;

    border-top-left-radius: 12px;
    border-top-right-radius: 12px;

    background-color: #f5f6fa;

    z-index: 1;

    transition: 100ms ease;
  }

  &__item--unselected {
    &:not(:hover) svg * {
      fill: #7790b3;
    }

    &-border-right {
      border-bottom-right-radius: 12px;
    }

    &-border-left {
      border-bottom-left-radius: 12px;
    }
  }

  &__item--unselected:nth-child(1) {
    background-color: #ffffff;

    border-top-right-radius: 0;
  }

  &__item--unselected:nth-child(2) {
    background-color: #ffffff;

    border-top-left-radius: 0;
  }

  &__item--unselected:nth-child(3) {
    background-color: #ffffff;

    border-top-left-radius: 0;
  }

  &__slider {
    display: none;
    position: absolute;

    width: 235px;
    height: 48px;

    background-color: #ffffff;
    box-shadow: 0 4px 7px -2px rgba(0, 0, 0, 0.1);
    border-radius: 9px;

    transition: transform 80ms ease;
  }
}
