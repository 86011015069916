.campaign-analytics {
  display: flex;
  height: 100%;
  min-width: 960px;

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: 100vh;
    overflow-y: auto;
  }

  &__header {
    display: flex;
  }

  &__add-opportunities-btn {
    margin-left: 7px;
  }

  &__header-title {
    display: flex;
    align-items: center;

    .colored-toggler {

      &__slider {
        background: #FFFFFF !important;
      }
    }
  }

  &__header-title-text {
    margin-left: 5px;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
