.ai-agents-page {
  display: flex;
  height: 100vh;

  .ai-agents-page__content {
    flex: 1;
    display: flex;
    flex-direction: row;
    position: relative;
    padding-top: 68px;

    .ai-agents-page__content-chat {
      flex-direction: row !important;
      display: flex;
    }

    .ai-agents-page__content-body {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      transform: translateY(-50%);
      position: relative;
      top: 50%;
      width: 100%;
      height: calc(100vh - 68px);
      justify-content: flex-end;
      padding-bottom: 60px;

      .ai-agents-page__content-body-title {
        text-align: center;
        font-size: 32px;
        font-weight: 400;
        line-height: normal;
        margin-top: -40px;
      }

      .ai-agents-page__content-body-list {
        display: flex;
        grid-column-gap: 13px;
        margin-bottom: 140px;
      }

      .ai-agents-page__content-body-input {
        max-width: 696px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid rgba(219, 223, 231, 0.7);
        border-radius: 15px;
        height: 66px;
        padding: 12px;
        margin-top: 26px;

        .input, input {
          width: 100%;
        }

        input {
          border: none;
          color: #191B1E;

          &::placeholder {
            color: #929AAB;
          }
        }

        &:has(input:focus) {
          border-color: #E5E5F1;
          box-shadow: 0px 6px 5.5px -1px rgba(224, 223, 255, 0.45);
        }

        button {
          min-width: 53px;
          padding: 0 10px;
        }
      }
    }

    .ai-agents-page__content-body::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 75px;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 1), transparent);
      pointer-events: none;
    }
  }

  .ai-agents-page__content-header {
    display: flex;
    align-items: center;
    margin-left: 50px;
    gap: 12px;

    input {
      width: 100%;
    }
  }
}

.chat-container {
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  padding-right: 15px;
  width: 100%;
  max-width: 696px;
}

.chat-bubble {
  display: flex;
  align-items: flex-start;
  grid-column-gap: 16px;
  width: 100%;
}

.chat-bubble-message {
  margin: 8px 0;
  padding: 20px 25px;
  border-radius: 12px;
  font-size: 16px;
  max-width: 70%;
  width: 100%;
  line-height: 167%;
}

.chat-bubble-user {
  background-color: #F5F6FA;
  margin: 16px 0 16px auto;
}

.chat-bubble-system {
  background-color: #f1f1f1;
  margin: 16px 0;
}
