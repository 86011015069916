.multi-value-container {
  border-radius: 6px;
  margin: 2px 4px 2px 0;

  &:last-child {
    margin-right: 0;
  }

  & > div {
    background-color: transparent;

    & > div {
      color: var(--colorValue);
    }
  }
}
