.file-extension-icon {
  height: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
  background: #4B4C4D;
  color: white;
  font-family: "Courier New";
  font-size: 10px;
  border-radius: 2px;
  margin-right: 6px;
}