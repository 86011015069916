@import '../../vendors/colors.scss';

.loader {
  font-size: 10px;
  margin: 0 auto;
  //text-indent: -9999em;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #ffffff;
  background: -moz-linear-gradient(left, $primary-main-color, $primary-main-color);
  background: -webkit-linear-gradient(left, $primary-main-color, $primary-main-color);
  background: -o-linear-gradient(left, $primary-main-color, $primary-main-color);
  background: -ms-linear-gradient(left, $primary-main-color, $primary-main-color);
  background: linear-gradient(to right, $primary-main-color, $primary-main-color);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);

  &--with-top-margin {
    margin-top: 20px;
  }

  &__full-screen-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
  }

  &__full-block-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
.loader:before {
  width: 50%;
  height: 50%;
  background: var(--background-color);
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}
.loader:after {
  background: var(--background-color);
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
