@import 'src/vendors/mixins';
@import 'src/vendors/colors';

.inbox-sidebar {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  height: 100vh;
  width: 210px;

  background-color: $grey-bg-color;

  &__header {
    padding: 17px 14px 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: $grey-black-color;
  }

  &__content {
    padding: 0 9px 8px;
    overflow-y: auto;
    height: 100vh;
  }

  &__folders,
  &__labels {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  &__folders,
  &__labels,
  &__mailboxes {
    display: flex;
    flex-direction: column;
  }

  &__labels {
    max-height: 440px;

    &-header {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: $grey-black-color;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;
      margin-left: 5px;

      &-add-button {
        display: none;
        height: 20px;

        &:hover {
          cursor: pointer;

          svg {
            @include svg-colors-path-fill($grey-black-color);
          }
        }
      }

      &:hover {
        cursor: pointer;

        .inbox-sidebar__labels-header-add-button {
          display: block;
        }
      }
    }

    &-input {
      .input__field {
        width: calc(100% - 4px);
      }
    }

    &-container-link {

      .button-show-more {
        color: $secondary-blue-color;
        background-color: $grey-bg-color;
        width: 100%;
        justify-content: flex-start;
        padding-left: 10px;

        &:hover {
          color: #7974f7;

          svg {
            @include svg-colors-path-fill(#7974f7);
          }
        }

        &.arrow-up {
          svg {
            transform: rotate(180deg);
          }
        }
      }

      .inbox-sidebar__link {
        &--active,
        &:hover {
          color: $secondary-blue-color;
          background-color: #eaedf2;
        }

        &:hover {
          cursor: pointer;

          svg {
            @include svg-colors-path-fill($secondary-blue-color);
          }
        }
      }
    }
  }

  &__mailboxes {
    margin-bottom: 12px;
  }

  &__folders,
  &__labels {
    border-top: 1px solid #ededed;
  }

  &__link {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 16px;
    line-height: 20px;
    color: #202430;
    text-decoration: none;
    padding: 10px 11px;
    border-radius: 8px;
    margin-bottom: 2px;

    &:last-child {
      margin-bottom: 0;
    }

    &-text {
      max-width: 192px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &-show-more {
      padding: 0;
      min-width: 20px;
      height: 20px;
      margin: 0 0 0 auto;

      svg {
        path {
          fill: #7b7b7b !important;
        }
      }

      &:hover {
        svg {
          path {
            fill:$secondary-blue-color !important;
          }
        }
      }
    }

    &-tooltip-dropdown {
      &__item {
        display: flex;
        align-items: center;
        padding: 7px 18px 7px 18px;

        &:first-child {
          margin-top: 14px;
        }

        &:last-child {
          margin-bottom: 14px;
        }

        &-icon {
          margin-right: 8px;

          svg {
            path {
              stroke: #FFFFFF !important;
              fill: #FFFFFF !important;
            }
          }
        }

        &:hover{
          background-color: #181818;
        }
      }
    }

    &--active,
    &:hover {
      color: $secondary-blue-color;
      background-color: #eaedf2;

      svg {
        @include svg-colors-path($primary-main-color);
      }
    }
  }

  &__link-icon {
    height: 16px;
    width: 16px;
    margin-right: 7px;

    svg {
      height: 16px;
      width: 16px;
    }
  }

  &__link-counter {
    font-size: 16px;
    margin: 0 auto;
    margin-right: 0;
    margin-left: 5px;
    color: #8F9199;
  }

  &__footer {
    height: 51px;
    border-top: 1px solid rgba(189, 189, 189, 0.31);

    display: flex;
    justify-content: center;
    align-items: center;

    &:visited {
      color: inherit;
    }
    text-decoration: none;

    cursor: pointer;

    &-text {
      margin-left: 10px;
      color: #8f9199;
    }

    &:hover {
      background-color: #e9eeff;
      span {
        color: $primary-main-color;
      }

      svg {
        @include svg-colors-path-fill($primary-main-color);
      }
    }
  }

  &__how-to {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-top: auto;
    height: 34px;
    width: 100%;
    padding-bottom: 12px;
    padding-top: 12px;
  }
}
