@import 'src/vendors/colors';

.contacts-sidebar-section-metrics {
  display: flex;
  flex-direction: column;
  gap: 18px;


  &__refresh-icon {
    &--animated {
      animation: rotate linear 1.5s infinite;
    }
  }

  &__tooltip {
    width: 367px;

    & > div {
      width: 367px;
    }
  }

  &--empty {
    color: #7b7b7b;
    font-size: 14px;
  }

  &__metric-col {
    display: flex;
    flex-direction: column;
    width: 100%;
    grid-row-gap: 10px;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &__metric-row {
    display: flex;
    width: 100%;
    grid-column-gap: 6px;
    grid-row-gap: 13px;
    align-items: center;

    &-icon {
      margin-top: -1px;
      width: 21px;
      height: 21px;

      img {
        margin-top: 0;
        width: 21px;
        height: 21px;
      }
    }

    &-data {
      width: 100%;
      display: flex;
      align-items: center;
      grid-column-gap: 11px;

      div {
        margin-bottom: 0;
      }

      &-label {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #7b7b7b;
        flex: none;
      }
    }
  }

  &__metric-options-row {
    display: flex;
    align-items: center;
    width: calc(100% + 40px);

    margin: -2px -20px 20px;
    padding: 0 20px 0;
    //border-bottom: 1px solid rgba(255, 255, 255, 0.19);

    &:first-child {
      margin-top: 6px;
    }
    &:last-child {
      margin-bottom: 9px;
      border-bottom: 0;
      padding-bottom: 12px;
    }

    span {
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      color: #ffffff;

      text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }

    &-icon {
      width: 21px;
      height: 21px;
      margin-right: 8px;

      img {
        width: 21px;
        height: 21px;
      }
    }

    &-refresh-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #303030;
      border-radius: 5px;

      width: 21px;
      height: 21px;
      margin-left: auto;

      background-color: transparent;

      cursor: pointer;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }

      svg path {
        fill: #615f5f;
      }

      &:hover svg path {
        fill: #2aa4cb;
      }
    }

    .checkbox {
      margin-left: auto;

      .checkbox__field {
        background-color: inherit;
        border-color: #303030;
        cursor: pointer;
      }

      .checkbox__field:checked {
        background-color: #3424eb;
      }
    }
  }
}

.contact-sidebar {
  &-crm-section {
    &__content {

      .contacts-sidebar-section-metrics {
        border: 1px solid #ECECEC;
        margin-bottom: 20px;
        border-radius: 10px;
        padding: 12px 22px 28px;

        &-header {

          &-title {
            font-weight: 600;
            font-size: 16px;
            margin: 10px 0;
            margin-bottom: 12px;
            display: flex;
            align-items: center;

            svg {
              margin-left: 8px;
              display: none;
            }

            &:hover {
              cursor: pointer;
              color: $primary-main-color;

              svg {
                display: block;
              }
            }
          }

          &-link {
            display: flex;
            align-items: center;
            grid-column-gap: 8px;
            font-weight: 600;
            margin-bottom: 12px;
          }
        }
      }
    }
  }
}
