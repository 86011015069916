@import "../../../vendors/colors";
@import "../../../vendors/mixins";

.schedules-settings {
  position: relative;
  height: 100vh;

  &__header-right {
    margin-left: auto;
    display: flex;
  }
}