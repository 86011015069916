@import "../../vendors/colors.scss";
@import "../../vendors/mixins.scss";

.counter-signs {
  display: flex;
  margin-top: 5px;
  font-size: 10px;
  color: #8F9199;
  align-items: center;
  justify-content: end;

  svg {
    margin-right: 7px;
    @include svg-colors-path($grey-text-secondary);
  }

  &--red {
    color: $alert-color;
    @include svg-colors-path($alert-color);
  }
}
