@import '../../vendors/colors';
@import '../../vendors/mixins';

.dropdown-panel {
  background-color: #f5f6fa;
  border-radius: 8px;

  &__header {
    @include mulish-text;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    display: flex;
    align-items: center;
    height: 52px;
    padding-left: 16px;
    padding-right: 16px;
    cursor: pointer;
    border-radius: 8px;

    &:hover {
      background-color: $secondary-grey-action-color;
    }
  }

  &--opened &__header {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  &__header-icon {
    height: 20px;
    margin-right: 12px;

    svg {
      @include svg-colors-path-stroke(#221cb6);
    }
  }

  &__header-chevron {
    display: flex;
    margin-left: auto;

    svg {
      transform: rotateX(180deg);
    }
  }

  &__content {
    padding: 24px;
    @include mulish-text;
    font-size: 16px;
    line-height: 16px;
  }

  &--opened > div > .dropdown-panel__header-chevron:first-of-type {
    svg {
      transform: rotateX(0);
    }
  }
}
