@import "src/vendors/mixins";
@import "src/vendors/colors";

.change-owner-modal {
  display: flex;
  flex-direction: column;
  width: 500px;

  &__header {
    @include modal-header
  }

  &__subheader {
    padding: 12px 24px 0;
    margin: 0;
    color: gray;
  }

  &__footer {
    @include modal-footer;
    display: flex;
    justify-content: space-between;
  }

  &__body {
    flex: 1;
    padding: 24px;
  }

  &__campaign-title {
    width: 131px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1;
    padding: 7px;
    margin-left: 10px;
    background: antiquewhite;
    border-radius: 10px;
  }


}
