.subscription-payment-form {
  &__fields {
    display: flex;
    align-items: center;
    height: 72px;
    min-width: 400px;
    background: #ffffff;
    border-radius: 8px;
  }

  &__input-field-wrapper {
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding-left: 10px;
  }

  &__input-field {
    height: 72px;
    min-width: 80px;

    &--card-number {
      min-width: 200px;
    }
  }

  &__cancel-btn {
    width: 20px;
    height: 20px;
    background-color: #ED483D;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 36px;

    svg {
      path {
        stroke-width: 3;
      }
    }

    &:hover {
      cursor: pointer;
      filter: brightness(0.8);
    }
  }
}
