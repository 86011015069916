@import 'src/vendors/colors';

$light-grey: #E1E1E1;
$medium-grey: #393939;
$icon-size: 23px;

.content-search-activity {
  &-title {
    font-size: 18px;
    font-weight: 600;
  }

  &-tabs {
    display: flex;
    align-items: flex-end;
    border-bottom: 1px solid $light-grey;
    width: 100%;
    margin-top: 20px;

    &__item {
      display: flex;
      align-items: center;
      padding: 23px;
      position: relative;

      &.active,
      &:hover {
        .content-search-activity-tabs__item-title {
          color: $primary-main-color;
        }

        &:before {
          content: '';
          position: absolute;
          bottom: -1px;
          height: 2px;
          left: 0;
          right: 0;
          background-color: $primary-main-color;
        }
      }

      &-icon {
        width: $icon-size;
        height: $icon-size;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 5px;

        &.grey {
          background-color: $light-grey;
        }

        &.blue {
          background-color: #DBE2FE;
        }

        &.green {
          background-color: #23E771;
        }
      }

      &-title {
        color: $medium-grey;
        font-size: 16px;
        font-weight: 500;
      }

      &-number {
        background-color: #DBE2FE;
        color: $primary-main-color;
        padding: 2px 6px;
        border-radius: 3px;
        display: block;
        font-size: 12px;
        margin-left: 5px;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .review-step-list {
    margin-top: 18px;
    max-height: 500px;
    overflow-y: auto;

    &__statistics {
      width: 100%;
      height: 150px;

      &--wide {
        height: 198px;
      }

      &__counters {
        display: flex;
        align-items: center;
        height: 150px;

        &__card {
          height: 70px;
          width: 25%;
          display: flex;
          align-items: center;
          justify-content: center;

          &--img {
            width: 60px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 18px;
            margin: 5px 20px;
          }

          &--value {
            line-height: 30px;
            font-size: 30px;
            font-weight: 600;
          }

          &--title {
            line-height: 14px;
            font-size: 14px;
            font-weight: 600;
            color: $grey-text-color;
          }
        }
      }

      &__download {
        height: 48px;
        display: flex;
        align-items: center;
        border-top: 1px solid #F4F4F4;
        padding: 0 20px;

        &__button {
          text-decoration: underline;
          cursor: pointer;
          margin-left: 4px;
          color: $primary-main-color;

          svg {
            margin-right: 2px;
          }
        }
      }
    }

    &__dropdown {
      width: 20px;
      height: 20px;
      position: absolute;
      right: 52px;
      top: 14px;

      &__btn {
        width: 100%;
        height: 100%;
        cursor: pointer;
        border: 1px solid rgba(143, 145, 153, 0.21);
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          border-color: #EEF1FC;
          background: #EEF1FC;
          svg {
            path {
              fill: $primary-main-color;
            }
          }
        }

        &--open {
          background: #EEF1FC;
          border-color: #EEF1FC;
          transform: rotate(180deg);

          svg {
            path {
              fill: $primary-main-color;
            }
          }
        }
      }
    }

    &__item {
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 50px;
      background: #FBFBFB;
      border-radius: 10px;

      .question-tooltip {
        margin-left: 5px;
      }

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      &-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        padding: 13px 20px;
        background: #FBFBFB;
        border-radius: 10px;
        transition: opacity 0.6s, padding 0.6s;

        &-content {
          flex: 1;
          display: flex;
          align-items: center;
          align-self: center;

          &-text {
            margin-left: 5px;
            max-width: 190px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            display: inline-block;

            .mr-5, .long-text {
              vertical-align: middle;
            }
          }

          &-icon-sircle {
            width: $icon-size;
            height: $icon-size;
            border-radius: 41px;
            background: #DBE2FE;
            display: flex;
            justify-content: center;
            align-items: center;

            &.green {
              background: #23E771;
            }
          }

          &-number {
            color: $primary-main-color;
            background-color: #DBE2FE;
            display: inline-flex;
            height: 17px;
            padding: 2px 6px;
            align-items: center;
            gap: 25px;
            border-radius: 3px;
            font-size: 12px;
          }
        }

        &-delete-button {
          display: none;
          width: 20px;
          height: 20px;
          justify-content: center;
          align-items: center;
          position: absolute;
          right: 23px;

          svg {
            path {
              fill: rgba(143, 145, 153, 0.21);
            }
          }

          &:hover {
            svg {
              path {
                fill: #ED483D;
              }
            }
          }
        }

        &-edit-button, &-download-button {
          width: 20px;
          height: 20px;
          border-radius: 4px;
          border: 1px solid rgba(143, 145, 153, 0.21);
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          right: 23px;

          &:hover {
            cursor: pointer;
            background: #EEF1FC;
            border: 1px solid #EEF1FC;
          }
        }

        &-edit-button {
          background-color: $primary-main-color;
        }

        &-download-button {
          &:hover {
            background: #EEF1FC;
            border-radius: 5px;

            svg {
              path {
                fill: $primary-main-color;
              }
            }
          }
        }

        &.hidden {
          opacity: 0;
          padding: 0 20px;
          box-sizing: border-box;
          overflow: hidden;
          transition: opacity 0.3s, padding 0.3s;
          z-index: -1;
        }

        &:hover {
          background: #f6f6f6;

          .review-step-list__item-container-delete-button {
            display: flex;
          }
        }
      }

      &-confirm-delete {
        display: flex;
        margin: 0 auto;
        gap: 15px;
        opacity: 1;
        padding: 13px 20px;
        align-items: center;
        width: 100%;
        justify-content: right;
        transition: opacity 0.6s, padding 0.6s;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        &.hidden {
          opacity: 0;
          padding: 0 20px;
          box-sizing: border-box;
          overflow: hidden;
          transition: opacity 0.3s, padding 0.3s;
          z-index: -1;
        }
      }

      &:hover {
        cursor: pointer;
      }

      &.d-none {
        display: none;
      }

      &--open {
        height: 200px;
      }

      &--open-wide {
        height: 255px;
      }
    }

    &__footer {
      border-radius: 5px;
      border: 1px solid #DBE2FE;
      padding: 6px;
      display: flex;
      justify-content: center;
      position: sticky;
      bottom: 0;
      background-color: white;

      &-button {
        display: flex;
        align-items: center;

        span {
          color: $primary-main-color;
          font-size: 12px;
        }

        &:hover {
          cursor: pointer;
          filter: brightness(0.8);
        }
      }

      &.d-none {
        display: none;
      }

      &:hover {
        background-color: #e9eeff;
        cursor: pointer;

        &-button span {
          color: $primary-main-color;
        }
      }
    }
  }
}
