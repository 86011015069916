.ai-agents-page {
    .ai-agents-page__content-instructions {
        flex-direction: row !important;
        display: flex;

        .ai-agents-page__content-body {
            justify-content: flex-start;
            height: calc(100vh - 68px);
            padding-top: 68px;
            overflow: auto;
        }

        .ai-agents-page__content-body::before {
            display: none;
        }
    }

    .ai-agents-page__instructions-row {
        display: flex;
        grid-column-gap: 25px;
        justify-content: space-between;
        max-width: 917px;
        width: 100%;
        padding: 0 15px;

        .ai-agents-page__instructions-column {
            width: 100%;

            input {
                width: 100%;
            }

            label {
                font-size: 14px;
                color: #8F9199;
                margin-bottom: 11px;
                display: inline-flex;
                align-items: center;
                grid-column-gap: 5px;

                svg {
                    cursor: pointer;
                }
            }

            textarea {
                min-height: 88px;
            }
        }

        .ai-agents-page__instructions-column.flex-vertical {
            display: flex;
            align-items: center;
            margin-top: 26px;
        }

        .ai-agents-page__instructions-column-flex {
            display: flex;
            align-items: center;
            grid-column-gap: 8px;

            p {
                color: #8F9199;

                span {
                    color: #000000;
                    margin-left: 7px;
                }
            }
        }

        .ai-agents-page__instructions-column-list {
            width: 100%;
            grid-row-gap: 11px;
            display: flex;
            flex-direction: column;

            .ai-agents-page__instructions-column-item {
                display: flex;
                justify-content: space-between;
                grid-column-gap: 26px;
                align-items: center;
                position: relative;

                .actions-menu {
                    position: absolute;
                    right: -40px;
                }
            }

            .ai-agents-page__instructions-column-item:first-child {
                label {
                    margin-bottom: 0;
                }
            }
        }
    }

    .ai-agents-page__instructions-row:not(:last-child) {
        margin-bottom: 37px;
    }

    .ai-agents-page__advanced-column,
    .advanced-settings-wrapper {
        width: 100%;
        max-width: 916px;

        .ai-agents-page-container {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 44px;
            width: 100%;
            margin: auto;
        }

        .ai-agents-page-section {
            display: flex;
            flex-direction: column;

            .ai-agents-page-section__item {
                margin-bottom: 38px;

                .ai-agents-page-label {
                    display: inline-block;
                    margin-bottom: 11px;
                    font-size: 14px;
                    color: #8F9199;
                }
            }

            .ai-agents-page-section__item.mb-small {
                margin-bottom: 28px;
            }
        }

        .ai-agents-page-section h4 {
            grid-column: span 2;
            font-size: 16px;
            font-weight: 500;
        }

        .ai-agents-page-toggle {
            display: flex;
            align-items: center;
            grid-column-gap: 10px;

        }

        .ai-agents-page-toggle:not(:last-child) {
            margin-bottom: 17px;
        }
    }
}
