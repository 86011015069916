@import "src/vendors/colors";

.player-volume {
  display: flex;
  align-items: center;
  margin-left: 8px;

  &__icon {
    height: 20px;
  }

  &__bar-wrapper {
    height: 6px;
    background: #ABA8E6;
    border-radius: 3px;
    width: 54px;
  }
}