.email-health {
  min-width: 612px;
  background-color: #ffffff;

  &--empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 110px;

    &-image {
      width: 454px;
    }

    &-text {
      width: 100%;
      max-width: 573px;
      min-width: 342px;
      margin-top: -55px;
      padding: 0 20px 45px;
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      text-align: center;
      color: #000000;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 14px 20px;

    &-row {
      display: flex;
      flex-direction: row;
      gap: 18px;
    }
  }

  &__card {
    flex-grow: 1;
    margin-bottom: 14px;
    padding: 27px 27px 5px 27px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 4px 90px rgba(201, 201, 201, 0.25);

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: calc(100% + 54px);
      margin: 0 -27px;
      padding: 0 27px 20px;
      border-bottom: 1px solid #e9ecf1;
    }
  }

  &__card-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    display: flex;
    align-items: center;

    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    color: #000000;
  }

  &__title-number {
    font-weight: 600;
    font-size: 40px;
    line-height: 40px;
    color: #202430;
  }

  &__last-check {
    margin-right: 18px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #7b7b7b;
  }

  &__button {
    margin: 15px auto 0;
  }

  &__label {
    width: 40px;
    padding: 5px;
    background-color: #e9ecf1;
    border-radius: 6px;
    font-size: 12px;
    text-align: center;
    line-height: 15px;
    color: #1b1e22;
  }
}
