.issues-dropdown-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  flex-shrink: 0;

  border-radius: 50%;
  margin-right: 8px;

  background-color: #e9e8f8;

  svg path {
    fill: #221cb6;
  }

  &--warning {
    background-color: rgba(255, 184, 0, 0.09);

    svg path {
      fill: #ffc300;
    }
  }

  &--danger {
    background-color: rgba(237, 72, 61, 0.1);

    svg path {
      fill: #ed483d;
    }
  }
}
