@import "../../../vendors/colors";
@import "../../../vendors/mixins";

.confirm-contact-campaign-builder {
  position: relative;
  padding-top: 55px;

  &__toggler {
    display: inline-block;
    position: absolute;
    right: 65px;
    top: 20px;
  }

  &__content {
    padding: 30px;
  }
}

.confirm-contact-campaign-modal {
  height: 605px;

  .modal__cross-btn {
    svg {
      path {
        fill: #BDBDBD;
      }
    }

    &:hover {
      svg {
        @include svg-colors-path-fill($grey-text-color);
      }
    }
  }

  .confirm-contact-campaign-builder {
    padding-top: 30px;

    &__header {
      gap: 11px;
      display: flex;
      align-items: center;
      padding-left: 37px;
      padding-bottom: 30px;
      border-bottom: 1px solid #DBDFE7;

      &-title {
        color: #000;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
      }

      &-info {
        border-radius: 5px;
        background-color: #E9E9E9;
        padding: 5px;
        height: 21px;
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }

    &__content {
      padding: 0 37px;

    }
  }
  .campaign-automation {
    &-content-body {
      padding-top: 11px;

      &__list {
        list-style-type: none;
        padding-left: 0;

        &-item {
          display: flex;
          align-items: center;
          gap: 8px;

          &:not(:last-child) {
            margin-bottom: 10px;
          }

          .input__field {
            max-width: 57px;
            padding-left: 16px;
            height: 38px;
          }

          .select {
            min-width: 150px;
            max-width: 550px;
          }

          &-label {
            font-size: 16px;
            color: #202430;
            display: flex;
            align-items: center;
            gap: 8px;
          }
        }
      }
    }

    .automation-advanced-settings-content {
      margin-top: 22px;
      padding-bottom: 39px;
      border-bottom: 1px solid #DBDFE7;
    }
  }
}
