@import "src/vendors/colors";
@import "src/vendors/mixins";

.editor-change-link {
  padding: 16px;
  background: #fff;
  box-shadow: 0 19px 31px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  position: absolute;
  z-index: 999;

  &__title {
    @include mulish-text;
    font-weight: 600;
    font-size: 15px;
    line-height: 26px;
    color: #7E8397;
  }

  &__body {
    display: flex;
    align-items: center;
  }

  &__link-wrapper {
    position: relative;
  }

  &__variables-button {
    position: absolute;
    height: 28px;
    width: 28px;
    top: 5px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;

    &:hover {
      background: #EAEDF2;

      svg {
        @include svg-colors-path-fill($primary-main-color);
      }
    }
  }

  &__variables {
    padding: 15px 0;
  }

  &__variables-item {
    padding: 0 10px;
    @include mulish-text;
    font-size: 14px;
    line-height: 30px;
    color: #fff;
    cursor: pointer;

    &:not(&:last-child) {
      margin-bottom: 10px;
    }

    &:hover {
      background-color: $secondary-grey-lines-color;
      color: $primary-main-color;
    }
  }

  &__input {
    .input__field {
      border-radius: 8px 0 0 8px;
      padding-right: 40px;
    }
  }

  &__apply-btn {
    border-radius: 0 8px 8px 0;
    width: 85px;
    height: 40px;
  }
}