.metrics-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__row {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 700px;
  }
}
