@import 'src/vendors/colors';
@import 'src/vendors/mixins';

.columns-select-tooltip {
  min-width: 226px;
  @include mulish-text;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: #fff;
  padding: 0;
  background-color: #000000;
  border-radius: 8px;

  &__title {
    padding: 20px 20px 14px;
  }

  &__row {
    display: flex;
    align-items: center;
    padding: 6px 20px 6px 10px;
    background-color: #000000;
    border-radius: 6px;

    &-drag-icon {
      padding: 0 10px;
    }

    &-name {
      margin-left: 10px;
      margin-right: 20px;

      font-size: 16px;

      cursor: default;
    }
  }

  &__checkbox {
    margin-left: auto;
    input {
      background-color: #181818;
      border: 1px solid #282828;
    }
  }

  &__wrapper {
    &:after {
      left: 95% !important;
      top: -3px !important;
      border-bottom-color: #000000 !important;
    }
  }

  &__add-custom {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #272727;
    padding: 14px 20px 16px;
    margin-top: 8px;

    cursor: pointer;

    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: #7974f6;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
