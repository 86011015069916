.ai-agents-page {
  display: flex;
  height: 100vh;

  .ai-agents-page__content {
    flex: 1;
    display: flex;
    flex-direction: column !important;
    position: relative;
    padding-top: 68px;

    .page-header.page-header__with-close-btn {
      left: 0;
    }
  }

  .ai-agents-page__content-header {
    display: flex;
    align-items: center;
    margin-left: 50px;
    gap: 12px;

    input {
      width: 100%;
    }
  }
}
