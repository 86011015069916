.page-header {
  &__actions-wrapper {
    display: flex;
    align-items: center;
    margin-left: 50px;
    gap: 12px;

    .header__search {
      .input__field {
        width: 100%;
      }
    }
  }

  &__button {
    white-space: nowrap;

    &--smaller {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
}