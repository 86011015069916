.health-check-dropdown {
  background-color: #ffffff;
  border-bottom: 1px solid #e9ecf1;
  border-radius: 0;
  margin-bottom: 4px;

  overflow: unset;

  &:last-of-type {
    border-bottom: none;
  }

  &--open {
    border-bottom: none;

    & > div {
      background-color: rgba(233, 236, 241, 0.33) !important;
      margin: 0 -19px;

      &:first-child {
        padding: 13px 19px;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
      }

      &:last-child {
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
      }
    }
  }

  &-header {
    padding: 18px 0 21px;

    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #000000;

    &:hover {
      cursor: pointer;
      background-color: unset;
    }

    &__text {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding-right: 19px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  &-content {
    padding: 0 38px 16px 38px;
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    padding: 16px 21px 0 21px;
    margin: 0 -21px;
    border-top: 1px solid #e9ecf1;
    color: rgba(0, 0, 0, 0.62);
  }

  &__description {
    flex: 1;
    text-align: end;
    padding-right: 20px;
    padding-left: 20px;
    white-space: pre-line;
  }
}

.health-check-dropdown-nested {
  background-color: transparent;
  border-bottom: none;
  border-radius: 0;

  margin-bottom: 4px;

  overflow: unset;

  & > div {
    margin: 0 -19px;

    &:first-child {
      padding: 13px 19px;
    }
  }

  &--open {
    & > div {
      background-color: #ffffff !important;

      &:first-child {
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
      }

      &:last-child {
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
      }
    }
  }

  &-header {
    padding: 21px 0;

    flex-grow: 1;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000000;

    &:hover {
      cursor: pointer;
      background-color: unset;
    }

    &__text {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  &-content {
    padding: 0 38px;
  }

  &__text-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #e9ecf1;
    padding: 9px 18px 11px;
    margin: 0 -21px;
  }

  &__text {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;

    color: #000000;
  }
}
