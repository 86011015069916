.section-select {
  position: relative;

  display: flex;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  width: calc(100% - 40px);
  margin: 0 20px 27px;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    height: 3px;
    border-radius: 2px;
    background-color: #f9f9f9;
  }

  &__container {
    border-bottom: 0;
    margin: 0;
    padding: 0 !important;

    .scroll-groups__children {
      padding-left: 0;
      padding-right: 0;
      margin: 0 auto;
    }
  }

  &__item {
    position: relative;
    flex-shrink: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 21px 24px;

    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #7b7b7b;

    cursor: pointer;

    &:hover {
      color: #221cb6;
    }

    &--active {
      text-shadow: 0 0 0.3px #221cb6;
      color: #221cb6;
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        height: 2px;
        border-radius: 2px;
        background-color: #221cb6;
      }
    }
  }
}
