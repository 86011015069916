@import 'src/vendors/colors';
@import 'src/vendors/mixins';

.meta-mail-info {
  margin-top: 20px;
  background-color: #fff;
  padding: 15px 0;
  border-radius: 8px;
  min-width: 500px;
  position: relative;

  &--sent {
    opacity: 0.6;
    pointer-events: none;
  }

  &__header {
    padding: 15px;
    border-bottom: 1px solid $secondary-grey-lines-color;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__header-left {
    display: flex;
  }

  &__from-avatar {
    margin-right: 15px;
  }

  &__from-name {
    @include mulish-text;
    font-weight: 600;
    font-size: 16px;
    color: #202430;
    margin-right: 8px;
  }

  &__from-info {
    margin-bottom: 8px;
  }

  &__from-email,
  &__to-name,
  &__to-email {
    @include mulish-text;
    font-size: 14px;
    color: $grey-text-secondary;
  }

  &__to-name {
    margin-right: 8px;
  }

  &__date {
    @include mulish-text;
    font-size: 14px;
    color: $grey-text-secondary;
    white-space: nowrap;
  }

  &__content {
    padding: 22px 28px 15px;
    word-break: break-word;
  }

  &__actions {
    top: 5px;
    right: 5px;
    position: absolute;
    display: flex;
    justify-content: center;
  }

  &__btn {
    height: 20px;
    width: 20px;
    min-width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-radius: 2px;

    &:hover {
      svg {
        @include svg-colors-path($primary-main-color);
      }
    }

    svg {
      height: 14px;
      min-height: 14px;
      width: 14px;
      min-width: 14px;
      margin-right: 0;
    }
  }
}
