@import "src/vendors/colors";
@import "src/vendors/mixins";

.inbox-editor-attachments {
  display: flex;
  align-items: center;

  height: 90px;
  min-height: 90px;

  padding: 0 18px;

  overflow-x: scroll;

  &__file-wrapper {
    position: relative;
  }

  &__file {
    height: 56px;
    max-width: 213px;
    margin-right: 9px;
    background: #FFE3E3;
    border-radius: 8px;
    position: relative;

    display: flex;
  }

  &__file-text-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 150px;
    padding-right: 20px;
  }

  &__file-title {
    @include mulish-text;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__file-size {
    margin-top: 6px;
    @include mulish-text;
    color: rgba(0, 0, 0, .4);
    font-size: 12px;
  }

  &__file-remove {
    position: absolute;
    top: -6px;
    right: -6px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      @include svg-colors-path-fill($alert-hover-color);
    }
  }

  &__loader {
    border-radius: 8px;
  }

  .file-extension-icon {
    height: 100%;
    width: 56px;
    min-width: 56px;

    margin-right: 12px;

    border: 1px solid #FFCCCC;

    display: flex;
    align-items: center;
    justify-content: center;

    background: #FFE3E3;

    color: #DA4848;

    font-size: 12px;
    font-weight: bold;
    border-radius: 8px;
  }
}
