@import 'src/vendors/mixins';

.dashboard {
  flex: 1;
  width: 100%;
  height: 100vh;
  min-width: 945px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;

  .page-header {
    position: sticky;
    top: 0;
    background-color: #ffffff;
    z-index: 1;
  }

  &__container {
    display: flex;
  }

  &__body {
    flex: 1;
    padding: 22px;
  }

  &__updates {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    padding: 30px 40px;
    margin-bottom: 4px;
    background-color: #f0effa;
    border-radius: 8px;

    &-title {
      position: relative;
      margin-bottom: 6px;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      color: #000000;
      &::after {
        content: '';
        position: absolute;
        width: 7px;
        height: 7px;
        background: #231db6;
        box-shadow: 0 4px 90px rgb(201 201 201 / 25%);
        border-radius: 50%;
        right: -16px;
        top: 12px;
        margin: auto;
      }
    }

    &-subtitle {
      margin-bottom: 18px;
      max-width: 40%;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      color: #8f8f8f;
    }

    button {
      height: 42px;
      font-size: 14px;
    }

    &-illustration {
      position: absolute;
      height: 100%;
      right: 8px;
      top: 0;
    }
  }

  &__section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 16px;
    padding-top: 22px;

    &-header {
      &-title {
        margin-bottom: 6px;
        font-weight: 600;
        font-size: 24px;
        line-height: 20px;
        color: #000000;
      }
      &-subtitle {
        margin-bottom: 22px;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        color: #8f8f8f;
      }
    }
  }

  &__getting-started {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    min-height: 132px;
    padding: 29px 27px 33px;
    background-color: #ffffff;
    box-shadow: 0 4px 90px rgba(201, 201, 201, 0.25);
    border-radius: 8px;
    margin-bottom: 17px;

    &--complete {
      box-shadow: none;
      border: 1px solid rgba(0, 0, 0, 0.1);
    }

    &-completed-check {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 53px;
      height: 53px;
      background: #23e771;
      border-radius: 50%;
      margin-right: 25px;
      font-weight: 600;
      font-size: 20px;
    }

    &-order {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 53px;
      height: 53px;
      background-color: rgba(34, 28, 182, 0.07);
      border-radius: 50%;
      margin-right: 25px;
      font-weight: 600;
      font-size: 20px;
      color: #221cb6;
    }

    &-text {
      display: flex;
      flex-direction: column;
      align-self: flex-start;
      max-width: 60%;

      &-title {
        margin-bottom: 6px;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #000000;
      }
      &-subtitle {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #8f8f8f;
      }
    }

    &-button {
      width: 141px;
      margin-left: auto;

      &--disabled {
        border-color: transparent !important;
        background-color: rgba(123, 123, 123, 0.08) !important;
        color: rgba(123, 123, 123, 0.65) !important;
        pointer-events: none;
        cursor: default;
      }
    }
  }

  &__insights {
    width: 100%;
    //padding: 25px;
    background-color: #ffffff;
    box-shadow: 0 4px 90px rgba(201, 201, 201, 0.25);
    border-radius: 8px;
  }

  &__help-items-row {
    display: flex;
    gap: 20px;
    width: 100%;
    margin-bottom: 35px;
  }

  &__help-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 269px;
    padding: 28px 24px 32px;
    background-color: #ffffff;
    box-shadow: 0 4px 90px rgba(201, 201, 201, 0.25);
    border-radius: 8px;

    &-header {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 16px;

      &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 56px;
        height: 56px;
        margin-right: 12px;
        border-radius: 16px;
      }

      &-title {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #000000;
      }
    }

    &-text-subtitle {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #8f8f8f;
    }

    &-button {
      width: 100%;
      height: 42px;
      margin-top: auto;
    }
  }

  &__help-hire-va {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    width: 100%;
    padding: 30px 33px 29px;
    background-color: #eff8fe;
    border-radius: 8px;

    &-title {
      margin-bottom: 11px;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      color: #000000;
    }

    &-subtitle {
      max-width: 55%;
      margin-bottom: 12px;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #8f8f8f;
    }

    &-button {
      height: 42px;
    }

    &-illustration {
      position: absolute;
      right: 10px;
      top: 0;
      height: 100%;
    }
  }

  &__button {
    font-size: 14px;
    color: #231db6;
    border: 1px solid rgba(34, 28, 182, 0.53);
    background-color: transparent;

    &:hover {
      background-color: #231db6;
      border-color: #231db6;
      color: #ffffff;
    }
  }
}
