@import 'src/vendors/colors';
@import 'src/vendors/mixins';

.person-sidebar {
  //padding-top: 89px;
  &__row {
    padding: 20px;
    padding-bottom: 0;
    position: relative;

    &:not(:first-child) {
      padding-top: 31px;
    }

    .input {
      width: 100%;

      input {
        padding-left: 17px;
        font-size: 14px;
        border-radius: 6px;
        min-height: 45px;
      }
    }

    .basic-multi-select {
      & > div {
        min-height: 45px;
      }

      .css-eqy8aq-control {
        border-radius: 6px;
      }
    }

  }

  &__field-wrapper {
    display: flex;
    align-items: center;
    position: relative;

    .input {
      width: 100%;
      input {
        padding-left: 17px;
        font-size: 14px;
        border-radius: 6px;
        min-height: 45px;
      }
    }
  }

  &__remove-field {
    margin-left: 10px;
    cursor: pointer;

    &:hover {
      svg {
        @include svg-colors-path-fill($alert-hover-color);
      }
    }
  }

  &__field-label {
    @include mulish-text;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: #8d8f92;
    margin-bottom: 8px;
    cursor: pointer;

    svg {
      @include svg-colors-path-fill($secondary-blue-color);
    }

    &:hover {
      svg {
        @include svg-colors-path-fill($secondary-light-blue-color);
      }
    }
  }

  &__add-field-btn {
    @include mulish-text;
    border: 1px solid $border-grey-color;
    border-radius: 8.5px;
    font-size: 16px;
    line-height: 16px;
    color: $grey-text-secondary;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
      border-color: $grey-text-color;
      color: $grey-black-color;
    }
  }
}

.person-sidebar-add-field-tooltip {
  max-height: 300px;
  padding: 10px;

  overflow: auto;

  &__item {
    padding: 3px 10px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    cursor: pointer;

    svg {
      margin-right: 5px;
      height: 20px;
    }
  }

  &__create-custom-field-button {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    padding: 3px 10px;
    color: $secondary-light-blue-color;

    &:hover {
      cursor: pointer;
      //background-color: $grey-black-color;
      color: $third-light-blue-color;
    }
  }
}
