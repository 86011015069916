@import "src/vendors/mixins";
@import "src/vendors/colors";

.sequence-save-template-modal {
  &__header {
    @include modal-header;
  }

  &__input {
    padding: 24px;

    input {
      width: 100%;
      height: auto;
      padding: 15px 20px;
    }
  }

  &__footer {
    padding: 12px 24px 24px 24px;
    display: flex;
    justify-content: flex-end;

    button {
      width: auto;
      height: auto;
      padding: 16px 24px;
    }
  }

  &__cancel-button {
    background-color: transparent;
    border: 1px solid $border-grey-color;
    color: $grey-text-secondary;
    margin-right: 1rem;

    &:hover {
      background-color: transparent;
      color: $grey-black-color;
    }
  }
}