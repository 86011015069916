.edit-unsubscribe-content-modal {
  &__header {
    display: flex;
    flex-direction: column;
    width: 582px;
    padding: 27px 24px 15px;

    border-bottom: 1px solid #f4f4f4;

    &-title {
      margin-bottom: 11px;
      font-weight: 600;
      font-size: 20px;
      line-height: 20px;
      color: #1b1e22;
    }

    &-subtitle {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #8f9199;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 29px 24px 30px;
  }

  &__input {
    width: 100%;

    input {
      width: 100%;
    }
  }

  &__save-button {
    margin-top: 25px;
  }
}
