@import "src/vendors/colors";

.add-email-sidebar {
  width: 525px;

  .right-sidebar {
    width: 525px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0 20px;
  }

  &__title {
    margin-top: 20px;
    margin-bottom: 25px;
    font-size: 16px;
    line-height: 22px;
  }

  &__provider {
    border-radius: 8px;
    display: flex;
    align-items: center;
    height: 145px;
    margin-bottom: 17px;
    padding-left: 23px;
    cursor: pointer;
    transition: box-shadow linear .3s;

    &:hover {
      box-shadow: #e5e5e5 -2px 4px 13px 3px;
    }

    &--gmail {
      background: rgba(251, 16, 16, 0.04);
    }

    &--microsoft {
      background: #E4EFFF;
    }

    &--other {
      background: #F3F3F3;
    }
  }

  &__provider-icon {
    margin-right: 22px;
  }

  &__provider-name {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 8px;
  }

  &__provider-desc {
    color: $grey-text-secondary;
  }
}