@import "../../vendors/mixins.scss";
@import "../../vendors/colors.scss";

.copy-icon {
  display: inline-block;
  cursor: pointer;
  margin-right: 5px;

  &:hover {
    svg {
      @include svg-colors-path-fill($secondary-blue-color)
    }
  }
}