@import '../../../vendors/mixins';
@import '../../../vendors/colors';

.credits-usage-by-user-report-graphics {
  &_item {
    padding: 20px 20px 0;
  }
}

.credits-usage-by-user-report-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100% - 68px);

  &__message {
    color: $secondary-black-color;
    font-size: 24px;
    font-weight: 600;
    margin-top: 3rem;
  }
}

.report-graphic-container {
  .recharts-responsive-container {
    margin: 10px;
  }
}

.credits-usage-by-user-report-graphics_table {
  padding: 20px 50px 0 20px;
  position: relative;

  &__select-columns-wrapper {
    position: absolute;
    right: 0;
    top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: auto;
    padding-right: 30px;
    padding-left: 5px;
    background-color: #ffffff;
  }

  .credits-usage-by-user-report-table {
    .table-th {
      &:first-child {
        padding-left: 0;
        width: 14%;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    &__th {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    &__body-row {
      border-bottom: 1px solid #0000000D;

      .table-td {
        padding: 16px 0;
        color: #525456;

        &:first-child {
          width: 14%;
        }

        &:not(:first-child) {
          text-align: left;
          padding-left: 5px;
        }

        .text-blue {
          color: #221CB6;
          font-weight: bold;
        }

        .category-label {
          padding: 8px 11px;
          border-radius: 39px;
          width: fit-content;
        }

        .credits-usage-by-user-report-table__name {
          font-weight: 600;
          max-width: 300px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          display: flex;
          align-items: center;
          grid-column-gap: 5px;

          .avatar-container {
            position: relative;
            border-radius: 100%;
          }

          .title-symbol-number {
            color: #1B1E22;
            font-weight: 600;
          }
        }

        .credits-usage-by-user-report-table__job-position {
          color: #525456;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 75px;
          display: inline-block;
          overflow: hidden;

          .percentage-pie {
            display: flex;
            align-items: center;

            &__title {
              font-family: Mulish;
              font-size: 14px;
              font-weight: 400;
              line-height: 18px;
              text-align: left;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
}

.credits-usage-by-user-report-upgrade {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);

  &__info {
    display: flex;
    align-items: center;
    flex-direction: column;

    h2 {
      font-size: 26px;
      font-weight: 600;
      margin-bottom: 16px;
      text-align: center;
    }

    p {
      font-size: 12px;
      font-weight: 400;
      line-height: 178.5%;
      text-align: center;
      margin-bottom: 23px;
      max-width: 293px;
    }
  }

  &--plain {
    position: relative;
    transform: translateY(0);
    top: 0;
    margin: 50px 20px 0;

    .blurred-table {
      position: relative;
      border-radius: 8px;
      width: 100%;

      .credits-usage-by-user-report-graphics_table {
        filter: blur(3px);
      }

      &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.18);
        border-radius: 10px;
      }

      &-image {
        max-width: 460px;
        width: 100%;
        background: white;
        border-radius: 8px;
        padding: 40px;
        position: absolute;
        top: 50%;
        right: 0;
        left: 0;
        transform: translateY(-50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
      }

    }
  }
}

.credits-usage-by-user-board-modal {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  &_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 49px;
    max-width: 493px;
    background-color: #FFFFFF;
    width: 100%;
    position: relative;
    top: 50%;
    left: 50%;
    border-radius: 19px;
    transform: translateY(-50%) translateX(-50%);

    &-image {
      padding: 40px;
      width: 294px;
      height: 222px;
    }

    &-title {
      color: #000;
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
      margin-top: 0;
    }

    &-info {
      color: #000;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      max-width: 238px;
      line-height: 178.5%;
      margin-bottom: 23px;
    }

    &-button {
      width: 186px;
      height: 45px;
    }
  }

  &_background {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(79, 79, 79, 0.29);
    backdrop-filter: blur(4px);
  }
}
