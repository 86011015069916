@import 'src/vendors/mixins';

.maintenance-page {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  min-width: 320px;
  min-height: 100vh;
  padding: 90px 60px 0;

  font-family: 'Oakes Grotesk', sans-serif;
  font-style: normal;
  font-weight: 500;

  overflow: hidden;

  @media (screen and max-width: 915px) {
    padding: 60px 15px 0;
  }

  &__gradient-left {
    position: absolute;
    top: 40%;
    left: -5%;
    width: 100%;
    max-width: 704px;
    max-height: 100%;

    pointer-events: none;
    z-index: 0;

    @media (screen and max-width: 1280px) {
      left: -35%;
      max-width: 100%;
    }

    @media (screen and max-width: 520px) {
      left: -50%;
    }
  }

  &__gradient-right {
    position: absolute;
    top: 0;
    right: 0;
    max-height: 100%;

    pointer-events: none;
    z-index: 0;
  }

  &__logo-container {
    position: absolute;
    width: auto;
    margin-bottom: 48px;
  }

  &__logo {
    height: 54px;

    @media (screen and max-width: 915px) {
      height: 50px;
    }

    @media (screen and max-width: 520px) {
      height: 40px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;

    max-width: 760px;
    width: 100%;
    height: 100%;
    padding-bottom: 60px;
  }

  &__message {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: auto;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 38px;
    line-height: 53px;
    text-align: center;
    letter-spacing: -1px;
    color: #111a45;

    @media (screen and max-width: 915px) {
      line-height: 52px;
      letter-spacing: -0.5px;
    }

    @media (screen and max-width: 540px) {
      font-size: 26px;
      line-height: 36px;
      letter-spacing: -0.5px;
    }
  }

  &__sub-title {
    max-width: 100%;
    width: 100%;

    margin-top: 28px;

    font-size: 18px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.5px;
    color: #525979;

    @media (screen and max-width: 540px) {
      font-size: 14px;
      line-height: 18px;
      margin-top: 4px;
    }

    &-highlight {
      color: #251fb6;
    }
  }

  &__contact {
    color: $grey-text-color;
    font-size: 13px;
    line-height: 20px;
    margin-top: 8px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    margin: 50px 0 41px;

    @media (screen and max-width: 915px) {
      margin: 30px 0 0;
    }
  }

  &__refresh-btn {
    margin-top: 20px;
    width: 180px;
  }
}
