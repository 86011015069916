.integrations-settings-page {
  &__header-actions {
    display: flex;
    align-items: center;

    .input__field {
      width: 200px;
    }

    .button {
      margin-left: 8px;
    }
  }

  &__header-actions-btn {
    white-space: nowrap;
    height: 40px;
    margin-right: 0;
  }
}