@import "../../vendors/colors";
@import "../../vendors/mixins";

.remove-two-steps-button {
  @include mulish-text;
  font-size: 14px;
  box-sizing: border-box;
  box-shadow: none;
  border: none;
  display: flex;
  align-items: center;
  background-color: transparent;

  &__text {
    transition: width .3s;
    overflow: hidden;
    white-space: nowrap;
    width: 0;
  }

  &__removing-item-text {
    display: inline-block;
    padding: 5px 6px;
    border-radius: 8.5px;
    background-color: #FFE3E3;
    margin: 0 7px;
  }


  svg {
    margin-right: 7px;

    path {
      fill: #000;
    }
  }

  &:hover {
    cursor: pointer;

    svg {
      @include svg-colors-path-fill(#FC0E0E);
    }
  }

  &:focus {
    outline: none;
  }

  &--clicked {
    color: #FC0E0E;

    .remove-two-steps-button__text {
      width: auto;
    }

    svg path {
      fill: #FC0E0E!important;
    }
  }
}