@import "src/vendors/mixins";
@import "src/vendors/colors";

.remove-member-sidebar {
  padding-top: 20px;

  &__text-content {
    @include mulish-text;
    font-size: 16px;
    line-height: 20px;
    color: #8F8F8F;
    padding: 0 20px;
  }

  &__member-info {
    @include mulish-text;
    color: $secondary-blue-color;
    font-size: 12px;
    margin: 6px 20px;
    padding: 6px 8px;
    background: #E9EEFF;
    border-radius: 8.5px;
  }

  &__member-info-name {
    font-weight: 700;
  }

  &__recommended-label {
    @include mulish-text;
    font-size: 12px;
    line-height: 15px;
    color: #FC8E0E;
    margin-bottom: 6px;
  }

  &__remove-options {
    margin: 20px 8px 0 8px;
    padding: 12px;
    border: 1px solid #F4F4F4;
    border-radius: 8px;

    .radio {
      height: auto;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__member-select {
    margin: 10px 0;
  }
}