.colored-checkbox {
  position: var(--checkbox-position);
  right: 0;
  top: 0;

  &-round {
    position: relative;

    label {
      background-color: transparent;
      border: 2px solid var(--checkbox-color);
      border-radius: 50%;
      cursor: pointer;
      height: 15px;
      left: 0;
      position: absolute;
      top: 0;
      width: 15px;

      &:after {
        color: #fff;
        content: "\2713";
        height: 15px;
        left: 50%;
        opacity: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
        width: 15px;
        font-family: system-ui;
        text-align: center;
        font-size: 11px;
      }
    }

    input[type="checkbox"] {
      visibility: hidden;

      &:checked + label {
        background-color: var(--checkbox-color);
        border-color: var(--checkbox-color);

        &:after {
          opacity: 1;
        }
      }
    }
  }
}

