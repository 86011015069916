@import 'src/vendors/mixins';

.toolbox-email-verifier {
  flex: 1;

  &__content {
    overflow: auto;
    padding: 53px 20px;
  }

  &__search {
    display: flex;
  }

  &__search-input {
    width: 100%;
    input {
      height: 48px;

      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &__search-btn {
    .button {
      height: 100%;
      width: 48px;
      min-width: 48px;
      border-radius: 0 8.5px 8.5px 0;
      padding: 0;
    }

    svg {
      @include svg-colors-path-fill(#ffffff);
      margin-right: 0;
    }
  }

  &__tooltip-content {
    padding: 16px !important;
    &,
    & > div {
      color: #ffffff !important;
    }
    max-width: 320px;
  }

  &__status-header {
    display: grid;
    grid-template-columns: minmax(max-content, 3fr) 1fr 1fr 1fr;
    grid-column-gap: 20px;

    margin-top: 26px;
    padding: 0 8px;

    border-bottom: 1px solid #e9ecf1;

    div {
      padding: 16px 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #8d8f92;

      svg {
        margin: 0 0 -1px 5px;
      }
    }
  }

  &__status {
    display: grid;
    grid-template-columns: minmax(max-content, 3fr) 1fr 1fr 1fr;
    grid-column-gap: 20px;

    padding: 17px 8px;

    & > div {
      display: flex;
      align-items: center;
    }
  }
}
