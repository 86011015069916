.email-select {
  &__search {
    margin-top: 18px;
    margin-bottom: 10px;
    padding: 0 10px;

    input {
      width: 100%;
      background-color: #000000;
      border: 1px solid #272727;
      border-radius: 6px;
      height: 32px;
      font-size: 14px;

      color: #c4c4c4;

      &::placeholder {
        color: #747474;
      }

      &:focus {
        border-color: #272727;
      }
    }
  }

  &__selected {
    border-bottom: 1px solid rgba(189, 189, 189, 0.35);

    &-label {
      color: #ffffff;
      font-size: 12px;
      padding: 8px 12px;
      border-bottom: 1px solid rgba(189, 189, 189, 0.35);
    }

    &-item {
      color: #4b4c4d;
      padding: 8px 12px;
      cursor: default;
    }
  }

  &__select-wrapper {
    flex: 1;
  }

  &__checkbox-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    cursor: pointer;

    & > span {
      flex: 1;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .checkbox {
      width: 16px;
    }
  }
}
