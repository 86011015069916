@import 'src/vendors/mixins';
@import 'src/vendors/colors';

.header-list {
  display: flex;
  align-items: center;
  list-style-type: none;

  &_item {
    background-color: white;
    padding: 12px 16px;
    border-radius: 8px;
    margin-right: 10px;
    height: 88px;
    width: 145px;

    &:last-child {
      margin-right: 0;
    }

    &__title {
      margin-top: 0;
      margin-bottom: 20px;

      @include mulish-text;
      font-size: 16px;
      font-weight: 600;
      color: #A0A0A0;
    }

    &__text {
      font-weight: 600;
      font-size: 15px;
    }
  }
}
