@import '../../../vendors/colors';
@import '../../../vendors/mixins';

.schedules-table {
  margin-top: 17px;

  &__tr {
    cursor: pointer;
    height: 90px;

    &:hover {
      background-color: #eef3ff;
    }
  }

  &__th {
    text-align: left;

    &:first-child {
      padding-left: 0;
    }
  }

  &__td {
    @include mulish-text;
    font-size: 14px;
    line-height: 130%;

    &--empty {
      padding: 0 3px 0 7px;
      vertical-align: middle;
      width: 16px;
    }

    &:first-child {
      padding-left: 0;
    }
  }

  &__actions-icon {
    cursor: pointer;

    &:hover {
      svg {
        @include svg-colors-path-fill(#4b4c4d);
      }
    }
  }
}
