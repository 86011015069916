.relationship-status {
  display: flex;
  align-items: center;
  font-size: 14px;

  &__icon {
    height: 16px;
    width: 16px;
    margin-right: 5px;
  }
}
