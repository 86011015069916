@import "src/vendors/mixins";

.campaign-rename-modal {
  width: fit-content;
}

.rename-modal {
  display: flex;
  flex-direction: column;
  height: 220px;

  &__header {
    @include modal-header;
  }

  &__body {
    flex: 1;
    display: flex;
    align-items: center;
    padding-left: 25px;
  }

  &__footer {
    @include modal-footer;
  }
}
