.inbox-selector {
  height: 40px;
  margin-bottom: 2px;

  &__content {
    max-height: 360px;
    width: 265px;
    background-color: #000000;
    border-radius: 8px;
    overflow: auto;
  }

  &__content-search input {
    height: 44px;
    width: 100%;
    background-color: #000000;
    border: 1px solid #272727;
    border-radius: 6px;

    color: #ffffff;

    &:focus {
      border-color: #272727;
    }
  }

  .input__field {
    width: 265px;
    color: white;
  }
  .react-tooltip {
    background-color: #000000 !important;
    border-radius: 8px !important;
    width: 265px !important;
    padding-bottom: 10px !important;
  }
}

.mailboxes-list {
  &__item {
    padding: 10px 14px;
    border-top: solid 1px #272727;

    display: flex;
    align-items: center;

    cursor: pointer;

    &:hover {
      background-color: #181818;
    }
  }

  &__item-text {
    margin-left: 8px;
    font-size: 16px;

    flex: 1;

    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;

    line-height: 1.5rem;
  }

  &__item-sign {
    margin-left: auto;
  }
}
