@import "src/vendors/colors";
@import "src/vendors/mixins";

.header-filters {
  position: relative;

  &__tooltip-button {
    display: flex;
    padding: 12px;
    color: #7B7B7B;
    background-color: $primary-grey-action-color;
    border-radius: 8px;
    height: 40px;

    &:hover {
      color: #474747;

      svg {
        path {
          fill: #474747;
        }
      }
    }

    svg {
      margin-right: 10px;
    }
  }

  &__content-wrapper {
    min-width: 40px;
    border-radius: 10px !important;
    min-height: 40px;

    &:after {
     display: none;
    }
  }

  &__throw-filters-button {
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    top: -2px;
    right: -2px;

    cursor: pointer;
  }
}
