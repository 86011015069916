.campaign-template-input {
  &__wrapper {
    position: relative;
    margin: 15px;

    input[id^='react-select-'] {
      opacity: 1 !important;
    }

    .select {
      .select__icon {
        svg {
          path {
            stroke: #251FB6;
          }
        }
      }

      .css-rzky46-control,
      .css-rzky46-control:hover,
      .css-eqy8aq-control:focus,
      .css-eqy8aq-control:hover {
        border-color: #DBDFE7;
        box-shadow: 0px 4px 11.5px rgba(0, 0, 0, 0.08);


      }

      .css-eqy8aq-control,
      .css-rzky46-control {
        border-radius: 5px;
      }

      &-grey {
        & + .campaign-template-input__square-button {
          background-color: #D9D9D9;

          svg {
            path {
              stroke: #979797;
            }
          }
        }
      }
    }

  }

  input {
    width: 100%;
  }

  textarea {
    min-height: 48px;
    max-height: 136px;
    padding: 12px 19px;

    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    &::placeholder {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #acacac;
      white-space: pre-line;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__container-row {
    display: flex;
    align-items: center;

    margin-bottom: 15px;
  }

  &__order {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 25px;
    height: 24px;

    margin-right: 6px;

    color: #8f9199;
    background: rgba(143, 145, 153, 0.13);
    border-radius: 6px;
  }

  &__title {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #8f9199;
  }

  &__square-button {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    width: 23px;
    height: 23px;
    min-width: auto;
    padding: 0;

    svg {
      transform: rotate(-90deg);
    }

    .refresh {
      animation: rotation 2s infinite linear;
    }
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}


