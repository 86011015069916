@import 'src/vendors/colors';
@import 'src/vendors/mixins';

.meta-info {
  box-sizing: border-box;
  border-bottom: 1px solid $secondary-grey-lines-color;
  border-radius: 8px 8px 0 0;
  background-color: #ffffff;
  padding: 22px 28px;

  &__row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 6px;

    margin-bottom: 6px;

    &::-webkit-scrollbar {
      display: none;
    }

    &--disabled {
      cursor: not-allowed;
    }
  }

  &__row-buttons {
    margin-top: 12px;
    display: flex;
  }

  &__label {
    @include mulish-text;
    font-size: 14px;
    color: $grey-text-secondary;
    margin-right: 6px;
  }

  &__to-emails {
    display: flex;
    flex-wrap: wrap;

    max-height: 200px;
    overflow-y: scroll;
  }

  &__value {
    @include mulish-text;
    font-size: 14px;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    height: 22px;
    padding: 4px 6px;
    background: $grey-selection-color;
    border-radius: 5px;
    margin-right: 6px;

    cursor: default;

    svg {
      width: 14px;
      height: 14px;
      margin-left: 4px;
      cursor: pointer;
    }

    &--disabled {
    }
  }

  &__remove-icon {
    height: 14px;
  }

  &__adding-button {
    @include mulish-text;
    font-size: 12px;
    margin-right: 12px;
    color: $secondary-blue-color;
    cursor: pointer;
  }

  &__chevron {
    cursor: pointer;

    svg {
      transform: rotateX(180deg);
    }
  }

  /* Overload styles */
  .input {
    &__field {
      height: 22px;
      //padding-left: 0;
    }
  }

  .meta-info__contacts {
    max-height: 360px;
    width: 265px;
    background-color: #000000;
    border-radius: 8px;
    overflow: auto;
  }

  .meta-info__contacts-search input {
    height: 45px;
    width: 100%;
    background-color: #000000;
    border: 1px solid #272727;
    border-radius: 6px;

    color: #ffffff;

    &:focus {
      border-color: #272727;
    }
  }

  .meta-info-contacts {
    &__contact {
      padding: 10px 14px;
      border-top: solid 1px #272727;

      display: flex;
      align-items: center;

      cursor: pointer;

      &:hover {
        background-color: #181818;
      }
    }
  }
}
