.name-container {
  a {
    &:hover {
      svg {
        path {
          fill: #221CB6;
        }
      }
    }
  }
}
