@import 'src/vendors/mixins';
@import 'src/vendors/colors';

.expandable-row {
  &__show-more-btn {
    padding: 0;
    min-width: 0;

    &:hover {
      svg {
        @include svg-colors-path($primary-black-color);
      }
    }

    &.show-less {
      transition: .3s;

      svg {
        transform: rotate(0);
      }
    }
  }
}
