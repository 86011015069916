.social-links {
  display: inline-flex;
  align-items: center;

  .social-link {
    &:first-of-type {
      margin-left: -2px;
    }

    &:not(&:last-child) {
      margin-right: 5px;
    }
  }
}
