.question-hint {
  height: 14px;
  width: 14px;
  line-height: 14px;
  display: inline-flex;
  align-items: center;
}

.question-hint-tooltip {
  max-width: 300px;

  &__content {
    padding: 10px;
    white-space: break-spaces;
  }
}