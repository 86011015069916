@import "src/vendors/mixins";

.settings-page {
  display: flex;

  &__content {
    flex: 1;
    max-width: 100%;
    height: 100vh;
    overflow-y: auto;

    .subscription-form__content {
      .subscription-form__due-today-price{
        height: 83px;

        &-value {
          font-size: 28px;
        }
      }

      .subscription-form__price {
        height: 79px;
      }

      .subscription-form__apply-btn {
        margin-top: 25px;
      }
    }
  }
}
