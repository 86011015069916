@import "../../../vendors/mixins";

.campaigns-filter-item {
  @include mulish-text;
  color: #FFFFFF;
  padding: 11px 22px 9px 20px;

  // Such height because of svg height, when we click to choose campaign to make item stable height
  height: 41px;
  width: 100%;

  display: flex;
  align-items: center;

  cursor: pointer;

  border-bottom: 1px solid #272727;

  &--in-folder {
    padding-left: 44px;
  }

  svg {
    margin-left: auto;
  }

  &__text {
    margin: 0;
    width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}