@import 'src/vendors/mixins';
@import 'src/vendors/colors';

.campaign-automation {
  position: relative;

  &__settings-title {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 20px;
    line-height: 100%;
    color: #000000;
  }

  .campaign-automation-search-mode {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(318px, auto));
    grid-gap: 24px;
    padding-bottom: 32px;
    border-bottom: 1px solid $secondary-grey-lines-color;

    &__mode-wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 24px;
      padding-bottom: 44px;
      background: #f5f5f5;
      border-radius: 8px;
      border: 1px solid transparent;

      cursor: pointer;

      &--active {
        background: $light-main-color;
        border-color: #221db6;
      }
    }

    &__mode-image {
      border-radius: 8px;
    }

    &__mode-content {
      @include mulish-text;
      margin-top: 24px;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
    }

    &__recommended {
      position: absolute;
      top: 0;
      left: 0;
      background: #221db6;
      box-shadow: 0 13px 25px -11px rgba(0, 0, 0, 0.29);
      border-radius: 5px;
      color: #ffffff;

      padding: 3px 8px 2px 7px;

      border-bottom-left-radius: 0;
      border-top-right-radius: 0;

      font-weight: 600;
      font-size: 9px;
      line-height: 14px;
    }

    &__cost {
      position: absolute;
      bottom: 14px;
      right: 24px;

      display: flex;
      align-items: center;
      padding: 5px 7px 6px 7px;
      font-weight: 700;
      font-size: 10px;
      line-height: 100%;
      color: #4b4c4d;

      background-color: #e9e9e9;
      border-radius: 5px;

      svg {
        width: 12px;
        height: 12px;
        margin-right: 4px;
      }
    }

    &--disabled {
      .campaign-automation-search-mode {
        &__mode-wrapper {
          background: #f5f5f5;
          cursor: unset;

          &--active {
            border: 1px solid #8d8f92;
          }
        }

        &__mode-image {
          opacity: 0.7;
        }
      }
    }
  }

  .automation-position-and-seniority {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__wrapper {
      width: fit-content;
      display: flex;
      align-items: center;
      gap: 6px;

      .select {
        min-width: 150px;
        max-width: 550px;

        .css-1c6f4l6-control {
          border: 1px solid #DBDFE7;
        }
      }
    }

    &__title-row {
      font-size: 16px;
      line-height: normal;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #202430;

      &-block {
        border-radius: 9px;
        display: flex;
        align-items: center;
        padding: 9px 14px;

        svg {
          margin-right: 8px;
        }
      }

      .automation-position-and-seniority__required-label {
        color: #202430;
      }
    }

    &__required-label {
      @include mulish-text;
      display: flex;
      font-size: 16px;
      margin-left: auto;
      margin-right: 8px;
      color: $grey-text-secondary;

      &--active {
        color: #000;
      }
    }
  }

  .automation-advanced-settings-content {
    &__row {
      display: flex;
      align-items: center;

      &:not(:first-of-type) {
        margin-top: 26px;
      }
    }

    &__title {
      font-weight: 600;
      font-size: 20px;
      line-height: 20px;

      color: #000000;
    }

    &__label {
      display: flex;
      margin-left: 12px;
    }
  }

  .campaign-automation-progress-info {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
    gap: 24px;

    &__card {
      flex-grow: 1;
      background-color: #ffffff;

      border-radius: 8px;
      min-width: 269px;
      height: 48px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;

      border: 1px solid rgba(99, 99, 99, 0.21);
    }

    &__card-title {
      display: flex;
      align-items: center;
      gap: 10px;
      @include mulish-text;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
    }

    &__card-value {
      &--color-green {
        color: $secondary-green-color;
      }

      &--color-blue {
        color: $primary-main-color;
      }

      &--color-red {
        color: $alert-color;
      }
    }
  }

  .campaign-automation-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    left: 0;
    right: 0;
    bottom: 0;

    padding-bottom: 24px;
    padding-top: 24px;

    background-color: #ffffff;

    &__notify {
      display: flex;
      align-items: center;
    }

    &__notify-title {
      margin-left: 10px;
    }

    &__play-icon {
      height: 24px;
      margin-right: 5px;
      margin-top: -2px;
    }

    &__stop-icon {
      width: 16px;
      height: 16px;

      border: 2px solid #ffffff;
      border-radius: 3px;

      margin-right: 8px;
    }

    &__button {
      display: flex;
      align-items: center;
      height: 43px;
    }

    &__button-results {
      svg {
        width: 16px;
        height: 16px;
        margin-right: 10px;
        path {
          fill: #ffffff;
        }
      }
    }
  }
}
