.add-tracking-domain-sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;

  width: 526px;
  padding: 28px 21px;

  &__content {
    display: flex;
    flex-direction: column;

    padding-bottom: 50px;
  }

  &__title {
    margin-bottom: 9px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    color: #000000;
  }

  &__records {
    display: flex;
    flex-direction: column;
    gap: 14px;

    margin-bottom: 8px;
    padding: 15px 15px 19px;
    background: rgba(196, 196, 196, 0.08);
    border: 1px solid #dbdfe7;
    border-radius: 8px;

    &-type {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #8f9199;
    }

    &-value {
      display: inline-flex;
      width: 100%;
      justify-content: space-between;
      color: #000000;

      & > span {
        margin-left: 9px;
        margin-bottom: -3px;

        cursor: pointer;
      }
    }
  }

  &__warning {
    display: flex;
    align-items: center;
    margin-bottom: 41px;
    padding: 9px 10px;
    background: rgba(252, 142, 14, 0.15);
    border-radius: 6px;

    svg {
      margin-right: 11px;
    }
  }

  &__label {
    margin-bottom: 8px;

    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #8f9199;
  }

  &__input {
    width: 100%;

    & > input {
      width: 100%;
      height: 48px;
      padding: 15px 16px 17px;

      &::placeholder {
        color: #8f9199;
      }
    }
  }

  &__footer {
    position: sticky;

    display: flex;
    justify-content: flex-end;
    width: 100%;
    bottom: 0;

    margin-top: auto;

    button:first-child {
      margin-right: 9px;
    }
  }
}
