.contacts-sidebar-section-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  &--empty {
    color: #7b7b7b;
    font-size: 14px;
  }

  &__tag {
    display: flex;
    align-items: center;

    flex-shrink: 0;

    padding: 6px 8px 7px;

    background: #ffffff;
    border: 1px solid #eaeaea;
    border-radius: 7px;

    font-weight: 400;
    font-size: 14px;
    line-height: 14px;

    color: #000000;

    &-delete-btn {
      display: flex;
      align-items: center;

      margin-top: 1px;
      margin-left: 8px;
      cursor: pointer;

      &:hover {
        svg path {
          fill: #ed483d;
        }
      }
    }
  }
}
