@import 'src/vendors/mixins';
@import 'src/vendors/colors';

.pipeline-deal-card {
  display: block;
  text-decoration: none;
  background: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  cursor: pointer;
  transition: all .2s ease-in-out;

  &:not(&:last-child) {
    margin-bottom: 20px;
  }

  //&.sortable-chosen,
  //&.sortable-selected {
  //  border: 1px solid $primary-main-color;
  //}

  &.sortable-ghost {
    background: #F5F5F5;
    height: 152px;
    width: 246px;
    border: none;

    .pipeline-deal-card__header,
    .pipeline-deal-card__footer {
      display: none;
    }
  }


  &:hover {
    transform: scale(1.05);

    .pipeline-deal-card__header-delete {
      display: block;
    }
  }


  &__user-info,
  &__website-info {
    display: flex;
    align-items: center;
    grid-column-gap: 12px;

    .card-checkbox {
      display: none;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;

      &.d-flex {
        display: flex;
      }
    }
  }

  &__website-info {
    &-logo {
      border-radius: 6px;
    }

    &-container {
      display: flex;
      grid-row-gap: 5px;
      flex-direction: column;
    }

    &-counter {
      color: $grey-text-secondary;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    color: #000;
    position: relative;

    &-delete {
      padding: 0;
      min-width: 20px;
      height: 20px;
      position: absolute;
      top: 20px;
      right: 20px;
      background: white;
      display: none;

      &:hover {
         svg {
          @include svg-colors-path($alert-hover-color);
        }
      }
    }

    &-confirmation {
      display: flex;
      align-items: center;
      grid-column-gap: 5px;
      width: 100%;
      justify-content: center;
      background: white;
      z-index: 10;
    }

    &-confirm,
    &-cancel {
      min-width: 25px;
      height: 25px;
      padding: 0;

      &:hover {
        opacity: 0.8;
        transform: scale(1.1);
      }
    }
  }

  &__user-name,
  &__website-name {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
  }

  &__website-name {
    font-size: 16px;
  }

  &__website {
    font-size: 14px;
    line-height: 15px;
    color: $grey-text-secondary;
  }

  &__footer {
    padding: 20px;
    border-top: 2px solid #F9F9F9;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-timing {
      color: #C4C6CD;
      display: flex;
      align-items: center;
      grid-column-gap: 5px;

      &--red {
        color: $alert-color;
      }
    }
  }
}
