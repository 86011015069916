@import 'src/vendors/mixins';
@import 'src/vendors/colors';

.create-workspace-step {
  display: flex;
  justify-content: center;
  text-align: center;
  @include mulish-text;
  line-height: unset;
  padding-top: 70px;

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__icon {
    background-color: $primary-grey-lines-color;
    width: 67px;
    height: 67px;

    border-radius: 50%;

    svg {
      width: 25px;
      height: 25px;
    }
  }

  &__title {
    font-weight: 400;
    font-size: 34px;
    margin: 1rem 0 0 0;
  }

  &__description {
    margin: 1rem 0 0 0;
  }

  &__settings {
    min-width: 600px;
    margin-top: 50px;
  }
}