@import "src/vendors/colors";
@import "src/vendors/mixins";

.organizations-header-more-actions-tooltip {
  padding: 10px 0;

  &__action {
    @include mulish-text;
    min-width: 150px;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
    padding: 6px 20px;
    display: flex;
    align-items: center;
    position: relative;

    svg {
      margin-right: 9px;
    }

    &:hover {
      background-color: #181818;
    }

    .input {
      color: #fff;
    }
  }


  &__select {
    width: 100%;
  }
}

.organizations-actions-menu {
  &__header {
    display: flex;
    justify-content: center;
  }

  &__button {
    @include mulish-text;
    font-size: 14px;
    color: $secondary-light-blue-color;
    display: flex;
    justify-content: center;
  }
}