.no-access-error {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  min-width: 600px;

  &__title {
    margin-top: 50px;
  }
  &__sub-title {
    margin-top: 21px;
  }
  &__btn {
    padding: 12px 25px;
    margin-top: 41px;
  }

  &__home {
    margin-top: 41px ;
  }
}