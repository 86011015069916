@import 'src/vendors/colors';
@import 'src/vendors/mixins';

.email-edition-sidebar {
  position: fixed;
  width: 962px;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #fff;
  box-shadow: 0 4px 50px rgba(18, 16, 68, 0.09);
  padding-top: 14px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  &--full-width {
    width: calc(100vw - 87px - 210px);
    box-shadow: none;
  }

  &--full-width-with-sidebar {
    width: calc(100vw - 190px - 210px);
    box-shadow: none;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 13px;
    font-size: 24px;
    font-weight: 600;
    border-bottom: 1px solid #f4f4f4;
  }

  &__content {
    display: flex;

    &-column {
      flex: 1;
      width: 50%;
      padding-top: 15px;
    }
  }

  &__close-btn {
    display: flex;
    align-items: center;
    margin-right: 15px;
    cursor: pointer;
  }

  &__row {
    position: relative;
    margin-top: 27px;
    padding: 0 20px;
  }

  &__input input {
    height: 48px;
  }

  &__label {
    display: flex;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #8f9199;
    margin-bottom: 8px;

    svg {
      margin-left: 8px;
      margin-top: 2px;
    }

    &--flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    & + input {
      height: 48px;
    }
  }

  &__signature-preview {
    width: 100%;
    overflow: auto;
    height: 202px;
    p {
      //       height: 20px;
    }
  }

  &__cancel-edit-signature-btn {
    cursor: pointer;

    &:hover {
      svg {
        @include svg-colors-path-fill($alert-hover-color);
      }
    }
  }

  &__edit-signature-btn {
    cursor: pointer;

    &:hover {
      svg {
        @include svg-colors-path-stroke($secondary-light-blue-color);
      }
    }
  }

  &__signature-editor {
    padding: 15px 5px;
    @include input-field;
    resize: none;
    width: 100%;
    height: 200px;
  }

  &__footer {
    display: flex;
    margin-top: auto;
  }

  &__footer-right {
    display: flex;
    margin-left: 8px;

    .button {
      white-space: nowrap;
      width: auto;
      height: 40px;
      margin-right: 8px;
    }
  }

  .input--disabled {
    .input__field {
      color: $grey-text-color;
    }
  }

  .slate-editor {
    &__editor {
      height: 320px;
      border: 1px solid #dbdfe7;
      border-radius: 4.5px;
    }
  }

  &__custom-option {
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.19);
    padding: 14px;

    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: #7974f6;

    cursor: pointer;
  }

  &__tracking-domain-option {
    width: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__tracking-domain-remove-btn {
    //position: absolute;
    margin-right: -8px;
    margin-left: auto;

    svg path {
      fill: #4B4C4D;
    }
  }
}
