@import 'src/vendors/colors';

.sequence {
  display: flex;
  min-height: 631px;
  min-width: 900px;
  height: calc(100vh - 190px);

  &__editor {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: calc(100% - 350px);

    .sequence-editor {
      height: 100%;

      &__step-content {
        .slate-editor {
          height: 100%;
        }
      }
    }

  }

  &__steps {
    overflow-y: auto;
    border-radius: 8px;
    margin-right: 12px;
    width: 33%;
    min-width: 350px; // We need min-width 350px for footer buttons in template select
    max-width: 390px;
    display: flex;
    flex-direction: column;

    .template-select {
      .select {
        .css-uho8kj-control,
        .css-iivs7-control {
          outline-color: #ABABAB;

          &:focus,
          &:hover {
            box-shadow: 0px 5px 13px rgba(0, 0, 0, 0.12);
            border-radius: 8px;
            border-color: #221cb6;
          }

        }
      }
    }
  }

  &-steps__create-btn {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    &:hover {
      background-color: #EEF3FF;
    }
  }

  &__unsubscribe-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 19px;
    padding-bottom: 12px;

    &-row {
      align-items: center;
      display: grid;
      grid-template-columns: 290px 30px;
    }
  }

  &__edit-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 26px;
    height: 26px;
    border-radius: 6px;

    &:hover {
      background-color: #e9eeff;
    }
  }
}
