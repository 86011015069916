@import '../../../../vendors/mixins';
@import '../../../../vendors/colors';

.import-file-flow {
  position: relative;
  border-radius: 8px;
  margin-top: 51px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__header-close-icon {
    cursor: pointer;
  }

  &__header-title {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 100%;

    color: #000000;

    svg {
      margin-right: 13px;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
  }

  &__title-text {
    margin-left: 1rem;
  }

  .import-file-flow-dnd-zone {
    margin-top: 24px;
    background: #ffffff;
    border: 2px dashed #dbdfe7;
    box-sizing: border-box;
    border-radius: 8px;
    height: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__icon {
      margin-bottom: 16px;
    }

    &__description {
      @include mulish-text;

      width: auto;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #000000;
    }

    &__input-label {
      @include mulish-text;
      content: 'Upload .CSV file';
      display: inline-flex;
      background-color: $primary-main-color;
      color: #ffffff;
      width: auto;
      height: 40px;
      padding: 12px 16px;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      cursor: pointer;
      border: none;
      margin-top: 20px;

      font-weight: 500;
      font-size: 16px;
      line-height: 16px;

      &:hover {
        background-color: #4e48dc;
      }
    }

    &__input-field {
      display: none;
    }
  }

  button {
    width: auto;
    padding: 0 20px;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    left: 0;
    right: 0;
    bottom: 0;
    margin-top: 24px;
    padding-bottom: 24px;
    background-color: #fff;

    padding-top: 24px;
    border-top: 1px solid rgba(99, 99, 99, 0.21);

    &__notify {
      display: flex;
      align-items: center;
    }

    &__notify-title {
      margin-left: 8px;
    }
  }
}
