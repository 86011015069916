.verifying-btn {
  white-space: nowrap;
  background-color: #221cb6;
  color: #ffffff;

  &:hover {
    background-color: #221cb6;
    color: #ffffff;

    opacity: 0.9;
  }

  &__exclamation-icon {
    width: 15px;
    height: 15px;
    margin-left: 8px;
  }

  &__stop-icon {
    width: 16px;
    height: 16px;

    border: 2px solid #ffffff;
    border-radius: 3px;

    margin-right: 8px;
  }
}
