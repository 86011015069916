@import "src/vendors/colors";
@import "src/vendors/mixins";

.onboarding-members {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__content {
    width: 600px;
    margin-top: 45px;
  }

  &__field-label {
    @include mulish-text;
    font-weight: 600;
    font-size: 14px;
    color: $grey-text-secondary;
    margin-bottom: 8px;
  }

  &__role-selection-label {
    @include mulish-text;
    font-weight: 600;
    font-size: 14px;
    color: $grey-text-secondary;
    margin-bottom: 15px;
  }

  &__role-selection {
    margin-top: 22px;
  }

  &__role-name {
    @include mulish-text;
    font-weight: 600;
    font-size: 14px;
    color: #1B1E22;
    width: 85px;
  }

  &__role-desc {
    @include mulish-text;
    font-weight: 600;
    font-size: 14px;
    color: $grey-text-secondary;
  }

  &__invite-btn {
    width: 600px;
    margin-top: 30px;
  }

  &__skip-btn {
    margin-top: 15px;
    color: #96989F;

    &:hover {
      color: #000;
    }
  }
}