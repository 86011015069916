@import "src/vendors/mixins";
@import "src/vendors/colors";

.player-bar {
  flex: 1;
  position: relative;

  &--inline {
    display: flex;
    align-items: center;

    .player-bar {
      &__progress {
        flex: 1;
        margin-bottom: 0;
      }

      &__time-start {
        margin-right: 5px;
      }

      &__time-end {
        margin-left: 5px;
      }
    }
  }

  &__progress {
    height: 6px;
    border-radius: 3px;
    position: relative;
    margin-bottom: 5px;
  }

  &__progress-knob {
    position: absolute;
    display: inline-block;
    width: 10px;
    height: 10px;
    top: -2px;
    background-color: #ABA8E6;
    border-radius: 100%;
  }

  &__time {
    display: flex;
    justify-content: space-between;
  }

  &__time-start {
    @include mulish-text;
    font-size: 12px;
    color: #050505;
  }

  &__time-end {
    @include mulish-text;
    font-size: 12px;
    color: #8F9199;
  }
}