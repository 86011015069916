@import 'src/vendors/colors';
@import 'src/vendors/mixins';

.onboarding-onboarding {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 94px;

  &__continue-btn-container {
    width: 100%;

    min-width: 320px;
    max-width: 1000px;
    padding: 7px 98px 0;

    text-align: center;
  }

  &__continue-btn {
    width: 100%;
    height: 48px;
  }

  &__skip-btn {
    margin-top: 15px;
    color: #96989f;

    &:hover {
      color: #000;
    }
  }
}
