@import "src/vendors/mixins";
@import "src/vendors/colors";

.relationships-move-modal-wrapper {
  width: fit-content;
}

.relationships-move-modal {
  width: 500px;

  &__header {
    @include modal-header;
  }

  &__campaign-title {
    @include mulish-text;
    font-size: 14px;
    line-height: 18px;
    margin: 0 12px;
    height: 28px;
    display: flex;
    align-items: center;
    padding: 0 5px;
    background: $light-main-color;
    color: $primary-main-color;
    border-radius: 8.5px;
  }

  &__selector {
    padding: 24px;
  }

  &__footer {
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
  }
}
