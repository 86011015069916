@import '../../vendors/mixins';
@import '../../vendors/colors';

.campaigns-empty-message {
  @include mulish-text;
  font-size: 24px;
  line-height: 130%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  flex: 1;
  margin-bottom: 10%;

  &__message {
    color: $secondary-black-color;
    margin-top: 3rem;
  }
  &__sub-message {
    color: $grey-black-color;
    font-size: 16px;
    margin-top: .2rem;
  }

  &__link {
    margin-top: 1.5rem;
  }
}