@import "src/vendors/colors";
@import "src/vendors/mixins";

.people-header-more-actions-tooltip {
  padding: 10px 0;
  width: 250px;

  &__action {
    @include mulish-text;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
    padding: 6px 20px;
    display: flex;
    align-items: center;
    position: relative;

    svg {
      margin-right: 9px;
    }

    &:hover {
      background-color: #181818;
    }

    .input {
      color: #fff;
    }
  }


  &__select {
    width: 100%;
  }

  &__company-title {
    padding: 8px;
    margin: 0;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.people-actions-menu {
  &__header {
    display: flex;
    justify-content: center;
  }

  &__button {
    @include mulish-text;
    font-size: 14px;
    color: $secondary-light-blue-color;
    display: flex;
    justify-content: center;
  }
}

.people-header-more-actions-react-select {
  &__menu {
//     background: transparent;
//     border-radius: 30px;
  }

  &__menu-list {
//     border-radius: 30px;
  }

  &__option {
    &:hover {
      background-color: #2E2E2E;
    }
  }
}