@import "src/vendors/mixins";

.settings-workspaces-table {
  margin-top: 1rem;
  &__wrapper {
    height: 75vh;
  }

  &__row {
    height: 60px;
    border-bottom: 1px solid $primary-grey-action-color;

    &--with-hover:hover {
      cursor: pointer;
      background-color: #eef3ff;
    }
  }

  &__cell {
    &--title {
      display: flex;
      align-items: center;
    }

    &--title-details {
      display: flex;
      flex-direction: column;
      max-width: 250px;
      margin-left: 13px;
    }
  }

  &__workspace-title {
    margin-top: 1px;
    margin-bottom: 2px;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #1b1e22;
  }

  .table-th:first-child {
    width: 2rem;
  }

  .table-td {
    @include mulish-text;
    color: #8D8F92;
    text-align: left;
    font-size: 14px;

    .actions-menu .react-tooltip:not(.react-tooltip--position-fixed) {
      left: -120px !important;
    }
  }

  .table-td:nth-child(2) {
    color: #1E1E20;
    font-weight: 600;
    width: 200px;
    max-width: 200px;
  }

  .table-td:last-child {
    width: 0;
  }

  .checkbox {
    margin-bottom: 0;
    display: none;
  }

  &__workspace-title-wrapper {
    display: flex;
    align-items: center;

  }

  &__workspace-image {
    width: 34px;
    height: 34px;
    border-radius: 50%;
  }

  &__workspace-title {
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .actions-tooltip-content svg {
    width: 1rem;
    height: 1rem;
  }
}
